import { Dimensions, StyleSheet } from 'react-native';
import { colors } from './color';
// import { getDMSansLightFont,  getDMSansSemiBoldFont } from './utils';
import { getDMSansRegularFont,getDMSansSemiBoldFont } from './responsive';

export const externalStyles = StyleSheet.create({

    //common
    container: {
        flex: 1, backgroundColor: colors.white
    },
    textInputTitle: {
        fontFamily: getDMSansRegularFont(), fontSize: 12
    },
    // loginscreen
    loginTextInput: {
        height: 47,
        backgroundColor: colors.white,
        borderWidth: 1,
        borderColor: "#9A9A9A",
        borderRadius: 5,
        fontSize: 12,
        fontWeight: "500",
        color: colors.textInputTextColor,
        lineHeight: 15,
        marginTop: 5,
        marginBottom: 10,
        paddingHorizontal: 18,
        fontFamily: getDMSansRegularFont()
    },
    loginBtn:
    {
        borderRadius: 10,
        height: 47,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.buttonColor,
        marginTop: 22
    },
    loginText: {
        color: colors.white,
        fontSize: 14,
        fontWeight: "600",
        // lineHeight: 29,
        textAlign: 'center',
        // bottom: 2,
        textTransform: "uppercase",
        fontFamily: getDMSansSemiBoldFont()
    },
    line: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: 20
    },
    lineText: {
        fontFamily: getDMSansSemiBoldFont(),
        fontSize: 11,
        lineHeight: 14,
        textAlign: 'center',
        color: "rgba(0, 0, 0, 0.5)",
        marginHorizontal: 13.3,
    },
    divider: {
        height: 1.07,
        width: 109.43,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        marginTop: 3
    },
    bottomContainer: {
        flexDirection: "row",
        alignSelf: "center",
        marginTop: 30,
    },
    colorBlackText: {
        fontWeight: "400",
        fontSize: 14,
        lineHeight: 18,
        color: "rgba(0, 0, 0, 0.5)",
        fontFamily: getDMSansRegularFont()
    },
    createAccountText: {
        fontWeight: "400",
        fontSize: 14,
        lineHeight: 18,
        color: colors.buttonColor,
        textDecorationLine: "underline",
        fontFamily: getDMSansRegularFont()
    },
    forgotPasswordContainer: {
        alignSelf: "flex-end",
        marginTop: -5
    },
    forgotPasswordText: {
        color: colors.buttonColor,
        fontWeight: "400",
        fontSize: 11,
        lineHeight: 14,
        fontFamily: getDMSansRegularFont()
    },

});