import React, { useCallback, useMemo, useState } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, Image, FlatList, ActivityIndicator, TouchableHighlight, Dimensions } from 'react-native';
import { colors } from '../common/color';
import { CustomConsole, CustomDigits, CustomProgressBar, FooterComponent, HeaderComponent, getDMSansBoldFont, getDMSansMediumFont, getDMSansRegularFont, getDMSansSemiBoldFont, scrollToTop, validateEmail } from '../common/utils';
import { APPLYCOUPANS, CARTLIST, CONFIRMORDER, COUPANS, DELETECART, EMPTYCART, LOGIN, PAYMENTMETHODS, PRODUCTDETAILS, PRODUCTREVIEWS, RAZORPAYORDER, RELATEDPRODUCTS, SHIPPINGLIST, SUBSCRIBELIST, SUBSCRIPTIONLIST, WALLETBALANCE } from '../common/webUtils';
import { useIsFocused } from '@react-navigation/native';
import { EMAIL, FIRST_NAME, LAST_NAME, PHONE, USER_ID, getSession } from '../common/LocalStorage';
import { PaperProvider, RadioButton } from 'react-native-paper';
import { Modal } from 'react-native';
// import RazorpayCheckout from 'react-native-razorpay';
import useRazorpay from "react-razorpay";
import { styles, ids } from '../common/responsive.js';

var paymentMethodStr = "";

function CartScreen({ navigation, route }) {

    const [Razorpay] = useRazorpay();

    const WIGGLE_ROOM = 128;

    const offset = useMemo(() => {
        return {
            top: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2,
            left: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2
        };
    }, []);

    const [loading, setLoading] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [coupon, setCoupon] = useState('');
    const [deliveryInstruction, setDeliveryInstruction] = useState('');
    const [address_id, setAddressId] = useState('');
    const [cartList, setCartList] = useState([]);
    const [couponList, setCouponList] = useState([]);
    const [shippingAddressList, setShippingAddressList] = useState([]);
    const [cartLength, setcartLength] = useState('0');
    const [couponId, setCouponId] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [couponDiscount, setCouponDiscount] = useState(0);
    const [visible, setVisible] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const [walletBalance, setWalletBalance] = useState('0');
    const [alertShow, setAlertShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [isSubscriptionOrdersAvailable, setIsSubscriptionOrdersAvailable] = useState(false);
    const [isCartSubscriptionAvailable, setIsCartSubscriptionAvailable] = useState(false);
    const [shippingAddress, setShippingAddress] = useState('');

    const [isAddressModalVisible, setIsAddressModalVisible] = useState(false);
    const [changeAddressCheck, setChangeAddressCheck] = useState(false);
    const [shippingAddressId, setShippingAddressId] = useState('');

    const [cartHasSubscription, setCartHasSubscription] = useState(false);
    const [validAddress, setValidAddress] = useState('');

    // address modal hide/show
    const showAddressModal = (text) => {
        setIsAddressModalVisible(true);
    };
    const hideAddressModal = () => setIsAddressModalVisible(false);

    // modal hide/show
    const showAlert = (text) => {
        setAlertShow(true);
        setAlertMsg(text)
    };
    const hideAlert = () => setAlertShow(false);

    // modal hide/show
    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
        paymentMethodStr = ""
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            cartListApi(user_id);
            couponListApi();
            walletBalanceApi();
            orderListApi(user_id);
        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async (user_id) => {
        try {
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST + "\n" + raw);

            setLoading(true);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);

                    if (json.data.cart.length != 0) {
                        const isCartHasSubscription = json.data.cart.find(cart => cart.is_subscription == "1");
                        CustomConsole(isCartHasSubscription)
                        if (isCartHasSubscription != undefined) {
                            setCartHasSubscription(true)
                        } else {
                            setCartHasSubscription(false)
                        }
                    }

                    cartList.length = 0;
                    for (let i = 0; i < json.data.cart.length; i++) {

                        let optionArr = [];
                        if (json.data.cart[i].option.length != 0) {
                            for (let j = 0; j < json.data.cart[i].option.length; j++) {
                                optionArr.push({
                                    product_option_id: json.data.cart[i].option[j].product_option_id,
                                    product_option_value_id: json.data.cart[i].option[j].product_option_value_id,
                                    option_id: json.data.cart[i].option[j].option_id,
                                    option_value_id: json.data.cart[i].option[j].option_value_id,
                                    name: json.data.cart[i].option[j].name,
                                    value: json.data.cart[i].option[j].value,
                                    type: json.data.cart[i].option[j].type,
                                    quantity: json.data.cart[i].option[j].quantity,
                                    subtract: json.data.cart[i].option[j].subtract,
                                    price: json.data.cart[i].option[j].price,
                                    price_prefix: json.data.cart[i].option[j].price_prefix,
                                    points: json.data.cart[i].option[j].points,
                                    points_prefix: json.data.cart[i].option[j].points_prefix,
                                    weight: json.data.cart[i].option[j].weight,
                                    weight_prefix: json.data.cart[i].option[j].weight_prefix,
                                });
                            }
                        } else {
                            optionArr = [];
                        }

                        cartList.push({
                            index: i,
                            cart_id: json.data.cart[i].cart_id,
                            product_id: json.data.cart[i].product_id,
                            name: json.data.cart[i].name,
                            model: json.data.cart[i].model,
                            shipping: json.data.cart[i].shipping,
                            image: json.data.cart[i].image,
                            subscription: json.data.cart[i].subscription,
                            download: json.data.cart[i].download,
                            quantity: json.data.cart[i].quantity,
                            minimum: json.data.cart[i].minimum,
                            subtract: json.data.cart[i].subtract,
                            stock: json.data.cart[i].stock,
                            price: json.data.cart[i].price,
                            total: json.data.cart[i].total,
                            reward: json.data.cart[i].reward,
                            points: json.data.cart[i].points,
                            tax_class_id: json.data.cart[i].tax_class_id,
                            weight: json.data.cart[i].weight,
                            weight_class_id: json.data.cart[i].weight_class_id,
                            length: json.data.cart[i].length,
                            width: json.data.cart[i].width,
                            height: json.data.cart[i].height,
                            length_class_id: json.data.cart[i].length_class_id,
                            recurring: json.data.cart[i].recurring,
                            is_subscription: json.data.cart[i].is_subscription,
                            option: optionArr,

                        });
                    }

                    setCartList(cartList);
                    shippingAddressListApi(user_id);
                    // setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Cart Api Error=>" + error);
                });

        } catch (e) {
            CustomConsole("Cart Api  Exception=>" + e + "");
        }
    }

    // order list api
    const orderListApi = async (userId) => {
        try {

            var raw = JSON.stringify({
                "customer_id": userId
            });
            var requestOptions = {
                method: 'POST',
                body: raw,
                redirect: 'follow'
            };

            CustomConsole(SUBSCRIPTIONLIST + "\n" + raw);

            fetch(SUBSCRIPTIONLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    for (let i = 0; i < json.data.length; i++) {
                        if (json.data[i].order_status == "Pending") {
                            setIsSubscriptionOrdersAvailable(true);
                            break;
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Order Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Order Exception=>" + e + "");
        }
    }

    // coupon list api
    const couponListApi = async (user_id) => {
        try {

            var requestOptions = {
                method: 'GET'
            };

            CustomConsole(COUPANS);

            // setLoading(true);
            fetch(COUPANS, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    couponList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {

                        couponList.push({
                            index: i,
                            coupon_id: json.data[i].coupon_id,
                            name: json.data[i].name,
                            code: json.data[i].code,
                            type: json.data[i].type,
                            discount: json.data[i].discount,
                            logged: json.data[i].logged,
                            shipping: json.data[i].shipping,
                            total: json.data[i].total,
                            date_start: json.data[i].date_start,
                            date_end: json.data[i].date_end,
                            uses_total: json.data[i].uses_total,
                            uses_customer: json.data[i].uses_customer,
                            status: json.data[i].status,
                            date_added: json.data[i].date_added,

                        });
                    }

                    setCouponList(couponList);
                    // setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Coupon Api Error=>" + error);
                });

        } catch (e) {
            CustomConsole("Coupon list Exception=>" + e + "");
        }
    }

    // shipping address list api
    const shippingAddressListApi = async (user_id) => {
        try {
            // const user_id = await getSession(USER_ID);

            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(SHIPPINGLIST + "&customer_id=" + user_id);

            // setLoading(true);
            fetch(SHIPPINGLIST + "&customer_id=" + user_id, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    if (json.data.length != 0) {
                        setAddressId(json.data[0].address_id);
                        setShippingAddressId(json.data[0].address_id);
                        setValidAddress(json.data[0].is_valid);
                        setShippingAddress(json.data[0].address_1 + ", \n" + json.data[0].address_2 + "\n" + json.data[0].city + ", " + json.data[0].zone + "\n" + json.data[0].country + "-" + json.data[0].postcode);
                        paymentMethodsApi(json.data[0].address_id, json.data[0].postcode, user_id);
                    } else {
                        setAddressId('');
                        setShippingAddressId('');
                        setShippingAddress('');
                        setValidAddress('');
                    }

                    shippingAddressList.length = 0;

                    for (let i = 0; i < json.data.length; i++) {
                        shippingAddressList.push({
                            index: i,
                            address_id: json.data[i].address_id,
                            firstname: json.data[i].firstname,
                            lastname: json.data[i].lastname,
                            company: json.data[i].company,
                            block: json.data[i].block,
                            apartment: json.data[i].apartment,
                            address_1: json.data[i].address_1,
                            address_2: json.data[i].address_2,
                            postcode: json.data[i].postcode,
                            city: json.data[i].city,
                            zone_id: json.data[i].zone_id,
                            zone: json.data[i].zone,
                            zone_code: json.data[i].zone_code,
                            country_id: json.data[i].country_id,
                            address_type: json.data[i].address_type,
                            country: json.data[i].country,
                            iso_code_2: json.data[i].iso_code_2,
                            iso_code_3: json.data[i].iso_code_3,
                            address_format: json.data[i].address_format,
                            is_valid: json.data[i].is_valid,
                        });
                    }

                    setShippingAddressList(shippingAddressList);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Shipping Address Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Shipping Address Exception=>" + e + "");
        }
    }

    // payment methods
    const paymentMethodsApi = async (addressId, postcode, user_id) => {
        try {

            var raw = JSON.stringify({
                "address_id": addressId,
                "comment": "",
                "postcode": postcode,
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(PAYMENTMETHODS + "\n" + raw);

            setLoading(true);
            fetch(PAYMENTMETHODS, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);
                    if (json.success == "1") {
                        const arr = Array.from(Object.keys(json.data.payment_methods), k => [`${k}`, json.data.payment_methods[k]]);
                        CustomConsole(arr[0][1].code);
                        paymentMethodStr = arr[0][1].code;
                        setSelectedPaymentMethod(arr[0][1].code);
                        setPaymentMethods([...arr]);
                        CustomConsole(paymentMethods);
                    } else {
                        if (json.error.length > 0) {
                            showAlert(json.error[0]);
                        }
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Payment Methods Api Error=>" + error);
                });
        } catch (error) {
            setLoading(true);
        }
    }

    // wallet api
    const walletBalanceApi = async () => {
        try {
            const user_id = await getSession(USER_ID);

            var requestOptions = {
                method: 'GET',
            };

            CustomConsole(WALLETBALANCE + user_id);

            // setLoading(true);
            fetch(WALLETBALANCE + user_id, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setWalletBalance(json.data.balance);
                    // setLoading(false);
                })
                .catch((error) => {
                    // setLoading(false);
                    CustomConsole("Wallet Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Wallet Exception=>" + e + "");
        }
    }

    // cart price total
    const cartTotal = () => {
        const total = cartList.reduce((partialSum, a) => partialSum + a.total, 0);
        return total;
    }

    // all price total
    const overAllTotal = (couponDiscount) => {
        CustomConsole("couponDiscount===> " + couponDiscount);
        const total = cartList.reduce((partialSum, a) => partialSum + a.total, 0);
        return total - couponDiscount;
    }

    // apply coupon
    const applyCoupon = async (code, userId) => {
        try {

            var raw = JSON.stringify({
                "coupon": code,
                "customer_id": userId
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(APPLYCOUPANS + "\n" + raw);

            setLoading(true);
            fetch(APPLYCOUPANS, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);
                    if (json.success == "1") {

                        setCouponId(json.data.coupon_id);
                        setCouponCode(json.data.code);
                        // json.data.type
                        if (json.data.type == "P") {
                            const amount = cartTotal();
                            var discount = (parseFloat(amount) * parseFloat(json.data.discount)) / 100;
                            setCouponDiscount(discount);

                        } else {
                            setCouponDiscount(parseFloat(json.data.discount));
                        }
                        setCoupon("");
                        hideModal();

                    } else {
                        if (json.error.length > 0) {
                            showAlert(json.error[0]);
                            hideModal();
                        }
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Apply coupon Api Error=>" + error);
                });
        } catch (error) {
            setLoading(true);
        }
    }

    // razorpay api
    const handlePayment = useCallback(async (couponDiscount, address_id) => {
        try {

            CustomConsole("Address id==> " + address_id);

            // var raw = JSON.stringify({
            //     "amount": (overAllTotal(couponDiscount) * 100).toFixed(),
            //     "currency": "INR",
            //     "receipt": "",
            //     "notes": {
            //         "notes_key_1": "",
            //         "notes_key_2": ""
            //     }
            // });

            const user_id = await getSession(USER_ID);

            var myHeaders = new Headers();
            // myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Cookie", "OCSESSID=0ede9b5d0a7524e248af7936ef; currency=INR");

            var raw = JSON.stringify({
                "amount": (overAllTotal(couponDiscount) * 100).toFixed(),
                "currency": "INR",
                "receipt": "",
                "notes": {
                    "notes_key_1": "",
                    "notes_key_2": ""
                },
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            CustomConsole(RAZORPAYORDER + "\n" + raw);

            // setLoading(true);
            fetch(RAZORPAYORDER, requestOptions)
                .then(response => response.json())
                .then(async (json) => {
                    CustomConsole(json);

                    if (json.success == 1 && json.data.id !== undefined) {

                        const first_name = await getSession(FIRST_NAME);
                        const last_name = await getSession(LAST_NAME);
                        const phone = await getSession(PHONE);
                        const email = await getSession(EMAIL);

                        const options: RazorpayOptions = {
                            key: "rzp_test_VXo0HvGmTrrLuR",
                            amount: (overAllTotal(couponDiscount) * 100).toFixed(),
                            currency: "INR",
                            name: "Acme Corp",
                            description: "Test Transaction",
                            image: "https://example.com/your_logo",
                            order_id: json.data.id,
                            handler: (res) => {
                                CustomConsole(res);

                                // razorpay_order_id
                                // razorpay_payment_id
                                // razorpay_signature

                                orderConfirm(res.razorpay_payment_id, address_id, selectedPaymentMethod, couponCode, deliveryInstruction);
                            },
                            prefill: {
                                name: first_name + " " + last_name,
                                email: email,
                                contact: phone,
                            },
                            notes: {
                                address: "Razorpay Corporate Office",
                            },
                            theme: {
                                color: "#3399cc",
                            },
                        };

                        const rzpay = new Razorpay(options);
                        rzpay.open();


                    } else {
                        setLoading(false);
                        showAlert(json.error[0]);
                    }

                    // setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Razorpay Api Error=>" + error);
                });


        } catch (error) {
            setLoading(false);
            CustomConsole("Razorpay api error=> ")
        }

    }, [Razorpay]);

    // order confirm
    const orderConfirm = async (payment_success_id, address_id, test, couponCode, deliveryInstruction) => {
        try {

            CustomConsole("In order confirm==> " + address_id);

            const user_id = await getSession(USER_ID);

            // var requestOptionsAdd = {
            //     method: 'GET',
            //     redirect: 'follow'
            // };

            // CustomConsole(SHIPPINGLIST + "&customer_id=" + user_id);

            // setLoading(true);
            // fetch(SHIPPINGLIST + "&customer_id=" + user_id, requestOptionsAdd)
            //     .then(responseAdd => responseAdd.json())
            //     .then((jsonAdd) => {
            //         CustomConsole(jsonAdd);

            //         if (jsonAdd.data.length != 0) {
            //             setAddressId(jsonAdd.data[0].address_id);

            if (paymentMethodStr == "wallet_system_payment") {
                var raw = JSON.stringify({
                    "customer_id": user_id,
                    "currency": "INR",
                    "payment_success_id": payment_success_id,
                    "address_id": address_id,
                    "billing_address_id": address_id,
                    "shipping_method": "free.free",
                    "payment_method": "wallet",
                    "coupon": couponCode,
                    "delivery_instructions": deliveryInstruction,
                    "wallet": "2"
                });
            } else {
                var raw = JSON.stringify({
                    "customer_id": user_id,
                    "currency": "INR",
                    "payment_success_id": payment_success_id,
                    "address_id": address_id,
                    "billing_address_id": address_id,
                    "shipping_method": "free.free",
                    "payment_method": paymentMethodStr,
                    "coupon": couponCode,
                    "delivery_instructions": deliveryInstruction,

                });
            }

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CONFIRMORDER + "\n" + raw);

            setConfirmLoading(true);
            fetch(CONFIRMORDER, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);
                    if (json.success == "1") {
                        setConfirmLoading(false);
                        setLoading(false);
                        // alert("Order placed successfully");
                        navigation.navigate("ThankyouScreen");
                        navigation.reset({
                            index: 0,
                            routes: [{ name: 'ThankyouScreen' }],
                        });
                    } else {
                        // if (json.error.length > 0) {
                        showAlert(json.error);
                        setLoading(false);
                        setConfirmLoading(false);
                        // }
                    }
                })
                .catch((error) => {
                    setConfirmLoading(false);
                    setLoading(false);
                    CustomConsole("Order confirm Api Error=>" + error);
                });
            // } else {
            //     setConfirmLoading(false);
            //     setLoading(false);
            //     showAlert("Address is not found!");
            //     setAddressId('');
            // }
            // })
            // .catch((error) => {
            //     setConfirmLoading(false);
            //     setLoading(false);
            //     CustomConsole("Shipping Address Api Error=>" + error);
            // });
        } catch (error) {
            setConfirmLoading(false);
            setLoading(false);
        }
    }

    // empty cart
    const emptyCart = async () => {
        try {

            var raw = JSON.stringify({
                "customer_id": userId
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(EMPTYCART + "\n" + raw);

            setLoading(true);
            fetch(EMPTYCART, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);
                    if (json.success == "1") {
                        // alert("Cart empty successfully.");
                        cartListApi(userId);
                    } else {
                        if (json.error.length > 0) {
                            showAlert(json.error[0]);
                        }
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Order confirm Api Error=>" + error);
                });
        } catch (error) {
            setLoading(true);
        }
    }

    // coupon item
    const couponItem = (item, index) => {
        return (
            <View style={styles.couponMainView} dataSet={{ media: ids.couponMainView }}>
                <View>
                    <Text style={styles.couponHeadingText} dataSet={{ media: ids.couponHeadingText }}>{item.code} </Text>
                    <Text style={styles.couponText} dataSet={{ media: ids.couponText }}>{item.name} </Text>
                    <Text style={styles.couponText} dataSet={{ media: ids.couponText }}>{CustomDigits(item.discount)} {item.type == "P" ? "% off" : "Flat off"}</Text>
                    <Text style={styles.couponText} dataSet={{ media: ids.couponText }}>Expire on: {item.date_end}</Text>
                </View>
                <View>
                    {couponId === item.coupon_id ?
                        <Pressable onPress={() => {
                            setCouponId('');
                            setCouponCode('');
                            setCouponDiscount(0);
                            hideModal();
                        }}
                            style={styles.couponButton}>
                            <Text style={styles.couponButtonText}>Remove</Text>
                        </Pressable>
                        : <Pressable onPress={() => {
                            applyCoupon(item.code, userId);
                        }}
                            style={styles.couponButton}>
                            <Text style={styles.couponButtonText}>Apply</Text>
                        </Pressable>}

                </View>
            </View>
        );
    }

    // delete cart api
    const deleteCart = async (cart_id) => {
        try {

            var raw = JSON.stringify({
                "cart_id": cart_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(DELETECART + "\n" + raw);

            setLoading(true);
            fetch(DELETECART, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);
                    if (json.success == "1") {
                        // alert("Cart deleted successfully.");
                        cartListApi(userId);
                    } else {
                        if (json.error.length > 0) {
                            showAlert(json.error[0]);
                        }
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Order confirm Api Error=>" + error);
                });
        } catch (error) {
            setLoading(true);
        }
    }

    // shipping address item
    const shippingAddressItem = (item, index) => {
        return (
            <View style={[styles.shippingAddressView, {
                borderColor: cartHasSubscription ?
                    item.is_valid != 1 ? "red" : "green" : colors.borderColor
            }]} dataSet={{ media: ids.shippingAddressView }}>
                {/* {cartHasSubscription ?
                    item.is_valid == 1 ?
                        <View>
                            <RadioButton
                                value={shippingAddressId}
                                status={shippingAddressId === item.address_id ? 'checked' : 'unchecked'}
                                onPress={() => {
                                    setShippingAddressId(item.address_id);
                                    setAddressId(item.address_id);
                                    setShippingAddress(item.address_1 + ", \n" + item.address_2 + "\n" + item.city + ", " + item.zone + "\n" + item.country + "-" + item.postcode)
                                    hideAddressModal()
                                }}
                                color={colors.buttonColor}
                            />
                        </View>
                        : null
                    :
                    <View>
                        <RadioButton
                            value={shippingAddressId}
                            status={shippingAddressId === item.address_id ? 'checked' : 'unchecked'}
                            onPress={() => {
                                setShippingAddressId(item.address_id);
                                setAddressId(item.address_id);
                                setShippingAddress(item.address_1 + ", \n" + item.address_2 + "\n" + item.city + ", " + item.zone + "\n" + item.country + "-" + item.postcode)
                                hideAddressModal()
                            }}
                            color={colors.buttonColor}
                        />
                    </View>
                } */}

                <View>
                    <RadioButton
                        value={shippingAddressId}
                        status={shippingAddressId === item.address_id ? 'checked' : 'unchecked'}
                        onPress={() => {
                            setShippingAddressId(item.address_id);
                            setAddressId(item.address_id);
                            setValidAddress(item.is_valid);
                            setShippingAddress(item.address_1 + ", \n" + item.address_2 + "\n" + item.city + ", " + item.zone + "\n" + item.country + "-" + item.postcode)
                            hideAddressModal()
                        }}
                        disabled={cartHasSubscription ?
                            item.is_valid != 1 ? true : false : false}
                        color={colors.buttonColor}
                    />
                </View>

                <View style={styles.addressDetailsSubView}>
                    <Text style={styles.addressType}>{item.address_type} <Text style={styles.availableAddressLineText}>{cartHasSubscription ? item.is_valid != 1 ? "(Unavailable)" : "(Available)" : ""}</Text></Text>
                    <Text style={styles.addressLineText}>{item.firstname}  {item.lastname}</Text>
                    <Text style={styles.addressLineText}>{item.address_1}</Text>
                    <Text style={styles.addressLineText}>{item.address_2}, {item.postcode}</Text>
                    <Text style={styles.addressLineText}>{item.city}, {item.zone}, {item.country}</Text>
                </View>
            </View >
        );
    }

    return (
        <PaperProvider>
            <View>
                <StatusBar barStyle="dark-content" />
                <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                    <View style={{ backgroundColor: colors.white }}>

                        {/* progress view */}
                        {loading ? CustomProgressBar(loading) : null}
                        {/* end of progress view */}

                        {/* Header view */}
                        {HeaderComponent(cartLength, navigation, userId)}
                        {/* end of Header view */}

                        {/* cart screen main view */}
                        <View style={styles.cartMainView} dataSet={{ media: ids.cartMainView }}>

                            {!confirmLoading ?

                                cartList.length ?
                                    <View style={styles.cartSubView} dataSet={{ media: ids.cartSubView }}>

                                        {/* cart product list view */}
                                        <View style={styles.cartContainer} dataSet={{ media: ids.cartContainer }}>
                                            <View style={styles.cartHeader} dataSet={{ media: ids.cartHeader }}>
                                                <Text style={styles.cartHeaderText}>{"Cart(" + cartList.length + " items)"}</Text>
                                                <Pressable onPress={emptyCart}
                                                    style={styles.emptyCartButton}>
                                                    <Text style={styles.emptyCartButtonText}>Empty Cart</Text>
                                                </Pressable>
                                            </View>

                                            <FlatList
                                                style={styles.cartItemList}
                                                dataSet={{ media: ids.cartItemList }}
                                                showsVerticalScrollIndicator={false}
                                                data={cartList}
                                                numColumns={(Dimensions.get("window").width > 767) ? 2 : 1}
                                                keyExtractor={(item, id) => String(id)}
                                                ItemSeparatorComponent={<View style={styles.cartItemSeparator} />}
                                                renderItem={({ item, index }) => {
                                                    return (
                                                        <View style={styles.cartItem} dataSet={{ media: ids.cartItem }}>

                                                            {/* cart top section */}
                                                            <View style={styles.cartItemContent} dataSet={{ media: ids.cartItemContent }}>
                                                                <Image source={{ uri: item.image }} style={styles.cartItemImage} />
                                                                <View style={styles.cartItemDetails}>
                                                                    <Text style={styles.cartItemName} numberOfLines={2}>{item.name} <Text style={styles.cartItemSize}>{"(" + (item.option.length !== 0 ? item.option[0].value : "") + ")"}</Text></Text>

                                                                    {item.subscription.subscription_type == undefined ?
                                                                        // buy once
                                                                        <Text style={styles.cartItemOption}>{CustomDigits(item.price)} x {item.quantity}</Text>
                                                                        : item.subscription.subscription_type == "daily" || item.subscription.subscription_type == "trial"
                                                                            ?
                                                                            // daily subscription
                                                                            <Text style={styles.cartItemOption}>{CustomDigits(item.price)} x {item.subscription.subscription_value} | <Text style={styles.cartItemSubscriptionName}>{item.subscription.subscription_type}</Text> </Text>
                                                                            : item.subscription.subscription_type == "alternate"
                                                                                ?
                                                                                // alternate subscription
                                                                                <>
                                                                                    <Text style={styles.cartItemOption}><Text style={styles.cartItemSubscriptionDay}>Day 1</Text> | {CustomDigits(item.price)} x {item.subscription.subscription_value}</Text>
                                                                                    <Text style={styles.cartItemOption}><Text style={styles.cartItemSubscriptionDay}>Day 2</Text> | {CustomDigits(item.price)} x 0</Text>
                                                                                </>
                                                                                :
                                                                                // varing subscription
                                                                                <>
                                                                                    <Text style={styles.cartItemOption}><Text style={styles.cartItemSubscriptionDay}>Day 1</Text> | {CustomDigits(item.price)} x {item.subscription.subscription_value.split(",")[0]}</Text>
                                                                                    <Text style={styles.cartItemOption}><Text style={styles.cartItemSubscriptionDay}>Day 2</Text> | {CustomDigits(item.price)} x {item.subscription.subscription_value.split(",")[1]}</Text>
                                                                                </>
                                                                    }


                                                                    {/* <Text style={styles.cartItemOption}>{item.option.length !== 0 ? item.option[0].value : ""} x  {item.subscription.subscription_value != undefined ? item.subscription.subscription_value + " | " : item.quantity} {item.subscription.subscription_type != undefined ? <Text style={styles.cartItemSubscriptionName}>{item.subscription.subscription_type}</Text> : null}</Text> */}

                                                                    <View style={styles.cartItemTotalPriceAndQty}>
                                                                        <Text style={styles.cartItemTotal}>Total: {CustomDigits(item.total)}</Text>
                                                                        {item.subscription.subscription_value != undefined ? <Text style={styles.cartItemTotal}>Total Qty: {item.quantity}</Text> : null}
                                                                    </View>



                                                                </View>
                                                            </View>
                                                            {/* end of cart top section */}

                                                            {/* cart bottom section */}
                                                            <View style={styles.cartItemBottomSection} dataSet={{ media: ids.cartItemBottomSection }}>
                                                                <Pressable style={styles.cartItemModifyView}
                                                                    onPress={() => {
                                                                        navigation.navigate("CheckoutScreen", {
                                                                            // paramProductName: productName,
                                                                            paramProductId: item.product_id,
                                                                            paramProductSize: item.option[0].value,
                                                                            paramProductPrice: "₹" + (parseFloat(item.price)).toFixed(),
                                                                            // paramProductImage: selectedImage,
                                                                            paramProductOptionId: item.option[0].product_option_id,
                                                                            paramProductOptionValueId: item.option[0].product_option_value_id,

                                                                            paramPurchaseType: item.subscription.subscription_type == undefined ? 1 : item.subscription.subscription_type == "trial" ? 3 : 2,
                                                                            paramSubscriptionPlan: item.subscription.subscription_type == undefined ? "" : item.subscription.subscription_type,
                                                                            paramSubscriptionPlanId: item.subscription.subscription_plan_id == undefined ? "" : item.subscription.subscription_plan_id,
                                                                            paramQty: item.subscription.subscription_value == undefined ? item.quantity : item.subscription.subscription_type == "varying" ? item.subscription.subscription_value.split(",")[0] : item.subscription.subscription_value,
                                                                            paramQty2: item.subscription.subscription_value == undefined ? "" : item.subscription.subscription_type == "varying" ? item.subscription.subscription_value.split(",")[1] : "",
                                                                            paramStartDate: item.subscription.start_date == undefined ? "" : item.subscription.start_date,
                                                                            paramEndDate: item.subscription.end_date == undefined ? "" : item.subscription.end_date,
                                                                            paramFrom: "modify",
                                                                            paramCartId: item.cart_id,
                                                                        });
                                                                    }}>
                                                                    <Text style={styles.cartItemModifyText}>Modify</Text>
                                                                </Pressable>
                                                                <Pressable style={styles.cartItemDeleteView}
                                                                    onPress={() => deleteCart(item.cart_id)}>
                                                                    <Text style={styles.cartItemDeleteText}>Delete</Text>
                                                                </Pressable>
                                                            </View>
                                                            {/* end of cart bottom section */}
                                                        </View>
                                                    );
                                                }} />
                                        </View>
                                        {/* end of cart product list view */}

                                        {/* coupon & order confirm view */}
                                        <View style={styles.couponContainer} dataSet={{ media: ids.couponContainer }}>

                                            {/* coupon view */}
                                            <View style={styles.couponViewContainer}>
                                                <Pressable onPress={showModal} style={styles.applyButton}>
                                                    <View style={styles.applyCouponView}>
                                                        <Image source={require("../assets/header/discount.png")}
                                                            style={styles.applyCouponImg1} />
                                                        <Text style={styles.applyButtonText}>{"Apply Coupon"}</Text>
                                                    </View>
                                                    <Image source={require("../assets/header/right_arrow.png")}
                                                        style={styles.applyCouponImg2} />
                                                </Pressable>
                                            </View>
                                            {/* end of coupon code */}

                                            {/* multiple addresses view */}
                                            <View style={styles.deliveryInfoContainer}>
                                                <View style={styles.deliveryInfoRow}>
                                                    <View style={styles.deliveryInfoIcon}>
                                                        <Image
                                                            source={require("../assets/header/address_pin.png")}
                                                            style={styles.deliveryInfoIconImage}
                                                        />
                                                    </View>
                                                    <Text style={styles.deliveryInfoText} dataSet={{ media: ids.deliveryInfoText }}>Delivery Address</Text>
                                                </View>

                                                <Text style={styles.deliveryAddressText} dataSet={{ media: ids.deliveryAddressText }}>{shippingAddress}</Text>

                                                {/* {shippingAddress != "" ? (<Pressable
                                                    onPress={() => {
                                                        // setChangeAddressCheck(true);
                                                        // showModal();
                                                        // navigation.navigate("CheckoutScreen");
                                                        navigation.navigate("AddShippingAddressScreen", {
                                                            // paramItem: item,
                                                            paramFrom: "EditShipping", paramIndex: shippingAddressId
                                                        });
                                                    }}
                                                    style={styles.addAddressButton}
                                                >
                                                    <Image
                                                        source={require("../assets/header/add.png")}
                                                        style={styles.deliveryInfoIconImage}
                                                    />
                                                    <Text style={styles.addAddressButtonText} dataSet={{ media: ids.addAddressButtonText }}>CHANGE ADDRESS</Text>
                                                </Pressable>
                                                ) : null} */}
                                                {/* {shippingAddressList.length === 0 ? ( */}
                                                <Pressable
                                                    onPress={() => {
                                                        showAddressModal();
                                                        // navigation.navigate("CheckoutScreen");
                                                    }}
                                                    style={styles.addAddressButton}
                                                >
                                                    <Image
                                                        source={require("../assets/header/add.png")}
                                                        style={styles.deliveryInfoIconImage}
                                                    />
                                                    <Text style={styles.addAddressButtonText} dataSet={{ media: ids.addAddressButtonText }}>SELECT ADDRESS</Text>
                                                </Pressable>
                                                {/* ) : null} */}

                                            </View>
                                            {/* end of multiple addresses view */}

                                            {/* delivery instructions */}
                                            <View style={styles.deliveryInstructions}>
                                                <Text style={styles.deliveryInstructionsText}>{"Delivery Instruction"}</Text>
                                                <TextInput
                                                    style={styles.deliveryInstructionsInput}
                                                    value={deliveryInstruction}
                                                    onChangeText={txt => { setDeliveryInstruction(txt) }}
                                                    placeholderTextColor={colors.placeHolderColor}
                                                    placeholder="Delivery Instruction" />
                                            </View>
                                            {/* end of delivery instructions */}

                                            {/* order summery */}
                                            <View style={styles.orderSummary}>
                                                <Text style={styles.orderSummaryText}>{"Order Summery"}</Text>

                                                <View style={styles.summaryRow}>
                                                    <Text style={styles.summaryLabelText}>{"Subtotal"}</Text>
                                                    <Text style={styles.summaryValueText}>{CustomDigits(cartTotal())}</Text>
                                                </View>
                                                {couponCode != '' ? <View style={styles.summaryRow}>
                                                    <Text style={styles.summaryLabelText}>{"Coupon"}</Text>
                                                    <View style={styles.couponApplyAmountView}>
                                                        <Text style={styles.summaryValueText}>{"-" + CustomDigits(couponDiscount)}</Text>
                                                        <Pressable onPress={() => {
                                                            setCouponId('');
                                                            setCouponCode('');
                                                            setCouponDiscount(0);
                                                        }}>
                                                            <Image source={require("../assets/header/close.png")}
                                                                style={styles.couponRemoveIcon} />
                                                        </Pressable>
                                                    </View>
                                                </View> : null}
                                                <View style={styles.summaryRow1}>
                                                    <Text style={styles.summaryLabelText1}>{"Total"}</Text>
                                                    <Text style={styles.summaryValueText1}>{CustomDigits(overAllTotal(couponDiscount))}</Text>
                                                </View>

                                            </View>
                                            {/* end of order summery */}

                                            {/* payment methods */}
                                            <View style={{}}>
                                                <Text style={styles.paymentMethodTitle}>{"Payments"}</Text>

                                                <FlatList
                                                    style={styles.paymentMethods}
                                                    showsVerticalScrollIndicator={false}
                                                    data={paymentMethods}
                                                    keyExtractor={(item, id) => String(id)}
                                                    ItemSeparatorComponent={<View style={styles.paymentSeparator} />}
                                                    renderItem={({ item, index }) => {
                                                        return (
                                                            <Pressable onPress={() => {
                                                                CustomConsole(item[1].code);
                                                                paymentMethodStr = item[1].code;
                                                                setSelectedPaymentMethod(item[1].code);
                                                            }}
                                                                style={styles.paymentMethodOption}>
                                                                <RadioButton
                                                                    value={selectedPaymentMethod}
                                                                    status={selectedPaymentMethod === item[1].code ? 'checked' : 'unchecked'}
                                                                    onPress={() => {
                                                                        CustomConsole(item[1].code);
                                                                        paymentMethodStr = item[1].code;
                                                                        setSelectedPaymentMethod(item[1].code);
                                                                    }}
                                                                    color={colors.buttonColor}
                                                                />
                                                                <View style={styles.paymentMethodTextView}>
                                                                    <Text style={styles.paymentMethodText} dataSet={{ media: ids.paymentMethodText }}>{item[1].name}</Text>
                                                                    {item[1].code.indexOf('wallet') >= 0 ?
                                                                        <Text style={styles.paymentWalletText}>Wallet: {walletBalance}</Text>
                                                                        : null}

                                                                </View>
                                                                {/* <Text style={{ textAlign: 'center', fontSize: 20, color: colors.black, textTransform: "uppercase", fontFamily: getDMSansBoldFont() }}>{item[1].code}</Text> */}
                                                            </Pressable>
                                                        );
                                                    }} />

                                                {selectedPaymentMethod === "wallet_system_payment" ?
                                                    isSubscriptionOrdersAvailable ?
                                                        <Text style={{ fontSize: 12, color: "red", textAlign: "left", fontFamily: getDMSansSemiBoldFont(), marginTop: 15 }}>Your active subscription will reduce.</Text>
                                                        : null
                                                    : null}

                                                <Pressable onPress={() => {
                                                    if (shippingAddressId == "") {
                                                        // alert("Address is not found! Kindly add address.");
                                                        showAlert("Address is not found! Kindly add address.");
                                                    }
                                                    else {
                                                        if (cartHasSubscription) {
                                                            if (validAddress == "1") {
                                                                if (selectedPaymentMethod == "") {
                                                                    showAlert("Please select payment methods");
                                                                } else {
                                                                    if (selectedPaymentMethod.indexOf('wallet') >= 0) {
                                                                        CustomConsole(overAllTotal(couponDiscount))
                                                                        if (overAllTotal(couponDiscount) <= parseFloat(walletBalance)) {
                                                                            orderConfirm("", shippingAddressId, selectedPaymentMethod, couponCode, deliveryInstruction);
                                                                        } else {
                                                                            showAlert("Wallet has no balance");
                                                                        }
                                                                    } else {
                                                                        handlePayment(couponDiscount, shippingAddressId);
                                                                    }
                                                                }
                                                            } else {
                                                                showAlert("Address is not valid for delivery. Kindly select other address");
                                                            }

                                                        } else {
                                                            if (selectedPaymentMethod == "") {
                                                                showAlert("Please select payment methods");
                                                            } else {
                                                                if (selectedPaymentMethod.indexOf('wallet') >= 0) {
                                                                    CustomConsole(overAllTotal(couponDiscount))
                                                                    if (overAllTotal(couponDiscount) <= parseFloat(walletBalance)) {
                                                                        orderConfirm("", shippingAddressId, selectedPaymentMethod, couponCode, deliveryInstruction);
                                                                    } else {
                                                                        showAlert("Wallet has no balance");
                                                                    }
                                                                } else {
                                                                    handlePayment(couponDiscount, shippingAddressId);
                                                                }
                                                            }
                                                        }
                                                    }
                                                }}
                                                    style={styles.confirmOrderButton}>
                                                    <Text style={styles.confirmOrderButtonText}>Place Your Order</Text>
                                                </Pressable>

                                                {/* <Pressable onPress={() => {
                                                    handlePayment()
                                                }} style={{ backgroundColor: colors.buttonColor, height: 50 }}>
                                                    <Text style={{ textAlign: 'center', fontSize: 12, color: colors.white, textTransform: "uppercase", fontFamily: getDMSansBoldFont() }}>Razorpay</Text>
                                                </Pressable> */}
                                            </View>
                                            {/* end of payment methods */}

                                        </View>
                                        {/* end of coupon & order confirm view */}

                                    </View>
                                    :
                                    !loading ? <View style={styles.orderEmptyView}>
                                        <Text style={styles.orderEmptyText}>Cart is empty</Text>
                                        <Pressable
                                            onPress={() => navigation.navigate("DashBoardScreen")}
                                            style={styles.commonModalButton}>
                                            <Text style={styles.commonModalButtonText}>Start Shopping</Text>
                                        </Pressable>
                                    </View> : null


                                : <View style={styles.cartLoadingView}>
                                    <ActivityIndicator size={50} color={colors.buttonColor} />
                                </View>}

                            {/* <View>
                                    <ActivityIndicator size={50} color={colors.buttonColor}/>
                                </View> */}
                        </View>
                        {/* end of cart screen main view */}

                        {/* coupon modal */}
                        <Modal
                            onRequestClose={hideModal}
                            transparent
                            visible={visible}
                            animationType={'slide'}
                        >
                            <View style={styles.couponModalMainContainer} dataSet={{ media: ids.couponModalMainContainer }}>
                                <View style={[styles.couponModalContainer, offset]} dataSet={{ media: ids.couponModalContainer }}>

                                    <ScrollView>
                                        <Text style={styles.couponModalTitle} dataSet={{ media: ids.couponModalTitle }}>{"Coupons"}</Text>

                                        <View style={styles.couponApplyView} dataSet={{ media: ids.couponApplyView }}>
                                            <TextInput
                                                style={styles.couponApplyTextInput}
                                                dataSet={{ media: ids.couponApplyTextInput }}
                                                value={coupon}
                                                onChangeText={txt => { setCoupon(txt) }}
                                                placeholderTextColor={colors.placeHolderColor}
                                                placeholder="Coupon Code" />
                                            <Pressable onPress={() => { applyCoupon(coupon, userId) }}
                                                style={styles.couponApplyButton} dataSet={{ media: ids.couponApplyButton }}>
                                                <Text style={styles.couponApplyButtonText} dataSet={{ media: ids.couponApplyButtonText }}>Apply</Text>
                                            </Pressable>
                                        </View>

                                        {/* <FlatList style={{}}
                                            numColumns={2}
                                            showsVerticalScrollIndicator={false}
                                            data={couponList}
                                            keyExtractor={(item, id) => String(id)}
                                            renderItem={({ item, index }) => couponItem(item, index)} /> */}
                                        <View style={styles.couponListView} dataSet={{ media: ids.couponListView }}>
                                            {couponList.map((item, index) => {
                                                return couponItem(item, index);
                                            })}
                                        </View>

                                    </ScrollView>

                                    <View style={styles.couponModalButtonContainer} dataSet={{ media: ids.couponModalButtonContainer }}>
                                        <Pressable onPress={hideModal} style={styles.couponModalCloseButton} dataSet={{ media: ids.couponModalCloseButton }}>
                                            <Text style={styles.couponModalCloseButtonText}>Close</Text>
                                        </Pressable>
                                    </View>

                                </View>
                            </View>
                        </Modal>
                        {/* end of coupon modal view */}

                        {/* common modal */}
                        <Modal
                            onRequestClose={hideAlert}
                            transparent
                            visible={alertShow}
                            animationType={'slide'}
                        >
                            <View style={styles.commonModalMainView} dataSet={{ media: ids.commonModalMainView }}>
                                <View style={[styles.commonModalViewContainer, offset]} dataSet={{ media: ids.commonModalViewContainer }}>

                                    <View>
                                        <Text style={styles.commonModalText}>{alertMsg}</Text>
                                    </View>
                                    <Pressable
                                        onPress={hideAlert}
                                        style={styles.commonModalButton}>
                                        <Text style={styles.commonModalButtonText}>Ok, Got it</Text>
                                    </Pressable>
                                </View>
                            </View>
                        </Modal>
                        {/* end of common modal */}

                        {/* address modal */}
                        <Modal
                            onRequestClose={hideAddressModal}
                            transparent
                            visible={isAddressModalVisible}
                            animationType={'slide'}>
                            <View style={styles.modalContainer}>
                                <View style={[styles.modalContent, offset]} dataSet={{ media: ids.modalContent }}>
                                    <Text style={styles.modalTitle}>{"Select Shipping Address"}</Text>
                                    <ScrollView>
                                        {shippingAddressList.length == 0 ?
                                            <View style={styles.noAddressContainer}>
                                                <Text style={styles.noAddressText}>No Addresses Found</Text>
                                            </View> :
                                            <FlatList style={{}}
                                                showsVerticalScrollIndicator={false}
                                                data={shippingAddressList}
                                                keyExtractor={(item, id) => String(id)}
                                                renderItem={({ item, index }) => shippingAddressItem(item, index)} />}
                                    </ScrollView>

                                    <View style={styles.modalButtonsContainer}>
                                        {/* {shippingAddressList.length == 0 ?  */}
                                        <Pressable onPress={() => {
                                            hideAddressModal();
                                            setChangeAddressCheck(true);
                                            navigation.navigate("AddShippingAddressScreen", { paramFrom: "AddShipping" })
                                        }} style={styles.addButton}>
                                            <Text style={styles.buttonText}>Add Address</Text>
                                        </Pressable>
                                        {/* : null} */}
                                        <Pressable onPress={hideAddressModal} style={styles.closeButton}>
                                            <Text style={styles.buttonText}>Close</Text>
                                        </Pressable>
                                    </View>

                                </View>
                            </View>
                        </Modal>
                        {/* end of address modal view */}

                        {/* Footer view */}
                        {/* {FooterComponent(navigation, userId)}*/}
                        {/* end of Footer view */}


                    </View>
                </ScrollView>
            </View>
        </PaperProvider>
    );
};

export default CartScreen;