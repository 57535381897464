import React, { useState } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, useWindowDimensions } from 'react-native';
import { colors } from '../common/color';
import { CustomConsole, HeaderComponent, FooterComponent, getDMSansBoldFont, getDMSansLightFont, validateEmail, getDMSansRegularFont, scrollToTop, getDMSansSemiBoldFont, CustomProgressBar } from '../common/utils';
import { CARTLIST, FAQ, GETFAQ, } from '../common/webUtils';
import { USER_ID, saveSession, getSession } from '../common/LocalStorage';
import { Image } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { FlatList } from 'react-native';
import RenderHTML from 'react-native-render-html';
import { styles, ids } from '../common/responsive.js';

function FaqScreen({ navigation }) {

    const { width } = useWindowDimensions();

    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [mainFaqList, setMainFaqList] = useState([]);
    const [faqList, setfaqList] = useState([]);
    const [selectedFaq, setSelectedFaq] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [cartLength, setcartLength] = React.useState('0');

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            faqListApi();
            cartListApi();
        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);

            // setLoading(true);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                    // setLoading(false);
                })
                .catch((error) => {
                    // setLoading(false);
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    // faq list
    const faqListApi = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(FAQ);

            setLoading(true);
            fetch(FAQ, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);
                    mainFaqList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {
                        mainFaqList.push({
                            index: i,
                            module_id: json.data[i].module_id,
                            name: json.data[i].name,
                            code: json.data[i].code
                        });
                    }

                    setMainFaqList(mainFaqList);

                    if (json.data.length != 0) {
                        getFaqListApi(json.data[0].module_id);
                    }

                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("FAQ Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("FAQ Exception=>" + e + "");
        }
    }

    // get faq list
    const getFaqListApi = async (id) => {
        try {

            setSelectedQuestion(id);
            var raw = JSON.stringify({
                "faq_id": id
            });

            var requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: raw

            };

            CustomConsole(GETFAQ);

            fetch(GETFAQ, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    faqList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {
                        faqList.push({
                            index: i,
                            question_id: json.data[i].question_id,
                            title: json.data[i].title,
                            description: (json.data[i].description).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
                        });
                    }

                    setfaqList([...faqList]);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("GETFAQ Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("GETFAQ Exception=>" + e + "");
        }
    }

    // main question item view
    const mainQuestionItem = (item, index) => {
        return (
            <Pressable onPress={() => getFaqListApi(item.module_id)}
                style={styles.mainQItem}>
                <Text style={selectedQuestion == item.module_id ? styles.mainQActiveText : styles.mainQInactiveText}>{item.name}</Text>
                <Image source={require("../assets/header/right_arrow.png")}
                    style={selectedQuestion == item.module_id ? styles.mainQActiveImage : styles.mainQInactiveImage} />
            </Pressable>
        );
    }

    // sub question item view
    const subQuestionItem = (item, index) => {
        return (
            <View style={styles.subQItem}>
                <Pressable onPress={() => {
                    if (selectedFaq == item.question_id) {
                        setSelectedFaq(null);
                    } else {
                        setSelectedFaq(item.question_id);
                    }

                }}
                    style={styles.subQPress}>
                    <Text style={styles.subQTitle}>{item.title}</Text>
                    <Image source={selectedFaq !== item.question_id ? require("../assets/header/down_arrow.png") : require("../assets/header/up_arrow.png")}
                        style={styles.subQImg} />

                </Pressable>
                {selectedFaq == item.question_id ? 
                <View style={styles.subQDescription}>
                    <RenderHTML
                        contentWidth={width}
                        source={{ html: item.description }}
                        tagsStyles={tagsStyles}
                        systemFonts={[getDMSansRegularFont()]}
                        baseStyle={styles.subQAnswerText}
                    />
                </View> : null}
            </View>
        );
    }

    return (
        <View>
            <StatusBar barStyle="dark-content" />
            <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent(cartLength, navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View style={styles.faqMainView}>
                        <Text style={styles.faqTitleText}>Frequently Asked Questions</Text>

                        <View style={styles.faqContainer}>

                            {/* faq main list */}
                            <View style={styles.faqMainList}>
                                <FlatList
                                    style={{}}
                                    showsVerticalScrollIndicator={false}
                                    data={mainFaqList}
                                    keyExtractor={(item, id) => String(id)}
                                    renderItem={({ item, index }) => mainQuestionItem(item, index)} />
                            </View>

                            {/* faq sub list */}
                            <View style={styles.faqSubList}>
                                <FlatList
                                    style={{}}
                                    showsVerticalScrollIndicator={false}
                                    data={faqList}
                                    keyExtractor={(item, id) => String(id)}
                                    renderItem={({ item, index }) => subQuestionItem(item, index)} />
                            </View>
                        </View>
                    </View>
                    {/* end of main view */}

                    {/* Footer view */}
                    {/* {FooterComponent(navigation, userId)}*/}
                    {/* end of Footer view */}

                </View>

            </ScrollView>
        </View>
    );
};

export default FaqScreen;

const tagsStyles = {
    p: styles.pTagWithBlackText,
    div: styles.divTag
}
