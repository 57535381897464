import React, { useState, useMemo } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, Image, Dimensions, Modal } from 'react-native';
import { colors } from '../common/color';
import { APP_NAME } from '../common/string';
import { CustomConsole, FooterComponent, HeaderComponent, getDMSansMediumFont, getDMSansRegularFont, getDMSansSemiBoldFont, scrollToTop, validateEmail } from '../common/utils';
import { CARTLIST, DELETEUSER, LOGIN, LOGOUT } from '../common/webUtils';
import { EMAIL, FIRST_NAME, LAST_NAME, OTP_VERIFY, PASSWORD, PHONE, USER_ID, getSession, saveSession, clearAsyncStorage, REFERRAL_CODE } from '../common/LocalStorage';
import { Button, Dialog, Portal, PaperProvider, } from 'react-native-paper';
import { useIsFocused } from '@react-navigation/native';
import { styles, ids } from '../common/responsive.js';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookShareButton,
    GabIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TelegramShareButton,
    TumblrIcon,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WhatsappShareButton,
    WorkplaceIcon,
    XIcon,
} from "react-share";

function MyAccountScreen({ navigation }) {

    const WIGGLE_ROOM = 128;

    const offset = useMemo(() => {
        return {
            top: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2,
            left: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2
        };
    }, []);

    const [alertShow, setAlertShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    // modal hide/show
    const showAlert = (text) => {
        setAlertShow(true);
        setAlertMsg(text)
    };
    const hideAlert = () => setAlertShow(false);

    // for dialog
    const [visible, setVisible] = useState(false);
    const [modalText, setModalText] = useState('');
    const [functionality, setFunctionality] = useState('');
    const [loading, setLoading] = useState('');
    const [userId, setUserId] = useState('');
    const [cartLength, setcartLength] = useState('0');
    const [referralCode, setReferralCode] = useState();
    const [shareShow, setShareShow] = useState(false);

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            const referral_code = await getSession(REFERRAL_CODE);
            // alert("Referral code ==> " + referral_code);

            setUserId(user_id);
            setReferralCode(referral_code);
            cartListApi();
        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);

            // setLoading(true);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                    // setLoading(false);
                })
                .catch((error) => {
                    // setLoading(false);
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    const showDialog = (text, functionality) => {
        setModalText(text);
        setFunctionality(functionality);
        setVisible(true)
    };

    const hideDialog = () => setVisible(false);

    // delete user/logout api
    const deletUserOrLogoutApi = async (functionality) => {
        try {

            const user_id = await getSession(USER_ID);
            const email = await getSession(EMAIL);
            const password = await getSession(PASSWORD);

            var raw = JSON.stringify({
                "email": email,
                "password": password
            });

            var raw1 = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: functionality == "0" ? raw : raw1,
                redirect: 'follow'
            };

            CustomConsole(functionality == "0" ? DELETEUSER : LOGOUT);
            CustomConsole(requestOptions);

            setLoading(true);
            fetch(functionality == "0" ? DELETEUSER : LOGOUT, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    setLoading(false);
                    CustomConsole(json);
                    if (json.success == 1) {
                        // alert("Success");
                        hideDialog();
                        clearAsyncStorage();
                        navigation.navigate('LoginScreen');
                        navigation.reset({
                            index: 0,
                            routes: [{ name: 'LoginScreen' }],
                        });
                    } else {
                        if (json.error.length > 0) {
                            showAlert(json.error[0]);
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Error=>" + error);
                });

        } catch (e) {
            CustomConsole("Delete user/Logout Exception=>" + e + "");
        }
    }

    const shareUrl = "Referral Code=> " + referralCode;
    const title = "GitHub";

    return (
        <PaperProvider>
            <View>
                <StatusBar barStyle="dark-content" />
                <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                    <View style={{}}>

                        {/* Header view */}
                        {HeaderComponent(cartLength, navigation, userId)}
                        {/* end of Header view */}

                        {/* main view */}
                        <View style={styles.myAccountMainView} dataSet={{ media: ids.myAccountMainView }}>

                            <View style={styles.myAccountHeadingView} dataSet={{ media: ids.myAccountHeadingView }}>
                                <Text style={styles.myAccountText}>{"My account"}</Text>
                                <Pressable onPress={async () => {
                                    setShareShow(!shareShow);
                                    // navigation.navigate("ThankyouScreen")
                                    // const referral_code = await getSession(REFERRAL_CODE);
                                    // CustomConsole(`https://dairyfarm.alakmalak.ca/register-screen/${referral_code}`);
                                    // alert("Referral code ==> " + referral_code);
                                    // window.location.href = encodeURI(`mailto:
                                    // ?subject=Referral Code
                                    // &body=Hi,\n\nhere is the referral code : ${referralCode} \n\n Press on: https://dairyfarm.alakmalak.ca/register-screen/${referral_code}`);
                                }}>
                                    <Text style={styles.referFriendText}>{"Refer to friend"}</Text>
                                </Pressable>

                                {shareShow ? <View style={styles.referCodeShareView}>
                                    <View style={styles.productMultipleShareButton}>
                                        <EmailShareButton
                                            url={`Hi,\n\nhere is the referral code : ${referralCode} \n\n Press on: https://dairyfarm.alakmalak.ca/register-screen/${referralCode}`}
                                            subject={"Soothe Organics"}
                                            body="body"
                                            className="Demo__some-network__share-button"
                                        >
                                            <EmailIcon size={32} round />
                                        </EmailShareButton>
                                    </View>
                                    {/* <View style={styles.productMultipleShareButton}>
                                        <FacebookShareButton
                                            url={`Hi,\n\nhere is the referral code : ${referralCode} \n\n Press on: https://dairyfarm.alakmalak.ca/register-screen/${referralCode}`}
                                            className="Demo__some-network__share-button"
                                        >
                                            <FacebookIcon size={32} round />
                                        </FacebookShareButton>
                                    </View> */}
                                    <View style={styles.productMultipleShareButton}>
                                        <WhatsappShareButton
                                            url={`Hi,\n\nhere is the referral code : ${referralCode} \n\n Press on: https://dairyfarm.alakmalak.ca/register-screen/${referralCode}`}
                                            title={"Soothe Organics"}
                                            separator=":: "
                                            className="Demo__some-network__share-button"
                                        >
                                            <WhatsappIcon size={32} round />
                                        </WhatsappShareButton>
                                    </View>
                                    <View style={styles.productMultipleShareButton}>
                                        <TwitterShareButton
                                            url={`Hi,\n\nhere is the referral code : ${referralCode} \n\n Press on: https://dairyfarm.alakmalak.ca/register-screen/${referralCode}`}
                                            title={"Soothe Organics"}
                                            className="Demo__some-network__share-button"
                                        >
                                            <XIcon size={32} round />
                                        </TwitterShareButton>
                                    </View>
                                    <View style={styles.productMultipleShareButton}>
                                        <TelegramShareButton
                                            url={`Hi,\n\nhere is the referral code : ${referralCode} \n\n Press on: https://dairyfarm.alakmalak.ca/register-screen/${referralCode}`}
                                            title={"Soothe Organics"}
                                            className="Demo__some-network__share-button"
                                        >
                                            <TelegramIcon size={32} round />
                                        </TelegramShareButton>
                                    </View>
                                </View> : null}

                            </View>


                            <View style={styles.myAccountBoxMainView} dataSet={{ media: ids.myAccountBoxMainView }}>

                                <View style={styles.myAccountBoxSubView} dataSet={{ media: ids.myAccountBoxSubView }}>
                                    <Pressable onPress={() => navigation.navigate("UpdateUserInfoScreen")} style={styles.myAccountBoxViewWithMarginRight} dataSet={{ media: ids.myAccountBoxViewWithMarginRight }}>
                                        <Text style={styles.myAccountBoxText}>Update Info</Text>
                                    </Pressable>
                                    <Pressable onPress={() => navigation.navigate("OrderListScreen")} style={styles.myAccountBoxViewWithMarginRight} dataSet={{ media: ids.myAccountBoxViewWithMarginRight }}>
                                        <Text style={styles.myAccountBoxText}>My Orders</Text>
                                    </Pressable>
                                    <Pressable onPress={() => navigation.navigate("ShippingAddressScreen")} style={styles.myAccountBoxViewWithoutMarginRight} dataSet={{ media: ids.myAccountBoxViewWithoutMarginRight }}>
                                        <Text style={styles.myAccountBoxText}>My Address</Text>
                                    </Pressable>
                                </View>

                                <View style={styles.myAccountBoxSubView} dataSet={{ media: ids.myAccountBoxSubView }}>
                                    <Pressable onPress={() => navigation.navigate("SubscribeListScreen")} style={styles.myAccountBoxViewWithMarginRight} dataSet={{ media: ids.myAccountBoxViewWithMarginRight }}>
                                        <Text style={styles.myAccountBoxText}>Subscriptions</Text>
                                    </Pressable>
                                    {/* <Pressable onPress={() => showDialog('Are you sure to delete user?', "0")} style={styles.myAccountBoxViewWithMarginRight} dataSet={{ media: ids.myAccountBoxViewWithMarginRight }}>
                                        <Text style={styles.myAccountBoxText}>Delete User</Text>
                                    </Pressable> */}
                                    <Pressable onPress={() => showDialog('Are you sure to logout?', "1")} style={styles.myAccountBoxViewWithMarginRight} dataSet={{ media: ids.myAccountBoxViewWithMarginRight }}>
                                        <Text style={styles.myAccountBoxText}>Logout</Text>
                                    </Pressable>
                                    <View style={styles.myAccountBoxViewWithoutMarginRightNotVisible} dataSet={{ media: ids.myAccountBoxViewWithoutMarginRightNotVisible }}>
                                        <Text style={styles.myAccountBoxText}></Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                        {/* end of main view */}

                        {/* Footer view */}
                        {/* {FooterComponent(navigation, userId)}*/}
                        {/* end of Footer view */}

                    </View>

                </ScrollView>

                <Portal>
                    <Dialog visible={visible} onDismiss={hideDialog} style={styles.myAccountDialogMainView}>
                        <Dialog.Title style={styles.myAccountDialogAlertText}>Alert</Dialog.Title>
                        <Dialog.Content>
                            <Text variant="bodyMedium" style={styles.myAccountDialogHeadingText}>{modalText}</Text>
                        </Dialog.Content>
                        <View style={styles.myAccountDialogButtonView}>
                            <Dialog.Actions>
                                <Button onPress={hideDialog} textColor={colors.white} style={styles.myAccountDialogButtonText}>No</Button>
                            </Dialog.Actions>
                            <Dialog.Actions>
                                <Button onPress={() => deletUserOrLogoutApi(functionality)} textColor={colors.white} style={styles.myAccountDialogButtonText}>Yes</Button>
                            </Dialog.Actions>
                        </View>
                    </Dialog>
                </Portal>

                {/* common modal */}
                <Modal
                    onRequestClose={hideAlert}
                    transparent
                    visible={alertShow}
                    animationType={'slide'}
                >
                    <View style={styles.commonModalMainView} dataSet={{ media: ids.commonModalMainView }}>
                        <View style={[styles.commonModalViewContainer, offset]} dataSet={{ media: ids.commonModalViewContainer }}>

                            <View>
                                <Text style={styles.commonModalText}>{alertMsg}</Text>
                            </View>
                            <Pressable
                                onPress={hideAlert}
                                style={styles.commonModalButton}>
                                <Text style={styles.commonModalButtonText}>Ok, Got it</Text>
                            </Pressable>
                        </View>
                    </View>
                </Modal>
                {/* end of common modal */}

            </View>
        </PaperProvider>
    );
};

export default MyAccountScreen;