import React, { useState, useRef, useCallback, useMemo } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, Image, Dimensions, useWindowDimensions, FlatList, Modal } from 'react-native';
import { colors } from '../common/color';
import { CustomConsole, CustomProgressBar, FooterComponent, getDMSansBoldFont, getDMSansMediumFont, getDMSansRegularFont, getDMSansSemiBoldFont, HeaderComponent, scrollToTop, validateEmail } from '../common/utils';
import { ADDREVIEW, ADDTOCART, CARTLIST, CONFIRMORDER, LOGIN, PRODUCTDETAILS, PRODUCTREVIEWS, RAZORPAYORDER, RELATEDPRODUCTS, SHIPPINGLIST } from '../common/webUtils';
import { useIsFocused } from '@react-navigation/native';
import { EMAIL, FIRST_NAME, getSession, LAST_NAME, PHONE, USER_ID } from '../common/LocalStorage';
import { Picker } from '@react-native-picker/picker';
import moment from 'moment';
import RenderHtml from 'react-native-render-html';
import { styles, ids } from '../common/responsive.js';
import useRazorpay from "react-razorpay";
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookShareButton,
    GabIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TelegramShareButton,
    TumblrIcon,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WhatsappShareButton,
    WorkplaceIcon,
    XIcon,
} from "react-share";

function ProductDetailsScreen({ navigation, route }) {

    const [Razorpay] = useRazorpay();

    const scrollRef = useRef();

    const onPressTouch = () => {
        scrollRef.current?.scrollTo({
            y: 0,
            animated: true,
        });
    }

    const productId = route.params.paramId;
    const isWallet = route.params.isWallet;
    const [itemWidth, setItemWidth] = useState(null);
    const WIGGLE_ROOM = 128;

    const offset = useMemo(() => {
        return {
            top: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2,
            left: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2
        };
    }, []);

    const [alertShow, setAlertShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    // modal hide/show
    const showAlert = (text) => {
        setAlertShow(true);
        setAlertMsg(text)
    };
    const hideAlert = () => setAlertShow(false);

    const { width } = useWindowDimensions();
    const [loading, setLoading] = useState(false);
    const [productName, setProductName] = useState('');
    const [productRating, setProductRating] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [productSizeName, setProductSizeName] = useState('1');
    const [productSize, setProductSize] = useState('1');
    const [productSizeList, setProductSizeList] = useState([]);
    const [productPrice, setProductPrice] = useState('');
    const [currencySymbol, setCurrencySymbol] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productModal, setProductModal] = useState('');
    const [productImageList, setProductImageList] = useState([]);
    const [productReviewList, setProductReviewList] = useState([]);
    const [userId, setUserId] = useState('');
    const [relatedProductList, setRelatedProductList] = useState([]);
    const [selectSize, setSelectSize] = useState([]);
    const [productDetailsType, setProductDetailsType] = useState(1);
    const [productQuantity, setProductQuantity] = useState('');
    const [productStockStatus, setProductStockStatus] = useState('');
    const [productReviews, setProductReviews] = useState('');
    const [product_option_id, setProductOptionId] = useState('');
    const [product_option_value_id, setProductOptionValueId] = useState('');
    const [productReceipe, setProductReceipe] = useState('');
    const [productInside, setProductInside] = useState('');
    const [productSizePrice, setProductSizePrice] = useState('');
    const [reviewView, setReviewView] = useState(1);
    const [customerName, setCustomerName] = useState('');
    const [productAddRating, setProductAddRating] = useState(0);
    const [review, setReview] = useState('');
    const [cartLength, setcartLength] = useState('0');
    const [isWalletProduct, setIsWalletProduct] = useState('');

    const [walletBalance, setWalletBalance] = useState('');
    const [isProductDetailWallet, setIsProductDetailWallet] = useState(null);
    const [shareShow, setShareShow] = useState(false);

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused, productId])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            const first_name = await getSession(FIRST_NAME);
            const last_name = await getSession(LAST_NAME);
            setUserId(user_id);
            setCustomerName(first_name + " " + last_name);
            productDetailsApi();
            cartListApi();
        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);

            // setLoading(true);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                    // setLoading(false);
                })
                .catch((error) => {
                    // setLoading(false);
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    // product details api
    const productDetailsApi = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(PRODUCTDETAILS + "&product_id=" + productId + "&currency=INR&lang=");

            setLoading(true);
            fetch(PRODUCTDETAILS + "&product_id=" + productId + "&currency=INR&lang=", requestOptions)
                .then(response => response.json())
                .then((json) => {

                    CustomConsole(json.data);
                    setProductName(json.data[0].name);
                    setIsWalletProduct(json.data[0].is_wallet == undefined ? 0 : json.data[0].is_wallet);
                    setSelectedImage(json.data[0].image);
                    setProductPrice(json.data[0].price.substring(1).replace(",", ""));
                    setCurrencySymbol(json.data[0].price.substring(0, 1));
                    setProductDescription(json.data[0].description);
                    setProductModal(json.data[0].model);
                    setProductRating(json.data[0].rating);
                    setProductReviews(json.data[0].reviews);
                    setProductQuantity(json.data[0].quantity);
                    setProductStockStatus(json.data[0].stock_status);
                    setProductInside(json.data[0].what_is_inside);
                    setProductReceipe(json.data[0].try_receipe);

                    CustomConsole(json.data[0].images.length);

                    productImageList.length = 0;
                    productImageList.push(json.data[0].image != "https://dairyfarm.alakmalak.ca/image/" ? { uri: json.data[0].image } : require("../assets/header/footer-logo.png"));

                    if (json.data[0].images.length != 0) {
                        for (let i = 0; i < json.data[0].images.length; i++) {
                            productImageList.push(json.data[0].images[i] != "https://dairyfarm.alakmalak.ca/image/" ? { uri: json.data[0].images[i] } : require("../assets/header/footer-logo.png"));
                        }
                    }
                    // else {
                    //     productImageList.push(require("../assets/header/footer-logo.png"));
                    //     // setSelectedImage(require("../assets/header/footer-logo.png"));
                    // }
                    setProductImageList(productImageList);

                    setSelectedImage(productImageList[0]);

                    let sizes_arr = [];

                    if (json.data[0].options.length != 0) {
                        for (let j = 0; j < json.data[0].options[0].product_option_value.length; j++) {
                            CustomConsole(json.data[0].options[0].product_option_value[j].product_option_value_id)
                            sizes_arr.push({
                                "product_option_value_id": json.data[0].options[0].product_option_value[j].product_option_value_id,
                                "option_value_id": json.data[0].options[0].product_option_value[j].option_value_id,
                                "name": json.data[0].options[0].product_option_value[j].name,
                                "image": json.data[0].options[0].product_option_value[j].image,
                                "quantity": json.data[0].options[0].product_option_value[j].quantity,
                                "subtract": json.data[0].options[0].product_option_value[j].subtract,
                                "price": json.data[0].options[0].product_option_value[j].price,
                                "price_prefix": json.data[0].options[0].product_option_value[j].price_prefix,
                                "weight": json.data[0].options[0].product_option_value[j].weight,
                                "weight_prefix": json.data[0].options[0].product_option_value[j].weight_prefix,
                                "product_option_id": json.data[0].options[0].product_option_id
                            });
                        }
                        setProductOptionId(json.data[0].options[0].product_option_id);
                        setProductOptionValueId(json.data[0].options[0].product_option_value[0].product_option_value_id);
                        setProductSizeName(json.data[0].options[0].product_option_value[0].name);
                        setProductSizePrice(json.data[0].options[0].product_option_value[0].price);
                        setProductSizeList(sizes_arr);
                    } else {
                        CustomConsole("Index");
                        setProductOptionValueId('');
                        setProductSizeName('');
                        setProductSizePrice('0');
                        setProductSizeList([]);
                        setProductOptionId('');
                    }

                    relatedProductListApi();
                    productReviewListApi();
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Product Api Error=>" + error);
                });

        } catch (e) {
            setLoading(false);
            CustomConsole("Product Details Exception=>" + e + "");
        }
    }

    // related product list api
    const relatedProductListApi = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(RELATEDPRODUCTS + "&product_id=" + productId + "&lang=&currency=INR");

            fetch(RELATEDPRODUCTS + "&product_id=" + productId + "&lang=&currency=INR", requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);
                    relatedProductList.length = 0;

                    for (let i = 0; i < json.data.length; i++) {
                        if (i > 3) {
                            break;
                        }

                        let sizes_arr = [];

                        if (json.data[i].options.length != 0) {
                            for (let j = 0; j < json.data[i].options[0].product_option_value.length; j++) {
                                sizes_arr.push({
                                    "product_option_value_id": json.data[i].options[0].product_option_value[j].product_option_value_id,
                                    "option_value_id": json.data[i].options[0].product_option_value[j].option_value_id,
                                    "name": json.data[i].options[0].product_option_value[j].name,
                                    "image": json.data[i].options[0].product_option_value[j].image,
                                    "quantity": json.data[i].options[0].product_option_value[j].quantity,
                                    "subtract": json.data[i].options[0].product_option_value[j].subtract,
                                    "price": json.data[i].options[0].product_option_value[j].price,
                                    "price_prefix": json.data[i].options[0].product_option_value[j].price_prefix,
                                    "weight": json.data[i].options[0].product_option_value[j].weight,
                                    "weight_prefix": json.data[i].options[0].product_option_value[j].weight_prefix,
                                    "product_option_id": json.data[i].options[0].product_option_id
                                });
                            }
                            let selectedSize = selectSize;
                            selectedSize[i] = {
                                product_option_value_id: json.data[i].options[0].product_option_value[0].product_option_value_id,
                                name: json.data[i].options[0].product_option_value[0].name,
                                product_option_id: json.data[i].options[0].product_option_id,
                                price: json.data[i].options[0].product_option_value[0].price,
                            }
                            setSelectSize(selectedSize);
                        } else {
                            CustomConsole("Index" + i);
                            let selectedSize = selectSize;
                            selectedSize[i] = {
                                product_option_value_id: '',
                                name: '',
                                product_option_id: '',
                                price: '0'
                            }
                            setSelectSize(selectedSize);
                        }

                        relatedProductList.push({
                            index: i,
                            product_id: json.data[i].product_id,
                            name: json.data[i].name,
                            is_wallet: json.data[i].is_wallet == undefined ? 0 : json.data[i].is_wallet,
                            description: json.data[i].description,
                            price: json.data[i].price.substring(1).replace(",", ""),
                            currencySymbol: json.data[i].price.substring(0, 1),
                            image: json.data[i].image != "https://dairyfarm.alakmalak.ca/image/" ? { uri: json.data[i].image } : require("../assets/header/footer-logo.png"),
                            weight: parseFloat(json.data[i].weight).toFixed(2),
                            rating: Math.floor(parseFloat(json.data[i].rating)),
                            sizes: sizes_arr
                        });
                    }

                    CustomConsole(relatedProductList);
                    setRelatedProductList([...relatedProductList]);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Product Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Product Details Exception=>" + e + "");
        }
    }

    // product reviews list api
    const productReviewListApi = async () => {
        try {

            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(PRODUCTREVIEWS + "&product_id=" + productId);

            fetch(PRODUCTREVIEWS + "&product_id=" + productId, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    productReviewList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {

                        var textColor = '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');

                        productReviewList.push({
                            index: i,
                            author: json.data[i].author,
                            authorFirstLetter: json.data[i].author.substring(0, 1),
                            rating: Math.floor(parseFloat(json.data[i].rating)),
                            text: json.data[i].text,
                            date_added: moment(new Date(json.data[i].date_added)).fromNow(),
                            backColor: textColor + '33',
                            textColor: textColor

                        });
                    }

                    setProductReviewList(productReviewList);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Product review Api Error=>" + error);
                });

        } catch (e) {
            setLoading(false);
            CustomConsole("Product review Exception=>" + e + "");
        }
    }

    // product image item view
    const productImageItem = (item, index) => {
        return (
            <Pressable onPress={() => setSelectedImage(item)}
                style={item == selectedImage ? styles.productDetailsActiveImage : styles.productDetailsInactiveImage}>
                <Image source={item}
                    style={styles.productImageItem} />
            </Pressable>
        );
    }

    // related product item view
    const productItem = (item, index) => {
        return (
            <View onLayout={(event) => setItemWidth(event.nativeEvent.layout)}
                style={styles.productDetailsScreenMainView} dataSet={{ media: ids.productDetailsScreenMainView }}>

                <Pressable style={styles.productScreenLogoContainer} dataSet={{ media: ids.productScreenLogoContainer }} onPress={() => navigation.navigate("ProductDetailsScreen", { paramId: item.product_id, paramName: item.name, isWallet: item.is_wallet })}>
                    <View style={styles.productScreenLogoWrapper} />
                    <Image source={item.image}
                        style={{
                            height: 290,
                            width: 150,
                            resizeMode: item.image != require("../assets/header/footer-logo.png") ? "stretch" : "contain"
                        }} />
                </Pressable>

                <View style={styles.productScreenItemListView}>
                    <Text style={styles.listItemTitle} numberOfLines={1} dataSet={{ media: ids.listItemTitle }}>{item.name}</Text>
                    <View style={styles.listItemContainer} dataSet={{ media: ids.listItemContainer }}>
                        <Image source={require("../assets/header/star.png")}
                            style={item.rating >= 1 ? styles.itemReviewActiveStar : styles.itemReviewInactiveStar} />
                        <Image source={require("../assets/header/star.png")}
                            style={item.rating >= 2 ? styles.itemReviewActiveStar : styles.itemReviewInactiveStar} />
                        <Image source={require("../assets/header/star.png")}
                            style={item.rating >= 3 ? styles.itemReviewActiveStar : styles.itemReviewInactiveStar} />
                        <Image source={require("../assets/header/star.png")}
                            style={item.rating >= 4 ? styles.itemReviewActiveStar : styles.itemReviewInactiveStar} />
                        <Image source={require("../assets/header/star.png")}
                            style={item.rating >= 5 ? styles.itemReviewActiveStar : styles.itemReviewInactiveStar} />
                    </View>

                    <View style={styles.itemsCardView} dataSet={{ media: ids.itemsCardView }}>
                        {item.sizes.length != 0 ? <Picker
                            style={styles.itemsCardPicker} dataSet={{ media: ids.itemsCardPicker }}
                            selectedValue={selectSize[index].product_option_value_id}
                            onValueChange={(itemValue, itemIndex) => {
                                // CustomConsole("index" + itemValue)
                                // CustomConsole("index===>" + item.sizes[itemIndex].product_option_id)
                                let tempSelectedSize = [...selectSize];
                                tempSelectedSize[index] = {
                                    product_option_value_id: itemValue,
                                    name: item.sizes[itemIndex].name,
                                    product_option_id: item.sizes[itemIndex].product_option_id,
                                    price: item.sizes[itemIndex].price,
                                };
                                setSelectSize(tempSelectedSize);
                            }}>
                            {item.sizes.map((item1, index1) => {
                                return (<Picker.Item label={item1.name} value={item1.product_option_value_id} />);
                            })}
                            {/* <Picker.Item label="1 Litre Pouch" value="1 Litre" />
                            <Picker.Item label="500 ml Pouch" value="500 ml" /> */}

                        </Picker> : null}
                        <Text style={styles.itemsCardLines} numberOfLines={1}>{item.currencySymbol}{(parseFloat(item.price) + parseFloat(selectSize[index].price)).toFixed()}</Text>
                    </View>

                    <Pressable onPress={() => {
                        if (userId == null || userId == "") {
                            navigation.navigate("RegisterScreen", { paramScreenRoute: window.location.href });
                        } else {
                            CustomConsole(selectSize);
                            CustomConsole(selectSize[index]);
                            navigation.navigate("CheckoutScreen", {
                                paramProductName: item.name,
                                paramProductId: item.product_id,
                                paramProductSize: selectSize[index].name,
                                paramProductPrice: item.currencySymbol + (parseFloat(item.price) + parseFloat(selectSize[index].price)).toFixed(),
                                paramProductImage: item.image,
                                paramProductOptionId: selectSize[index].product_option_id,
                                paramProductOptionValueId: selectSize[index].product_option_value_id,
                            });
                            // navigation.navigate("CheckoutScreen");
                        }
                    }}
                        style={styles.itemsCardCartButton}>
                        <Text style={styles.itemsCardCartText} dataSet={{ media: ids.itemsCardCartText }}>ADD TO CART</Text>
                    </Pressable>
                </View>

            </View>
        );
    }

    // random color generate rgb
    function getRandomColor() {
        var trans = '0.5'; // 50% transparency
        var color = 'rgba(';
        for (var i = 0; i < 3; i++) {
            color += Math.floor(Math.random() * 255) + ',';
        }
        color += trans + ')'; // add the transparency
        return color;
    }

    // product reviews item view
    const reviewItem = (item, index) => {

        return (
            <View style={styles.productReviewItemView}>
                <View style={styles.productReviewSubView}>
                    <View style={[{ backgroundColor: item.backColor, borderColor: item.textColor }, styles.productReviewAutherFirstLetterView]}>
                        <Text style={[{ color: item.textColor, }, styles.productReviewAutherFirstLetterText]}>{item.authorFirstLetter}</Text>
                    </View>

                    <View style={styles.productReviewImgContainer}>
                        <Text style={styles.productReviewImgTitle}>{item.author}</Text>
                        <View style={styles.productReviewImgList}>
                            <Image source={require("../assets/header/star.png")}
                                style={item.rating >= 1 ? styles.productDetailsReviewActiveStar : styles.productDetailsReviewInactiveStar} />
                            <Image source={require("../assets/header/star.png")}
                                style={item.rating >= 2 ? styles.productDetailsReviewActiveStar : styles.productDetailsReviewInactiveStar} />
                            <Image source={require("../assets/header/star.png")}
                                style={item.rating >= 3 ? styles.productDetailsReviewActiveStar : styles.productDetailsReviewInactiveStar} />
                            <Image source={require("../assets/header/star.png")}
                                style={item.rating >= 4 ? styles.productDetailsReviewActiveStar : styles.productDetailsReviewInactiveStar} />
                            <Image source={require("../assets/header/star.png")}
                                style={item.rating >= 5 ? styles.productDetailsReviewActiveStar : styles.productDetailsReviewInactiveStar} />
                            <Text style={styles.productReviewDate}>{item.date_added}</Text>
                        </View>
                        <Text style={styles.productReviewLines} numberOfLines={5}>{item.text}</Text>
                    </View>
                </View>
            </View>
        );
    }

    // product size item view
    const productSizeItem = (item, index) => {
        return (
            <Pressable onPress={() => {
                CustomConsole(item.price)
                setProductOptionValueId(item.product_option_value_id);
                setProductSizeName(item.name);
                setProductSizePrice(item.price);
            }}
                style={item.product_option_value_id == product_option_value_id ? styles.productDetailsActiveSizeView : styles.productDetailsInactiveSizeView}>
                <Text style={item.product_option_value_id == product_option_value_id ? styles.productDetailsActiveSizeText : styles.productDetailsInactiveSizeText}>{item.name}</Text>
            </Pressable>
        );
    }

    // add review
    const addReview = async () => {
        try {

            if (userId == null || userId == "") {
                navigation.navigate("RegisterScreen", { paramScreenRoute: window.location.href });
            } else {
                if (productAddRating == 0) {
                    showAlert("Please give rating by tap on stars");
                }
                else if (review.length == 0) {
                    showAlert("Please write review");
                }
                else {
                    var raw = JSON.stringify({
                        "customer_id": userId,
                        "product_id": productId,
                        "name": customerName,
                        "text": review,
                        "rating": productAddRating
                    });

                    var requestOptions = {
                        method: 'POST',
                        body: raw,
                    };

                    CustomConsole(ADDREVIEW + "\n" + raw);

                    setLoading(true);
                    fetch(ADDREVIEW, requestOptions)
                        .then(response => response.json())
                        .then((json) => {
                            CustomConsole(json);
                            if (json.success == 1) {
                                setLoading(false);
                                setProductAddRating(0);
                                setReview('');
                                showAlert("Review Added Successfully.");

                            } else {
                                setLoading(false);
                                if (json.error.length > 0) {
                                    showAlert(json.error[0]);
                                }
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            CustomConsole("Add review Api Error=>" + error);
                        });
                }
            }
        } catch (error) {

        }
    }

    const [activeImage, setActiveImage] = useState(2);
    let Flatlistref = useRef(null);

    // React.useEffect(() => {
    //     // fires every time when "current" is updated
    //     flatlistRef.current.scrollToIndex({ index: current, animated: true });
    // }, [current]);

    // add wallet balance
    const addWalletBalanceApi = async () => {
        try {

            if (walletBalance != '') {

                const user_id = await getSession(USER_ID);

                var requestOptionsAdd = {
                    method: 'GET',
                    redirect: 'follow'
                };

                CustomConsole(SHIPPINGLIST + "&customer_id=" + user_id);
                fetch(SHIPPINGLIST + "&customer_id=" + user_id, requestOptionsAdd)
                    .then(responseAdd => responseAdd.json())
                    .then((jsonAdd) => {
                        CustomConsole(jsonAdd);

                        if (jsonAdd.data.length != 0) {

                            // cart api call
                            var raw = JSON.stringify({
                                "cart": [
                                    {
                                        "product_id": productId,
                                        "quantity": 1,
                                        "amount": walletBalance
                                    }

                                ],
                                "customer_id": userId
                            });

                            CustomConsole(ADDTOCART + "\n" + raw);

                            var requestOptions = {
                                method: 'POST',
                                body: raw
                            };

                            setLoading(true);
                            fetch(ADDTOCART, requestOptions)
                                .then(response => response.json())
                                .then((json) => {
                                    CustomConsole(json);
                                    if (json.success == 1) {
                                        setLoading(false);
                                        // alert("Product added into cart");
                                        // navigation.navigate("CartScreen");
                                        handlePayment(walletBalance, jsonAdd.data[0].address_id);
                                    } else {
                                        setLoading(false);
                                        if (json.error.length > 0) {
                                            showAlert(json.error[0]);
                                        }
                                        // alert("Not Success");
                                    }

                                })
                                .catch((error) => {
                                    setLoading(false);
                                    CustomConsole("Error=>" + error);
                                });
                            // end of cart api call

                        } else {
                            setLoading(false);
                            showAlert("Address is not found! Kindly add address.");
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        CustomConsole("Shipping Address Api Error=>" + error);
                    });

            } else {
                showAlert("Enter wallet amount!");
            }

        } catch (e) {
            setLoading(false);
            CustomConsole("Add to cart Exception=>" + e + "");
        }
    }

    // razorpay api
    const handlePayment = useCallback(async (walletBalance, address_id) => {
        try {

            const user_id = await getSession(USER_ID);

            CustomConsole(walletBalance);

            var myHeaders = new Headers();
            myHeaders.append("Cookie", "OCSESSID=0ede9b5d0a7524e248af7936ef; currency=INR");

            var raw = JSON.stringify({
                "amount": (parseFloat(walletBalance) * 100).toFixed(),
                "currency": "INR",
                "receipt": "",
                "notes": {
                    "notes_key_1": "",
                    "notes_key_2": ""
                },
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            CustomConsole(RAZORPAYORDER + "\n" + raw);

            setLoading(true);
            fetch(RAZORPAYORDER, requestOptions)
                .then(response => response.json())
                .then(async (json) => {
                    CustomConsole(json);

                    if (json.success == 1 && json.data.id !== undefined) {

                        const first_name = await getSession(FIRST_NAME);
                        const last_name = await getSession(LAST_NAME);
                        const phone = await getSession(PHONE);
                        const email = await getSession(EMAIL);

                        const options: RazorpayOptions = {
                            key: "rzp_test_VXo0HvGmTrrLuR",
                            amount: (parseFloat(walletBalance) * 100).toFixed(),
                            currency: "INR",
                            name: "Acme Corp",
                            description: "Test Transaction",
                            image: "https://example.com/your_logo",
                            order_id: json.data.id,
                            handler: (res) => {
                                CustomConsole(res);

                                // razorpay_order_id
                                // razorpay_payment_id
                                // razorpay_signature

                                orderConfirm(res.razorpay_payment_id, address_id, "", "", "");
                            },
                            prefill: {
                                name: first_name + " " + last_name,
                                email: email,
                                contact: phone,
                            },
                            notes: {
                                address: "Razorpay Corporate Office",
                            },
                            theme: {
                                color: "#3399cc",
                            },
                        };

                        const rzpay = new Razorpay(options);
                        rzpay.open();


                    } else {
                        setLoading(false);
                        showAlert(json.error[0]);
                    }

                    // setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Razorpay Api Error=>" + error);
                });


        } catch (error) {
            setLoading(false);
            CustomConsole("Razorpay api error=> ")
        }

    }, [Razorpay]);

    // order confirm
    const orderConfirm = async (payment_success_id, address_id, test, couponCode, deliveryInstruction) => {
        try {

            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id,
                "currency": "INR",
                "payment_success_id": payment_success_id,
                "address_id": address_id,
                "billing_address_id": address_id,
                "shipping_method": "free.free",
                "payment_method": "razorpay",
                "coupon": "",
                "delivery_instructions": ""
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CONFIRMORDER + "\n" + raw);

            // setConfirmLoading(true);
            fetch(CONFIRMORDER, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);
                    if (json.success == "1") {
                        // setConfirmLoading(false);
                        setLoading(false);
                        showAlert("Amount Added into wallet");
                        navigation.navigate('WalletScreen');
                        navigation.reset({
                            index: 0,
                            routes: [{ name: 'WalletScreen' }],
                        });

                    } else {
                        if (json.error.length > 0) {
                            showAlert(json.error[0]);
                            setLoading(false);
                            // setConfirmLoading(false);
                        }
                    }
                })
                .catch((error) => {
                    // setConfirmLoading(false);
                    setLoading(false);
                    CustomConsole("Order confirm Api Error=>" + error);
                });

        } catch (error) {
            // setConfirmLoading(false);
            setLoading(false);
        }
    }

    return (
        <View>
            <StatusBar barStyle="dark-content" />
            <ScrollView ref={scrollRef} style={styles.container} keyboardShouldPersistTaps='handled'>
                <View>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent(cartLength, navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View style={styles.productDetailMainView} dataSet={{ media: ids.productDetailMainView }}>

                        {isWallet != 1 ?
                            isWalletProduct != 1 ?
                                <>
                                    {/* slider image & product detail */}
                                    <View style={styles.productDetailSlider} dataSet={{ media: ids.productDetailSlider }}>

                                        {/* vertical image list */}
                                        <View style={styles.verticalImgList} dataSet={{ media: ids.verticalImgList }}>
                                            {/* <FlatList
                                            style={styles.verticalImgList}
                                            dataSet={{media:ids.verticalImgList}}
                                            showsVerticalScrollIndicator={false}
                                            data={productImageList}
                                            keyExtractor={(item, id) => String(id)}
                                            renderItem={({ item, index }) => productImageItem(item, index)} /> */}

                                            {productImageList.map((item, index) => {
                                                return productImageItem(item, index);
                                            })}
                                        </View>
                                        {/* end of vertical image list */}

                                        {/* single image */}
                                        <View style={styles.singleImgContainer} dataSet={{ media: ids.singleImgContainer }}>
                                            <View style={styles.singleImgWrapper} >
                                                <View style={styles.singleImgView} />
                                                <Image source={selectedImage}
                                                    style={selectedImage != require("../assets/header/footer-logo.png") ? styles.singleActiveImg : styles.singleInActiveImg} />
                                            </View>
                                        </View>
                                        {/* end of single image */}

                                        {/* product detail */}
                                        <View style={styles.productDetailMainView1} dataSet={{ media: ids.productDetailMainView1 }}>
                                            <Text style={styles.productDetailName} dataSet={{ media: ids.productDetailName }}>{productName}</Text>
                                            <View style={styles.productDetailContainer} dataSet={{ media: ids.productDetailContainer }}>
                                                <View style={styles.productDetailWrapper} dataSet={{ media: ids.productDetailWrapper }}>
                                                    <Image source={require("../assets/header/star.png")}
                                                        style={productRating >= 1 ? styles.singleProductReviewActiveStar : styles.singleProductReviewInactiveStar} />
                                                    <Image source={require("../assets/header/star.png")}
                                                        style={productRating >= 2 ? styles.singleProductReviewActiveStar : styles.singleProductReviewInactiveStar} />
                                                    <Image source={require("../assets/header/star.png")}
                                                        style={productRating >= 3 ? styles.singleProductReviewActiveStar : styles.singleProductReviewInactiveStar} />
                                                    <Image source={require("../assets/header/star.png")}
                                                        style={productRating >= 4 ? styles.singleProductReviewActiveStar : styles.singleProductReviewInactiveStar} />
                                                    <Image source={require("../assets/header/star.png")}
                                                        style={productRating >= 5 ? styles.singleProductReviewActiveStar : styles.singleProductReviewInactiveStar} />
                                                </View>
                                                <Text style={styles.productDetailText} dataSet={{ media: ids.productDetailText }}>{productRating + "/5"}</Text>
                                                <Text style={styles.productDetailText} dataSet={{ media: ids.productDetailText }}>{"(" + productReviews + " Rating)"}</Text>
                                            </View>

                                            <View style={styles.productDescription} dataSet={{ media: ids.productDescription }}>
                                                <RenderHtml
                                                    contentWidth={width}
                                                    source={{ html: productDescription.slice(0, 200) }}
                                                    tagsStyles={tagsStyles}
                                                    systemFonts={[getDMSansRegularFont()]}
                                                    baseStyle={styles.productDescriptionText}
                                                />
                                            </View>
                                            {/* <Text style={{ fontSize: 12, color: colors.black, marginTop: 20, fontFamily: getDMSansRegularFont(), lineHeight: 18 }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Text> */}

                                            <Text style={styles.productDetailPrice} dataSet={{ media: ids.productDetailPrice }}>Price : {currencySymbol}{(parseFloat(productPrice) + parseFloat(productSizePrice)).toFixed()}</Text>

                                            {/* product size list */}
                                            <View style={styles.productSizeList} dataSet={{ media: ids.productSizeList }}>
                                                <FlatList
                                                    horizontal
                                                    showsVerticalScrollIndicator={false}
                                                    data={productSizeList}
                                                    keyExtractor={(item, id) => String(id)}
                                                    renderItem={({ item, index }) => productSizeItem(item, index)} />
                                            </View>
                                            {/* end of product size list */}

                                            <View style={styles.productOrderContainer} dataSet={{ media: ids.productOrderContainer }}>
                                                <Pressable onPress={() => {
                                                    if (userId == null || userId == "") {
                                                        navigation.navigate("RegisterScreen", { paramScreenRoute: window.location.href });
                                                    } else {
                                                        navigation.navigate("CheckoutScreen", {
                                                            // paramProductName: productName,
                                                            paramProductId: productId,
                                                            paramProductSize: productSizeName,
                                                            paramProductPrice: currencySymbol + (parseFloat(productPrice) + parseFloat(productSizePrice)).toFixed(),
                                                            // paramProductImage: selectedImage,
                                                            paramProductOptionId: product_option_id,
                                                            paramProductOptionValueId: product_option_value_id
                                                        });
                                                    }

                                                }}
                                                    style={styles.productOrderButton} dataSet={{ media: ids.productOrderButton }}>
                                                    <Text style={styles.productOrderText} dataSet={{ media: ids.productOrderText }}>Order Now</Text>
                                                </Pressable>

                                                <Pressable onPress={() => {
                                                    setShareShow(!shareShow);
                                                    // window.location.href = encodeURI(`mailto:
                                                    // ?subject=Share product Info
                                                    // &body=Hi,\n\nhere is the product : ${productName} \n\n Press on: https://dairyfarm.alakmalak.ca/product-details-screen/${productId}`);
                                                }}
                                                    style={styles.productShareButton}>
                                                    <Image source={require("../assets/header/share.png")}
                                                        style={styles.productShareImg} />
                                                </Pressable>

                                            </View>

                                            {shareShow ? <View style={styles.productMultipleShareBtnView}>
                                                <View style={styles.productMultipleShareButton}>
                                                    <EmailShareButton
                                                        url={`Hi,\n\nhere is the product : ${productName} \n\n Press on: https://dairyfarm.alakmalak.ca/product-details-screen/${productId}/${isWallet == undefined ? 0 : isWallet}`}
                                                        subject={"Soothe Organics"}
                                                        body="body"
                                                        className="Demo__some-network__share-button"
                                                    >
                                                        <EmailIcon size={32} round />
                                                    </EmailShareButton>
                                                </View>
                                                {/* <View style={styles.productMultipleShareButton}>
                                                <FacebookShareButton
                                                    url={`Hi,\n\nhere is the product : ${productName} \n\n Press on: https://dairyfarm.alakmalak.ca/product-details-screen/${productId}/${isWallet == undefined ? 0 : isWallet}`}
                                                    className="Demo__some-network__share-button"
                                                >
                                                    <FacebookIcon size={32} round />
                                                </FacebookShareButton>
                                            </View> */}
                                                <View style={styles.productMultipleShareButton}>
                                                    <WhatsappShareButton
                                                        url={`Hi,\n\nhere is the product : ${productName} \n\n Press on: https://dairyfarm.alakmalak.ca/product-details-screen/${productId}/${isWallet == undefined ? 0 : isWallet}`}
                                                        title={"Soothe Organics"}
                                                        separator=":: "
                                                        className="Demo__some-network__share-button"
                                                    >
                                                        <WhatsappIcon size={32} round />
                                                    </WhatsappShareButton>
                                                </View>
                                                <View style={styles.productMultipleShareButton}>
                                                    <TwitterShareButton
                                                        url={`Hi,\n\nhere is the product : ${productName} \n\n Press on: https://dairyfarm.alakmalak.ca/product-details-screen/${productId}/${isWallet == undefined ? 0 : isWallet}`}
                                                        title={"Soothe Organics"}
                                                        className="Demo__some-network__share-button"
                                                    >
                                                        <XIcon size={32} round />
                                                    </TwitterShareButton>
                                                </View>
                                                <View style={styles.productMultipleShareButton}>
                                                    <TelegramShareButton
                                                        url={`Hi,\n\nhere is the product : ${productName} \n\n Press on: https://dairyfarm.alakmalak.ca/product-details-screen/${productId}/${isWallet == undefined ? 0 : isWallet}`}
                                                        title={"Soothe Organics"}
                                                        className="Demo__some-network__share-button"
                                                    >
                                                        <TelegramIcon size={32} round />
                                                    </TelegramShareButton>
                                                </View>
                                            </View> : null}
                                        </View>
                                        {/* end of product detail */}

                                    </View>
                                    {/* end of slider image & product detail */}

                                    {/* product description */}
                                    <View style={styles.productDescriptionMainView} dataSet={{ media: ids.productDescriptionMainView }}>
                                        <View style={styles.productDescriptionContainer} dataSet={{ media: ids.productDescriptionContainer }}>
                                            <Pressable onPress={() => setProductDetailsType(1)}
                                                style={productDetailsType == 1 ? styles.productDetailActiveTabView : styles.productDetailInactiveTabView} dataSet={{ media: productDetailsType == 1 ? ids.productDetailActiveTabView : ids.productDetailInactiveTabView }}>
                                                <Text style={productDetailsType == 1 ? styles.productDetailActiveTabText : styles.productDetailInactiveTabText} dataSet={{ media: productDetailsType == 1 ? ids.productDetailActiveTabText : ids.productDetailInactiveTabText }}>What is it?</Text>
                                            </Pressable>
                                            <Pressable onPress={() => setProductDetailsType(2)}
                                                style={productDetailsType == 2 ? styles.productDetailActiveTabView : styles.productDetailInactiveTabView} dataSet={{ media: productDetailsType == 2 ? ids.productDetailActiveTabView : ids.productDetailInactiveTabView }}>
                                                <Text style={productDetailsType == 2 ? styles.productDetailActiveTabText : styles.productDetailInactiveTabText} dataSet={{ media: productDetailsType == 2 ? ids.productDetailActiveTabText : ids.productDetailInactiveTabText }}>what is inside</Text>
                                            </Pressable>
                                            <Pressable onPress={() => setProductDetailsType(3)}
                                                style={productDetailsType == 3 ? styles.productDetailActiveTabView : styles.productDetailInactiveTabView} dataSet={{ media: productDetailsType == 3 ? ids.productDetailActiveTabView : ids.productDetailInactiveTabView }}>
                                                <Text style={productDetailsType == 3 ? styles.productDetailActiveTabText : styles.productDetailInactiveTabText} dataSet={{ media: productDetailsType == 3 ? ids.productDetailActiveTabText : ids.productDetailInactiveTabText }}>Try receipe</Text>
                                            </Pressable>
                                        </View>
                                        {productDetailsType == 1 ?
                                            <>
                                                <Text style={styles.productDesText1} dataSet={{ media: ids.productDesText1 }}>Product Details</Text>
                                                <View style={styles.productDesView} dataSet={{ media: ids.productDesView }}>
                                                    {productDescription != "" ?
                                                        <RenderHtml
                                                            contentWidth={width}
                                                            source={{ html: productDescription }}
                                                            tagsStyles={tagsStyles}
                                                            systemFonts={[getDMSansRegularFont()]}
                                                            baseStyle={styles.productLongDescriptionText}
                                                        />
                                                        :
                                                        <View style={styles.productDesTextView}>
                                                            <Text style={styles.productDesText2} dataSet={{ media: ids.productDesText2 }}>Data is not available</Text>
                                                        </View>}
                                                </View>
                                            </> :
                                            productDetailsType == 2 ?
                                                <>
                                                    <View style={styles.productDesView}>
                                                        {productInside != "" ?
                                                            <RenderHtml
                                                                contentWidth={width}
                                                                source={{ html: productInside }}
                                                                tagsStyles={tagsStyles}
                                                                systemFonts={[getDMSansRegularFont()]}
                                                                baseStyle={styles.productInsideText}
                                                            />
                                                            : <View style={styles.productDesTextView}>
                                                                <Text style={styles.productDesText2}>Data is not available</Text>
                                                            </View>}
                                                    </View>
                                                </> :
                                                <>
                                                    <View style={{ marginTop: 20, }}>
                                                        {productReceipe != "" ?
                                                            <RenderHtml
                                                                contentWidth={width}
                                                                source={{ html: productReceipe }}
                                                                tagsStyles={tagsStyles}
                                                                systemFonts={[getDMSansRegularFont()]}
                                                                baseStyle={styles.productReceipeText}
                                                            />
                                                            :
                                                            <View style={styles.productDesTextView}>
                                                                <Text style={styles.productDesText2}>Data is not available</Text>
                                                            </View>}
                                                    </View>
                                                </>}
                                    </View>
                                    {/* end of product description */}

                                    {/* product reviews */}
                                    <View style={styles.productRevMainView}
                                        dataSet={{ media: ids.productRevMainView }}>
                                        <View style={styles.productRevContainer}>

                                            <Pressable onPress={() => { setReviewView(1); productReviewListApi(); }}
                                                style={reviewView == 1 ? styles.productDetailActiveTabView : styles.productDetailInactiveTabView} dataSet={{ media: reviewView == 1 ? ids.productDetailActiveTabView : ids.productDetailInactiveTabView }}>
                                                <Text style={reviewView == 1 ? styles.productDetailActiveTabText : styles.productDetailInactiveTabText}
                                                    dataSet={{ media: reviewView == 1 ? ids.productDetailActiveTabText : ids.productDetailInactiveTabText }}>read reviews</Text>
                                            </Pressable>
                                            <Pressable onPress={() => setReviewView(2)}
                                                style={reviewView == 2 ? styles.productDetailActiveTabView : styles.productDetailInactiveTabView} dataSet={{ media: reviewView == 2 ? ids.productDetailActiveTabView : ids.productDetailInactiveTabView }}>
                                                <Text style={reviewView == 2 ? styles.productDetailActiveTabText : styles.productDetailInactiveTabText}
                                                    dataSet={{ media: reviewView == 2 ? ids.productDetailActiveTabText : ids.productDetailInactiveTabText }}>rate your experience</Text>
                                            </Pressable>
                                        </View>

                                        {reviewView == 1 ? productReviewList.length != 0 ?
                                            <FlatList style={styles.productRevFlatlist} dataSet={{ media: ids.productRevFlatlist }}
                                                data={productReviewList}
                                                keyExtractor={(item, id) => String(id)}
                                                renderItem={({ item, index }) => reviewItem(item, index)} />
                                            :
                                            <View style={styles.productRevTextView}>
                                                <Text style={styles.productRevText}>Reviews are not available</Text>
                                            </View>

                                            : <View style={{}}>

                                                <View style={styles.productRevImgView}>
                                                    <Pressable onPress={() => setProductAddRating(1)}>
                                                        <Image source={require("../assets/header/star.png")}
                                                            style={productAddRating >= 1 ? styles.addReviewActiveStar : styles.addReviewInactiveStar} />
                                                    </Pressable>
                                                    <Pressable onPress={() => setProductAddRating(2)}>
                                                        <Image source={require("../assets/header/star.png")}
                                                            style={productAddRating >= 2 ? styles.addReviewActiveStar : styles.addReviewInactiveStar} />
                                                    </Pressable>
                                                    <Pressable onPress={() => setProductAddRating(3)}>
                                                        <Image source={require("../assets/header/star.png")}
                                                            style={productAddRating >= 3 ? styles.addReviewActiveStar : styles.addReviewInactiveStar} />
                                                    </Pressable>
                                                    <Pressable onPress={() => setProductAddRating(4)}>
                                                        <Image source={require("../assets/header/star.png")}
                                                            style={productAddRating >= 4 ? styles.addReviewActiveStar : styles.addReviewInactiveStar} />
                                                    </Pressable>
                                                    <Pressable onPress={() => setProductAddRating(5)}>
                                                        <Image source={require("../assets/header/star.png")}
                                                            style={productAddRating >= 5 ? styles.addReviewActiveStar : styles.addReviewInactiveStar} />
                                                    </Pressable>
                                                </View>

                                                {productAddRating == 0 ?
                                                    <Text style={styles.addRatingTitle}>Tap on the stars to rate this product.</Text>
                                                    :
                                                    <Pressable onPress={() => setProductAddRating(0)} style={styles.clearRatingButton}>
                                                        <Text style={styles.clearRatingText}>Clear Rating</Text>
                                                    </Pressable>}



                                                <TextInput
                                                    style={styles.addReview}
                                                    value={review}
                                                    multiline={true}
                                                    onChangeText={txt => { setReview(txt) }}
                                                    placeholderTextColor={colors.placeHolderColor}
                                                    placeholder="Write your review" />

                                                <Pressable onPress={addReview}
                                                    style={styles.addReviewButton}>
                                                    <Text style={styles.addReviewText}>Add Review</Text>
                                                </Pressable>
                                            </View>}

                                    </View>
                                    {/* end of product reviews */}

                                    {/* Related products */}
                                    {relatedProductList.length != 0 ? <View style={styles.relatedProductView}>
                                        <Text style={styles.relatedProductTitle}>Related Products</Text>
                                        {/* <FlatList style={{ marginTop: 50 }}
    horizontal
    showsVerticalScrollIndicator={false}
    data={relatedProductList}
    keyExtractor={(item, id) => String(id)}
    renderItem={({ item, index }) => productItem(item, index)} /> */}
                                        <View style={styles.relatedProductContainer}>
                                            <Pressable style={styles.relatedProductSubContainer} onPress={() => {

                                                CustomConsole("active index=> " + activeImage);
                                                CustomConsole(activeImage - 2)
                                                // if (activeImage - 2 >= 2) {
                                                // console.log("Active image=> ", activeImage)
                                                Flatlistref.current.scrollToIndex({ animated: true, index: 0 });
                                                // setActiveImage(activeImage - 2);
                                                // }
                                            }}>
                                                <Image source={require("../assets/header/final_slider_left_arrow.png")}
                                                    style={styles.relatedImg1} resizeMode="contain" />
                                            </Pressable>

                                            <FlatList style={styles.relatedFlatList}
                                                ref={Flatlistref}
                                                horizontal
                                                showsVerticalScrollIndicator={false}
                                                data={relatedProductList}
                                                onScroll={e => {
                                                    const offsetX = e.nativeEvent.contentOffset.x;
                                                    const index = Math.floor(offsetX / itemWidth.width); // adjust ITEM_WIDTH based on your item size

                                                    // CustomConsole("e.nativeEvent.contentOffset.x1===>"+offsetX);
                                                    // CustomConsole("e.nativeEvent.contentOffset.x2===>"+JSON.stringify(itemWidth));
                                                    // CustomConsole("e.nativeEvent.contentOffset.x2===>"+itemWidth.width);
                                                    // CustomConsole("e.nativeEvent.contentOffset.x3===>"+index);
                                                    // if (activeImage + 1 < relatedProductList.length) {
                                                    //     setActiveImage(activeImage + 1);
                                                    // }
                                                }}
                                                keyExtractor={(item, id) => String(id)}
                                                renderItem={({ item, index }) => productItem(item, index)}
                                            // ListHeaderComponent={<Pressable style={{ padding: 10, }} onPress={() => {
                                            //     CustomConsole(activeImage - 1)
                                            //     if (activeImage - 1 >= 2) {
                                            //         console.log("Active image=> ", activeImage)
                                            //         Flatlistref.current.scrollToIndex({ animated: true, index: activeImage - 1 });
                                            //         setActiveImage(activeImage - 1);
                                            //     }
                                            // }}>
                                            //     <Image source={require("../assets/header/final_slider_left_arrow.png")}
                                            //         style={{ width: 22, height: 22 }} resizeMode="contain" />
                                            // </Pressable>}

                                            // ListFooterComponent={<Pressable style={{ padding: 10 }} onPress={() => {
                                            //     if (activeImage + 1 < relatedProductList.length) {
                                            //         console.log("Active image=> ", activeImage)
                                            //         Flatlistref.current.scrollToIndex({ animated: true, index: activeImage + 1 });
                                            //         setActiveImage(activeImage + 1);
                                            //     }
                                            // }}>
                                            //     <Image source={require("../assets/header/final_slider_right_arrow.png")}
                                            //         style={{ width: 22, height: 22 }} resizeMode="contain" />
                                            // </Pressable>} 
                                            />

                                            {relatedProductList.length > 3 ? <Pressable style={styles.relatedProductSubContainer} onPress={() => {
                                                CustomConsole("active index=> " + activeImage);
                                                CustomConsole(activeImage + 1)
                                                // if ((activeImage + 1) < relatedProductList.length) {
                                                // console.log("Active image=> ", activeImage)
                                                Flatlistref.current.scrollToIndex({ animated: true, index: activeImage + 1 });
                                                // setActiveImage(activeImage + 1);
                                                // }
                                            }}>
                                                <Image source={require("../assets/header/final_slider_right_arrow.png")}
                                                    style={styles.relatedImg2} resizeMode="contain" />
                                            </Pressable> : null}
                                        </View>
                                    </View> : null}
                                    {/* end of related products */}
                                </>
                                :
                                <>
                                    <View style={styles.orderEmptyView}>
                                        <Text style={styles.orderEmptyText}>Wrong URL</Text>
                                    </View>
                                </>
                            : isWalletProduct == 1
                                ?
                                <>
                                    <View style={styles.addWalletView}>
                                        <Text style={styles.addWalletText}>{"Add Wallet Balance"}</Text>
                                    </View>

                                    <View style={{}}>
                                        <TextInput
                                            style={styles.addWalletTextInput}
                                            dataSet={{ media: ids.addWalletTextInput }}
                                            value={walletBalance}
                                            onChangeText={txt => { setWalletBalance(txt.replace(/[^0-9]/g, '')) }}
                                            placeholderTextColor={colors.placeHolderColor}
                                            placeholder="Add Amount" />

                                        <Pressable onPress={() => addWalletBalanceApi()}
                                            style={styles.addWalletButtonView}>
                                            <Text style={styles.addWalletButtonText}>Add Balance</Text>
                                        </Pressable>
                                    </View>

                                </>
                                :
                                <>
                                    <View style={styles.orderEmptyView}>
                                        <Text style={styles.orderEmptyText}>Wrong URL</Text>
                                    </View>
                                </>}
                    </View>
                    {/* end of main view */}

                    {/* common modal */}
                    <Modal
                        onRequestClose={hideAlert}
                        transparent
                        visible={alertShow}
                        animationType={'slide'}
                    >
                        <View style={styles.commonModalMainView} dataSet={{ media: ids.commonModalMainView }}>
                            <View style={[styles.commonModalViewContainer, offset]} dataSet={{ media: ids.commonModalViewContainer }}>

                                <View>
                                    <Text style={styles.commonModalText}>{alertMsg}</Text>
                                </View>
                                <Pressable
                                    onPress={hideAlert}
                                    style={styles.commonModalButton}>
                                    <Text style={styles.commonModalButtonText}>Ok, Got it</Text>
                                </Pressable>
                            </View>
                        </View>
                    </Modal>
                    {/* end of common modal */}

                    {/* Footer view */}
                    {/* {FooterComponent(navigation, userId)}*/}
                    {/* end of Footer view */}

                </View>
            </ScrollView>
        </View>
    );
};

export default ProductDetailsScreen;

const tagsStyles = {
    p: styles.pTagWithBlackText,
    div: styles.divTag
}
