//common urls
export const COMMONURL = "https://dairyfarm.alakmalak.ca/index.php?route=extension/mstore"

// Login
export const LOGIN = COMMONURL + "/account.login"
export const REGISTER = COMMONURL + "/account.register"
export const SENDOTP = COMMONURL + "/sendotp"
export const FORGOTPASSWORD = COMMONURL + "/account.forgottPassword"
export const FORGOTVERIFYOTP = COMMONURL + "/account.forgotVerify"
export const CHANGEPASSWORD = COMMONURL + "/account.ChangePassword"
export const CHANGEPASSWORDDRAWER = COMMONURL + "/account.changepassworduser"
export const VERIFYOTP = COMMONURL + "/otp_verify"
export const UPDATEPROFILE = COMMONURL + "/account.edit"
export const DELETEUSER = COMMONURL + "/delete_user"
export const LOGOUT = COMMONURL + "/account.logout&lang=en&currency=INR"

// dashboard
export const BANNER = COMMONURL + "/bannerdata&id=9"
export const GETFAQ = COMMONURL + "/faq.getfaq"
export const FAQ = COMMONURL + "/faq.list"
export const STORY = COMMONURL + "/product.getProductStories"
export const TOPREVIEWS = COMMONURL + "/review.getTopReviews"
export const CMSPAGE = COMMONURL + "/information&id="
export const FEATURED = COMMONURL + "/product.featured&currency=INR"

// product
export const PRODUCT = COMMONURL + "/product&category=&currency=INR&lang=gr"
export const PRODUCTDETAILS = COMMONURL + "/product_detail"
export const RELATEDPRODUCTS = COMMONURL + "/product.getRelatedProducts"
export const PRODUCTREVIEWS = COMMONURL + "/review"
export const ADDTOCART = COMMONURL + "/cart.add"
export const SUBSCRIBELIST = COMMONURL + "/subscription"
export const CARTLIST = COMMONURL + "/cart.list"
export const ADDREVIEW = COMMONURL + "/review"
export const EMPTYCART = COMMONURL + "/cart.emptyCart"
export const COUPANS = COMMONURL + "/cart.coupons"
export const APPLYCOUPANS = COMMONURL + "/cart.coupon&lang=en&currency=INR"
export const PAYMENTMETHODS = COMMONURL + "/payment_method"
export const SUBSCRIPTIONPAUSE = COMMONURL + "/subscription.pause"
export const SUBSCRIPTIONCONTINUE = COMMONURL + "/subscription.continue"
export const SUBSCRIPTIONPAUSELIST = COMMONURL + "/subscription.getPause&subscription_id="
export const DELETECART = COMMONURL + "/cart.remove"
export const MODIFYCART = COMMONURL + "/cart.modifycart"
export const MODIFYSINGLEDAYQTY = COMMONURL + "/order.modifydatesubscription"
export const SUBSCRIPTIONMODIFYLOG = COMMONURL + "/subscription.modifylog"

// wallet
export const WALLETBALANCE = COMMONURL + "/wallet&customer_id="
export const WALLETHISTORY = COMMONURL + "/wallet.getWalletHistory&customer_id="

// orders
export const ORDERLIST = COMMONURL + "/order.orders"
export const SUBSCRIPTIONLIST = COMMONURL + "/order.subscriptionOrders"
export const CANCELSUBSCRIPTION = COMMONURL + "/subscription.cancel"
export const CANCELSUBSCRIPTIONLIST = COMMONURL + "/order.cancelledSubscriptionOrders"
export const CONFIRMORDER = COMMONURL + "/order.confirm&currency=INR&lang=gr"
export const RAZORPAYORDER = COMMONURL + "/order.razorpayorder"

// Shipping Address
export const SHIPPINGLIST = COMMONURL + "/shipping_address"
export const ADDSHIPPING = COMMONURL + "/shipping_address.update"
export const DELETESHIPPING = COMMONURL + "/shipping_address.delete"
export const COUNTRY = COMMONURL + "/shipping_address.countries"
export const STATE = COMMONURL + "/shipping_address.states"
export const CITY = COMMONURL + "/city"

// timezone
export const TIMEZONE = COMMONURL + "/timezone"
export const INQUIRY = COMMONURL + "/account.contactus"


