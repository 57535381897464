import React, { useState } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, Image, Dimensions, FlatList } from 'react-native';
import { colors } from '../common/color';
import { CustomConsole, CustomDigits, CustomProgressBar, FooterComponent, HeaderComponent, formatDate, getDMSansBoldFont, getDMSansRegularFont, getDMSansSemiBoldFont, scrollToTop, validateEmail } from '../common/utils';
import { CARTLIST, LOGIN, ORDERLIST } from '../common/webUtils';
import { useIsFocused } from '@react-navigation/native';
import { getSession, USER_ID } from '../common/LocalStorage';
import { styles, ids } from '../common/responsive.js';

function OrderListScreen({ navigation }) {

    const [loading, setLoading] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [userId, setUserId] = useState('');
    const [cartLength, setcartLength] = React.useState('0');

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])


    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            orderListApi(user_id);
            cartListApi();
        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);

            // setLoading(true);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                    // setLoading(false);
                })
                .catch((error) => {
                    // setLoading(false);
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    // order list api
    const orderListApi = async (user_id) => {
        try {
            var raw = JSON.stringify({
                "customer_id": user_id
            });
            var requestOptions = {
                method: 'POST',
                body: raw,
                redirect: 'follow'
            };

            CustomConsole(ORDERLIST);

            setLoading(true);
            fetch(ORDERLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    orderList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {
                        orderList.push({
                            index: i,
                            order_id: json.data[i].order_id,
                            invoice_no: json.data[i].invoice_no,
                            total: CustomDigits(json.data[i].total),
                            date_added: formatDate(new Date(json.data[i].date_added)),
                            line_items: json.data[i].line_items,
                            firstname: json.data[i].firstname,
                            lastname: json.data[i].lastname,
                            email: json.data[i].email,
                            telephone: json.data[i].telephone,
                            delivery_instructions: json.data[i].delivery_instructions,
                            order_status: json.data[i].order_status,
                        });
                    }

                    setOrderList(orderList);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Order Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Order Exception=>" + e + "");
        }
    }

    // order item view
    const orderItem = (item, index) => {
        return (
            // <View style={styles.orderItemMainView} dataSet={{media:ids.orderItemMainView}}>
            //     <View style={styles.orderItemDateView} dataSet={{media:ids.orderItemDateView}}>
            //         <Text style={styles.orderItemDateText}>{item.date_added}</Text>
            //     </View>
            //     <View style={styles.orderItemDetails} dataSet={{media:ids.orderItemDetails}}>
            //         <Text style={styles.orderItemOrderNoText}>Order No. : {item.order_id}</Text>
            //         <Text style={styles.orderItemOrderDetailsText}>Total: {item.total}</Text>
            //         {/* <Text style={styles.orderItemOrderDetailsText}>Status: {item.order_status}</Text> */}
            //         <Text style={styles.orderItemOrderDetailsText}>Products:</Text>

            //         <View style={styles.orderItemProductDetailsView} dataSet={{media:ids.orderItemProductDetailsView}}>
            //             {item.line_items.map((item1, index1) => {
            //                 // CustomConsole(item1)
            //                 return (
            //                     <View style={(item.line_items.length - 1) == index1 ? styles.orderItemProductDetailsViewWithoutBorder : styles.orderItemProductDetailsViewWithBorder}>
            //                         <Image source={{ uri: item1.product_data.image }} style={styles.orderItemProductImage} />
            //                         <View style={styles.orderItemProductDetailsSubView}>
            //                             <Text style={styles.orderItemProductName} numberOfLines={2}>{item1.name}</Text>
            //                             <Text style={styles.orderItemProductQty}>{CustomDigits(item1.price)} x {item1.quantity}</Text>
            //                             <Text style={styles.orderItemProductTotal}>Total: {CustomDigits(item1.total)}</Text>
            //                         </View>
            //                     </View>
            //                 );
            //             })}
            //         </View>
            //     </View>
            // </View>
            <View style={styles.orderItemContainerView} dataSet={{ media: ids.orderItemContainerView }}>
                <View style={styles.orderItemMainView} dataSet={{ media: ids.orderItemMainView }}>

                    {/* date view */}
                    <View style={styles.orderItemDateView} dataSet={{ media: ids.orderItemDateView }}>
                        <Text style={styles.orderItemDateText} dataSet={{ media: ids.orderItemDateText }}>{item.date_added}</Text>
                    </View>
                    {/* end of date view */}

                    {/* order details view*/}
                    <View style={styles.orderItemDetails} dataSet={{ media: ids.orderItemDetails }}>
                        <Text style={styles.orderItemOrderNoText} dataSet={{ media: ids.orderItemOrderNoText }}>Order No. : <Text style={styles.orderItemOrderNoBoldText} dataSet={{ media: ids.orderItemOrderNoBoldText }}>{item.order_id}</Text></Text>
                        <Text style={styles.orderItemOrderDetailsText} dataSet={{ media: ids.orderItemOrderDetailsText }}>Total: <Text style={styles.orderItemOrderDetailsBoldText} dataSet={{ media: ids.orderItemOrderDetailsBoldText }}>{item.total}</Text></Text>
                        <Text style={styles.orderItemOrderDetailsText} dataSet={{ media: ids.orderItemOrderDetailsText }}>Status: <Text style={(item.order_status == "Cancelled") ? styles.orderItemOrderDetailsCancelledStatusText : item.order_status == "Pause" ? styles.orderItemOrderDetailsPauseStatusText : item.order_status == "Pending" ? styles.orderItemOrderDetailsPendingStatusText : styles.orderItemOrderDetailsOtherStatusText} dataSet={{ media: (item.order_status == "Cancelled") ? ids.orderItemOrderDetailsCancelledStatusText : item.order_status == "Pause" ? ids.orderItemOrderDetailsPauseStatusText : item.order_status == "Pending" ? ids.orderItemOrderDetailsPendingStatusText : ids.orderItemOrderDetailsOtherStatusText }}>{item.order_status}</Text></Text>
                        {/* <Text style={styles.orderItemOrderDetailsText}>Products:</Text> */}
                    </View>
                    {/* end of order details view */}

                    {/* order product details view */}
                    <View style={styles.orderItemProductDetailsView} dataSet={{ media: ids.orderItemProductDetailsView }}>
                        {item.line_items.map((item1, index1) => {
                            return (
                                <View style={(item.line_items.length - 1) == index1 ? styles.orderItemProductDetailsViewWithoutBorder : styles.orderItemProductDetailsViewWithBorder}>
                                    <View style={styles.orderItemProductImageView}>
                                        <Image source={item1.product_data.image != "" ? { uri: item1.product_data.image } : require("../assets/header/footer-logo.png")} style={styles.orderItemProductImage} />
                                    </View>
                                    <View style={styles.orderItemProductDetailsSubView}>
                                        <Text style={styles.orderItemProductName} numberOfLines={2}>{item1.name}</Text>
                                        {/* <Text style={styles.orderItemProductQty}>{CustomDigits(item1.price)} x {item1.quantity}</Text> */}

                                        {item1.product_data.subscription.subscription_type == undefined ?
                                            <Text style={styles.cartItemOption}> {item1.quantity}</Text>
                                            : item1.product_data.subscription.subscription_type == "daily" || item1.product_data.subscription.subscription_type == "trial"
                                                ?
                                                // daily subscription
                                                <>
                                                    <Text style={styles.orderItemProductQty}>{CustomDigits(item1.price)} x {item1.product_data.subscription.subscription_value} | <Text style={styles.cartItemSubscriptionName}>{item1.product_data.subscription.subscription_type}</Text></Text>
                                                    <Text style={styles.cartItemOption}>Start Date: {formatDate(new Date(item1.product_data.subscription.start_date))} | End Date: {formatDate(new Date(item1.product_data.subscription.end_date))}</Text>
                                                </>
                                                :
                                                item1.product_data.subscription.subscription_type == "alternate"
                                                    ?
                                                    // alternate subscription
                                                    <>
                                                        <Text style={styles.cartItemOption}><Text style={styles.cartItemSubscriptionDay}>Day 1</Text>  - {CustomDigits(item1.price)} x {item1.product_data.subscription.subscription_value}  |  <Text style={styles.cartItemOption}><Text style={styles.cartItemSubscriptionDay}>Day 2</Text>  - {CustomDigits(item1.price)} x 0</Text></Text>
                                                        <Text style={styles.cartItemOption}>Start Date: {formatDate(new Date(item1.product_data.subscription.start_date))} | End Date: {formatDate(new Date(item1.product_data.subscription.end_date))}</Text>
                                                    </>
                                                    :// varing subscription
                                                    <>
                                                        <Text style={styles.cartItemOption}><Text style={styles.cartItemSubscriptionDay}>Day 1</Text>  - {CustomDigits(item1.price)} x {item1.product_data.subscription.subscription_value.split(",")[0]}  |  <Text style={styles.cartItemOption}><Text style={styles.cartItemSubscriptionDay}>Day 2</Text>  - {CustomDigits(item1.price)} x {item1.product_data.subscription.subscription_value.split(",")[1]}</Text></Text>
                                                        <Text style={styles.cartItemOption}>Start Date: {formatDate(new Date(item1.product_data.subscription.start_date))} | End Date: {formatDate(new Date(item1.product_data.subscription.end_date))}</Text>
                                                    </>
                                        }

                                        <Text style={styles.orderItemProductTotalQty}>Total Qty: <Text style={styles.orderItemProductTotalQtyBold}>{item1.quantity}</Text></Text>
                                        <Text style={styles.orderItemProductTotal}>Total: <Text style={styles.orderItemProductTotalBold}>{CustomDigits(item1.total)}</Text></Text>

                                    </View>
                                </View>
                            );
                        })}
                    </View>
                    {/* end of order product details view */}

                </View>
            </View>
        );
    }

    return (
        <View>
            <StatusBar barStyle="dark-content" />
            <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                <View>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent(cartLength, navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View style={styles.orderListMainView} dataSet={{ media: ids.orderListMainView }}>

                        <View style={styles.orderHeadingTextView}>
                            <Text style={styles.orderHeadingText}>{"Order Management"}</Text>
                        </View>

                        {/* order list view */}
                        {orderList.length != 0 ?
                            <FlatList
                                style={styles.orderFlatListView}
                                dataSet={{ media: ids.orderFlatListView }}
                                data={orderList}
                                keyExtractor={(item, id) => String(id)}
                                ItemSeparatorComponent={<View style={styles.orderItemSeperator} />}
                                renderItem={({ item, index }) => orderItem(item, index)} />
                            :
                            !loading ?
                                <View style={styles.orderEmptyView} dataSet={{ media: ids.orderEmptyView }}>
                                    <Text style={styles.orderEmptyText}>Orders is not available</Text>
                                </View> : null
                        }

                    </View>
                    {/* end of main view */}

                    {/* Footer view */}
                    {/* {FooterComponent(navigation, userId)}*/}
                    {/* end of Footer view */}

                </View>
            </ScrollView>
        </View>
    );
};

export default OrderListScreen;
