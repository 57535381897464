import React, { useState, useMemo } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, Modal, Linking } from 'react-native';
import { colors } from '../common/color';
import { CustomConsole, HeaderComponent, FooterComponent, getDMSansBoldFont, getDMSansLightFont, validateEmail, getDMSansRegularFont, scrollToTop, CustomProgressBar } from '../common/utils';
import { CARTLIST, LOGIN, SENDOTP } from '../common/webUtils';
import { EMAIL, FIRST_NAME, LAST_NAME, OTP_VERIFY, PASSWORD, PHONE, USER_ID, saveSession, getSession, REFERRAL_CODE, CITY_NAME, STATE_ID, COUNTRY_ID } from '../common/LocalStorage';
import { Image } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { Divider } from 'react-native-paper';
import { styles, ids } from '../common/responsive.js';

function LoginScreen({ navigation, route }) {

    var onlyDigitReg = /^\d+$/;
    const WIGGLE_ROOM = 128;

    const offset = useMemo(() => {
        return {
            top: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2,
            left: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2
        };
    }, []);

    const [alertShow, setAlertShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    // modal hide/show
    const showAlert = (text) => {
        setAlertShow(true);
        setAlertMsg(text)
    };
    const hideAlert = () => setAlertShow(false);

    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [signInWithOtp, setSignInWithOtp] = useState(false);
    const [cartLength, setcartLength] = useState('0');
    const [emailError, setEmailError] = useState(false);
    const [invalidEmailError, setInvalidEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [passwordLengthError, setPasswordLengthError] = useState(false);
    const [phoneNoError, setPhoneNoError] = useState(false);
    const [phoneNoDigitsError, setPhoneNoDigitsError] = useState(false);
    const [phoneOnlyDigitError, setPhoneNoOnlyDigitError] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            cartListApi();
        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);
                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                })
                .catch((error) => {
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    // login with password
    const signInViaSimple = () => {

        if (email.trim().length == 0) {
            // alert("Enter Email");
            setEmailError(true);
        }
        else if (!validateEmail(email.trim())) {
            // alert("Invalid email");
            setInvalidEmailError(true);
        }
        else if (password.trim().length == 0) {
            // alert("Enter password");
            setPasswordError(true);
        }
        // else if (password.trim().length < 8) {
        //     // alert("Please enter password more than 6 characters");
        //     setPasswordLengthError(true);
        // }
        else {
            try {
                var raw = JSON.stringify({
                    "email": email,
                    "password": password
                });

                var requestOptions = {
                    method: 'POST',
                    body: raw,
                    redirect: 'follow'
                };

                CustomConsole(LOGIN + "\n" + raw);

                setLoading(true);
                fetch(LOGIN, requestOptions)
                    .then(response => response.json())
                    .then((json) => {

                        CustomConsole(json);
                        if (json.success == 1) {
                            //store deatils
                            saveSession(USER_ID, json.data.user_id);
                            saveSession(FIRST_NAME, json.data.first_name);
                            saveSession(LAST_NAME, json.data.last_name);
                            saveSession(PHONE, json.data.phone);
                            saveSession(OTP_VERIFY, json.data.otp_verify);
                            saveSession(EMAIL, json.data.email);
                            saveSession(REFERRAL_CODE, json.data.user_referral_code);
                            saveSession(PASSWORD, password);
                            saveSession(CITY_NAME, json.data.location.city);
                            saveSession(STATE_ID, json.data.location.state_id);
                            saveSession(COUNTRY_ID, json.data.location.country_id);
                            setLoading(false);
                            // navigation.goBack();
                            // window.history.back();
                            // navigation.navigate('DashBoardScreen');
                            // navigation.reset({
                            //     index: 0,
                            //     routes: [{ name: 'DashBoardScreen' }],
                            // });
                            if (route.params) {
                                if (route.params.paramScreenRoute) {
                                    // window.c (route.params.paramScreenRoute);
                                    window.open(route.params.paramScreenRoute, "_self");
                                    // navigation.goBack();
                                } else {
                                    navigation.navigate('DashBoardScreen');
                                }
                            } else {
                                navigation.navigate('DashBoardScreen');
                            }
                        } else {
                            setLoading(false);
                            if (json.error.length > 0) {
                                showAlert(json.error[0]);
                            }
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        CustomConsole("Error=>" + error);
                    });
            } catch (e) {
                setLoading(false);
                CustomConsole("Exception=>" + e + "");
            }

        }
    }

    // login with otp
    const sendToOtp = () => {
        if (phone.trim().length == 0) {
            // alert("Enter Mobile Number");
            setPhoneNoError(true);
        } else if (phone.trim().length != 10) {
            // alert("Enter 10 - Digit Mobile Number");
            setPhoneNoDigitsError(true);
        } else if (!onlyDigitReg.test(phone)) {
            // alert("Mobile Number should be only digits");
            setPhoneNoOnlyDigitError(true);
        }
        else {
            try {
                var raw = JSON.stringify({
                    "phone": phone,
                    "is_guest_checkout": "0"
                });

                var requestOptions = {
                    method: 'POST',
                    body: raw,
                    redirect: 'follow'
                };

                CustomConsole(SENDOTP + "\n" + raw);

                setLoading(true);
                fetch(SENDOTP, requestOptions)
                    .then(response => response.json())
                    .then((json) => {

                        CustomConsole(json);
                        if (json.success == 1) {
                            setLoading(false);
                            navigation.navigate('OtpScreen', {
                                paramOtp: json.otp,
                                paramPhone: phone,
                                paramFrom: "login"
                            });
                        } else {
                            setLoading(false);
                            showAlert(json.message);
                            // if (json.error.length > 0) {
                            //     showAlert(json.error[0]);
                            // }
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        CustomConsole("Error=>" + error);
                    });
            } catch (error) {
                setLoading(false);
            }
        }

    }

    return (
        <View>
            <StatusBar barStyle="dark-content" />
            <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent(cartLength, navigation, userId)}
                    {/* end of Header view */}

                    {/* login form view */}
                    <View style={styles.loginMainView} dataSet={{ media: ids.loginMainView }}>
                        <View style={styles.loginSubView} dataSet={{ media: ids.loginSubView }}>

                            {/* welcome to text view */}
                            <View style={styles.loginWelcomeTextView} dataSet={{ media: ids.loginWelcomeTextView }}>
                                <Image source={require("../assets/header/register.png")}
                                    style={styles.loginImageIcon} dataSet={{ media: ids.loginImageIcon }} />
                                <Text style={styles.loginWelcomeText} dataSet={{ media: ids.loginWelcomeText }}>{"Welcome to\nSoothe Dairy Farm"}</Text>
                                <Image source={require("../assets/header/register-img.png")}
                                    style={styles.loginBackgroundImage} dataSet={{ media: ids.loginBackgroundImage }} />
                            </View>
                            {/* end of welcome to text view */}

                            <View style={styles.loginTextInputView} dataSet={{ media: ids.loginTextInputView }} >

                                {/* view of sign in */}
                                {signInWithOtp ?
                                    <>
                                        <Text style={styles.loginFillDetailsText} dataSet={{ media: ids.loginFillDetailsText }}>Fill the Details to Sign in</Text>

                                        {/* mobile no view */}
                                        <Text style={styles.loginLabelText} dataSet={{ media: ids.loginLabelText }}>Mobile Number</Text>
                                        <TextInput
                                            style={styles.loginTextInput}
                                            value={phone}
                                            onChangeText={txt => { setPhone(txt.replace(/[^0-9]/g, '')); setPhoneNoError(false); setPhoneNoDigitsError(false); setPhoneNoOnlyDigitError(false); }}
                                            placeholderTextColor={colors.placeHolderColor}
                                            maxLength={10}
                                            keyboardType='numeric'
                                            inputMode='numeric'
                                            placeholder="Enter Mobile Number" dataSet={{ media: ids.loginTextInput }} />
                                        {phoneNoError ? <Text style={styles.textInputError}>Enter mobile number</Text> : null}
                                        {phoneNoDigitsError ? <Text style={styles.textInputError}>Enter 10-digit mobile number</Text> : null}
                                        {phoneOnlyDigitError ? <Text style={styles.textInputError}>Mobile number should be only digits</Text> : null}

                                        {/* sign in button view */}
                                        <Pressable style={styles.loginBtn} dataSet={{ media: ids.loginBtn }} onPress={sendToOtp}>
                                            <Text style={styles.loginText} dataSet={{ media: ids.loginText }}>Send OTP</Text>
                                        </Pressable>

                                        {/* divide view */}
                                        <View style={styles.line}>
                                            <Divider style={styles.divider} />
                                            <Text style={styles.lineText}> or </Text>
                                            <Divider style={styles.divider} />
                                        </View>

                                        {/* sign in button view */}
                                        <Pressable style={styles.loginBtn} onPress={() => setSignInWithOtp(false)}>
                                            <Text style={styles.loginText}>Sign in with Password</Text>
                                        </Pressable>

                                    </>
                                    : <>
                                        <Text style={styles.loginFillDetailsText} dataSet={{ media: ids.loginFillDetailsText }}>Fill the Details to Sign in</Text>

                                        {/* email address view */}
                                        <Text style={styles.loginLabelText} dataSet={{ media: ids.loginLabelText }}>Email ID</Text>
                                        <TextInput
                                            style={styles.loginTextInput}
                                            dataSet={{ media: ids.loginTextInput }}
                                            value={email}
                                            onChangeText={txt => { setEmail(txt); setEmailError(false); setInvalidEmailError(false); }}
                                            placeholderTextColor={colors.placeHolderColor}
                                            placeholder="Enter Email address" />
                                        {emailError ? <Text style={styles.textInputError}>Enter email address</Text> : null}
                                        {invalidEmailError ? <Text style={styles.textInputError}>Invalid email address</Text> : null}

                                        {/* password view */}
                                        <Text style={styles.loginLabelText} dataSet={{ media: ids.loginLabelText }}>Password</Text>
                                        <View style={styles.loginPasswordView} dataSet={{ media: ids.loginPasswordView }}>
                                            <TextInput
                                                style={styles.loginPasswordTextInput}
                                                dataSet={{ media: ids.loginPasswordTextInput }}
                                                value={password}
                                                onChangeText={txt => { setPassword(txt); setPasswordError(false); setPasswordLengthError(false); }}
                                                placeholderTextColor={colors.placeHolderColor}
                                                placeholder="Enter Password"
                                                secureTextEntry={!passwordShow} />
                                            <Pressable onPress={() => { setPasswordShow(!passwordShow) }}>
                                                <Image source={passwordShow ? require("../assets/header/plus.png") : require("../assets/header/hide.png")}
                                                    style={styles.loginPasswordIcon} dataSet={{ media: ids.loginPasswordIcon }} />
                                            </Pressable>
                                        </View>
                                        {passwordError ? <Text style={styles.textInputError}>Enter password</Text> : null}
                                        {passwordLengthError ? <Text style={styles.textInputError}>Please enter password more than 8 characters</Text> : null}

                                        {/* forgot password view */}
                                        <View style={styles.forgotPasswordContainer} dataSet={{ media: ids.forgotPasswordContainer }}>
                                            <Pressable onPress={() => navigation.navigate("ForgotPasswordScreen")}>
                                                <Text style={styles.forgotPasswordText} dataSet={{ media: ids.forgotPasswordText }}>Forgot Password ?</Text>
                                            </Pressable>
                                        </View>

                                        {/* sign in button view */}
                                        <Pressable style={styles.loginBtn} onPress={signInViaSimple}>
                                            <Text style={styles.loginText}>Sign in</Text>
                                        </Pressable>

                                        {/* divide view */}
                                        <View style={styles.line}>
                                            <Divider style={styles.divider} />
                                            <Text style={styles.lineText}> or </Text>
                                            <Divider style={styles.divider} />
                                        </View>

                                        {/* sign in button view */}
                                        <Pressable style={styles.loginBtn} onPress={() => setSignInWithOtp(true)}>
                                            <Text style={styles.loginText}>Sign in with OTP</Text>
                                        </Pressable>

                                    </>}
                                {/* end of sign in */}

                                {/* sign up view */}
                                <View style={styles.bottomContainer}>
                                    <Text style={styles.colorBlackText} dataSet={{ media: ids.colorBlackText }}>Don't have an account? </Text>
                                    <Pressable onPress={() => navigation.navigate("RegisterScreen")}>
                                        <Text style={styles.createAccountText}>sign up</Text>
                                    </Pressable>
                                </View>
                                {/* end of sign up view */}

                            </View>
                        </View>
                    </View>
                    {/* end of form view */}

                    {/* common modal */}
                    <Modal
                        onRequestClose={hideAlert}
                        transparent
                        visible={alertShow}
                        animationType={'slide'}
                    >
                        <View style={styles.commonModalMainView} dataSet={{ media: ids.commonModalMainView }}>
                            <View style={[styles.commonModalViewContainer, offset]} dataSet={{ media: ids.commonModalViewContainer }}>

                                <View>
                                    <Text style={styles.commonModalText}>{alertMsg}</Text>
                                </View>
                                <Pressable
                                    onPress={hideAlert}
                                    style={styles.commonModalButton}>
                                    <Text style={styles.commonModalButtonText}>Ok, Got it</Text>
                                </Pressable>
                            </View>
                        </View>
                    </Modal>
                    {/* end of common modal */}

                    {/* Footer view */}
                    {/* {FooterComponent(navigation, userId)}*/}
                    {/* end of Footer view */}

                </View>

            </ScrollView>
        </View>
    );
};

export default LoginScreen;
