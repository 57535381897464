import React, { useState, useMemo } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, Modal } from 'react-native';
import { colors } from '../common/color';
import { CustomConsole, HeaderComponent, FooterComponent, getDMSansBoldFont, getDMSansLightFont, scrollToTop, validateEmail, CustomProgressBar } from '../common/utils';
import { CARTLIST, FORGOTPASSWORD, LOGIN, SENDOTP } from '../common/webUtils';
import { EMAIL, FIRST_NAME, LAST_NAME, OTP_VERIFY, PASSWORD, PHONE, USER_ID, saveSession, getSession } from '../common/LocalStorage';
import { Image } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { styles, ids } from '../common/responsive.js';

function ForgotPasswordScreen({ navigation }) {

    const WIGGLE_ROOM = 128;

    const offset = useMemo(() => {
        return {
            top: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2,
            left: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2
        };
    }, []);

    const [alertShow, setAlertShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    // modal hide/show
    const showAlert = (text) => {
        setAlertShow(true);
        setAlertMsg(text)
    };
    const hideAlert = () => setAlertShow(false);

    // const [email, setEmail] = useState('kapildev12@alakmalak.com');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [cartLength, setcartLength] = React.useState('0');
    const [emailError, setEmailError] = useState(false);
    const [invalidEmailError, setInvalidEmailError] = useState(false);

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            cartListApi();
        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);

            // setLoading(true);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                    // setLoading(false);
                })
                .catch((error) => {
                    // setLoading(false);
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    // send otp
    const sendToOtp = () => {
        // navigation.navigate('ChangePasswordScreen', {
        //     paramToken: '',
        //     paramEmail: email,
        //     paramFrom: "forgotpassword"
        // });
        if (email.trim().length == 0) {
            // alert("Enter Email");
            setEmailError(true);
        }
        else if (!validateEmail(email.trim())) {
            // alert("Invalid email");
            setInvalidEmailError(true);
        }
        else {
            try {
                var raw = JSON.stringify({
                    "email": email
                });

                var requestOptions = {
                    method: 'POST',
                    body: raw,
                    redirect: 'follow'
                };

                CustomConsole(FORGOTPASSWORD + "\n" + raw);

                setLoading(true);
                fetch(FORGOTPASSWORD, requestOptions)
                    .then(response => response.json())
                    .then((json) => {

                        CustomConsole(json);
                        if (json.success == 1) {
                            setLoading(false);
                            // alert(json.data.msg);
                            navigation.navigate('OtpScreen', {
                                paramOtp: json.data.otp,
                                paramEmail: email,
                                paramPhone: json.data.telephone,
                                paramFrom: "forgotpassword"
                            });

                        } else {
                            setLoading(false);
                            // if (json.error.length > 0) {
                            showAlert(json.data.msg);
                            // }
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        CustomConsole("Error=>" + error);
                    });
            } catch (error) {
                setLoading(false);
            }
        }

    }

    return (
        <View>
            <StatusBar barStyle="dark-content" />
            <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent(cartLength, navigation, userId)}
                    {/* end of Header view */}

                    {/* forgot password form view */}
                    <View style={styles.loginMainView} dataSet={{ media: ids.loginMainView }}>
                        <View style={styles.loginSubView} dataSet={{ media: ids.loginSubView }}>

                            {/* welcome to text view */}
                            <View style={styles.loginWelcomeTextView} dataSet={{ media: ids.loginWelcomeTextView }}>
                                <Image source={require("../assets/header/register.png")}
                                    style={styles.loginImageIcon} dataSet={{ media: ids.loginImageIcon }} />
                                <Text style={styles.loginWelcomeText} dataSet={{ media: ids.loginWelcomeText }}>{"Forgot\nPassword"}</Text>
                                <Image source={require("../assets/header/register-img.png")}
                                    style={styles.loginBackgroundImage} dataSet={{ media: ids.loginBackgroundImage }} />
                            </View>
                            {/* end of welcome to text view */}

                            <View style={styles.loginTextInputView} dataSet={{ media: ids.loginTextInputView }}>

                                {/* view of forgot password */}
                                <Text style={styles.loginFillDetailsText} dataSet={{ media: ids.loginFillDetailsText }}>Fill the Details to Forgot password</Text>

                                {/* email address view */}
                                <Text style={styles.loginLabelText} dataSet={{ media: ids.loginLabelText }}>Email ID</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    dataSet={{ media: ids.loginTextInput }}
                                    value={email}
                                    onChangeText={txt => { setEmail(txt); setEmailError(false); setInvalidEmailError(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter Email address" />
                                {emailError ? <Text style={styles.textInputError}>Enter email address</Text> : null}
                                {invalidEmailError ? <Text style={styles.textInputError}>Invalid email address</Text> : null}

                                {/* send otp in button view */}
                                <Pressable style={styles.loginBtn} onPress={sendToOtp}>
                                    <Text style={styles.loginText}>Send OTP</Text>
                                </Pressable>
                                {/* end of forgot password */}

                                {/* sign in view */}
                                <View style={styles.bottomContainer}>
                                    <Text style={styles.colorBlackText}>Back to </Text>
                                    <Pressable onPress={() => navigation.goBack()}>
                                        <Text style={styles.createAccountText}>sign in</Text>
                                    </Pressable>
                                </View>
                                {/* end of sign in view */}

                            </View>
                        </View>
                    </View>
                    {/* end of forgot password view */}

                    {/* common modal */}
                    <Modal
                        onRequestClose={hideAlert}
                        transparent
                        visible={alertShow}
                        animationType={'slide'}
                    >
                        <View style={styles.commonModalMainView} dataSet={{ media: ids.commonModalMainView }}>
                            <View style={[styles.commonModalViewContainer, offset]} dataSet={{media:ids.commonModalViewContainer}}>

                                <View>
                                    <Text style={styles.commonModalText}>{alertMsg}</Text>
                                </View>
                                <Pressable
                                    onPress={hideAlert}
                                    style={styles.commonModalButton}>
                                    <Text style={styles.commonModalButtonText}>Ok, Got it</Text>
                                </Pressable>
                            </View>
                        </View>
                    </Modal>
                    {/* end of common modal */}

                    {/* Footer view */}
                    {/* {FooterComponent(navigation, userId)}*/}
                    {/* end of Footer view */}

                </View>

            </ScrollView>
        </View>
    );
};

export default ForgotPasswordScreen;
