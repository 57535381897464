import React, { useState, useMemo } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, Modal } from 'react-native';
import { colors } from '../common/color';
import { APP_NAME } from '../common/string';
import { CustomConsole, HeaderComponent, FooterComponent, getDMSansBoldFont, getDMSansLightFont, getDMSansRegularFont, scrollToTop, validateEmail, CustomProgressBar } from '../common/utils';
import { CARTLIST, CHANGEPASSWORD, CHANGEPASSWORDDRAWER, LOGIN, SENDOTP } from '../common/webUtils';
import { EMAIL, FIRST_NAME, LAST_NAME, OTP_VERIFY, PASSWORD, PHONE, USER_ID, saveSession, getSession } from '../common/LocalStorage';
import { Image } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { Divider } from 'react-native-paper';
import { styles, ids } from '../common/responsive.js';

function ChangePasswordScreen({ navigation, route }) {

    const email = route.params.paramEmail;
    const routeFrom = route.params.paramFrom;
    const token = route.params.paramToken;

    const WIGGLE_ROOM = 128;

    const offset = useMemo(() => {
        return {
            top: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2,
            left: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2
        };
    }, []);

    const [alertShow, setAlertShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    // modal hide/show
    const showAlert = (text) => {
        setAlertShow(true);
        setAlertMsg(text)
    };
    const hideAlert = () => setAlertShow(false);

    // success modal
    const [alertSuccessShow, setAlertSuccessShow] = useState(false);
    const [alertSuccessMsg, setAlertSuccessMsg] = useState('');

    // modal hide/show
    const showSuccessAlert = (text) => {
        setAlertSuccessShow(true);
        setAlertSuccessMsg(text)
    };
    const hideSuccessAlert = () => setAlertSuccessShow(false);

    const [password, setPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [cartLength, setcartLength] = useState('0');
    const [passwordError, setPasswordError] = useState(false);
    const [oldPasswordError, setOldPasswordError] = useState(false);
    const [oldPasswordMisMatchError, setOldPasswordMisMatchError] = useState(false);
    const [passwordLengthError, setPasswordLengthError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [matchPasswordError, setMatchPasswordError] = useState(false);
    const [oldPasswordShow, setOldPasswordShow] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            cartListApi();
        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);

            // setLoading(true);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                    // setLoading(false);
                })
                .catch((error) => {
                    // setLoading(false);
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    // change password
    const signInViaSimple = async () => {

        if (routeFrom == "drawer") {
            const save_passowrd = await getSession(PASSWORD);

            if (oldPassword.trim().length == 0) {
                // alert("Enter password");
                setOldPasswordError(true);
            }
            else if (oldPassword != save_passowrd) {
                setOldPasswordMisMatchError(true);
            }
            else if (password.trim().length == 0) {
                // alert("Enter password");
                setPasswordError(true);
            }
            else if (confirm_password.trim().length == 0) {
                // alert("Enter confirm password");
                setConfirmPasswordError(true);
            }
            else if (password !== confirm_password) {
                // alert("Password and confirm password should be same.");
                setMatchPasswordError(true);
            }
            else {
                try {
                    const user_id = await getSession(USER_ID);

                    var raw = JSON.stringify({
                        "user_id": user_id,
                        "new_password": password
                    });

                    var requestOptions = {
                        method: 'POST',
                        body: raw,
                        redirect: 'follow'
                    };

                    CustomConsole(CHANGEPASSWORDDRAWER + "\n" + raw);

                    setLoading(true);
                    fetch(CHANGEPASSWORDDRAWER, requestOptions)
                        .then(response => response.json())
                        .then((json) => {

                            CustomConsole(json);
                            if (json.success == 1) {
                                setLoading(false);
                                showSuccessAlert(json.data.msg);
                                saveSession(PASSWORD, password);
                                // navigation.navigate('LoginScreen');
                            } else {
                                setLoading(false);
                                // if (json.error.length > 0) {
                                showAlert(json.data);
                                // }
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            CustomConsole("Error=>" + error);
                        });
                } catch (e) {
                    setLoading(false);
                    CustomConsole("Exception=>" + e + "");
                }
            }
        } else {
            if (password.trim().length == 0) {
                // alert("Enter password");
                setPasswordError(true);
            }
            // else if (password.trim().length < 8) {
            //     // alert("Please enter password more than 6 characters");
            //     setPasswordLengthError(true);
            // }
            else if (confirm_password.trim().length == 0) {
                // alert("Enter confirm password");
                setConfirmPasswordError(true);
            }
            // else if (confirm_password.trim().length < 6) {
            //     alert("Please enter confirm password more than 6 characters");
            // }
            else if (password !== confirm_password) {
                // alert("Password and confirm password should be same.");
                setMatchPasswordError(true);
            }
            else {
                try {
                    var raw = JSON.stringify({
                        "email": email,
                        "token": token,
                        "password": password
                    });

                    var requestOptions = {
                        method: 'POST',
                        body: raw,
                        redirect: 'follow'
                    };

                    CustomConsole(CHANGEPASSWORD + "\n" + raw);

                    setLoading(true);
                    fetch(CHANGEPASSWORD, requestOptions)
                        .then(response => response.json())
                        .then((json) => {

                            CustomConsole(json);
                            if (json.success == 1) {
                                setLoading(false);
                                // alert(json.data.msg);
                                navigation.navigate('LoginScreen');
                            } else {
                                setLoading(false);
                                // if (json.error.length > 0) {
                                showAlert(json.data);
                                // }
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            CustomConsole("Error=>" + error);
                        });
                } catch (e) {
                    setLoading(false);
                    CustomConsole("Exception=>" + e + "");
                }

            }
        }
    }

    return (
        <View>
            <StatusBar barStyle="dark-content" />
            <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent(cartLength, navigation, userId)}
                    {/* end of Header view */}

                    {/* password form view */}
                    <View style={styles.loginMainView} dataSet={{ media: ids.loginMainView }}>
                        <View style={styles.loginSubView} dataSet={{ media: ids.loginSubView }}>

                            <View style={styles.loginWelcomeTextView} dataSet={{ media: ids.loginWelcomeTextView }}>
                                <Image source={require("../assets/header/register.png")}
                                    style={styles.loginImageIcon} dataSet={{ media: ids.loginImageIcon }} />
                                <Text style={styles.loginWelcomeText} dataSet={{ media: ids.loginWelcomeText }}>{"Change\nPassword"}</Text>
                                <Image source={require("../assets/header/register-img.png")}
                                    style={styles.loginBackgroundImage} dataSet={{ media: ids.loginBackgroundImage }} />
                            </View>

                            <View style={styles.loginTextInputView} dataSet={{ media: ids.loginTextInputView }}>

                            {routeFrom == "drawer" ?
                            <>
                                {/* old password view */}
                                <Text style={styles.changePasswordLabelText} dataSet={{ media: ids.changePasswordLabelText }}>Old Password</Text>
                                <View style={styles.loginPasswordView} dataSet={{ media: ids.loginPasswordView }}>
                                    <TextInput
                                        style={styles.loginPasswordTextInput}
                                        dataSet={{ media: ids.loginPasswordTextInput }}
                                        value={oldPassword}
                                        onChangeText={txt => { setOldPassword(txt); setOldPasswordError(false); setOldPasswordMisMatchError(false); }}
                                        placeholderTextColor={colors.placeHolderColor}
                                        placeholder="Enter Old Password"
                                        secureTextEntry={!oldPasswordShow} />
                                    <Pressable onPress={() => { setOldPasswordShow(!oldPasswordShow) }}>
                                        <Image source={oldPasswordShow ? require("../assets/header/plus.png") : require("../assets/header/hide.png")}
                                            style={styles.loginPasswordIcon} dataSet={{ media: ids.loginPasswordIcon }} />
                                    </Pressable>
                                </View>
                                {oldPasswordError ? <Text style={styles.textInputError}>Enter old password</Text> : null}
                                {oldPasswordMisMatchError ? <Text style={styles.textInputError}>Your old password is wrong</Text> : null}
                                </>:null}

                                {/* password view */}
                                <Text style={styles.changePasswordLabelText} dataSet={{ media: ids.changePasswordLabelText }}>Password</Text>
                                <View style={styles.loginPasswordView} dataSet={{ media: ids.loginPasswordView }}>
                                    <TextInput
                                        style={styles.loginPasswordTextInput}
                                        dataSet={{ media: ids.loginPasswordTextInput }}
                                        value={password}
                                        onChangeText={txt => { setPassword(txt); setPasswordError(false); setPasswordLengthError(false); setMatchPasswordError(false); }}
                                        placeholderTextColor={colors.placeHolderColor}
                                        placeholder="Enter Password"
                                        secureTextEntry={!passwordShow} />
                                    <Pressable onPress={() => { setPasswordShow(!passwordShow) }}>
                                        <Image source={passwordShow ? require("../assets/header/plus.png") : require("../assets/header/hide.png")}
                                            style={styles.loginPasswordIcon} dataSet={{ media: ids.loginPasswordIcon }} />
                                    </Pressable>
                                </View>
                                {passwordError ? <Text style={styles.textInputError}>Enter password</Text> : null}
                                {passwordLengthError ? <Text style={styles.textInputError}>Please enter password more than 8 characters</Text> : null}

                                <Text style={styles.changePasswordLabelText} dataSet={{ media: ids.changePasswordLabelText }}>Confirm Password</Text>
                                <View style={styles.loginPasswordView} dataSet={{ media: ids.loginPasswordView }}>
                                    <TextInput
                                        style={styles.loginPasswordTextInput}
                                        dataSet={{ media: ids.loginPasswordTextInput }}
                                        value={confirm_password}
                                        onChangeText={txt => { setConfirmPassword(txt); setConfirmPasswordError(false); setMatchPasswordError(false); }}
                                        placeholderTextColor={colors.placeHolderColor}
                                        placeholder="Enter Confirm Password"
                                        secureTextEntry={!confirmPasswordShow} />
                                    <Pressable onPress={() => { setConfirmPasswordShow(!confirmPasswordShow) }}>
                                        <Image source={confirmPasswordShow ? require("../assets/header/plus.png") : require("../assets/header/hide.png")}
                                            style={styles.loginPasswordIcon} dataSet={{ media: ids.loginPasswordIcon }} />
                                    </Pressable>
                                </View>
                                {confirmPasswordError ? <Text style={styles.textInputError}>Enter confirm password</Text> : null}
                                {matchPasswordError ? <Text style={styles.textInputError}>Please Check Password and Confirm Password. Both should be same</Text> : null}

                                {/* change password button view */}
                                <Pressable style={styles.loginBtn} onPress={signInViaSimple}>
                                    <Text style={styles.loginText}>Change password</Text>
                                </Pressable>
                                {/* end of password */}

                                {/* sign in view */}
                                {routeFrom != "drawer" ? <View style={styles.bottomContainer}>
                                    <Text style={styles.colorBlackText}>Back to </Text>
                                    <Pressable onPress={() => navigation.goBack()}>
                                        <Text style={styles.createAccountText}>sign in</Text>
                                    </Pressable>
                                </View> : null}
                                {/* end of sign in view */}

                            </View>
                        </View>
                    </View>
                    {/* end of form view */}

                    {/* common modal */}
                    <Modal
                        onRequestClose={hideAlert}
                        transparent
                        visible={alertShow}
                        animationType={'slide'}
                    >
                        <View style={styles.commonModalMainView} dataSet={{ media: ids.commonModalMainView }}>
                            <View style={[styles.commonModalViewContainer, offset]} dataSet={{ media: ids.commonModalViewContainer }}>

                                <View>
                                    <Text style={styles.commonModalText}>{alertMsg}</Text>
                                </View>
                                <Pressable
                                    onPress={hideAlert}
                                    style={styles.commonModalButton}>
                                    <Text style={styles.commonModalButtonText}>Ok, Got it</Text>
                                </Pressable>
                            </View>
                        </View>
                    </Modal>
                    {/* end of common modal */}


                    {/* success modal */}
                    <Modal
                        onRequestClose={hideSuccessAlert}
                        transparent
                        visible={alertSuccessShow}
                        animationType={'slide'}
                    >
                        <View style={styles.commonModalMainView} dataSet={{ media: ids.commonModalMainView }}>
                            <View style={[styles.commonModalViewContainer, offset]} dataSet={{ media: ids.commonModalViewContainer }}>

                                <View>
                                    <Text style={styles.commonModalText}>{alertSuccessMsg}</Text>
                                </View>
                                <Pressable
                                    onPress={()=>{
                                        navigation.goBack();
                                        hideSuccessAlert();
                                    }}
                                    style={styles.commonModalButton}>
                                    <Text style={styles.commonModalButtonText}>Ok, Got it</Text>
                                </Pressable>
                            </View>
                        </View>
                    </Modal>
                    {/* end of success modal */}

                    {/* Footer view */}
                    {/* {FooterComponent(navigation, userId)}*/}
                    {/* end of Footer view */}

                </View>

            </ScrollView>
        </View>
    );
};

export default ChangePasswordScreen;