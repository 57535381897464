import React, { useState, useRef, useMemo } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, Image, FlatList, Dimensions, useWindowDimensions, Linking } from 'react-native';
import { colors } from '../common/color';
import { CustomConsole, HeaderComponent, FooterComponent, getDMSansBoldFont, getDMSansLightFont, getDMSansMediumFont, getDMSansRegularFont, getDMSansSemiBoldFont, getRobotoSlabExtraBoldFont, scrollToTop, validateEmail, CustomModal } from '../common/utils';
import { BANNER, CARTLIST, FAQ, FEATURED, GETFAQ, LOGIN, PRODUCT, STORY, TOPREVIEWS } from '../common/webUtils';
import { useIsFocused } from '@react-navigation/native';
import RenderHtml, { RenderHTML } from 'react-native-render-html';
import { getSession, USER_ID } from '../common/LocalStorage';
import { Button, Dialog, Portal, PaperProvider, Chip, } from 'react-native-paper';
import { Modal } from 'react-native';
import { styles, ids } from '../common/responsive.js';

function DashBoardScreen({ navigation }) {

    const scrollRef = useRef();

    const onPressTouch = () => {
        scrollRef.current?.scrollTo({
            y: 0,
            animated: true,
        });
    }

    const [visible, setVisible] = React.useState(false);
    const [answer, setAnswer] = React.useState('');
    const [question, setQuestion] = React.useState('');
    const [scrollposition, setscrollposition] = React.useState('');
    const [loading, setLoading] = useState('');
    const [userId, setUserId] = useState('');
    const [hover, setHover] = useState(0);
    const [questionHover, setQuestionHover] = useState(0);
    const [questionNo, setQuestionNo] = useState(0);
    const [product, setProduct] = useState(0);
    const [bannerList, setBannerList] = useState([]);
    const [checked, setChecked] = React.useState('first');
    const [productList, setProductList] = useState([]);
    const [selectSize, setSelectSize] = useState([]);
    const [faqList, setfaqList] = useState([]);
    const [storyList, setStoryList] = useState([]);
    const [reviewList, setReviewList] = useState([]);
    const [selectedBannerImage, setSelectedBannerImage] = useState('');
    const [selectedBannerName, setSelectedBannerName] = useState('');
    const [selectedBannerSubName, setSelectedBannerSubName] = useState('');
    const [selectedBannerFat, setSelectedBannerFat] = useState('');
    const [selectedBannerSize, setSelectedBannerSize] = useState('');
    const [selectedBannerOther, setSelectedBannerOther] = useState('');
    const [cartLength, setcartLength] = React.useState('0');

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        // onPressTouch();
        scrollToTop();
    }, [isFocused, productList.length == 0])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            bannerListApi();
            cartListApi();

        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);

            // setLoading(true);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                    // setLoading(false);
                })
                .catch((error) => {
                    // setLoading(false);
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    // banner list api
    const bannerListApi = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(BANNER);

            setLoading(true);
            fetch(BANNER, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    bannerList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {
                        setSelectedBannerFat(json.data[0].fat_content);
                        setSelectedBannerImage(json.data[0].image);
                        setSelectedBannerName(json.data[0].title);
                        setSelectedBannerSubName(json.data[0].subtitle);
                        setSelectedBannerOther(json.data[0].other_content);
                        setSelectedBannerSize(json.data[0].size);
                        bannerList.push({
                            index: i,
                            id: json.data[i].id,
                            title: json.data[i].title,
                            link: json.data[i].link,
                            sort_order: json.data[i].sort_order,
                            image: json.data[i].image,
                            size: json.data[i].size,
                            fat_content: json.data[i].fat_content,
                            other_content: json.data[i].other_content,
                            subtitle: json.data[i].subtitle,
                        });
                    }

                    setBannerList(bannerList);
                    productListApi();
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Banner Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Banner Exception=>" + e + "");
        }
    }

    // product list api
    const productListApi = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(FEATURED);

            // setLoading(true);
            fetch(FEATURED, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    productList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {

                        let sizes_arr = [];

                        if (json.data[i].options.length != 0) {
                            for (let j = 0; j < json.data[i].options[0].product_option_value.length; j++) {
                                sizes_arr.push({
                                    "product_option_value_id": json.data[i].options[0].product_option_value[j].product_option_value_id,
                                    "option_value_id": json.data[i].options[0].product_option_value[j].option_value_id,
                                    "name": json.data[i].options[0].product_option_value[j].name,
                                    "image": json.data[i].options[0].product_option_value[j].image,
                                    "quantity": json.data[i].options[0].product_option_value[j].quantity,
                                    "subtract": json.data[i].options[0].product_option_value[j].subtract,
                                    "price": json.data[i].options[0].product_option_value[j].price,
                                    "price_prefix": json.data[i].options[0].product_option_value[j].price_prefix,
                                    "weight": json.data[i].options[0].product_option_value[j].weight,
                                    "weight_prefix": json.data[i].options[0].product_option_value[j].weight_prefix,
                                    "product_option_id": json.data[i].options[0].product_option_id
                                });
                            }
                            let selectedSize = selectSize;
                            selectedSize[i] = {
                                product_option_value_id: json.data[i].options[0].product_option_value[0].product_option_value_id,
                                name: json.data[i].options[0].product_option_value[0].name,
                                product_option_id: json.data[i].options[0].product_option_id,
                                price: json.data[i].options[0].product_option_value[0].price,
                            }
                            setSelectSize(selectedSize);
                        } else {
                            CustomConsole("Index" + i);
                            let selectedSize = selectSize;
                            selectedSize[i] = {
                                product_option_value_id: '',
                                name: '',
                                product_option_id: '',
                                price: '0'
                            }
                            setSelectSize(selectedSize);
                        }

                        productList.push({
                            index: i,
                            product_id: json.data[i].product_id,
                            name: json.data[i].name,
                            is_wallet: json.data[i].is_wallet == undefined ? 0 : json.data[i].is_wallet,
                            description: json.data[i].description.split("</p>")[0].slice(0, 120),
                            price: json.data[i].price.substring(1).replace(",", ""),
                            currencySymbol: json.data[i].price.substring(0, 1),
                            image: json.data[i].image != "https://dairyfarm.alakmalak.ca/image/" ? { uri: json.data[i].image } : require("../assets/header/footer-logo.png"),
                            weight: parseFloat(json.data[i].weight).toFixed(2),
                            rating: Math.floor(parseFloat(json.data[i].rating)),
                            sizes: sizes_arr
                        });
                    }

                    setProductList(productList);
                    faqListApi();
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Product Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Product Exception=>" + e + "");
        }
    }

    // faq list
    const faqListApi = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(FAQ);

            setLoading(true);
            fetch(FAQ, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    if (json.data.length != 0) {
                        getFaqListApi(json.data[0].module_id);
                    } else {

                    }
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("FAQ Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("FAQ Exception=>" + e + "");
        }
    }

    // get faq list
    const getFaqListApi = async (id) => {
        try {

            var raw = JSON.stringify({
                "faq_id": id
            });

            var requestOptions = {
                method: 'POST',
                redirect: 'follow',
                body: raw

            };

            CustomConsole(GETFAQ);

            setLoading(true);
            fetch(GETFAQ, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    faqList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {
                        faqList.push({
                            index: i,
                            question_id: json.data[i].question_id,
                            title: json.data[i].title,
                            description: (json.data[i].description).replace(/&lt;/g, '<').replace(/&gt;/g, '>')
                        });
                    }

                    setfaqList(faqList);
                    getStoryListApi();
                    getReviewsListApi();

                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("GETFAQ Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("GETFAQ Exception=>" + e + "");
        }
    }

    // reviews list
    const getReviewsListApi = async (id) => {
        try {

            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(TOPREVIEWS);

            setLoading(true);
            fetch(TOPREVIEWS, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    reviewList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {
                        if (i > 2) {
                            break;
                        }

                        reviewList.push({
                            index: i,
                            review_id: json.data[i].review_id,
                            product_id: json.data[i].product_id,
                            rating: Math.floor(parseFloat(json.data[i].rating)),
                            text: json.data[i].text,
                            author: json.data[i].author,
                            authorFirstLetter: json.data[i].author.substring(0, 1),
                        });
                    }

                    setReviewList(reviewList);

                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("review Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("review Exception=>" + e + "");
        }
    }

    // story list
    const getStoryListApi = async (id) => {
        try {

            var requestOptions = {
                method: 'GET',
                redirect: 'follow',
            };

            CustomConsole(STORY + "&product_id=");

            setLoading(true);
            fetch(STORY + "&product_id=", requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    storyList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {
                        storyList.push({
                            index: i,
                            story: (json.data[i].story).replace(/&lt;/g, '<').replace(/&gt;/g, '>'),
                            image: json.data[i].image,
                            date: (json.data[i].date).replace(/\-/g, '.'),
                            link: json.data[i].link,

                        });
                    }

                    setStoryList(storyList);

                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Banner Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Banner Exception=>" + e + "");
        }
    }

    // banner small items view
    const smallBannerItem = (item, index) => {
        return (
            <Pressable onPress={() => {
                setSelectedBannerFat(item.fat_content);
                setSelectedBannerImage(item.image);
                setSelectedBannerName(item.title);
                setSelectedBannerSubName(item.subtitle);
                setSelectedBannerOther(item.other_content);
                setSelectedBannerSize(item.size);
            }}
                style={item.title == selectedBannerName ? styles.smallBannerActiveItemView : styles.smallBannerInActiveItemView} dataSet={{ media: item.title == selectedBannerName ? ids.smallBannerActiveItemView : ids.smallBannerInActiveItemView }}>
                <Text style={styles.smallBannerItemTitle} dataSet={{ media: ids.smallBannerItemTitle }}>{item.title}</Text>
                <View style={styles.smallBannerItemImageView} dataSet={{ media: ids.smallBannerItemImageView }}>
                    <Image source={{ uri: item.image }} style={styles.smallBannerItemImage} dataSet={{ media: ids.smallBannerItemImage }} />
                </View>
            </Pressable>
        );
    }

    // FAQ item view
    const questionItem = (item, index) => {
        return (
            <Chip
                onMouseEnter={() => { setQuestionHover(1); setQuestionNo(index); }}
                onMouseLeave={() => { setQuestionHover(0); setQuestionNo(0); }}
                onPress={() => {
                    showDialog(item.description, item.title);
                    CustomConsole(scrollposition)
                }} mode="outlined"
                style={questionHover == 1 && questionNo == index ? styles.questionActiveItemView : styles.questionInactiveItemView}
                dataSet={{ media: questionHover == 1 && questionNo == index ? ids.questionActiveItemView : ids.questionInactiveItemView }}
                textStyle={questionHover == 1 && questionNo == index ? styles.questionActiveItemText : styles.questionInactiveItemText}
                avatar={
                    <View style={styles.questionItemIconView}>
                        <Image source={require("../assets/header/question.png")} style={styles.questionItemIcon} />
                    </View>
                }
                maxFontSizeMultiplier={10}
            >{item.title}
                <Image source={require("../assets/header/right_arrow.png")}
                    style={questionHover == 1 && questionNo == index ? styles.questionActiveItemRightArrow : styles.questionInactiveItemRightArrow} />
            </Chip>
        );
    }

    // product review item view
    const reviewItem = (item, index) => {
        if (Dimensions.get("window").width > 767) {
            return (
                <View style={[styles.dashboardReviewItemMainFlexEndView, {
                    alignSelf: (index + 1) % 2 == 0 ? "flex-end" : "flex-start",
                    marginLeft: (index + 1) % 3 == 0 ? 10 : 0,
                    marginTop: (index + 1) % 3 == 0 ? -9 : 0,
                }]} dataSet={{ media: ids.dashboardReviewItemMainFlexEndView }}>
                    <View style={{}}>
                        <View style={styles.dashboardReviewItemSubView}>
                            {/* <View style={{ height: 40, width: 40, borderRadius: 20, justifyContent: "center", alignItems: "center" }}>
                                <Image source={require("../assets/header/anti.png")}
                                    style={{
                                        height: 40,
                                        width: 40, resizeMode: "contain"
                                    }} />
                            </View> */}
                            <View style={styles.dashboardReviewItemAutherView}>
                                <Text style={styles.dashboardReviewItemAutherText} dataSet={{ media: ids.dashboardReviewItemAutherText }}>{item.authorFirstLetter}</Text>
                            </View>
                            <View style={styles.dashboardReviewItemReviewStarView}>
                                <Text style={styles.dashboardReviewItemAutherSecondText}>{item.author}</Text>
                                <View style={styles.dashboardReviewItemReviewStarSubView}>
                                    <Image source={require("../assets/header/star.png")}
                                        style={item.rating >= 1 ? styles.dashboardReviewActiveStar : styles.dashboardReviewInactiveStar} />
                                    <Image source={require("../assets/header/star.png")}
                                        style={item.rating >= 2 ? styles.dashboardReviewActiveStar : styles.dashboardReviewInactiveStar} />
                                    <Image source={require("../assets/header/star.png")}
                                        style={item.rating >= 3 ? styles.dashboardReviewActiveStar : styles.dashboardReviewInactiveStar} />
                                    <Image source={require("../assets/header/star.png")}
                                        style={item.rating >= 4 ? styles.dashboardReviewActiveStar : styles.dashboardReviewInactiveStar} />
                                    <Image source={require("../assets/header/star.png")}
                                        style={item.rating >= 5 ? styles.dashboardReviewActiveStar : styles.dashboardReviewInactiveStar} />
                                </View>
                            </View>
                            <Image source={require("../assets/header/quote.png")} style={styles.dashboardReviewQuoteImage} />
                        </View>

                        <Text style={styles.dashboardReviewText} numberOfLines={5} dataSet={{ media: ids.dashboardReviewText }}>{item.text}</Text>
                    </View>
                </View>
            );
        } else {
            return (
                <View style={[styles.dashboardReviewItemMainFlexEndViewMobile, {
                }]} dataSet={{ media: ids.dashboardReviewItemMainFlexEndViewMobile }}>
                    <View style={{}}>
                        <View style={styles.dashboardReviewItemSubView}>
                            {/* <View style={{ height: 40, width: 40, borderRadius: 20, justifyContent: "center", alignItems: "center" }}>
                                <Image source={require("../assets/header/anti.png")}
                                    style={{
                                        height: 40,
                                        width: 40, resizeMode: "contain"
                                    }} />
                            </View> */}
                            <View style={styles.dashboardReviewItemAutherView}>
                                <Text style={styles.dashboardReviewItemAutherText}>{item.authorFirstLetter}</Text>
                            </View>
                            <View style={styles.dashboardReviewItemReviewStarView}>
                                <Text style={styles.dashboardReviewItemAutherSecondText}>{item.author}</Text>
                                <View style={styles.dashboardReviewItemReviewStarSubView}>
                                    <Image source={require("../assets/header/star.png")}
                                        style={item.rating >= 1 ? styles.dashboardReviewActiveStar : styles.dashboardReviewInactiveStar} />
                                    <Image source={require("../assets/header/star.png")}
                                        style={item.rating >= 2 ? styles.dashboardReviewActiveStar : styles.dashboardReviewInactiveStar} />
                                    <Image source={require("../assets/header/star.png")}
                                        style={item.rating >= 3 ? styles.dashboardReviewActiveStar : styles.dashboardReviewInactiveStar} />
                                    <Image source={require("../assets/header/star.png")}
                                        style={item.rating >= 4 ? styles.dashboardReviewActiveStar : styles.dashboardReviewInactiveStar} />
                                    <Image source={require("../assets/header/star.png")}
                                        style={item.rating >= 5 ? styles.dashboardReviewActiveStar : styles.dashboardReviewInactiveStar} />
                                </View>
                            </View>
                            <Image source={require("../assets/header/quote.png")} style={styles.dashboardReviewQuoteImage} />
                        </View>

                        <Text style={styles.dashboardReviewText} numberOfLines={5}>{item.text}</Text>
                    </View>
                </View>
            );
        }
    }

    // story item view
    const storyItem = (item, index) => {
        return (
            <View style={styles.storyItemMainView} dataSet={{ media: ids.storyItemMainView }}>
                <View style={styles.storyTitleView} dataSet={{ media: ids.storyTitleView }}>
                    <Text style={styles.storyTitleText} dataSet={{ media: ids.storyTitleText }}>{item.date}</Text>
                </View>
                <View style={styles.storyItemSubView} dataSet={{ media: ids.storyItemSubView }}>
                    <RenderHTML
                        contentWidth={width}
                        source={{ html: item.story }}
                        tagsStyles={tagsStyles3}
                        baseStyle={styles.storyItemDetailText}
                        systemFonts={[getDMSansMediumFont()]}
                    />
                    {item.link != "" ? <Pressable onPress={() => Linking.openURL(item.link)} style={styles.storyItemReadMoreButtonView} dataSet={{ media: ids.storyItemReadMoreButtonView }}>
                        <Text style={styles.storyItemReadMoreText} dataSet={{ media: ids.storyItemReadMoreText }}>Read More</Text>
                    </Pressable> : null}
                </View>
                <View style={styles.storyItemImgView} dataSet={{ media: ids.storyItemImgView }}>
                    <Image source={item.image != "" ? { uri: item.image } : require("../assets/header/footer-logo.png")}
                        style={styles.storyItemImg} dataSet={{ media: ids.storyItemImg }} />
                </View>
            </View>
        );
    }

    const { width } = useWindowDimensions();

    // dialog box 
    const showDialog = (answer, question) => {
        setAnswer(answer);
        setQuestion(question);
        setVisible(true)
    };

    const hideDialog = () => setVisible(false);

    const WIGGLE_ROOM = 128;

    const offset = useMemo(() => {
        return {
            top: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2,
            left: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2
        };
    }, []);

    return (
        <PaperProvider>
            <View>
                <StatusBar barStyle="dark-content" />
                <ScrollView ref={scrollRef} style={styles.container} keyboardShouldPersistTaps='handled'
                    onScroll={event => {
                        // this.yOffset = event.nativeEvent.contentOffset.y
                        setscrollposition(event.nativeEvent.contentOffset.y)
                    }}
                    scrollEventThrottle={160}>
                    <View style={styles.dashboardMainView}>

                        {/* Header view */}
                        {HeaderComponent(cartLength, navigation, userId)}
                        {/* end of Header view */}

                        {loading}

                        {/* banner list view */}
                        <View style={styles.dashboardBannerListView} dataSet={{ media: ids.dashboardBannerListView }}>

                            <View style={styles.dashboardBannerListContainer} dataSet={{ media: ids.dashboardBannerListContainer }}>

                                <View style={styles.dashboardBannerListWrapper} dataSet={{ media: ids.dashboardBannerListWrapper }}>
                                    <Text style={styles.dashboardBannerListTitle} dataSet={{ media: ids.dashboardBannerListTitle }}>{selectedBannerName}</Text>
                                    <Text style={styles.dashboardBannerListSubTitle} dataSet={{ media: ids.dashboardBannerListSubTitle }}>{selectedBannerSubName}</Text>
                                    {/* <Text style={{ fontSize: 20, color: colors.buttonColor, fontFamily: getDMSansBoldFont() }}>With Creamy Taste</Text> */}

                                    <View style={styles.dashboardBannerViewItems1} dataSet={{ media: ids.dashboardBannerViewItems1 }}>
                                        <Text style={styles.dashboardBannerViewTitleText} dataSet={{ media: ids.dashboardBannerViewTitleText }}>{selectedBannerSize}</Text>
                                        <Text style={styles.dashboardBannerViewText} dataSet={{ media: ids.dashboardBannerViewText }}>Grams</Text>
                                    </View>
                                    <View style={styles.dashboardBannerViewItems} dataSet={{ media: ids.dashboardBannerViewItems }}>
                                        <Text style={styles.dashboardBannerViewTitleText} dataSet={{ media: ids.dashboardBannerViewTitleText }}>{selectedBannerFat}</Text>
                                        <Text style={styles.dashboardBannerViewText} dataSet={{ media: ids.dashboardBannerViewText }}>Fat Content</Text>
                                    </View>
                                    <View style={styles.dashboardBannerViewItems} dataSet={{ media: ids.dashboardBannerViewItems }}>
                                        <Text style={styles.dashboardBannerViewTitleText} dataSet={{ media: ids.dashboardBannerViewTitleText }}>{selectedBannerOther}</Text>
                                        <Text style={styles.dashboardBannerViewText} dataSet={{ media: ids.dashboardBannerViewText }}>Some Content</Text>
                                    </View>
                                </View>

                                <Image source={{ uri: selectedBannerImage }}
                                    style={styles.dashboardBannerImage} dataSet={{ media: ids.dashboardBannerImage }} />

                                <View style={styles.dashboardSmallBannerList} dataSet={{ media: ids.dashboardSmallBannerList }}>
                                    {/* <FlatList
                                        style={styles.dashboardFlatlist}
                                        dataSet={{media:ids.dashboardFlatlist}}
                                        showsVerticalScrollIndicator={false}
                                        data={bannerList}
                                        keyExtractor={(item, id) => String(id)}
                                        ItemSeparatorComponent={<View style={styles.dashboardSmallBannerSeperator} dataSet={{media:ids.dashboardSmallBannerSeperator}} />}
                                        renderItem={({ item, index }) => smallBannerItem(item, index)} /> */}

                                    {bannerList.map((item, index) => {
                                        return smallBannerItem(item, index);
                                    })}
                                </View>
                            </View>

                        </View>

                        {/* product list view */}
                        <View style={styles.productListMainView} dataSet={{ media: ids.productListMainView }}>
                            <Text style={styles.productListMainTitle} dataSet={{ media: ids.productListMainTitle }}>Our Products</Text>
                            <Text style={styles.productListMainText} dataSet={{ media: ids.productListMainText }}>Our flagship product, fresh milk, is the epitome of wholesome goodness. Straight from the udders to your table, it's packed with essential nutrients like calcium, protein, and vitamins, making it a cornerstone of a healthy diet.</Text>

                            <View style={styles.productListMainContainer} dataSet={{ media: ids.productListMainContainer }}>

                                {productList.length > 0 ? <>
                                    {
                                        productList.length >= 1 ?
                                            <Pressable
                                                onMouseEnter={() => { setHover(1); setProduct(1); }}
                                                onMouseLeave={() => { setHover(0); setProduct(0); }}
                                                style={hover == 1 && product == 1 ? [styles.hoverViewStyle, { left: 0 }] : [styles.withoutHoverViewStyle, { left: 0 }]} dataSet={{ media: (hover == 1 && product == 1) ? ids.hoverViewStyle : ids.withoutHoverViewStyle }}>
                                                <Pressable onPress={() => navigation.navigate("ProductDetailsScreen", { paramId: productList[0].product_id, paramName: productList[0].name, isWallet: productList[0].is_wallet })}>
                                                    <Text style={hover == 1 && product == 1 ? styles.hoverTextStyle : styles.withoutHoverTextStyle} numberOfLines={1}>{productList[0].name}</Text>
                                                </Pressable>
                                                {hover == 1 && product == 1 ?
                                                    <>
                                                        <View style={styles.productListImageContainer}>
                                                            <Image source={require("../assets/header/star.png")} style={productList[0].rating >= 1 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                            <Image source={require("../assets/header/star.png")} style={productList[0].rating >= 2 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                            <Image source={require("../assets/header/star.png")} style={productList[0].rating >= 3 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                            <Image source={require("../assets/header/star.png")} style={productList[0].rating >= 4 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                            <Image source={require("../assets/header/star.png")} style={productList[0].rating >= 5 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                        </View>
                                                        {/* <Text style={{ fontSize: 12, color: colors.white, marginTop: 5 }} numberOfLines={2}>{productList[0].description}</Text> */}
                                                        <Pressable onPress={() => navigation.navigate("ProductDetailsScreen", { paramId: productList[0].product_id, paramName: productList[0].name, isWallet: productList[0].is_wallet })}>
                                                            <RenderHtml
                                                                contentWidth={width}
                                                                source={{ html: productList[0].description }}
                                                                tagsStyles={tagsStyles}
                                                                baseStyle={styles.productHoverDetailsText}
                                                                systemFonts={[getDMSansRegularFont()]}
                                                            />
                                                        </Pressable>

                                                        <View style={styles.productCartView}>
                                                            <Text style={styles.productNameText} numberOfLines={1}>{selectSize.length != 0 ? selectSize[0].name : ''}</Text>
                                                            <Text style={styles.productPriceText} numberOfLines={1}>{productList[0].currencySymbol}{(parseFloat(productList[0].price) + parseFloat(selectSize[0].price)).toFixed()}</Text>

                                                            <View style={styles.addToCartView}>
                                                                <Pressable onPress={() => {
                                                                    if (userId == null || userId == "") {
                                                                        navigation.navigate("RegisterScreen", { paramScreenRoute: window.location.href });
                                                                    } else {
                                                                        navigation.navigate("CheckoutScreen", {
                                                                            paramProductName: productList[0].name,
                                                                            paramProductId: productList[0].product_id,
                                                                            paramProductSize: selectSize[0].name,
                                                                            paramProductPrice: productList[0].currencySymbol + (parseFloat(productList[0].price) + parseFloat(selectSize[0].price)).toFixed(),
                                                                            paramProductImage: productList[0].image,
                                                                            paramProductOptionId: selectSize[0].product_option_id,
                                                                            paramProductOptionValueId: selectSize[0].product_option_value_id,
                                                                        });
                                                                    }
                                                                }}
                                                                    style={styles.addToCartButtonView} dataSet={{ media: ids.addToCartButtonView }}>
                                                                    <Text style={styles.addToCartButtonText} dataSet={{ media: ids.addToCartButtonText }}>ADD TO CART</Text>
                                                                </Pressable>
                                                            </View>
                                                        </View>

                                                    </> : null}

                                                <Pressable onPress={() => navigation.navigate("ProductDetailsScreen", { paramId: productList[0].product_id, paramName: productList[0].name, isWallet: productList[0].is_wallet })}
                                                    style={styles.productItemImageView}>
                                                    <Image source={productList[0].image}
                                                        style={hover == 1 && product == 1 ? styles.hoverImageStyle : styles.withoutHoverImageStyle} />
                                                </Pressable>
                                            </Pressable>
                                            : null
                                    }
                                    {
                                        productList.length >= 2 ?
                                            <Pressable
                                                onMouseEnter={() => { setHover(1); setProduct(2); }}
                                                onMouseLeave={() => { setHover(0); setProduct(0); }}
                                                // style={hover == 1 && product == 2 ? [styles.hoverViewStyle, { left: -50 }] : [styles.withoutHoverViewStyle, { left: -50 }]}>
                                                style={hover == 1 && product == 2 ? [styles.hoverViewStyle, { left: -50 }] : [styles.withoutHoverViewStyle, { left: -50 }]} dataSet={{ media: (hover == 1 && product == 2) ? ids.hoverViewStyle : ids.withoutHoverViewStyle }}>
                                                <Pressable onPress={() => navigation.navigate("ProductDetailsScreen", { paramId: productList[1].product_id, paramName: productList[1].name, isWallet: productList[1].is_wallet })}>
                                                    <Text style={hover == 1 && product == 2 ? styles.hoverTextStyle : styles.withoutHoverTextStyle} numberOfLines={1}>{productList[1].name}</Text>
                                                </Pressable>
                                                {hover == 1 && product == 2 ?
                                                    <>
                                                        <View style={styles.productListImageContainer}>
                                                            <Image source={require("../assets/header/star.png")} style={productList[1].rating >= 1 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                            <Image source={require("../assets/header/star.png")} style={productList[1].rating >= 2 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                            <Image source={require("../assets/header/star.png")} style={productList[1].rating >= 3 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                            <Image source={require("../assets/header/star.png")} style={productList[1].rating >= 4 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                            <Image source={require("../assets/header/star.png")} style={productList[1].rating >= 5 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                        </View>
                                                        {/* <Text style={{ fontSize: 12, color: colors.white, marginTop: 5 }} numberOfLines={2}>{productList[1].description}</Text> */}
                                                        <Pressable onPress={() => navigation.navigate("ProductDetailsScreen", { paramId: productList[1].product_id, paramName: productList[1].name, isWallet: productList[1].is_wallet })}>
                                                            <RenderHtml
                                                                contentWidth={width}
                                                                source={{ html: productList[1].description }}
                                                                tagsStyles={tagsStyles}
                                                                baseStyle={styles.productHoverDetailsText}
                                                                systemFonts={[getDMSansRegularFont()]}
                                                            />
                                                        </Pressable>
                                                        <View style={styles.productCartView}>
                                                            <Text style={styles.productNameText} numberOfLines={1}>{selectSize.length != 0 ? selectSize[1].name : ''}</Text>
                                                            <Text style={styles.productPriceText} numberOfLines={1}>{productList[1].currencySymbol}{(parseFloat(productList[1].price) + parseFloat(selectSize[1].price)).toFixed()}</Text>

                                                            <View style={styles.addToCartView}>
                                                                <Pressable
                                                                    onPress={() => {
                                                                        if (userId == null || userId == "") {
                                                                            navigation.navigate("RegisterScreen", { paramScreenRoute: window.location.href });
                                                                        } else {
                                                                            navigation.navigate("CheckoutScreen", {
                                                                                paramProductName: productList[1].name,
                                                                                paramProductId: productList[1].product_id,
                                                                                paramProductSize: selectSize[1].name,
                                                                                paramProductPrice: productList[1].currencySymbol + (parseFloat(productList[1].price) + parseFloat(selectSize[1].price)).toFixed(),
                                                                                paramProductImage: productList[1].image,
                                                                                paramProductOptionId: selectSize[1].product_option_id,
                                                                                paramProductOptionValueId: selectSize[1].product_option_value_id,
                                                                            });
                                                                        }
                                                                    }}
                                                                    style={styles.addToCartButtonView}>
                                                                    <Text style={styles.addToCartButtonText}>ADD TO CART</Text>
                                                                </Pressable>
                                                            </View>
                                                        </View>

                                                    </> : null}
                                                <Pressable onPress={() => navigation.navigate("ProductDetailsScreen", { paramId: productList[1].product_id, paramName: productList[1].name, isWallet: productList[1].is_wallet })}
                                                    style={styles.productItemImageView}>
                                                    <Image source={productList[1].image}
                                                        style={hover == 1 && product == 2 ? styles.hoverImageStyle : styles.withoutHoverImageStyle} />
                                                </Pressable>
                                            </Pressable>
                                            : null
                                    }
                                    {
                                        productList.length >= 3 ?
                                            <Pressable
                                                onMouseEnter={() => { setHover(1); setProduct(3); }}
                                                onMouseLeave={() => { setHover(0); setProduct(0); }}
                                                // style={hover == 1 && product == 3 ? [styles.hoverViewStyle, { left: -100 }] : [styles.withoutHoverViewStyle, { left: -100 }]}>
                                                style={hover == 1 && product == 3 ? [styles.hoverViewStyle, { left: -100 }] : [styles.withoutHoverViewStyle, { left: -100 }]} dataSet={{ media: (hover == 1 && product == 3) ? ids.hoverViewStyle : ids.withoutHoverViewStyle }}>
                                                <Pressable onPress={() => navigation.navigate("ProductDetailsScreen", { paramId: productList[2].product_id, paramName: productList[2].name, isWallet: productList[2].is_wallet })}>
                                                    <Text style={hover == 1 && product == 3 ? styles.hoverTextStyle : styles.withoutHoverTextStyle} numberOfLines={1}>{productList[2].name}</Text>
                                                </Pressable>
                                                {hover == 1 && product == 3 ?
                                                    <>
                                                        <View style={styles.productListImageContainer}>
                                                            <Image source={require("../assets/header/star.png")} style={productList[2].rating >= 1 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                            <Image source={require("../assets/header/star.png")} style={productList[2].rating >= 2 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                            <Image source={require("../assets/header/star.png")} style={productList[2].rating >= 3 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                            <Image source={require("../assets/header/star.png")} style={productList[2].rating >= 4 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                            <Image source={require("../assets/header/star.png")} style={productList[2].rating >= 5 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                        </View>
                                                        {/* <Text style={{ fontSize: 12, color: colors.white, marginTop: 5 }} numberOfLines={2}>{productList[2].description}</Text> */}
                                                        <Pressable onPress={() => navigation.navigate("ProductDetailsScreen", { paramId: productList[2].product_id, paramName: productList[2].name, isWallet: productList[2].is_wallet })}>
                                                            <RenderHtml
                                                                contentWidth={width}
                                                                source={{ html: productList[2].description }}
                                                                tagsStyles={tagsStyles}
                                                                baseStyle={styles.productHoverDetailsText}
                                                                systemFonts={[getDMSansRegularFont()]}
                                                            />
                                                        </Pressable>
                                                        <View style={styles.productCartView}>
                                                            <Text style={styles.productNameText} numberOfLines={1}>{selectSize.length != 0 ? selectSize[2].name : ''}</Text>
                                                            <Text style={styles.productPriceText} numberOfLines={1}>{productList[2].currencySymbol}{(parseFloat(productList[2].price) + parseFloat(selectSize[2].price)).toFixed()}</Text>

                                                            <View style={styles.addToCartView}>
                                                                <Pressable
                                                                    onPress={() => {
                                                                        if (userId == null || userId == "") {
                                                                            navigation.navigate("RegisterScreen", { paramScreenRoute: window.location.href });
                                                                        } else {
                                                                            navigation.navigate("CheckoutScreen", {
                                                                                paramProductName: productList[2].name,
                                                                                paramProductId: productList[2].product_id,
                                                                                paramProductSize: selectSize[2].name,
                                                                                paramProductPrice: productList[2].currencySymbol + (parseFloat(productList[2].price) + parseFloat(selectSize[2].price)).toFixed(),
                                                                                paramProductImage: productList[2].image,
                                                                                paramProductOptionId: selectSize[2].product_option_id,
                                                                                paramProductOptionValueId: selectSize[2].product_option_value_id,
                                                                            });
                                                                        }
                                                                    }}
                                                                    style={styles.addToCartButtonView}>
                                                                    <Text style={styles.addToCartButtonText}>ADD TO CART</Text>
                                                                </Pressable>
                                                            </View>
                                                        </View>

                                                    </> : null}
                                                <Pressable onPress={() => navigation.navigate("ProductDetailsScreen", { paramId: productList[2].product_id, paramName: productList[2].name, isWallet: productList[2].is_wallet })}
                                                    style={styles.productItemImageView}>
                                                    <Image source={productList[2].image}
                                                        style={hover == 1 && product == 3 ? styles.hoverImageStyle : styles.withoutHoverImageStyle} />
                                                </Pressable>
                                            </Pressable>
                                            : null
                                    }
                                    {
                                        productList.length >= 4 ?
                                            <Pressable
                                                onMouseEnter={() => { setHover(1); setProduct(4); }}
                                                onMouseLeave={() => { setHover(0); setProduct(0); }}
                                                // style={hover == 1 && product == 4 ? [styles.hoverViewStyle, { left: -150 }] : [styles.withoutHoverViewStyle, { left: -150 }]}>
                                                style={hover == 1 && product == 4 ? [styles.hoverViewStyle, { left: -150 }] : [styles.withoutHoverViewStyle, { left: -150 }]} dataSet={{ media: (hover == 1 && product == 4) ? ids.hoverViewStyle : ids.withoutHoverViewStyle }}>
                                                <Pressable onPress={() => navigation.navigate("ProductDetailsScreen", { paramId: productList[3].product_id, paramName: productList[3].name, isWallet: productList[3].is_wallet })}>
                                                    <Text style={hover == 1 && product == 4 ? styles.hoverTextStyle : styles.withoutHoverTextStyle} numberOfLines={1}>{productList[3].name}</Text>
                                                </Pressable>
                                                {hover == 1 && product == 4 ?
                                                    <>
                                                        <View style={styles.productListImageContainer}>
                                                            <Image source={require("../assets/header/star.png")} style={productList[3].rating >= 1 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                            <Image source={require("../assets/header/star.png")} style={productList[3].rating >= 2 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                            <Image source={require("../assets/header/star.png")} style={productList[3].rating >= 3 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                            <Image source={require("../assets/header/star.png")} style={productList[3].rating >= 4 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                            <Image source={require("../assets/header/star.png")} style={productList[3].rating >= 5 ? styles.productActiveReviewStar : styles.productInactiveReviewStar} />
                                                        </View>
                                                        {/* <Text style={{ fontSize: 12, color: colors.white, marginTop: 5 }} numberOfLines={2}>{productList[3].description}</Text> */}
                                                        <Pressable onPress={() => navigation.navigate("ProductDetailsScreen", { paramId: productList[3].product_id, paramName: productList[3].name, isWallet: productList[3].is_wallet })}>
                                                            <RenderHtml
                                                                contentWidth={width}
                                                                source={{ html: productList[3].description }}
                                                                tagsStyles={tagsStyles}
                                                                baseStyle={styles.productHoverDetailsText}
                                                                systemFonts={[getDMSansRegularFont()]}
                                                            />
                                                        </Pressable>
                                                        <View style={styles.productCartView}>
                                                            <Text style={styles.productNameText} numberOfLines={1}>{selectSize.length != 0 ? selectSize[3].name : ''}</Text>
                                                            <Text style={styles.productPriceText} numberOfLines={1}>{productList[3].currencySymbol}{(parseFloat(productList[3].price) + parseFloat(selectSize[3].price)).toFixed()}</Text>

                                                            <View style={styles.addToCartView}>
                                                                <Pressable
                                                                    onPress={() => {
                                                                        if (userId == null || userId == "") {
                                                                            navigation.navigate("RegisterScreen", { paramScreenRoute: window.location.href });
                                                                        } else {
                                                                            navigation.navigate("CheckoutScreen", {
                                                                                paramProductName: productList[3].name,
                                                                                paramProductId: productList[3].product_id,
                                                                                paramProductSize: selectSize[3].name,
                                                                                paramProductPrice: productList[3].currencySymbol + (parseFloat(productList[3].price) + parseFloat(selectSize[3].price)).toFixed(),
                                                                                paramProductImage: productList[3].image,
                                                                                paramProductOptionId: selectSize[3].product_option_id,
                                                                                paramProductOptionValueId: selectSize[3].product_option_value_id,
                                                                            });
                                                                        }
                                                                    }}
                                                                    style={styles.addToCartButtonView}>
                                                                    <Text style={styles.addToCartButtonText}>ADD TO CART</Text>
                                                                </Pressable>
                                                            </View>
                                                        </View>

                                                    </> : null}
                                                <Pressable onPress={() => navigation.navigate("ProductDetailsScreen", { paramId: productList[3].product_id, paramName: productList[3].name, isWallet: productList[3].is_wallet })}
                                                    style={styles.productItemImageView}>
                                                    <Image source={productList[3].image}
                                                        style={hover == 1 && product == 4 ? styles.hoverImageStyle : styles.withoutHoverImageStyle} />
                                                </Pressable>
                                            </Pressable>
                                            : null
                                    }
                                </>
                                    : null}
                            </View>

                            <View style={styles.allproductButtonView}>
                                <Pressable onPress={() => navigation.navigate("ProductListScreen")}
                                    style={styles.allproductButton}>
                                    <Text style={styles.allproductButtonText}>All Products</Text>
                                </Pressable>
                            </View>
                        </View>
                        {/* end of product list view */}

                        {/* sooth view */}
                        <View style={styles.soothViewMain}>

                            {/* view 1 */}
                            <View style={styles.soothView} dataSet={{ media: ids.soothView }}>

                                <View style={styles.soothLetterS} dataSet={{ media: ids.soothLetterS }}>
                                    <Text style={styles.soothLetterTitle}>S</Text>
                                </View>

                                <View style={styles.soothImageContainer1} dataSet={{ media: ids.soothImageContainer1 }}>
                                    <View style={styles.soothImageWrapper1}>
                                        <Image source={require("../assets/header/icon1.png")}
                                            style={styles.soothImage1} />
                                    </View>
                                    <Text style={styles.soothImageText1} dataSet={{ media: ids.soothImageText1 }}>{"Gentle\nPasteurization"}</Text>
                                </View>

                                <View style={styles.soothLetterO} dataSet={{ media: ids.soothLetterO }}>
                                    <Text style={styles.soothLetterOText}>O</Text>
                                </View>

                                <View style={styles.soothImageContainer2} dataSet={{ media: ids.soothImageContainer2 }}>
                                    <View style={styles.soothImageWrapper2}>
                                        <Image source={require("../assets/header/icon2.png")}
                                            style={styles.soothImage2} />
                                    </View>
                                    <Text style={styles.soothImageText2} dataSet={{ media: ids.soothImageText2 }}>{"Heating\nTemperature\nLess than 80*C"}</Text>
                                </View>

                                <View style={styles.soothLetterH} dataSet={{ media: ids.soothLetterH }}>
                                    <Text style={styles.soothLetterTitle}>H</Text>
                                </View>

                                <View style={styles.soothImageContainer3} dataSet={{ media: ids.soothImageContainer3 }}>
                                    <Image source={require("../assets/header/batli.png")}
                                        style={styles.soothImage3} />
                                </View>

                            </View>
                            {/* end of view 1 */}
                            {/* view 2 */}
                            <View style={styles.soothView2} dataSet={{ media: ids.soothView2 }}>

                                <View style={styles.soothImageContainer4} dataSet={{ media: ids.soothImageContainer4 }}>
                                    <Image source={require("../assets/header/khokhu.png")}
                                        style={styles.soothImage4} />
                                </View>

                                <View style={styles.soothLetterO2} dataSet={{ media: ids.soothLetterO2 }}>
                                    <Text style={styles.soothLetterTitle}>O</Text>
                                </View>

                                <View style={styles.soothImageContainer5} dataSet={{ media: ids.soothImageContainer5 }}>
                                    <View style={styles.soothImageWrapper5}>
                                        <Image source={require("../assets/header/icon3.png")}
                                            style={styles.soothImage5} />
                                    </View>
                                    <Text style={styles.soothImageText5} dataSet={{ media: ids.soothImageText5 }}>{"16 Days\nShelf Life"}</Text>
                                </View>

                                <View style={styles.soothLetterT} dataSet={{ media: ids.soothLetterT }}>
                                    <Text style={styles.soothLetterTitle}>T</Text>
                                </View>

                                <View style={styles.soothImageContainer6} dataSet={{ media: ids.soothImageContainer6 }}>
                                    <View style={styles.soothImageWrapper6}>
                                        <Image source={require("../assets/header/icon4.png")}
                                            style={styles.soothImage6} />
                                    </View>
                                    <Text style={styles.soothImageText6} dataSet={{ media: ids.soothImageText6 }}>{"Fresh\n& Natural"}</Text>
                                </View>

                                <View style={styles.soothLetterE} dataSet={{ media: ids.soothLetterE }}>
                                    <Text style={styles.soothLetterTitle}>E</Text>
                                </View>
                            </View>
                            {/* end of view 2 */}

                        </View>
                        {/* end of sooth view */}

                        {/* review view */}
                        <View style={styles.reviewViewContainer} dataSet={{ media: ids.reviewViewContainer }}>
                            <View style={styles.titleWrapper} dataSet={{ media: ids.titleWrapper }}>
                                <Text style={styles.titleText} dataSet={{ media: ids.titleText }}>Reviews</Text>
                                <View style={styles.reviewImgWrapper} dataSet={{ media: ids.reviewImgWrapper }}>
                                    <Text style={styles.reviewImgText} dataSet={{ media: ids.reviewImgText }}>{(reviewList.reduce((acc, next) => {
                                        return acc + next.rating
                                    }, 0) / 5)}/5 rating | {reviewList.length} reviews on</Text>
                                    <Image source={require("../assets/header/google.png")}
                                        style={styles.reviewImg} dataSet={{ media: ids.reviewImg }} />
                                </View>

                            </View>

                            <FlatList
                                style={styles.reviewFlatList}
                                showsVerticalScrollIndicator={false}
                                data={reviewList}
                                keyExtractor={(item, id) => String(id)}
                                renderItem={({ item, index }) => reviewItem(item, index)} />
                        </View>
                        {/* end of review view */}

                        {/* FAQ view */}
                        <View style={styles.faqViewMain} dataSet={{ media: ids.faqViewMain }}>
                            <Text style={styles.faqText1} dataSet={{ media: ids.faqText1 }}>Things you wanted to know</Text>
                            <Text style={styles.faqText2} dataSet={{ media: ids.faqText2 }}>Welcome to soothe organics, where every drop tells a story of care, quality, and commitment. Here are a few things you might want to know about us.</Text>

                            <View style={styles.faqFlatList} dataSet={{ media: ids.faqFlatList }}>
                                {faqList.map((item, index) => {
                                    return questionItem(item, index);
                                })}
                            </View>

                            <View style={styles.faqButton} dataSet={{ media: ids.faqButton }}>
                                <Pressable onPress={() => navigation.navigate("FaqScreen")} style={styles.allproductButton}>
                                    <Text style={styles.faqButtonText}>Read More FAQS</Text>
                                </Pressable>
                            </View>
                            {/* <FlatList
                            style={{ marginTop: "3%" }}
                            showsVerticalScrollIndicator={false}
                            data={["1", "2", "3", "4"]}
                            keyExtractor={(item, id) => String(id)}
                            renderItem={({ item, index }) => questionItem(item, index)} /> */}
                        </View>
                        {/* end of FAQ view */}

                        {/* Blogs view */}
                        <View style={styles.blogViewMain} dataSet={{ media: ids.blogViewMain }}>
                            <Text style={styles.blogText1} dataSet={{ media: ids.blogText1 }}>Latest Stories</Text>
                            <Text style={styles.blogText2} dataSet={{ media: ids.blogText2 }}>From Farm to Table, Stories of Purity and Passion: Experience the Essence of Freshness with Every Sip! Join Us on a Journey of Taste and Tradition, Where Every Bottle Holds the Promise of Wholesome Goodness and Nourishment for the Soul.</Text>

                            <FlatList
                                style={styles.blogFlatList}
                                dataSet={{ media: ids.blogFlatList }}
                                showsVerticalScrollIndicator={false}
                                data={storyList}
                                keyExtractor={(item, id) => String(id)}
                                renderItem={({ item, index }) => storyItem(item, index)} />

                            <View style={styles.blogButtonView} dataSet={{ media: ids.blogButtonView }}>
                                <Pressable onPress={() => navigation.navigate("StoriesScreen")}
                                    style={styles.blogButton}>
                                    <Text style={styles.blogButtonText}>Read More Blogs</Text>
                                </Pressable>
                            </View>

                        </View>
                        {/* end of Blog view */}

                        {/* Footer view */}
                        {FooterComponent(navigation, userId)}
                        {/* end of Footer view */}

                    </View>

                    {/* <Portal>
                        <Dialog visible={visible} onDismiss={hideDialog} style={{ marginTop: Dimensions.get("window").height * 0.8, width: 300, backgroundColor: colors.white, alignSelf: "center" }}>
                            <Dialog.Title style={{ color: colors.buttonColor, alignSelf: "center" }}>Alert</Dialog.Title>
                            <Dialog.Content>
                                <Text variant="bodyMedium" style={{ color: colors.black, alignSelf: "center" }}>{modalText}</Text>
                            </Dialog.Content>
                            <View style={{ flexDirection: "row", alignSelf: "center" }}>
                                <Dialog.Actions>
                                    <Button onPress={hideDialog} textColor={colors.white} style={{ backgroundColor: colors.buttonColor, width: 100, borderRadius: 10 }}>Ok, Got it</Button>
                                </Dialog.Actions>
                            </View>
                        </Dialog>
                    </Portal> */}

                    <Modal
                        onRequestClose={hideDialog}
                        transparent
                        visible={visible}
                        animationType={'slide'}
                    >
                        <View style={styles.modalMainView} dataSet={{ media: ids.modalMainView }}>
                            <View style={[styles.modalViewContainer, offset]} dataSet={{ media: ids.modalViewContainer }}>

                                <Text style={styles.cardTitle}>{question}</Text>

                                <ScrollView style={styles.modalRenderHtmlView}>
                                    {/* <View style={styles.modalRenderHtmlView} dataSet={{ media: ids.modalRenderHtmlView }}> */}
                                    <RenderHTML
                                        contentWidth={width}
                                        source={{ html: answer }}
                                        tagsStyles={tagsStyles2}
                                        systemFonts={[getDMSansRegularFont()]}
                                        baseStyle={styles.modalRenderHtmlText}
                                    />
                                    {/* </View> */}
                                </ScrollView>
                                <Pressable onPress={hideDialog} style={styles.modalButton}>
                                    <Text style={styles.modalButtonText}>Ok, Got it</Text>
                                </Pressable>
                            </View>
                        </View>
                    </Modal>

                </ScrollView >
            </View >
        </PaperProvider>
    );
};

export default DashBoardScreen;

const tagsStyles = {
    p: styles.pTagWithWhiteText,
    div: styles.divTag
}

const tagsStyles2 = {
    p: styles.pTagWithBlackText,
    div: styles.divTag
}

const tagsStyles3 = {
    p: styles.pTagWithBlackMediumText,
}
