import React, { useMemo, useRef, useState } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, Dimensions, FlatList, Modal } from 'react-native';
import { colors } from '../common/color';
import { CustomConsole, HeaderComponent, FooterComponent, validateEmail, getDMSansSemiBoldFont, getDMSansRegularFont, getRobotoSlabLightFont, formatDate, scrollToTop, getDMSansBoldFont, getDMSansMediumFont, CustomProgressBar, formatDateYMD } from '../common/utils';
import { ADDTOCART, CARTLIST, DELETECART, LOGIN, MODIFYCART, PRODUCTDETAILS, SHIPPINGLIST, SUBSCRIBELIST, TIMEZONE } from '../common/webUtils';
import { EMAIL, FIRST_NAME, LAST_NAME, OTP_VERIFY, PASSWORD, PHONE, USER_ID, saveSession, getSession } from '../common/LocalStorage';
import { Image } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { Button, PaperProvider, Portal, RadioButton } from 'react-native-paper';
import moment from 'moment/moment';
import { styles, ids } from '../common/responsive.js';

function CheckoutScreen({ navigation, route }) {

    const WIGGLE_ROOM = 128;

    const offset = useMemo(() => {
        return {
            top: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2,
            left: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2
        };
    }, []);

    const [alertShow, setAlertShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [changeAddressCheck, setChangeAddressCheck] = useState(false);

    // modal hide/show
    const showAlert = (text) => {
        setAlertShow(true);
        setAlertMsg(text)
    };
    const hideAlert = () => setAlertShow(false);

    // add days into date
    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    const inputRef = useRef(null);
    // const productName = route.params.paramProductName
    const productId = route.params.paramProductId
    const productSize = route.params.paramProductSize
    const productPrice = route.params.paramProductPrice
    const productPriceWithoutSymbol = route.params.paramProductPrice.substring(1).replace(",", "")
    // const productImage = route.params.paramProductImage
    const product_option_id = route.params.paramProductOptionId
    const product_option_value_id = route.params.paramProductOptionValueId


    const cart_purchase_type = route.params.paramPurchaseType
    const cart_subscribe_plan = route.params.paramSubscriptionPlan
    const cart_subscribe_plan_id = route.params.paramSubscriptionPlanId
    const cart_quantity = route.params.paramQty
    const cart_quantity2 = route.params.paramQty2
    const cart_start_date = route.params.paramStartDate
    const cart_end_date = route.params.paramEndDate
    const toFrom = route.params.paramFrom
    const cart_id = route.params.paramCartId

    // CustomConsole(productImage);

    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [subscribeType, setSubscribeType] = useState("daily");
    const [productQty, setProductQty] = useState(1);
    const [varingQty1, setVaringQty1] = useState(1);
    const [varingQty2, setVaringQty2] = useState(1);
    const [purchaseType, setPurchaseType] = useState(1);
    const [startDate, setStartDate] = useState(null);
    const [subscribeList, setSubscribeList] = useState([]);
    const [subscribe_plan_id, setSubscribePlanId] = useState('');
    const [subscribe_plan_value, setSubscribePlanValue] = useState('');
    const [subscribe_plan_interval, setSubscribePlanInterval] = useState('1');
    const [visible, setVisible] = React.useState(false);
    const [shippingAddressList, setShippingAddressList] = useState([]);
    const [shippingAddressId, setShippingAddressId] = useState('');
    const [shippingAddress, setShippingAddress] = useState('');
    const [cartLength, setcartLength] = React.useState('0');
    const [endDate, setEndDate] = React.useState(null);
    const [subscriptionDuration, setSubscriptionDuration] = React.useState('');
    const [subscriptionTotalAmount, setSubscriptionTotalAmount] = React.useState('0');
    const [subscriptionTotalQty, setSubscriptionTotalQty] = React.useState(0);
    const [is_buy_once, setIsBuyOnce] = React.useState('');

    const [productName, setProductName] = useState('');
    const [productImage, setProductImage] = useState('');
    const [productSubscriptionPlanList, setProductSubscriptionPlanList] = useState([]);
    const [selectedDateStart, setSelectedDateStart] = useState(formatDateYMD((new Date(Date.now())).addDays(1)));

    // modal hide/show
    const showModal = () => setVisible(true);
    const hideModal = () => setVisible(false);

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        CustomConsole("changeAddressCheck==>" + changeAddressCheck)
        if (!changeAddressCheck) {
            getSessionData();
            scrollToTop();

            CustomConsole(route.params.paramPurchaseType);
            CustomConsole(route.params.paramSubscriptionPlan);
            CustomConsole(route.params.paramQty);
            CustomConsole(route.params.paramStartDate);
            CustomConsole(route.params.paramEndDate);
            CustomConsole("paramSubscriptionPlanId==>" + route.params.paramSubscriptionPlanId);
        } else {
            // shippingAddressListApi();
        }
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            setShippingAddressId('');
            setShippingAddress('');
            setStartDate(null);
            productDetailsApi();
            // subscribeListApi();
            cartListApi();
            timeZoneApi();
            inputRef.current.value = "";
        } catch (error) {

        }
    }

    // timezone api
    const timeZoneApi = async () => {
        var requestOptionsTimezone = {
            method: 'GET'
        };
        CustomConsole(TIMEZONE);
        fetch(TIMEZONE, requestOptionsTimezone)
            .then(responseTimezone => responseTimezone.json())
            .then((jsonTimezone) => {
                CustomConsole(jsonTimezone);
                if (jsonTimezone.success == 1) {
                    CustomConsole(jsonTimezone.data.current_time);
                    if (jsonTimezone.data.current_time >= "22:00:00") {
                        setSelectedDateStart(formatDateYMD((new Date(Date.now())).addDays(2)));
                    } else {
                        setSelectedDateStart(formatDateYMD((new Date(Date.now())).addDays(1)));
                    }
                }
            })
            .catch((errorTimezone) => {
                CustomConsole("Timezone Api Error=>" + errorTimezone);
            });
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);

            // setLoading(true);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                    // setLoading(false);
                })
                .catch((error) => {
                    // setLoading(false);
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    // product details api
    const productDetailsApi = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(PRODUCTDETAILS + "&product_id=" + productId + "&currency=INR&lang=");

            setLoading(true);
            fetch(PRODUCTDETAILS + "&product_id=" + productId + "&currency=INR&lang=", requestOptions)
                .then(response => response.json())
                .then((json) => {

                    CustomConsole(json.data);

                    setProductName(json.data[0].name);
                    setProductImage(json.data[0].image);
                    setIsBuyOnce(json.data[0].is_buy_once);

                    productSubscriptionPlanList.length = 0;

                    if (json.data[0].subscription_plans.length != 0) {

                        if (cart_purchase_type != undefined) {
                            setPurchaseType(cart_purchase_type);
                            setSubscribeType(cart_subscribe_plan);
                            setSubscribePlanId(cart_subscribe_plan_id);
                            setStartDate(cart_start_date == undefined || cart_start_date == "" ? null : cart_start_date);
                            setEndDate(cart_end_date == undefined || cart_end_date == "" ? null : formatDate(new Date(cart_end_date)));
                            setProductQty(parseFloat(cart_quantity));
                            setSubscribePlanValue(cart_quantity);


                            if (cart_subscribe_plan == "varying") {
                                setVaringQty1(parseFloat(cart_quantity));
                                setVaringQty2(parseFloat(cart_quantity2));
                            }

                            var start = moment(route.params.paramStartDate);
                            var end = moment(route.params.paramEndDate);
                            const diff = end.diff(start, "days");
                            CustomConsole("diff====> " + diff);

                            if (diff == 30) {
                                setSubscriptionDuration("1 month");
                                priceCalc(cart_subscribe_plan, "1 month", parseFloat(cart_quantity), parseFloat(cart_quantity), parseFloat(cart_quantity2))
                            } else if (diff == 90) {
                                setSubscriptionDuration("3 month");
                                priceCalc(cart_subscribe_plan, "3 month", parseFloat(cart_quantity), parseFloat(cart_quantity), parseFloat(cart_quantity2))
                            } else if (diff == 180) {
                                setSubscriptionDuration("6 month");
                                priceCalc(cart_subscribe_plan, "6 month", parseFloat(cart_quantity), parseFloat(cart_quantity), parseFloat(cart_quantity2))
                            } else if (diff == 360) {
                                setSubscriptionDuration("12 month");
                                priceCalc(cart_subscribe_plan, "12 month", parseFloat(cart_quantity), parseFloat(cart_quantity), parseFloat(cart_quantity2))
                            } else {
                                setSubscriptionDuration("");
                            }

                        } else {
                            setPurchaseType(json.data[0].is_buy_once == "1" ? 1 : 2);
                            setSubscriptionDuration("");
                            if (json.data[0].subscription_plans.some(element => element.frequency == "daily")) {
                                setSubscribeType("daily");
                            }
                            else if (json.data[0].subscription_plans.some(element => element.frequency == "alternate")) {
                                setSubscribeType("alternate");
                            }
                            else if (json.data[0].subscription_plans.some(element => element.frequency == "varying")) {
                                setSubscribeType("varying");
                            }
                        }

                        for (let j = 0; j < json.data[0].subscription_plans.length; j++) {
                            productSubscriptionPlanList.push({
                                product_id: json.data[0].subscription_plans[j].product_id,
                                subscription_plan_id: json.data[0].subscription_plans[j].subscription_plan_id,
                                customer_group_id: json.data[0].subscription_plans[j].customer_group_id,
                                trial_price: json.data[0].subscription_plans[j].trial_price,
                                price: json.data[0].subscription_plans[j].price,
                                trial_frequency: json.data[0].subscription_plans[j].trial_frequency,
                                trial_duration: json.data[0].subscription_plans[j].trial_duration,
                                trial_cycle: json.data[0].subscription_plans[j].trial_cycle,
                                trial_status: json.data[0].subscription_plans[j].trial_status,
                                frequency: json.data[0].subscription_plans[j].frequency,
                                duration: json.data[0].subscription_plans[j].duration,
                                cycle: json.data[0].subscription_plans[j].cycle,
                                status: json.data[0].subscription_plans[j].status,
                                sort_order: json.data[0].subscription_plans[j].sort_order,
                                language_id: json.data[0].subscription_plans[j].language_id,
                                name: json.data[0].subscription_plans[j].name,
                            });
                        }
                    }

                    setProductSubscriptionPlanList(productSubscriptionPlanList);
                    subscribeListApi();

                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Product Api Error=>" + error);
                });

        } catch (e) {
            setLoading(false);
            CustomConsole("Product Details Exception=>" + e + "");
        }
    }

    // subscribe list api
    const subscribeListApi = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(SUBSCRIBELIST);

            setLoading(true);
            fetch(SUBSCRIBELIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    subscribeList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {
                        subscribeList.push({
                            index: i,
                            subscription_plan_id: json.data[i].subscription_plan_id,
                            trial_frequency: json.data[i].trial_frequency,
                            trial_duration: json.data[i].trial_duration,
                            trial_cycle: json.data[i].trial_cycle,
                            trial_status: json.data[i].trial_status,
                            frequency: json.data[i].frequency,
                            duration: json.data[i].duration,
                            cycle: json.data[i].cycle,
                            status: json.data[i].status,
                            sort_order: json.data[i].sort_order,

                        });
                    }

                    setSubscribeList(subscribeList);

                    if (toFrom != "modify") {
                        let subscribeData = subscribeList.find(element => element.frequency == "daily");
                        CustomConsole(subscribeData);
                        setSubscribePlanId(subscribeData.subscription_plan_id);
                        setSubscribePlanValue('1');
                    }

                    setLoading(false);
                    // shippingAddressListApi();
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Subscribe Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Subscribe Exception=>" + e + "");
        }
    }

    // shipping address list api
    const shippingAddressListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);

            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(SHIPPINGLIST + "&customer_id=" + user_id);

            fetch(SHIPPINGLIST + "&customer_id=" + user_id, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    if (json.data.length != 0) {
                        setShippingAddressId(json.data[0].address_id);
                        setShippingAddress(json.data[0].address_1 + ", \n" + json.data[0].address_2 + "\n" + json.data[0].city + ", " + json.data[0].zone + "\n" + json.data[0].country + "-" + json.data[0].postcode);
                    } else {
                        setShippingAddressId('');
                        setShippingAddress('');
                    }

                    shippingAddressList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {
                        shippingAddressList.push({
                            index: i,
                            address_id: json.data[i].address_id,
                            firstname: json.data[i].firstname,
                            lastname: json.data[i].lastname,
                            company: json.data[i].company,
                            block: json.data[i].block,
                            apartment: json.data[i].apartment,
                            address_1: json.data[i].address_1,
                            address_2: json.data[i].address_2,
                            postcode: json.data[i].postcode,
                            city: json.data[i].city,
                            zone_id: json.data[i].zone_id,
                            zone: json.data[i].zone,
                            zone_code: json.data[i].zone_code,
                            country_id: json.data[i].country_id,
                            address_type: json.data[i].address_type,
                            country: json.data[i].country,
                            iso_code_2: json.data[i].iso_code_2,
                            iso_code_3: json.data[i].iso_code_3,
                            address_format: json.data[i].address_format,
                        });
                    }

                    setShippingAddressList(shippingAddressList);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Shipping Address Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Shipping Address Exception=>" + e + "");
        }
    }

    // add item
    const addItemApi = async () => {
        try {
            CustomConsole(endDate)

            var qty = 0;

            if (subscriptionDuration === "1 month") {
                // 1 month 30 days
                if (subscribeType === "daily") {
                    qty = productQty * 30;
                }
                else if (subscribeType === "alternate") {
                    qty = productQty * 15;
                }
                else {
                    qty = (varingQty1 * (30 / 2)) + (varingQty2 * (30 / 2));
                }
            }
            else if (subscriptionDuration === "3 month") {
                // 3 month 90 days
                if (subscribeType === "daily") {
                    qty = productQty * 90;
                }
                else if (subscribeType === "alternate") {
                    qty = productQty * 45;
                }
                else {
                    qty = (varingQty1 * (90 / 2)) + (varingQty2 * (90 / 2));
                }
            }
            else if (subscriptionDuration === "6 month") {
                // 6 month 180 days
                if (subscribeType === "daily") {
                    qty = productQty * 180;
                }
                else if (subscribeType === "alternate") {
                    qty = productQty * 90;
                }
                else {
                    qty = (varingQty1 * (180 / 2)) + (varingQty2 * (180 / 2));
                }
            }
            else {
                // 12 month 365 days
                if (subscribeType === "daily") {
                    qty = productQty * 360;
                }
                else if (subscribeType === "alternate") {
                    qty = productQty * 180;
                }
                else {
                    qty = (varingQty1 * 180) + (varingQty2 * 180);
                }
            }

            CustomConsole("qty===>" + qty);
            CustomConsole("start date===>" + startDate);

            var isItemAdd = 0;

            if (purchaseType == 1) {
                // if (shippingAddressId != "") {
                isItemAdd = 1;
                // buy once
                var raw = JSON.stringify({
                    "cart": [
                        {
                            "product_id": productId,
                            "quantity": productQty,
                            "option": {
                                [product_option_id]: product_option_value_id
                            },
                        }
                    ],
                    "customer_id": userId
                });
                // } else {
                //     isItemAdd = 0;
                //     showAlert("Please select delivery address")
                // }

            } else if (purchaseType == 2) {
                // if (shippingAddressId != "" && startDate != null) {
                if (startDate != null) {
                    isItemAdd = 1;

                    // subscribe
                    var raw = JSON.stringify({
                        "cart": [
                            {
                                "product_id": productId,
                                "quantity": qty,
                                "option": {
                                    [product_option_id]: product_option_value_id
                                },
                                "subscription_plan_id": subscribe_plan_id,
                                "subscription_type": subscribeType,
                                "subscription_value": subscribeType == "varying" ? varingQty1 + "," + varingQty2 : productQty,
                                "is_subscription": 1,
                                "subscription_interval": subscribe_plan_interval,
                                "delivery_time": "12:30",
                                "start_date": formatDate(new Date(startDate)),
                                "end_date": endDate

                            }

                        ],
                        "customer_id": userId
                    });
                } else {
                    isItemAdd = 0;
                    // showAlert("Please select delivery address & select start date");
                    showAlert("Please select start date");
                }

            } else {
                // if (shippingAddressId != "" && startDate != null) {
                if (startDate != null) {
                    isItemAdd = 1;
                    let subscribeData = subscribeList.find(element => element.frequency == "trial");
                    CustomConsole(subscribeData);
                    setSubscribePlanId(subscribeData.subscription_plan_id);
                    setSubscribePlanValue("trial");

                    isItemAdd = 1;
                    // trial order
                    var raw = JSON.stringify({
                        "cart": [
                            {
                                "product_id": productId,
                                "quantity": productQty * 7,
                                "option": {
                                    [product_option_id]: product_option_value_id
                                },
                                "subscription_plan_id": subscribeData.subscription_plan_id,
                                "subscription_type": "trial",
                                "subscription_value": productQty,
                                "is_subscription": 1,
                                "subscription_interval": 1,
                                "delivery_time": "12:30",
                                "start_date": formatDate(new Date(startDate)),
                                "end_date": formatDate(new Date(startDate).addDays(7))

                            }

                        ],
                        "customer_id": userId
                    });
                } else {
                    isItemAdd = 0;
                    // showAlert("Please select delivery address & select start date");
                    showAlert("Please select start date");
                }
            }

            if (isItemAdd == 1) {

                // CustomConsole(raw);
                CustomConsole(ADDTOCART + "\n" + raw);

                var requestOptions = {
                    method: 'POST',
                    body: raw
                };

                setLoading(true);
                fetch(ADDTOCART, requestOptions)
                    .then(response => response.json())
                    .then((json) => {
                        CustomConsole(json);
                        if (json.success == 1) {
                            setLoading(false);
                            // alert("Product added into cart");
                            // navigation.navigate("CartScreen");
                            if (toFrom == "modify") {
                                navigation.navigate("CartScreen");
                            } else {
                                navigation.navigate("ProductListScreen");
                                navigation.reset({
                                    index: 0,
                                    routes: [{ name: 'ProductListScreen' }],
                                });
                            }
                        } else {
                            setLoading(false);
                            if (json.error.length > 0) {
                                showAlert(json.error[0]);
                            }
                            // alert("Not Success");
                        }

                    })
                    .catch((error) => {
                        setLoading(false);
                        CustomConsole("Error=>" + error);
                    });
            }

        } catch (e) {
            setLoading(false);
            CustomConsole("Add to cart Exception=>" + e + "");
        }
    }

    // modify cart item
    const modifyItemApi = async () => {
        try {
            CustomConsole(endDate)

            var qty = 0;

            if (subscriptionDuration === "1 month") {
                // 1 month 30 days
                if (subscribeType === "daily") {
                    qty = productQty * 30;
                }
                else if (subscribeType === "alternate") {
                    qty = productQty * 15;
                }
                else {
                    qty = (varingQty1 * (30 / 2)) + (varingQty2 * (30 / 2));
                }
            }
            else if (subscriptionDuration === "3 month") {
                // 3 month 90 days
                if (subscribeType === "daily") {
                    qty = productQty * 90;
                }
                else if (subscribeType === "alternate") {
                    qty = productQty * 45;
                }
                else {
                    qty = (varingQty1 * (90 / 2)) + (varingQty2 * (90 / 2));
                }
            }
            else if (subscriptionDuration === "6 month") {
                // 6 month 180 days
                if (subscribeType === "daily") {
                    qty = productQty * 180;
                }
                else if (subscribeType === "alternate") {
                    qty = productQty * 90;
                }
                else {
                    qty = (varingQty1 * (180 / 2)) + (varingQty2 * (180 / 2));
                }
            }
            else {
                // 12 month 365 days
                if (subscribeType === "daily") {
                    qty = productQty * 360;
                }
                else if (subscribeType === "alternate") {
                    qty = productQty * 180;
                }
                else {
                    qty = (varingQty1 * 180) + (varingQty2 * 180);
                }
            }

            CustomConsole("qty===>" + qty);
            CustomConsole("start date===>" + startDate);

            var isItemAdd = 0;

            if (purchaseType == 1) {
                // if (shippingAddressId != "") {
                // isItemAdd = 1;
                // buy once
                // var raw = JSON.stringify({
                //     "cart": [
                //         {
                //             "product_id": productId,
                //             "quantity": productQty,
                //             "option": {
                //                 [product_option_id]: product_option_value_id
                //             },
                //         }
                //     ],
                //     "customer_id": userId
                // });
                var raw1 = JSON.stringify({
                    "cart_id": cart_id
                });

                var requestOptions1 = {
                    method: 'POST',
                    body: raw1,
                };

                CustomConsole(DELETECART + "\n" + raw1);
                setLoading(true);
                fetch(DELETECART, requestOptions1)
                    .then(response => response.json())
                    .then((json) => {
                        CustomConsole(json);
                        if (json.success == "1") {
                            // alert("Cart deleted successfully.");
                            addItemApi();
                        } else {
                            if (json.error.length > 0) {
                                showAlert(json.error[0]);
                            }
                        }
                        // setLoading(false);
                    })
                    .catch((error) => {
                        setLoading(false);
                        CustomConsole("Order confirm Api Error=>" + error);
                    });

                // } else {
                //     isItemAdd = 0;
                //     showAlert("Please select delivery address")
                // }

            } else if (purchaseType == 2) {
                // if (shippingAddressId != "" && startDate != null) {
                if (startDate != null) {
                    isItemAdd = 1;

                    // subscribe
                    var raw = JSON.stringify({
                        "cart": [
                            {
                                "product_id": productId,
                                "quantity": qty,
                                "option": {
                                    [product_option_id]: product_option_value_id
                                },
                                "subscription_plan_id": subscribe_plan_id,
                                "subscription_type": subscribeType,
                                "subscription_value": subscribeType == "varying" ? varingQty1 + "," + varingQty2 : productQty,
                                "is_subscription": 1,
                                "subscription_interval": subscribe_plan_interval,
                                "delivery_time": "12:30",
                                "start_date": formatDate(new Date(startDate)),
                                "end_date": endDate

                            }

                        ],
                        "cart_id": cart_id,
                        "customer_id": userId
                    });
                } else {
                    isItemAdd = 0;
                    // showAlert("Please select delivery address & select start date");
                    showAlert("Please select start date");
                }

            } else {
                // if (shippingAddressId != "" && startDate != null) {
                if (startDate != null) {
                    isItemAdd = 1;
                    let subscribeData = subscribeList.find(element => element.frequency == "trial");
                    CustomConsole(subscribeData);
                    setSubscribePlanId(subscribeData.subscription_plan_id);
                    setSubscribePlanValue("trial");

                    isItemAdd = 1;
                    // trial order
                    var raw = JSON.stringify({
                        "cart": [
                            {
                                "product_id": productId,
                                "quantity": productQty * 7,
                                "option": {
                                    [product_option_id]: product_option_value_id
                                },
                                "subscription_plan_id": subscribeData.subscription_plan_id,
                                "subscription_type": "trial",
                                "subscription_value": productQty,
                                "is_subscription": 1,
                                "subscription_interval": 1,
                                "delivery_time": "12:30",
                                "start_date": formatDate(new Date(startDate)),
                                "end_date": formatDate(new Date(startDate).addDays(7))

                            }

                        ],
                        "cart_id": cart_id,
                        "customer_id": userId
                    });
                } else {
                    isItemAdd = 0;
                    // showAlert("Please select delivery address & select start date");
                    showAlert("Please select start date");
                }
            }

            if (isItemAdd == 1) {

                // CustomConsole(raw);
                CustomConsole(MODIFYCART + "\n" + raw);

                var requestOptions = {
                    method: 'POST',
                    body: raw
                };

                setLoading(true);
                fetch(MODIFYCART, requestOptions)
                    .then(response => response.json())
                    .then((json) => {
                        CustomConsole(json);
                        if (json.success == 1) {
                            setLoading(false);
                            // alert("Product added into cart");
                            navigation.navigate("CartScreen");
                        } else {
                            setLoading(false);
                            if (json.error.length > 0) {
                                showAlert(json.error[0]);
                            }
                            // alert("Not Success");
                        }

                    })
                    .catch((error) => {
                        setLoading(false);
                        CustomConsole("Error=>" + error);
                    });
            }

        } catch (e) {
            setLoading(false);
            CustomConsole("Add to cart Exception=>" + e + "");
        }
    }

    // shipping address item
    const shippingAddressItem = (item, index) => {
        return (
            <View style={styles.shippingAddressView}>
                <View>
                    <RadioButton
                        value={shippingAddressId}
                        status={shippingAddressId === item.address_id ? 'checked' : 'unchecked'}
                        onPress={() => {
                            setShippingAddressId(item.address_id);
                            setShippingAddress(item.address_1 + ", \n" + item.address_2 + "\n" + item.city + ", " + item.zone + "\n" + item.country + "-" + item.postcode)
                            hideModal()
                        }}
                        color={colors.buttonColor}
                    />
                </View>
                <View>
                    <Text style={styles.addressType}>{item.address_type} </Text>
                    <Text style={styles.addressLineText}>{item.firstname}  {item.lastname}</Text>
                    <Text style={styles.addressLineText}>{item.address_1}</Text>
                    <Text style={styles.addressLineText}>{item.address_2}, {item.postcode}</Text>
                    <Text style={styles.addressLineText}>{item.city}, {item.zone}, {item.country}</Text>
                </View>
            </View>
        );
    }

    const priceCalc = (subscribeType, subscriptionDuration, productQty, varingQty1, varingQty2) => {
        // CustomConsole("productqty====>  " + productQty);
        // console.log(varingQty1*15)
        // console.log(varingQty2*15)
        // console.log((varingQty1*15) + (varingQty2*15))
        // console.log(productPriceWithoutSymbol)
        // console.log(((varingQty1*15) + (varingQty2*15)) * productPriceWithoutSymbol)
        var totalDays = 0;
        var qty = productQty;
        if (subscribeType === "daily") {
            if (subscriptionDuration === "1 month") {
                totalDays = 30
            } else if (subscriptionDuration === "3 month") {
                totalDays = 90
            } else if (subscriptionDuration === "6 month") {
                totalDays = 180
            } else {
                totalDays = 360
            }
        } else if (subscribeType === "alternate") {
            if (subscriptionDuration === "1 month") {
                totalDays = 15
            } else if (subscriptionDuration === "3 month") {
                totalDays = 45
            } else if (subscriptionDuration === "6 month") {
                totalDays = 90
            } else {
                totalDays = 180
            }
        } else {
            qty = varingQty1 + varingQty2;
            if (subscriptionDuration === "1 month") {
                totalDays = 15
            } else if (subscriptionDuration === "3 month") {
                totalDays = 45
            } else if (subscriptionDuration === "6 month") {
                totalDays = 90
            } else {
                totalDays = 180
            }
        }
        // console.log(totalDays)
        // console.log(productQty)
        // console.log(productPriceWithoutSymbol)
        setLoading(true);
        setSubscriptionTotalAmount(qty * totalDays * productPriceWithoutSymbol);
        setLoading(false);
    }

    return (
        <PaperProvider>
            <View>
                <StatusBar barStyle="dark-content" />
                <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                    <View style={{}}>

                        {/* progress view */}
                        {loading ? CustomProgressBar(loading) : null}
                        {/* end of progress view */}

                        {/* Header view */}
                        {HeaderComponent(cartLength, navigation, userId)}
                        {/* end of Header view */}

                        {/* main view */}
                        <View style={styles.csProductMainView1} dataSet={{ media: ids.csProductMainView1 }}>

                            {/* product info */}
                            <View style={styles.csProductContainer} dataSet={{ media: ids.csProductContainer }}>
                                <View style={styles.csProductInfoContainer} dataSet={{ media: ids.csProductInfoContainer }}>
                                    <View style={styles.csProductImageContainer}>
                                        <Image source={productImage} style={styles.csProductImage} />
                                    </View>
                                    <View style={styles.csProductDetailsContainer} dataSet={{ media: ids.csProductDetailsContainer }}>
                                        <Text style={styles.csProductName} numberOfLines={1} dataSet={{ media: ids.csProductName }}>{productName}</Text>
                                        <Text style={styles.csProductPrice} numberOfLines={1} dataSet={{ media: ids.csProductPrice }}>Price: {productPrice}</Text>
                                        <Text style={styles.csProductSize} numberOfLines={1} dataSet={{ media: ids.csProductSize }}>{productSize}</Text>
                                    </View>
                                </View>
                            </View>
                            {/* end of product info */}

                            {/* checkout info */}
                            <View style={styles.checkOutInfoView} dataSet={{ media: ids.checkOutInfoView }}>

                                {/* buy once && subscribe && trial */}
                                <View style={styles.purchaseTypeContainer} dataSet={{ media: ids.purchaseTypeContainer }}>
                                    {is_buy_once == "1" ? <Pressable onPress={() => {
                                        setPurchaseType(1);
                                        if (cart_purchase_type != undefined) {
                                            if (cart_purchase_type == 1) {
                                                setProductQty(parseFloat(cart_quantity));
                                            } else {
                                                setProductQty(1);
                                            }
                                        } else {
                                            setProductQty(1);
                                        }
                                    }}
                                        style={purchaseType == 1 ? styles.coActivePurchaseTypeView : styles.coInactivePurchaseTypeView} dataSet={{ media: ids.coActivePurchaseTypeView }}>
                                        <Text style={purchaseType == 1 ? styles.coActivePurchaseTypeText : styles.coInactivePurchaseTypeText} dataSet={{ media: ids.coInactivePurchaseTypeText }}>Buy Once</Text>
                                    </Pressable> : null}
                                    {productSubscriptionPlanList.length != 0 ?
                                        <>
                                            {productSubscriptionPlanList.some(element => element.frequency != "trial") ?
                                                <Pressable onPress={() => {
                                                    setPurchaseType(2);

                                                    if (cart_purchase_type != undefined) {
                                                        CustomConsole("===>" + cart_subscribe_plan)
                                                        CustomConsole("123===>" + cart_purchase_type)
                                                        if (cart_purchase_type == 2) {
                                                            if (cart_subscribe_plan == "daily") {
                                                                setProductQty(parseFloat(cart_quantity));
                                                            }
                                                            else if (cart_subscribe_plan == "alternate") {
                                                                setProductQty(parseFloat(cart_quantity));
                                                            }
                                                            else if (cart_subscribe_plan == "varying") {
                                                                setVaringQty1(parseFloat(cart_quantity));
                                                                setVaringQty2(parseFloat(cart_quantity2));
                                                            }

                                                        } else {
                                                            setProductQty(1);
                                                            if (productSubscriptionPlanList.some(element => element.frequency == "daily")) {
                                                                setSubscribeType("daily");
                                                                let subscribeData = subscribeList.find(element => element.frequency == "daily");
                                                                CustomConsole(subscribeData);
                                                                setSubscribePlanId(subscribeData.subscription_plan_id);
                                                            }
                                                            else if (productSubscriptionPlanList.some(element => element.frequency == "alternate")) {
                                                                setSubscribeType("alternate");
                                                                let subscribeData = subscribeList.find(element => element.frequency == "alternate");
                                                                CustomConsole(subscribeData);
                                                                setSubscribePlanId(subscribeData.subscription_plan_id);
                                                            }
                                                            else if (productSubscriptionPlanList.some(element => element.frequency == "varying")) {
                                                                setSubscribeType("varying");
                                                                let subscribeData = subscribeList.find(element => element.frequency == "varying");
                                                                CustomConsole(subscribeData);
                                                                setSubscribePlanId(subscribeData.subscription_plan_id);
                                                            }
                                                        }
                                                    } else {
                                                        setProductQty(1);
                                                        // setVaringQty1(1);
                                                        // setVaringQty2(1);
                                                        CustomConsole(productSubscriptionPlanList)
                                                        if (productSubscriptionPlanList.some(element => element.frequency == "daily")) {
                                                            setSubscribeType("daily");
                                                            let subscribeData = subscribeList.find(element => element.frequency == "daily");
                                                            CustomConsole(subscribeData);
                                                            setSubscribePlanId(subscribeData.subscription_plan_id);
                                                        }
                                                        else if (productSubscriptionPlanList.some(element => element.frequency == "alternate")) {
                                                            setSubscribeType("alternate");
                                                            let subscribeData = subscribeList.find(element => element.frequency == "alternate");
                                                            CustomConsole(subscribeData);
                                                            setSubscribePlanId(subscribeData.subscription_plan_id);
                                                        }
                                                        else if (productSubscriptionPlanList.some(element => element.frequency == "varying")) {
                                                            setSubscribeType("varying");
                                                            let subscribeData = subscribeList.find(element => element.frequency == "varying");
                                                            CustomConsole(subscribeData);
                                                            setSubscribePlanId(subscribeData.subscription_plan_id);
                                                        }
                                                    }

                                                }}
                                                    style={purchaseType == 2 ? styles.coActivePurchaseTypeView : styles.coInactivePurchaseTypeView} dataSet={{ media: ids.coInactivePurchaseTypeView }}>
                                                    <Text style={purchaseType == 2 ? styles.coActivePurchaseTypeText : styles.coInactivePurchaseTypeText} dataSet={{ media: ids.coInactivePurchaseTypeText }}>Subscribe</Text>
                                                </Pressable>
                                                : null
                                            }
                                            {productSubscriptionPlanList.some(element => element.frequency == "trial") ?
                                                <Pressable onPress={() => {
                                                    setPurchaseType(3);
                                                    if (cart_purchase_type != undefined) {
                                                        if (cart_purchase_type == 3) {
                                                            setProductQty(parseFloat(cart_quantity));
                                                        } else {
                                                            setProductQty(1);
                                                        }
                                                    } else {
                                                        setProductQty(1);
                                                    }
                                                }}
                                                    style={purchaseType == 3 ? styles.coActivePurchaseTypeView : styles.coInactivePurchaseTypeView} dataSet={{ media: ids.coInactivePurchaseTypeView }}>
                                                    <Text style={purchaseType == 3 ? styles.coActivePurchaseTypeText : styles.coInactivePurchaseTypeText} dataSet={{ media: ids.coInactivePurchaseTypeText }}>Trial for 7 days</Text>
                                                </Pressable>
                                                : null
                                            }


                                        </> : null}
                                </View>
                                {/* end of buy once && subscribe && trial */}

                                {purchaseType == 1 ?
                                    <>
                                        {/* buy once */}
                                        <View style={styles.quantityContainer}>
                                            <View style={styles.quantityRow}>
                                                <Text style={styles.quantityLabel} dataSet={{ media: ids.quantityLabel }}>Quantity</Text>
                                                <View style={styles.quantityControlContainer}>
                                                    <Pressable onPress={() => {
                                                        if ((productQty - 1) <= 0) {
                                                            setProductQty(1);
                                                        } else {
                                                            setProductQty(productQty - 1);
                                                        }
                                                    }}
                                                        style={styles.quantityControlButton}>
                                                        <Text style={styles.quantityText}>-</Text>
                                                    </Pressable>
                                                    <Text style={styles.quantityValue}>{productQty.toFixed()}</Text>
                                                    <Pressable onPress={() => {
                                                        setProductQty(productQty + 1);
                                                    }}
                                                        style={styles.quantityControlButton}>
                                                        <Text style={styles.quantityText}>+</Text>
                                                    </Pressable>
                                                </View>
                                            </View>
                                        </View>
                                    </>
                                    : purchaseType == 2 ?
                                        <>
                                            {/* subscribe */}
                                            {/* subscribe type */}
                                            {productSubscriptionPlanList.length != 0 ?
                                                <View style={styles.subscribeTypeContainer}>
                                                    {productSubscriptionPlanList.some(element => element.frequency == "daily") ? <View style={styles.subscribeOption}>
                                                        <RadioButton
                                                            value={subscribeType}
                                                            status={subscribeType === "daily" ? 'checked' : 'unchecked'}
                                                            onPress={() => {
                                                                setSubscribeType("daily");

                                                                if (cart_purchase_type != undefined) {
                                                                    if (cart_purchase_type == 2) {
                                                                        if (cart_subscribe_plan == "daily") {
                                                                            setProductQty(parseFloat(cart_quantity));
                                                                        }
                                                                        else {
                                                                            setProductQty(1);
                                                                        }

                                                                    } else {
                                                                        setProductQty(1);
                                                                    }
                                                                } else {
                                                                    setProductQty(1);
                                                                }



                                                                let subscribeData = subscribeList.find(element => element.frequency == "daily");
                                                                CustomConsole(subscribeData);
                                                                setSubscribePlanId(subscribeData.subscription_plan_id);
                                                                setSubscribePlanValue('1');
                                                                // setSubscribePlanInterval(subscribeData.);
                                                                priceCalc("daily", subscriptionDuration, 1, varingQty1, varingQty2)
                                                            }}
                                                            color={colors.buttonColor}
                                                        />
                                                        <Text style={styles.radioButton} dataSet={{ media: ids.radioButton }}>Daily</Text>
                                                    </View> : null}

                                                    {productSubscriptionPlanList.some(element => element.frequency == "alternate") ? <View style={styles.subscribeOption}>
                                                        <RadioButton
                                                            value={subscribeType}
                                                            status={subscribeType === "alternate" ? 'checked' : 'unchecked'}
                                                            onPress={() => {
                                                                setSubscribeType("alternate");

                                                                if (cart_purchase_type != undefined) {
                                                                    if (cart_purchase_type == 2) {
                                                                        if (cart_subscribe_plan == "alternate") {
                                                                            setProductQty(parseFloat(cart_quantity));
                                                                        }
                                                                        else {
                                                                            setProductQty(1);
                                                                        }

                                                                    } else {
                                                                        setProductQty(1);
                                                                    }
                                                                } else {
                                                                    setProductQty(1);
                                                                }

                                                                let subscribeData = subscribeList.find(element => element.frequency == "alternate");
                                                                CustomConsole(subscribeData);
                                                                setSubscribePlanId(subscribeData.subscription_plan_id);
                                                                setSubscribePlanValue('1');
                                                                // setSubscribePlanInterval(subscribeData.);
                                                                priceCalc("alternate", subscriptionDuration, 1, varingQty1, varingQty2)
                                                            }}
                                                            color={colors.buttonColor}
                                                        />
                                                        <Text style={styles.radioButton} dataSet={{ media: ids.radioButton }}>Alternate</Text>
                                                    </View> : null}

                                                    {productSubscriptionPlanList.some(element => element.frequency == "varying") ? <View style={styles.subscribeOption}>
                                                        <RadioButton
                                                            value={subscribeType}
                                                            status={subscribeType === "varying" ? 'checked' : 'unchecked'}
                                                            onPress={() => {
                                                                setSubscribeType("varying");

                                                                if (cart_purchase_type != undefined) {
                                                                    if (cart_purchase_type == 2) {
                                                                        if (cart_subscribe_plan == "varying") {
                                                                            setVaringQty1(parseFloat(cart_quantity));
                                                                            setVaringQty2(parseFloat(cart_quantity2));
                                                                        } else {
                                                                            setVaringQty1(1);
                                                                            setVaringQty2(1);
                                                                        }

                                                                    } else {
                                                                        setVaringQty1(1);
                                                                        setVaringQty2(1);
                                                                    }
                                                                } else {
                                                                    setVaringQty1(1);
                                                                    setVaringQty2(1);
                                                                }

                                                                let subscribeData = subscribeList.find(element => element.frequency == "varying");
                                                                CustomConsole(subscribeData);
                                                                setSubscribePlanId(subscribeData.subscription_plan_id);
                                                                setSubscribePlanValue("varying");
                                                                // setSubscribePlanInterval(subscribeData.);
                                                                priceCalc("varying", subscriptionDuration, productQty, 1, 1)
                                                            }}
                                                            color={colors.buttonColor}
                                                        />
                                                        <Text style={styles.radioButton} dataSet={{ media: ids.radioButton }}>Varying</Text>
                                                    </View> : null}

                                                    {/* <View style={{ flexDirection: "row", alignItems: "center", marginLeft: 10 }}>
                                   <RadioButton
                                       value={subscribeType}
                                       status={subscribeType === "weekly" ? 'checked' : 'unchecked'}
                                       onPress={() => setSubscribeType("weekly")}
                                       color={colors.buttonColor}
                                   />
                                   <Text style={{}}>Weekly</Text>
                               </View> */}
                                                </View> : null}


                                            {/* instructions */}
                                            <View style={styles.instructionsContainer}>
                                                {subscribeType != "" ?
                                                    subscribeType == "daily" ?
                                                        <>
                                                            <Text style={styles.instructionsTitle} dataSet={{ media: ids.instructionsTitle }}>Daily Delivery</Text>
                                                            <Text style={styles.instructionsText} dataSet={{ media: ids.instructionsText }}>Fixed quantity is delivered on everydays.</Text>
                                                        </>
                                                        :
                                                        subscribeType == 'alternate' ?
                                                            <>
                                                                <Text style={styles.instructionsTitle} dataSet={{ media: ids.instructionsTitle }}>Alternate Days Delivery</Text>
                                                                <Text style={styles.instructionsText} dataSet={{ media: ids.instructionsText }}>Fixed quantity is delivered on alternate days.</Text>
                                                            </>
                                                            : <>
                                                                <Text style={styles.instructionsTitle} dataSet={{ media: ids.instructionsTitle }}>Varying Delivery</Text>
                                                                <Text style={[styles.instructionsText, styles.varyingInstructionsText]} dataSet={{ media: ids.instructionsText }}>Varying quantities are repeated every 2 days.</Text>
                                                                <Text style={styles.instructionsText} dataSet={{ media: ids.instructionsText }}>E.g. 1 bottle today, 2 bottles tomorrow, 1 bottle days after tomorrow, followed by 2 bottles again and so on.</Text>
                                                            </>
                                                    : null
                                                }

                                            </View>

                                            {/* qty */}
                                            <View style={styles.varyingQuantityContainer}>
                                                {subscribeType != "" ?
                                                    subscribeType == "varying" ?
                                                        <>
                                                            <View style={styles.varyingQtyRow}>
                                                                <Text style={styles.varyingQtyText} dataSet={{ media: ids.varyingQtyText }}>Day 1 Quantity</Text>
                                                                <View style={styles.varyingQtyInputContainer}>
                                                                    <Pressable onPress={() => {
                                                                        if ((varingQty1 - 1) <= 0) {
                                                                            setVaringQty1(1);
                                                                            priceCalc(subscribeType, subscriptionDuration, productQty, 1, varingQty2)
                                                                        } else {
                                                                            setVaringQty1(varingQty1 - 1);
                                                                            priceCalc(subscribeType, subscriptionDuration, productQty, varingQty1 - 1, varingQty2)

                                                                        }
                                                                    }}
                                                                        style={styles.varyingQtyControl}>
                                                                        <Text style={styles.varyingQtyText2}>-</Text>
                                                                    </Pressable>
                                                                    <Text style={styles.varyingQtyValue}>{varingQty1.toFixed()}</Text>
                                                                    <Pressable onPress={() => {
                                                                        setVaringQty1(varingQty1 + 1);
                                                                        priceCalc(subscribeType, subscriptionDuration, productQty, varingQty1 + 1, varingQty2)
                                                                    }}
                                                                        style={styles.varyingQtyControl}>
                                                                        <Text style={styles.varyingQtyText2}>+</Text>
                                                                    </Pressable>
                                                                </View>
                                                            </View>
                                                            <View style={[styles.varyingQtyRow, { marginTop: 30 }]}>
                                                                <Text style={styles.varyingQtyText}>Day 2 Quantity</Text>
                                                                <View style={styles.varyingQtyInputContainer}>
                                                                    <Pressable onPress={() => {
                                                                        if ((varingQty2 - 1) <= 0) {
                                                                            setVaringQty2(1);
                                                                            priceCalc(subscribeType, subscriptionDuration, productQty, varingQty1, 1)
                                                                        } else {
                                                                            setVaringQty2(varingQty2 - 1);
                                                                            priceCalc(subscribeType, subscriptionDuration, productQty, varingQty1, varingQty2 - 1)
                                                                        }

                                                                    }}
                                                                        style={styles.varyingQtyControl}>
                                                                        <Text style={styles.varyingQtyText2}>-</Text>
                                                                    </Pressable>
                                                                    <Text style={styles.varyingQtyValue}>{varingQty2.toFixed()}</Text>
                                                                    <Pressable onPress={() => {
                                                                        setVaringQty2(varingQty2 + 1);
                                                                        priceCalc(subscribeType, subscriptionDuration, productQty, varingQty1, varingQty2 + 1)
                                                                    }}
                                                                        style={styles.varyingQtyControl}>
                                                                        <Text style={styles.varyingQtyText2}>+</Text>
                                                                    </Pressable>
                                                                </View>
                                                            </View>
                                                        </>
                                                        : <View style={styles.varyingQtyRow}>
                                                            <Text style={styles.varyingQtyText} dataSet={{ media: ids.varyingQtyText }}>Quantity</Text>
                                                            <View style={styles.varyingQtyInputContainer}>
                                                                <Pressable onPress={() => {
                                                                    if ((productQty - 1) <= 0) {
                                                                        setProductQty(1);
                                                                        priceCalc(subscribeType, subscriptionDuration, 1, varingQty1, varingQty2)
                                                                    } else {
                                                                        setProductQty(productQty - 1);
                                                                        priceCalc(subscribeType, subscriptionDuration, productQty - 1, varingQty1, varingQty2)
                                                                    }
                                                                }}
                                                                    style={styles.varyingQtyControl}>
                                                                    <Text style={styles.varyingQtyText2}>-</Text>
                                                                </Pressable>
                                                                <Text style={styles.varyingQtyValue}>{productQty.toFixed()}</Text>
                                                                <Pressable onPress={() => {
                                                                    setProductQty(productQty + 1);
                                                                    priceCalc(subscribeType, subscriptionDuration, productQty + 1, varingQty1, varingQty2)
                                                                }}
                                                                    style={styles.varyingQtyControl}>
                                                                    <Text style={styles.varyingQtyText2}>+</Text>
                                                                </Pressable>
                                                            </View>
                                                        </View>
                                                    : null
                                                }
                                            </View>



                                            {/* start date & end date */}
                                            <View>

                                                {/* start date view */}
                                                {subscribeType != "" ? <View style={styles.startDateContainer}>
                                                    <Text style={styles.startDateText} dataSet={{ media: ids.startDateText }}>Select Start Date</Text>
                                                    {/* <View style={{ marginLeft: 15, borderWidth: 1, borderColor: colors.blogBorderColor, flexDirection: 'row', alignItems: "center", borderRadius: 10, paddingVertical: 10, paddingHorizontal: 10 }}> */}
                                                    {/* <Text style={{ textAlign: 'left', fontSize: 15, color: colors.black, fontFamily: getDMSansSemiBoldFont() }}>12 Nov, 2023</Text>
                                                        <Pressable style={{ marginLeft: 20, }}>
                                                            <Image source={require("../assets/header/calendar.png")}
                                                                style={{ width: 20, height: 20, tintColor: colors.buttonColor, resizeMode: "contain" }} />
                                                        </Pressable> */}
                                                    <input style={styles.startDateInput}
                                                        type="date"
                                                        id="startDate"
                                                        name=""
                                                        ref={inputRef}
                                                        value={startDate}
                                                        // min={formatDateYMD((new Date(Date.now())).addDays(2))}
                                                        min={selectedDateStart}
                                                        onChange={(e) => {
                                                            // console.log(e.target.value);
                                                            setStartDate(e.target.value);
                                                            setSubscriptionDuration("1 month");
                                                            setEndDate(formatDate(new Date(e.target.value).addDays(30)));

                                                            priceCalc(subscribeType, "1 month", productQty, varingQty1, varingQty2)
                                                        }}
                                                    />
                                                    {/* </View> */}
                                                </View> : null}
                                                {/* end of start date view */}

                                                {/* end date view */}
                                                {subscribeType != "" ? <View style={styles.endDateContainer}>
                                                    <Text style={styles.endDateText} dataSet={{ media: ids.endDateText }}>Select End Date</Text>

                                                    <View style={styles.radioButtonContainer} dataSet={{ media: ids.radioButtonContainer }}>
                                                        <Pressable onPress={() => {
                                                            if (startDate != null) {
                                                                setSubscriptionDuration("1 month");
                                                                setEndDate(formatDate(new Date(startDate).addDays(30)));

                                                                priceCalc(subscribeType, "1 month", productQty, varingQty1, varingQty2)

                                                            } else {
                                                                showAlert("Please select start date");
                                                            }
                                                        }}
                                                            style={styles.radioButton}>
                                                            <RadioButton
                                                                value={subscriptionDuration}
                                                                status={subscriptionDuration === "1 month" ? 'checked' : 'unchecked'}
                                                                onPress={() => {
                                                                    if (startDate != null) {
                                                                        setSubscriptionDuration("1 month");
                                                                        setEndDate(formatDate(new Date(startDate).addDays(30)));

                                                                        priceCalc(subscribeType, "1 month", productQty, varingQty1, varingQty2)
                                                                    } else {
                                                                        showAlert("Please select start date");
                                                                    }
                                                                }}
                                                                color={colors.buttonColor}
                                                            />
                                                            <Text style={styles.radioButtonLabel} dataSet={{ media: ids.radioButtonLabel }}>1 Month</Text>
                                                        </Pressable>

                                                        <Pressable onPress={() => {
                                                            if (startDate != null) {
                                                                setSubscriptionDuration("3 month");
                                                                setEndDate(formatDate(new Date(startDate).addDays(90)));

                                                                priceCalc(subscribeType, "3 month", productQty, varingQty1, varingQty2)
                                                            } else {
                                                                showAlert("Please select start date");
                                                            }
                                                        }}
                                                            style={styles.radioButton}>
                                                            <RadioButton
                                                                value={subscriptionDuration}
                                                                status={subscriptionDuration === "3 month" ? 'checked' : 'unchecked'}
                                                                onPress={() => {
                                                                    if (startDate != null) {
                                                                        setSubscriptionDuration("3 month");
                                                                        setEndDate(formatDate(new Date(startDate).addDays(90)));

                                                                        priceCalc(subscribeType, "3 month", productQty, varingQty1, varingQty2)
                                                                    } else {
                                                                        showAlert("Please select start date");
                                                                    }
                                                                }}
                                                                color={colors.buttonColor}
                                                            />
                                                            <Text style={styles.radioButtonLabel} dataSet={{ media: ids.radioButtonLabel }}>3 Month</Text>
                                                        </Pressable>

                                                        <Pressable onPress={() => {
                                                            if (startDate != null) {
                                                                setSubscriptionDuration("6 month");
                                                                setEndDate(formatDate(new Date(startDate).addDays(180)));

                                                                priceCalc(subscribeType, "6 month", productQty, varingQty1, varingQty2)
                                                            } else {
                                                                showAlert("Please select start date");
                                                            }
                                                        }}
                                                            style={styles.radioButton}>
                                                            <RadioButton
                                                                value={subscriptionDuration}
                                                                status={subscriptionDuration === "6 month" ? 'checked' : 'unchecked'}
                                                                onPress={() => {
                                                                    if (startDate != null) {
                                                                        setSubscriptionDuration("6 month");
                                                                        setEndDate(formatDate(new Date(startDate).addDays(180)));

                                                                        priceCalc(subscribeType, "6 month", productQty, varingQty1, varingQty2)
                                                                    } else {
                                                                        showAlert("Please select start date");
                                                                    }
                                                                }}
                                                                color={colors.buttonColor}
                                                            />
                                                            <Text style={styles.radioButtonLabel} dataSet={{ media: ids.radioButtonLabel }}>6 Month</Text>
                                                        </Pressable>

                                                        <Pressable onPress={() => {
                                                            if (startDate != null) {
                                                                setSubscriptionDuration("12 month");
                                                                setEndDate(formatDate(new Date(startDate).addDays(360)));

                                                                priceCalc(subscribeType, "12 month", productQty, varingQty1, varingQty2)
                                                            } else {
                                                                showAlert("Please select start date");
                                                            }
                                                        }}
                                                            style={styles.radioButton}>
                                                            <RadioButton
                                                                value={subscriptionDuration}
                                                                status={subscriptionDuration === "12 month" ? 'checked' : 'unchecked'}
                                                                onPress={() => {
                                                                    if (startDate != null) {
                                                                        setSubscriptionDuration("12 month");
                                                                        setEndDate(formatDate(new Date(startDate).addDays(360)));

                                                                        priceCalc(subscribeType, "12 month", productQty, varingQty1, varingQty2)
                                                                    } else {
                                                                        showAlert("Please select start date");
                                                                    }
                                                                }}
                                                                color={colors.buttonColor}
                                                            />
                                                            <Text style={styles.radioButtonLabel} dataSet={{ media: ids.radioButtonLabel }}>12 Month</Text>
                                                        </Pressable>

                                                    </View>

                                                    {endDate != null ? <Text style={styles.totalAmountText} dataSet={{ media: ids.totalAmountText }}>Total: {subscriptionTotalAmount} </Text>
                                                        : null}
                                                    {/* <Text style={{ fontFamily: getDMSansSemiBoldFont(), fontSize: 13 }}>{endDate}</Text> */}
                                                </View> : null}
                                                {/* end of end date view */}

                                            </View>

                                        </>
                                        :
                                        <>
                                            {/* trial order */}

                                            <View style={styles.quantityContainer}>
                                                <View style={styles.quantityRow}>
                                                    <Text style={styles.quantityLabel}>Quantity</Text>
                                                    <View style={styles.quantityControlContainer}>
                                                        <Pressable onPress={() => {
                                                            if ((productQty - 1) <= 0) {
                                                                setProductQty(1);
                                                            } else {
                                                                setProductQty(productQty - 1);
                                                            }
                                                        }}
                                                            style={styles.quantityControlButton}>
                                                            <Text style={styles.quantityText}>-</Text>
                                                        </Pressable>
                                                        <Text style={styles.quantityValue}>{productQty.toFixed()}</Text>
                                                        <Pressable onPress={() => {
                                                            setProductQty(productQty + 1);
                                                        }}
                                                            style={styles.quantityControlButton}>
                                                            <Text style={styles.quantityText}>+</Text>
                                                        </Pressable>
                                                    </View>
                                                </View>
                                            </View>

                                            {/* start date */}
                                            <View>
                                                <View style={styles.startDateContainer}>
                                                    <Text style={styles.startDateText}>Select Start Date</Text>
                                                    {/* <View style={{ marginLeft: 15, borderWidth: 1, borderColor: colors.blogBorderColor, flexDirection: 'row', alignItems: "center", borderRadius: 10, paddingVertical: 10, paddingHorizontal: 10 }}> */}
                                                    {/* <Text style={{ textAlign: 'left', fontSize: 15, color: colors.black, fontFamily: getDMSansSemiBoldFont() }}>12 Nov, 2023</Text>
                                                        <Pressable style={{ marginLeft: 20, }}>
                                                            <Image source={require("../assets/header/calendar.png")}
                                                                style={{ width: 20, height: 20, tintColor: colors.buttonColor, resizeMode: "contain" }} />
                                                        </Pressable> */}
                                                    <input style={styles.startDateInput}
                                                        type="date"
                                                        id="startDate"
                                                        name=""
                                                        value={startDate}
                                                        min={formatDateYMD(new Date(Date.now()))}
                                                        onChange={(e) => {
                                                            // console.log(e.target.value);
                                                            setStartDate(e.target.value);
                                                        }}
                                                    />
                                                    {/* </View> */}
                                                </View>
                                            </View>

                                        </>}

                            </View>
                            {/* end of checkout info */}

                            {/* delivery info */}
                            {/* <View style={styles.deliveryInfoContainer}>
                                <View style={styles.deliveryInfoRow}>
                                    <View style={styles.deliveryInfoIcon}>
                                        <Image
                                            source={require("../assets/header/address_pin.png")}
                                            style={styles.deliveryInfoIconImage}
                                        />
                                    </View>
                                    <Text style={styles.deliveryInfoText} dataSet={{ media: ids.deliveryInfoText }}>Delivery Address</Text>
                                </View>
                                <Text style={styles.deliveryAddressText} dataSet={{ media: ids.deliveryAddressText }}>{shippingAddress}</Text>
                                {shippingAddressList.length != 0 ? (<Pressable
                                    onPress={() => {
                                        setChangeAddressCheck(true);
                                        // showModal();
                                        // navigation.navigate("CheckoutScreen");
                                        navigation.navigate("AddShippingAddressScreen", {
                                            // paramItem: item,
                                            paramFrom: "EditShipping"
                                        });
                                    }}
                                    style={styles.addAddressButton}
                                >
                                    <Image
                                        source={require("../assets/header/add.png")}
                                        style={styles.deliveryInfoIconImage}
                                    />
                                    <Text style={styles.addAddressButtonText} dataSet={{ media: ids.addAddressButtonText }}>CHANGE ADDRESS</Text>
                                </Pressable>
                                ) : null}
                                {shippingAddressList.length === 0 ? (
                                    <Pressable
                                        onPress={() => {
                                            showModal();
                                            // navigation.navigate("CheckoutScreen");
                                        }}
                                        style={styles.addAddressButton}
                                    >
                                        <Image
                                            source={require("../assets/header/add.png")}
                                            style={styles.deliveryInfoIconImage}
                                        />
                                        <Text style={styles.addAddressButtonText} dataSet={{ media: ids.addAddressButtonText }}>ADD ADDRESS</Text>
                                    </Pressable>
                                ) : null}
                            </View>  */}
                            {/* end of delivery info */}

                            {/* Add item */}
                            <View style={styles.addItemContainer}>
                                <Pressable onPress={() => {
                                    if (toFrom == "modify") {
                                        modifyItemApi();
                                    } else {
                                        addItemApi();
                                    }
                                }} style={styles.addItemButton}>
                                    <Image source={require("../assets/header/add.png")} style={styles.addItemIcon} />
                                    <Text style={styles.addItemButtonText} dataSet={{ media: ids.addItemButtonText }}>ADD ITEM</Text>
                                </Pressable>
                            </View>
                            {/* end of add item */}

                        </View>
                        {/* end of main view */}

                        {/* address modal */}
                        {/* <Modal
                            onRequestClose={hideModal}
                            transparent
                            visible={visible}
                            animationType={'slide'}>
                            <View style={styles.modalContainer}>
                                <View style={[styles.modalContent, offset]} dataSet={{ media: ids.modalContent }}>
                                    <ScrollView>
                                        <Text style={styles.modalTitle}>{"Select Shipping Address"}</Text>
                                        {shippingAddressList.length == 0 ?
                                            <View style={styles.noAddressContainer}>
                                                <Text style={styles.noAddressText}>No Addresses Found</Text>
                                            </View> :
                                            <FlatList style={{}}
                                                showsVerticalScrollIndicator={false}
                                                data={shippingAddressList}
                                                keyExtractor={(item, id) => String(id)}
                                                renderItem={({ item, index }) => shippingAddressItem(item, index)} />}
                                    </ScrollView>

                                    <View style={styles.modalButtonsContainer}>
                                        {shippingAddressList.length == 0 ? <Pressable onPress={() => {
                                            hideModal();
                                            setChangeAddressCheck(true);
                                            navigation.navigate("AddShippingAddressScreen")
                                        }} style={styles.addButton}>
                                            <Text style={styles.buttonText}>Add Address</Text>
                                        </Pressable> : null}
                                        <Pressable onPress={hideModal} style={styles.closeButton}>
                                            <Text style={styles.buttonText}>Close</Text>
                                        </Pressable>
                                    </View>

                                </View>
                            </View>
                        </Modal>  */}
                        {/* end of address modal view */}

                        {/* common modal */}
                        <Modal
                            onRequestClose={hideAlert}
                            transparent
                            visible={alertShow}
                            animationType={'slide'}
                        >
                            <View style={styles.commonModalMainView} dataSet={{ media: ids.commonModalMainView }}>
                                <View style={[styles.commonModalViewContainer, offset]} dataSet={{ media: ids.commonModalViewContainer }}>

                                    <View>
                                        <Text style={styles.commonModalText}>{alertMsg}</Text>
                                    </View>
                                    <Pressable
                                        onPress={hideAlert}
                                        style={styles.commonModalButton}>
                                        <Text style={styles.commonModalButtonText}>Ok, Got it</Text>
                                    </Pressable>
                                </View>
                            </View>
                        </Modal>
                        {/* end of common modal */}

                        {/* Footer view */}
                        {/* {FooterComponent(navigation, userId)}*/}
                        {/* end of Footer view */}

                    </View>

                </ScrollView>
            </View>
        </PaperProvider>
    );
};

export default CheckoutScreen;
