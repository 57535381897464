import React, { useState } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, Image, Dimensions, FlatList } from 'react-native';
import { colors } from '../common/color';
import { APP_NAME } from '../common/string';
import { CustomConsole, CustomDigits, CustomProgressBar, FooterComponent, HeaderComponent, formatDate, getDMSansBoldFont, getDMSansRegularFont, getDMSansSemiBoldFont, scrollToTop, validateEmail } from '../common/utils';
import { CANCELSUBSCRIPTION, CANCELSUBSCRIPTIONLIST, CARTLIST, LOGIN, ORDERLIST, SUBSCRIPTIONLIST, SUBSCRIPTIONMODIFYLOG } from '../common/webUtils';
import { useIsFocused } from '@react-navigation/native';
import { getSession, USER_ID } from '../common/LocalStorage';
import { styles, ids } from '../common/responsive.js';

function LogSubscriptionListScreen({ navigation }) {

    const [loading, setLoading] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [userId, setUserId] = useState('');
    const [cartLength, setcartLength] = React.useState('0');

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])


    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            orderListApi(user_id);
            cartListApi();
        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST + "\n" + raw);

            // setLoading(true);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                    // setLoading(false);
                })
                .catch((error) => {
                    // setLoading(false);
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    // order list api
    const orderListApi = async (userId) => {
        try {
            var raw = JSON.stringify({
                "customer_id": userId
            });
            var requestOptions = {
                method: 'POST',
                body: raw,
                redirect: 'follow'
            };

            CustomConsole(SUBSCRIPTIONMODIFYLOG + "\n" + raw);

            setLoading(true);
            fetch(SUBSCRIPTIONMODIFYLOG, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);
                    // setOrderList(json.data);
                    orderList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {

                        orderList.push({
                            index: i,
                            customer_id: json.data[i].customer_id,
                            product_name: json.data[i].product_name,
                            order_id: json.data[i].order_id,
                            subscription_id: json.data[i].subscription_id,
                            product_id: json.data[i].product_id,
                            product_option_id: json.data[i].product_option_id,
                            product_option_value_id: json.data[i].product_option_value_id,
                            option_value_id: json.data[i].option_value_id,
                            option_name: json.data[i].option_name,
                            modify_date: json.data[i].modify_date,
                            created_date: json.data[i].created_date,
                            quantity: json.data[i].quantity,
                            subscription_balance: json.data[i].subscription_balance,
                        });
                    }

                    setOrderList(orderList);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Order Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Order Exception=>" + e + "");
        }
    }

    // order item view
    const orderItem = (item, index) => {
        return (
            
            <View style={styles.orderItemContainerView} dataSet={{ media: ids.orderItemContainerView }}>
                <View style={styles.orderItemMainView} dataSet={{ media: ids.orderItemMainView }}>

                    {/* order details view*/}
                    <View style={styles.logItemDetails} dataSet={{ media: ids.logItemDetails }}>
                        <Text style={styles.orderItemOrderNoText} dataSet={{ media: ids.orderItemOrderNoText }}>{item.product_name} </Text>
                        <Text style={styles.cartItemOption} dataSet={{ media: ids.cartItemOption }}>{item.option_name} X {item.quantity} </Text>
                        <Text style={styles.cartItemOption} dataSet={{ media: ids.cartItemOption }}>Modify on: {item.modify_date} </Text>
                        <Text style={styles.cartItemOption} dataSet={{ media: ids.cartItemOption }}>Created on: {item.created_date} </Text>
                    </View>
                    {/* end of order details view */}

                </View>
            </View>
        );
    }

    return (
        <View>
            <StatusBar barStyle="dark-content" />
            <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                <View>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent(cartLength, navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View style={styles.orderListMainView} dataSet={{ media: ids.orderListMainView }}>

                        <View style={styles.orderHeadingTextView}>
                            <Text style={styles.orderHeadingText}>{"Modify Logs"}</Text>
                        </View>

                        {/* order list view */}
                        {orderList.length != 0 ?
                            <FlatList
                                style={styles.orderFlatListView}
                                data={orderList}
                                keyExtractor={(item, id) => String(id)}
                                ItemSeparatorComponent={<View style={styles.orderItemSeperator} />}
                                renderItem={({ item, index }) => orderItem(item, index)} />
                            :
                            !loading ?
                                <View style={styles.orderEmptyView}>
                                    <Text style={styles.orderEmptyText}>Subscriptions is not available</Text>
                                </View> : null
                        }

                    </View>
                    {/* end of main view */}

                    {/* Footer view */}
                    {/* {FooterComponent(navigation, userId)}*/}
                    {/* end of Footer view */}

                </View>
            </ScrollView>
        </View>
    );
};

export default LogSubscriptionListScreen;
