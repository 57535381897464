import React, { useMemo, useState } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, Image, Dimensions, FlatList, } from 'react-native';
import { colors } from '../common/color';
import { APP_NAME } from '../common/string';
import { CustomConsole, CustomDigits, CustomProgressBar, FooterComponent, HeaderComponent, formatDate, formatDateDMY, formatDateYMD, getDMSansBoldFont, getDMSansMediumFont, getDMSansRegularFont, getDMSansSemiBoldFont, scrollToTop, validateEmail } from '../common/utils';
import { ADDTOCART, CANCELSUBSCRIPTION, CARTLIST, CONFIRMORDER, EMPTYCART, LOGIN, MODIFYSINGLEDAYQTY, ORDERLIST, SUBSCRIPTIONCONTINUE, SUBSCRIPTIONLIST, SUBSCRIPTIONMODIFYLOG, SUBSCRIPTIONPAUSE, SUBSCRIPTIONPAUSELIST, TIMEZONE } from '../common/webUtils';
import { useIsFocused } from '@react-navigation/native';
import { getSession, USER_ID } from '../common/LocalStorage';
import { Modal } from 'react-native';
import { styles, ids } from '../common/responsive.js';
import moment from 'moment';
import DateRangePicker from "rn-select-date-range";
import { Picker } from '@react-native-picker/picker';

function SubscribeListScreen({ navigation }) {

    const [selectedRange, setRange] = useState({});

    const WIGGLE_ROOM = 128;

    const offset = useMemo(() => {
        return {
            top: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2,
            left: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2
        };
    }, []);

    const [alertShow, setAlertShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    // modal hide/show
    const showAlert = (text) => {
        setAlertShow(true);
        setAlertMsg(text)
    };
    const hideAlert = () => setAlertShow(false);

    // confirm cancel modal
    const [confirmCancelAlertShow, setConfirmCancelAlertShow] = useState(false);

    // modal hide/show
    const showConfirmCancelAlert = (text) => {
        setConfirmCancelAlertShow(true);
    };
    const hideConfirmCancelAlert = () => setConfirmCancelAlertShow(false);

    const [loading, setLoading] = useState(false);
    const [orderList, setOrderList] = useState([]);
    const [modifyLogList, setModifyLogList] = useState([]);
    const [userId, setUserId] = useState('');
    const [cartLength, setcartLength] = useState('0');
    const [modifyShow, setModifyShow] = useState([]);
    const [productList, setProductList] = useState([]);
    const [modifyProductQty, setModifyProductQty] = useState([]);
    const [visible, setVisible] = useState(false);
    const [pauseSubscriptionVisible, setPauseSubscriptionVisible] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [subscriptionId, setSubscriptionId] = useState('');
    const [subscriptionIndex, setSubscriptionIndex] = useState('');
    const [timeZoneEnbled, setTimeZoneEnbled] = useState(false);
    const [openQtyShowView, setOpenQtyShowView] = useState(null);
    const [subscriptionStatus, setSubscriptionStatus] = useState('');
    const [subscriptionModifyModal, setSubscriptionModifyModal] = useState(false);
    const [subscribe_list_items_arr, setList_items_arr] = useState([]);

    const [vacation_history, setVacation_history] = useState([]);
    const [vacationModalVisible, setVacationModalVisible] = useState(false);

    // vacation history modal hide/show
    const showVacationHistoryModal = (pause_dates) => {
        setVacationModalVisible(true);
        setVacation_history(pause_dates);
    }
    const hideVacationHistoryModal = () => setVacationModalVisible(false);

    // modal hide/show
    const showModal = (index, subscription_id, order_status) => {
        setVisible(true);
        setSubscriptionIndex(index);
        setSubscriptionId(subscription_id);
        setSubscriptionStatus(order_status);
    };
    const hideModal = () => setVisible(false);

    // subscription pause hide/show
    const showSubscriptionPauseModal = (subscription_id) => {
        setPauseSubscriptionVisible(true);
        setSubscriptionId(subscription_id);
    };
    const hideSubscriptionPauseModal = () => setPauseSubscriptionVisible(false);

    // subscription modify modal hide/show
    const showSubscriptionModifyModal = (list_items_arr, subscription_id) => {
        setSubscriptionModifyModal(true);
        setList_items_arr(list_items_arr);
        setSubscriptionId(subscription_id);
    };
    const hideSubscriptionModifyModal = () => setSubscriptionModifyModal(false);

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        timeZoneApi();

    }, [isFocused])

    // timezone api
    const timeZoneApi = async () => {
        var requestOptionsTimezone = {
            method: 'GET'
        };
        CustomConsole(TIMEZONE);
        setLoading(true);
        fetch(TIMEZONE, requestOptionsTimezone)
            .then(responseTimezone => responseTimezone.json())
            .then((jsonTimezone) => {
                CustomConsole(jsonTimezone);
                if (jsonTimezone.success == 1) {
                    CustomConsole(jsonTimezone.data.current_time);
                    if (jsonTimezone.data.current_time >= "22:00:00") {
                        setTimeZoneEnbled(true);
                    } else {
                        setTimeZoneEnbled(false);
                    }
                    getSessionData();
                }
            })
            .catch((errorTimezone) => {
                setLoading(false);
                CustomConsole("Timezone Api Error=>" + errorTimezone);
            });
    }

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            modifyLogListApi(user_id);
            cartListApi();
            scrollToTop();
        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);

            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                })
                .catch((error) => {
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    // Function to check if a date string is between two other date strings
    function isDateBetween(dateString, startDateString, endDateString) {
        var date = new Date(dateString);
        var startDate = new Date(startDateString);
        var endDate = new Date(endDateString);

        return date >= startDate && date <= endDate;
    }

    // modify scription log api
    const modifyLogListApi = async (userId) => {
        try {
            var raw = JSON.stringify({
                "customer_id": userId
            });
            var requestOptions = {
                method: 'POST',
                body: raw,
                redirect: 'follow'
            };

            CustomConsole(SUBSCRIPTIONMODIFYLOG + "\n" + raw);

            setLoading(true);
            fetch(SUBSCRIPTIONMODIFYLOG, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);
                    modifyLogList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {

                        modifyLogList.push({
                            index: i,
                            customer_id: json.data[i].customer_id,
                            order_id: json.data[i].order_id,
                            subscription_id: json.data[i].subscription_id,
                            product_id: json.data[i].product_id,
                            product_option_id: json.data[i].product_option_id,
                            product_option_value_id: json.data[i].product_option_value_id,
                            option_value_id: json.data[i].option_value_id,
                            option_name: json.data[i].option_name,
                            modify_date: json.data[i].modify_date,
                            created_date: json.data[i].created_date,
                            quantity: json.data[i].quantity,
                            subscription_balance: json.data[i].subscription_balance,
                        });
                    }

                    setModifyLogList(modifyLogList);
                    orderListApi(userId, modifyLogList);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Modify scription log Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Modify scription log Exception=>" + e + "");
        }
    }

    // order list api
    const orderListApi = async (userId, modifyLogList) => {
        try {

            CustomConsole("timeZone enabled===> " + modifyLogList);

            var raw = JSON.stringify({
                "customer_id": userId
            });
            var requestOptions = {
                method: 'POST',
                body: raw,
                redirect: 'follow'
            };

            CustomConsole(SUBSCRIPTIONLIST + "\n" + raw);


            fetch(SUBSCRIPTIONLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    orderList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {
                        modifyShow.push(false);

                        let list_items_arr = [];
                        let list_items_arr1 = [];

                        for (let j = 0; j < json.data[i].line_items.length; j++) {
                            if (json.data[i].line_items[j].is_subscription == "1") {
                                list_items_arr.push(json.data[i].line_items[j]);
                            }
                        }

                        for (let j = 0; j < json.data[i].line_items.length; j++) {
                            if (json.data[i].line_items[j].is_subscription == "1" && json.data[i].line_items[j].product_data.subscription.subscription_value !== undefined) {
                                let date_arr = [];

                                var start = moment(formatDateYMD(new Date(Date.now())));
                                if (timeZoneEnbled) {
                                    start = moment(formatDateYMD(new Date(Date.now()).addDays(1)));
                                }

                                var end = moment(json.data[i].line_items[j].product_data.subscription.end_date);
                                var diff = end.diff(start, "days")
                                var qut_diff = end.diff(start, "days") - 1;//to remove todays date


                                // CustomConsole("qut_diff=====> " + qut_diff);
                                // CustomConsole("Diii=====> " + diff);

                                // if (diff > 5) {
                                //     diff = 5;
                                // }
                                if (diff > 3) {
                                    diff = 3;
                                }

                                var startFrom = 1;
                                if (timeZoneEnbled) {
                                    startFrom = 2;
                                }

                                for (let k = 0; k < diff; k++) {
                                    var quantity_val = "0";
                                    if (json.data[i].line_items[j].product_data.subscription.subscription_type === "alternate") {
                                        if (qut_diff % 2 == 0) {
                                            quantity_val = json.data[i].line_items[j].product_data.subscription.subscription_value;
                                        } else {
                                            quantity_val = "0"
                                        }
                                    } else if (json.data[i].line_items[j].product_data.subscription.subscription_type === "varying") {
                                        if (qut_diff % 2 == 0) {
                                            quantity_val = json.data[i].line_items[j].product_data.subscription.subscription_value.split(",")[0];
                                        } else {
                                            quantity_val = json.data[i].line_items[j].product_data.subscription.subscription_value.split(",")[1];
                                        }
                                    } else {
                                        quantity_val = json.data[i].line_items[j].product_data.subscription.subscription_value;
                                    }
                                    qut_diff -= 1;

                                    var is_pause = 0;

                                    for (let l = 0; l < json.data[i].pause_dates.length; l++) {
                                        // CustomConsole("Start date==> " + json.data[i].pause_dates[l].pause_start_date);
                                        // CustomConsole("End date==> " + json.data[i].pause_dates[l].pause_end_date);

                                        if (json.data[i].line_items[j].product_data.subscription.subscription_id == json.data[i].pause_dates[l].subscription_id) {
                                            if (isDateBetween(formatDateYMD(new Date(Date.now()).addDays(k + startFrom)), json.data[i].pause_dates[l].pause_start_date, json.data[i].pause_dates[l].pause_end_date)) {
                                                // console.log(" is between ");
                                                is_pause = 1;
                                                break;
                                            }
                                        }

                                        // else {
                                        //     console.log(" is NOT between ");
                                        // }
                                    }

                                    if (modifyLogList.find(element => element.subscription_id == json.data[i].line_items[j].product_data.subscription.subscription_id && element.modify_date == formatDateYMD(new Date(Date.now()).addDays(k + startFrom))) != undefined) {
                                        var logData = modifyLogList.find(element => element.subscription_id == json.data[i].line_items[j].product_data.subscription.subscription_id && element.modify_date == formatDateYMD(new Date(Date.now()).addDays(k + startFrom)))
                                        // CustomConsole("in if===>" + logData.modify_date);
                                        date_arr.push({
                                            quantity: logData.quantity,
                                            startDate: formatDateYMD(new Date(Date.now()).addDays(k + startFrom)),
                                            endDate: json.data[i].line_items[j].product_data.subscription.end_date,
                                            addToCartQty: logData.quantity,
                                            isPause: is_pause,
                                            subscription_id: json.data[i].line_items[j].product_data.subscription.subscription_id,
                                            all_options: json.data[i].line_items[j].product_data.all_options,
                                            selected_option_id: logData.product_option_id,
                                            selected_option_value_id: logData.product_option_value_id
                                        });

                                    } else {
                                        // CustomConsole("in else===>");
                                        date_arr.push({
                                            quantity: quantity_val,
                                            startDate: formatDateYMD(new Date(Date.now()).addDays(k + startFrom)),
                                            endDate: json.data[i].line_items[j].product_data.subscription.end_date,
                                            // addToCartQty: 1,
                                            addToCartQty: parseInt(quantity_val),
                                            isPause: is_pause,
                                            subscription_id: json.data[i].line_items[j].product_data.subscription.subscription_id,
                                            all_options: json.data[i].line_items[j].product_data.all_options,
                                            selected_option_id: json.data[i].line_items[j].product_data.options[0].product_option_id,
                                            selected_option_value_id: json.data[i].line_items[j].product_data.options[0].product_option_value_id
                                        });
                                    }


                                }

                                // console.log("date_arr===>", date_arr);
                                list_items_arr1.push({
                                    dateArr: date_arr,
                                    orderId: json.data[i].order_id,
                                    productName: json.data[i].line_items[j].product_data.name,
                                    productId: json.data[i].line_items[j].product_data.product_id,
                                    productOptionId: json.data[i].line_items[j].product_data.options[0].product_option_id,
                                    productOptionValueId: json.data[i].line_items[j].product_data.options[0].product_option_value_id,

                                });
                            }
                        }

                        orderList.push({
                            index: i,
                            order_id: json.data[i].order_id,
                            invoice_no: json.data[i].invoice_no,
                            total: CustomDigits(json.data[i].total),
                            date_added: formatDate(new Date(json.data[i].date_added)),
                            // line_items: json.data[i].line_items,
                            line_items: list_items_arr,
                            list_items_arr: list_items_arr1,
                            firstname: json.data[i].firstname,
                            lastname: json.data[i].lastname,
                            email: json.data[i].email,
                            telephone: json.data[i].telephone,
                            delivery_instructions: json.data[i].delivery_instructions,
                            subscription_id: json.data[i].subscription_id,
                            order_status: json.data[i].order_status,
                            pause_dates: json.data[i].pause_dates
                        });
                    }

                    setOrderList([...orderList]);
                    setModifyShow(modifyShow);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Order Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Order Exception=>" + e + "");
        }
    }

    // cancel api call
    const cancelApi = async (subscription_id) => {
        try {

            var raw = JSON.stringify({
                "subscription_id": subscription_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw
            };

            CustomConsole(CANCELSUBSCRIPTION + "\n" + raw);

            setLoading(true);
            fetch(CANCELSUBSCRIPTION, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);
                    if (json.status == 1) {
                        // showAlert(json.message);
                        hideModal();
                        hideConfirmCancelAlert();
                        // orderListApi(userId);
                        modifyLogListApi(userId);
                    } else {
                        hideConfirmCancelAlert();
                        showAlert(json.message);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Cancel Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Cancel Exception=>" + e + "");
        }

    }

    // modify api call
    const modifyApi = async (subscriptionIndex) => {
        try {

            CustomConsole(productList);
            CustomConsole(modifyProductQty);

            var i = subscriptionIndex;
            var orderConfirmCall = 1;

            if (i <= productList.length - 1) {
                // for (var i = 0; i < productList.length; i++) {
                CustomConsole("index iteration==>" + i); //Index
                // CustomConsole("index==>" + productList[i].product_id); //product id
                // CustomConsole("index1==>" + productList[i].product_data.options[0].product_option_id); //product_option_id
                // CustomConsole("index1==>" + productList[i].product_data.options[0].product_option_value_id); //product_option_value_id
                // CustomConsole("index1==>" + productList[i].product_data.subscription.subscription_plan_id); //subscription_plan_id
                // CustomConsole("index1==>" + productList[i].product_data.subscription.subscription_type); //subscription_type
                // CustomConsole("index1==>" + productList[i].product_data.subscription.subscription_value); //subscription_value
                // CustomConsole("index1==>" + productList[i].is_subscription); //is_subscription
                // CustomConsole("index1==>" + productList[i].product_data.subscription.shipping_address_id); //shipping_address_id
                // CustomConsole("index1==>" + 1); //subscribe_plan_interval
                // CustomConsole(modifyProductQty[i]);

                var modifyQty = 0;

                var start = moment(new Date(Date.now()));
                var end = moment(productList[i].product_data.subscription.end_date);
                const diff = end.diff(start, "days");


                CustomConsole("Modifyqty days ===> " + (diff + 1));

                if (productList[i].product_data.subscription.subscription_type == "daily") {
                    modifyQty = modifyProductQty[i].product_qty * (diff + 1);
                } else if (productList[i].product_data.subscription.subscription_type == "alternate") {
                    modifyQty = Math.ceil(modifyProductQty[i].product_qty * (diff + 1)) / 2;
                } else {
                    var calQty = Math.ceil((modifyProductQty[i].product_qty * (diff + 1)) / 2);
                    var calQty2 = Math.floor((modifyProductQty[i].product_qty2 * (diff + 1)) / 2);
                    modifyQty = calQty + calQty2;
                }

                CustomConsole("Modifyqty final ===> " + modifyQty);

                var raw = JSON.stringify({
                    "cart": [
                        {
                            "product_id": productList[i].product_id,
                            "quantity": modifyQty,
                            "option": {
                                [productList[i].product_data.options[0].product_option_id]: productList[i].product_data.options[0].product_option_value_id
                            },
                            "subscription_plan_id": productList[i].product_data.subscription.subscription_plan_id,
                            "subscription_type": productList[i].product_data.subscription.subscription_type,
                            "subscription_value": productList[i].product_data.subscription.subscription_type == "varying" ? modifyProductQty[i].product_qty + "," + modifyProductQty[i].product_qty2 : modifyProductQty[i].product_qty,
                            "is_subscription": 1,
                            "subscription_interval": 1,
                            "delivery_time": "12:30",
                            "start_date": formatDate(new Date(Date.now())),
                            "end_date": formatDate(new Date(productList[i].product_data.subscription.end_date))

                        }

                    ],
                    "customer_id": userId
                });

                CustomConsole(raw);

                CustomConsole(ADDTOCART + "\n" + raw);

                var requestOptions = {
                    method: 'POST',
                    body: raw
                };

                fetch(ADDTOCART, requestOptions)
                    .then(response => response.json())
                    .then((json) => {
                        CustomConsole(json);
                        if (json.success == 1) {
                            setLoading(false);
                            // alert("Product added into cart");

                            CustomConsole("index====> " + (i));
                            CustomConsole("product length ====> " + (productList.length - 1));

                            if ((productList.length - 1) == i) {
                                CustomConsole("index in if ====> " + (i));
                                orderConfirmApi(orderConfirmCall, productList[(i)].product_data.subscription.shipping_address_id, productList[(i)].product_data.subscription.subscription_id);
                            } else {
                                modifyApi(subscriptionIndex++);
                            }

                        } else {
                            CustomConsole("Order confirm==>" + i + "====>" + json)

                            orderConfirmCall = 0;
                            setLoading(false);
                            showAlert(json.error);
                            // if (json.error.length > 0) {
                            //     alert(json.error[0]);
                            // }
                        }

                    })
                    .catch((error) => {
                        setLoading(false);
                        CustomConsole("Error=>" + error);
                    });

            }

        } catch (e) {
            setLoading(false);
            CustomConsole("Modify Exception=>" + e + "");
        }

    }

    // order confirm call
    const orderConfirmApi = async (orderConfirmCall, shipping_address_id, subscription_id) => {
        CustomConsole("Order confirm==>" + orderConfirmCall)

        if (orderConfirmCall == 1) {
            // alert("success");


            var rawOrderConfirm = JSON.stringify({
                "customer_id": userId,
                "currency": "INR",
                "payment_success_id": "0",
                "address_id": shipping_address_id,
                "billing_address_id": shipping_address_id,
                "shipping_method": "free.free",
                "payment_method": "razorpay",
                "existing_subscription_id": subscription_id
            });

            var requestOptionsOrderConfirm = {
                method: 'POST',
                body: rawOrderConfirm,
            };

            CustomConsole(CONFIRMORDER + "\n" + rawOrderConfirm);

            fetch(CONFIRMORDER, requestOptionsOrderConfirm)
                .then(responseOrderConfirm => responseOrderConfirm.json())
                .then((jsonOrderConfirm) => {
                    CustomConsole(jsonOrderConfirm);
                    if (jsonOrderConfirm.success == "1") {
                        // setConfirmLoading(false);
                        hideModal();
                        setLoading(false);
                        // alert("Order modified successfully");
                        // orderListApi(userId);
                        modifyLogListApi(userId);
                    } else {
                        // if (json.error.length > 0) {
                        showAlert(jsonOrderConfirm.error);
                        setLoading(false);
                        // setConfirmLoading(false);
                        // }
                    }
                })
                .catch((error) => {
                    // setConfirmLoading(false);
                    setLoading(false);
                    CustomConsole("Order confirm Api Error=>" + error);
                });

        } else {
            showAlert("Something went wrong!");
        }
    }

    // pause api call
    const pauseApi = async (subscription_id, startDate, endDate) => {
        try {
            var raw = JSON.stringify({
                "subscription_id": subscription_id,
                "start_date": startDate,
                "end_date": endDate
            });

            var requestOptions = {
                method: 'POST',
                body: raw
            };

            CustomConsole(SUBSCRIPTIONPAUSE + "\n" + raw);

            setLoading(true);
            fetch(SUBSCRIPTIONPAUSE, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);
                    if (json.status == 1) {
                        // orderListApi(userId);
                        modifyLogListApi(userId);
                        showAlert(json.message);
                        hideSubscriptionPauseModal();
                        hideSubscriptionModifyModal();
                    } else {
                        showAlert(json.message);
                        hideSubscriptionPauseModal();
                        hideSubscriptionModifyModal();
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Pause Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Pause Exception=>" + e + "");
        }
    }

    // add days into date
    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    // minus days into date
    Date.prototype.minusDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() - days);
        return date;
    }

    // resume api call
    const resumeApi = async (subscription_id, continue_date) => {
        try {

            var requestOptionsGet = {
                method: 'GET'
            };
            fetch(SUBSCRIPTIONPAUSELIST + subscription_id, requestOptionsGet)
                .then(responseget => responseget.json())
                .then((jsonget) => {
                    CustomConsole(jsonget);

                    var raw = JSON.stringify({
                        "pause_id": jsonget.data[0].id,
                        "continue_date": continue_date
                    });

                    CustomConsole(raw);

                    var requestOptions = {
                        method: 'POST',
                        body: raw
                    };

                    CustomConsole(SUBSCRIPTIONCONTINUE + "\n" + raw);

                    setLoading(true);
                    fetch(SUBSCRIPTIONCONTINUE, requestOptions)
                        .then(response => response.json())
                        .then((json) => {
                            CustomConsole(json);
                            if (json.status == 1) {
                                // orderListApi(userId);
                                modifyLogListApi(userId);
                                hideSubscriptionModifyModal();
                                showAlert(json.message);
                            } else {
                                hideSubscriptionModifyModal();
                                if (json.error.length > 0) {
                                    showAlert(json.error[0]);
                                }
                            }
                            setLoading(false);
                        })
                        .catch((error) => {
                            setLoading(false);
                            CustomConsole("Resume Api Error=>" + error);
                        });

                })
                .catch((errorget) => {
                    setLoading(false);
                    CustomConsole("Get Pause Api Error=>" + errorget);
                });

        } catch (e) {
            setLoading(false);
            CustomConsole("PauseResume Exception=>" + e + "");
        }
    }

    // single day qty and option modify
    const modifySingleDayQty = async (subscription_id, order_id, product_id, product_option_id, product_option_value_id, quantity, date) => {
        try {

            var raw = JSON.stringify({
                "customer_id": userId,
                "subscription_id": subscription_id,
                "order_id": order_id,
                "product_id": product_id,
                "product_option_id": product_option_id,
                "product_option_value_id": product_option_value_id,
                "quantity": quantity,
                "date": date
            });

            CustomConsole(MODIFYSINGLEDAYQTY + "\n" + raw);

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(MODIFYSINGLEDAYQTY, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);
                    if (json.success == 1) {
                        setLoading(false);
                        hideSubscriptionModifyModal();
                        showAlert(json.message);
                    } else {
                        setLoading(false);
                        hideSubscriptionModifyModal();
                        showAlert(json.message);
                    }

                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Modify Single Day Qty and Option Error=>" + error);
                });

        } catch (error) {
            setLoading(false);
            CustomConsole("Modify Single Day Qty and Option Exception=>" + error + "");
        }
    }

    // order item view
    const orderItem = (item, index) => {
        return (
            <View style={styles.orderItemContainerView} dataSet={{ media: ids.orderItemContainerView }}>


                <View style={styles.orderItemMainView} dataSet={{ media: ids.orderItemMainView }}>

                    {/* date view */}
                    <View style={styles.orderItemDateView} dataSet={{ media: ids.orderItemDateView }}>
                        <Text style={styles.orderItemDateText} dataSet={{ media: ids.orderItemDateText }}>{item.date_added}</Text>
                    </View>
                    {/* end of date view */}

                    {/* order details view*/}
                    <View style={styles.orderItemDetails} dataSet={{ media: ids.orderItemDetails }}>
                        <Text style={styles.orderItemOrderNoText} dataSet={{ media: ids.orderItemOrderNoText }}>Order No. : <Text style={styles.orderItemOrderNoBoldText} dataSet={{ media: ids.orderItemOrderNoBoldText }}>{item.order_id}</Text></Text>
                        <Text style={styles.orderItemOrderDetailsText} dataSet={{ media: ids.orderItemOrderDetailsText }}>Total: <Text style={styles.orderItemOrderDetailsBoldText} dataSet={{ media: ids.orderItemOrderDetailsBoldText }}>{item.total}</Text></Text>
                        <Text style={styles.orderItemOrderDetailsText} dataSet={{ media: ids.orderItemOrderDetailsText }}>Status: <Text style={(item.order_status == "Cancelled") ? styles.orderItemOrderDetailsCancelledStatusText : item.order_status == "Pause" ? styles.orderItemOrderDetailsPauseStatusText : item.order_status == "Pending" ? styles.orderItemOrderDetailsPendingStatusText : styles.orderItemOrderDetailsOtherStatusText} dataSet={{ media: (item.order_status == "Cancelled") ? ids.orderItemOrderDetailsCancelledStatusText : item.order_status == "Pause" ? ids.orderItemOrderDetailsPauseStatusText : item.order_status == "Pending" ? ids.orderItemOrderDetailsPendingStatusText : ids.orderItemOrderDetailsOtherStatusText }}>{item.order_status}</Text></Text>
                        {/* <Text style={styles.orderItemOrderDetailsText}>Products:</Text> */}
                    </View>
                    {/* end of order details view */}

                    {/* order product details view */}
                    <View style={styles.orderItemProductDetailsView} dataSet={{ media: ids.orderItemProductDetailsView }}>
                        {item.line_items.map((item1, index1) => {

                            var diff = "";
                            if (item1.product_data.subscription.subscription_type != undefined) {
                                var start = moment(formatDateYMD(new Date(Date.now())));
                                var end = moment(item1.product_data.subscription.end_date);
                                diff = end.diff(start, "days");
                            }

                            return (
                                <View style={(item.line_items.length - 1) == index1 ? styles.orderItemProductDetailsViewWithoutBorder : styles.orderItemProductDetailsViewWithBorder}>
                                    <View style={styles.orderItemProductImageView}>
                                        <Image source={item1.product_data.image != "" ? { uri: item1.product_data.image } : require("../assets/header/footer-logo.png")} style={styles.orderItemProductImage} />
                                    </View>
                                    <View style={styles.orderItemProductDetailsSubView}>
                                        <Text style={styles.orderItemProductName} numberOfLines={2}>{item1.name}</Text>
                                        {/* <Text style={styles.orderItemProductQty}>{CustomDigits(item1.price)} x {item1.quantity}</Text> */}

                                        {item1.product_data.subscription.subscription_type == undefined ?
                                            <Text style={styles.cartItemOption}> {item1.quantity}</Text>
                                            : item1.product_data.subscription.subscription_type == "daily" || item1.product_data.subscription.subscription_type == "trial"
                                                ?
                                                // daily subscription
                                                <>
                                                    <Text style={styles.orderItemProductQty}>{CustomDigits(item1.price)} x {item1.product_data.subscription.subscription_value} | <Text style={styles.cartItemSubscriptionName}>{item1.product_data.subscription.subscription_type}</Text></Text>
                                                    <Text style={styles.cartItemOption}>Start Date: {formatDate(new Date(item1.product_data.subscription.start_date))} | End Date: {formatDate(new Date(item1.product_data.subscription.end_date))}</Text>
                                                    {diff > 0 ? <Text style={styles.cartItemOption}>Remaining Days: {diff}</Text> : null}
                                                </>
                                                :
                                                item1.product_data.subscription.subscription_type == "alternate"
                                                    ?
                                                    // alternate subscription
                                                    <>
                                                        <Text style={styles.cartItemOption}><Text style={styles.cartItemSubscriptionDay}>Day 1</Text>  - {CustomDigits(item1.price)} x {item1.product_data.subscription.subscription_value}  |  <Text style={styles.cartItemOption}><Text style={styles.cartItemSubscriptionDay}>Day 2</Text>  - {CustomDigits(item1.price)} x 0</Text></Text>
                                                        <Text style={styles.cartItemOption}>Start Date: {formatDate(new Date(item1.product_data.subscription.start_date))} | End Date: {formatDate(new Date(item1.product_data.subscription.end_date))}</Text>
                                                        {diff > 0 ? <Text style={styles.cartItemOption}>Remaining Days: {diff}</Text> : null}
                                                    </>
                                                    :// varing subscription
                                                    <>
                                                        <Text style={styles.cartItemOption}><Text style={styles.cartItemSubscriptionDay}>Day 1</Text>  - {CustomDigits(item1.price)} x {item1.product_data.subscription.subscription_value.split(",")[0]}  |  <Text style={styles.cartItemOption}><Text style={styles.cartItemSubscriptionDay}>Day 2</Text>  - {CustomDigits(item1.price)} x {item1.product_data.subscription.subscription_value.split(",")[1]}</Text></Text>
                                                        <Text style={styles.cartItemOption}>Start Date: {formatDate(new Date(item1.product_data.subscription.start_date))} | End Date: {formatDate(new Date(item1.product_data.subscription.end_date))}</Text>
                                                        {diff > 0 ? <Text style={styles.cartItemOption}>Remaining Days: {diff}</Text> : null}
                                                    </>
                                        }

                                        <Text style={styles.orderItemProductTotalQty}>Total Qty: <Text style={styles.orderItemProductTotalQtyBold}>{item1.quantity}</Text></Text>
                                        <Text style={styles.orderItemProductTotal}>Total: <Text style={styles.orderItemProductTotalBold}>{CustomDigits(item1.total)}</Text></Text>

                                    </View>
                                </View>
                            );
                        })}
                    </View>
                    {/* end of order product details view */}

                    {/* buttons view */}
                    <View style={styles.orderItemView} dataSet={{ media: ids.orderItemView }}>
                        {(item.order_status != "Cancelled") ?
                            <View style={styles.orderItemButtonsView} dataSet={{ media: ids.orderItemButtonsView }}>

                                {/* {item.order_status != "Pause" ?
                                    <Pressable onPress={() => { cancelApi(item.subscription_id) }}
                                        style={styles.orderItemCancelButton}>
                                        <Text style={styles.orderItemCancelButtonText}>Cancel</Text>
                                    </Pressable> : null
                                } */}
                                {item.order_status != "Pause" ?
                                    <Pressable onPress={() => {
                                        // setProductList(item.line_items);

                                        // modifyProductQty.length = 0;
                                        // productList.length = 0;

                                        // for (let i = 0; i < item.line_items.length; i++) {

                                        //     if (item.line_items[i].product_data.subscription.subscription_type != undefined) {

                                        //         productList.push(item.line_items[i]);

                                        //         var start = moment(item.line_items[i].product_data.subscription.start_date);
                                        //         var end = moment(item.line_items[i].product_data.subscription.end_date);
                                        //         const diff = end.diff(start, "days")

                                        //         // CustomConsole("difference=> " + diff);
                                        //         var qty = 0;
                                        //         var qty2 = 0;

                                        //         if (item.line_items[i].product_data.subscription.subscription_type == "daily") {
                                        //             // qty = (parseFloat(item.line_items[i].quantity) / diff);
                                        //             qty = parseFloat((item.line_items[i].product_data.subscription.subscription_value));
                                        //         }
                                        //         else if (item.line_items[i].product_data.subscription.subscription_type == "alternate") {
                                        //             CustomConsole("DB qty=> " + item.line_items[i].quantity);
                                        //             CustomConsole("Diff qty=> " + diff);
                                        //             // qty = parseFloat(item.line_items[i].quantity) / (diff / 2);
                                        //             qty = parseFloat((item.line_items[i].product_data.subscription.subscription_value));
                                        //         }
                                        //         else if (item.line_items[i].product_data.subscription.subscription_type == "trial") {
                                        //             // qty = (parseFloat(item.line_items[i].quantity) / diff); 
                                        //             qty = parseFloat((item.line_items[i].product_data.subscription.subscription_value));
                                        //         }
                                        //         else {
                                        //             // CustomConsole(item.line_items[i].product_data.subscription.subscription_value);
                                        //             qty = parseFloat((item.line_items[i].product_data.subscription.subscription_value).split(",")[0]);
                                        //             qty2 = parseFloat((item.line_items[i].product_data.subscription.subscription_value).split(",")[1]);
                                        //         }

                                        //         // CustomConsole("Diff=> " + diff);
                                        //         // CustomConsole("Qty=> " + qty);
                                        //         // CustomConsole("Qty1=> " + qty2);
                                        //         // CustomConsole(item.line_items[i].product_data);
                                        //         // CustomConsole(item.line_items[i].product_data.options[0]);
                                        //         // CustomConsole(item.line_items[i].product_data.subscription.start_date);
                                        //         // CustomConsole(item.line_items[i].product_data.subscription.end_date);
                                        //         modifyProductQty.push({
                                        //             product_id: item.line_items[i].product_id,
                                        //             product_qty: qty,
                                        //             product_qty2: qty2,
                                        //         });
                                        //     }
                                        // }
                                        // setProductList(productList);
                                        // setModifyProductQty([...modifyProductQty]);
                                        // showModal(index, item.subscription_id, item.order_status);

                                        // if (openQtyShowView == index) {
                                        //     setOpenQtyShowView(null);
                                        // } else {
                                        setOpenQtyShowView(index);
                                        // productList.length = 0;
                                        // for (let i = 0; i < item.line_items.length; i++) {
                                        //     productList.push(item.line_items[i]);
                                        // }
                                        // setProductList(productList);

                                        showSubscriptionModifyModal(item.list_items_arr, item.subscription_id);
                                        // }
                                    }}
                                        style={styles.orderItemModifyButton}>
                                        <Text style={styles.orderItemModifyButtonText}>Modify</Text>
                                    </Pressable>
                                    : null}

                                {item.order_status != "Pause" ?
                                    <Pressable onPress={() => { showSubscriptionPauseModal(item.subscription_id) }}
                                        style={styles.orderItemPauseButton}>
                                        <Text style={styles.orderItemPauseButtonText}>Vacation</Text>
                                    </Pressable> : null}

                                {item.order_status == "Pause" ? <Pressable onPress={() => { resumeApi(item.subscription_id, formatDateYMD(new Date(Date.now()).addDays(1))) }}
                                    style={styles.orderItemPauseButton}>
                                    <Text style={styles.orderItemPauseButtonText}>Resume</Text>
                                </Pressable> : null}
                            </View>
                            : null}
                    </View>
                    {/* end of buttons view */}

                </View>

                {/* {item.order_status == "Pending" || item.order_status == "Processing" ? <Pressable onPress={() => {
                    if (openQtyShowView == index) {
                        setOpenQtyShowView(null);
                    } else {
                        setOpenQtyShowView(index);
                    }
                }}
                    style={styles.orderModifyTextButton} dataSet={{ media: ids.orderModifyTextButton }}>
                    <Text style={styles.orderModifyBlueText} dataSet={{ media: ids.orderModifyBlueText }} >Modify</Text>
                </Pressable> : null} */}

                <View style={styles.orderVacationHistoryView} dataSet={{media: ids.orderVacationHistoryView}}>

                    {/* MODIFY SUBSCRIPTION ORDER */}
                    {item.order_status == "Pending" || item.order_status == "Processing" ? <Pressable onPress={() => {
                        // setProductList(item.line_items);

                        modifyProductQty.length = 0;
                        productList.length = 0;

                        for (let i = 0; i < item.line_items.length; i++) {

                            if (item.line_items[i].product_data.subscription.subscription_type != undefined) {

                                productList.push(item.line_items[i]);

                                var start = moment(item.line_items[i].product_data.subscription.start_date);
                                var end = moment(item.line_items[i].product_data.subscription.end_date);
                                const diff = end.diff(start, "days")

                                // CustomConsole("difference=> " + diff);
                                var qty = 0;
                                var qty2 = 0;

                                if (item.line_items[i].product_data.subscription.subscription_type == "daily") {
                                    // qty = (parseFloat(item.line_items[i].quantity) / diff);
                                    qty = parseFloat((item.line_items[i].product_data.subscription.subscription_value));
                                }
                                else if (item.line_items[i].product_data.subscription.subscription_type == "alternate") {
                                    CustomConsole("DB qty=> " + item.line_items[i].quantity);
                                    CustomConsole("Diff qty=> " + diff);
                                    // qty = parseFloat(item.line_items[i].quantity) / (diff / 2);
                                    qty = parseFloat((item.line_items[i].product_data.subscription.subscription_value));
                                }
                                else if (item.line_items[i].product_data.subscription.subscription_type == "trial") {
                                    // qty = (parseFloat(item.line_items[i].quantity) / diff); 
                                    qty = parseFloat((item.line_items[i].product_data.subscription.subscription_value));
                                }
                                else {
                                    // CustomConsole(item.line_items[i].product_data.subscription.subscription_value);
                                    qty = parseFloat((item.line_items[i].product_data.subscription.subscription_value).split(",")[0]);
                                    qty2 = parseFloat((item.line_items[i].product_data.subscription.subscription_value).split(",")[1]);
                                }

                                // CustomConsole("Diff=> " + diff);
                                // CustomConsole("Qty=> " + qty);
                                // CustomConsole("Qty1=> " + qty2);
                                // CustomConsole(item.line_items[i].product_data);
                                // CustomConsole(item.line_items[i].product_data.options[0]);
                                // CustomConsole(item.line_items[i].product_data.subscription.start_date);
                                // CustomConsole(item.line_items[i].product_data.subscription.end_date);
                                modifyProductQty.push({
                                    product_id: item.line_items[i].product_id,
                                    product_qty: qty,
                                    product_qty2: qty2,
                                });
                            }
                        }
                        setProductList(productList);
                        setModifyProductQty([...modifyProductQty]);
                        showModal(index, item.subscription_id, item.order_status);
                    }}
                        style={styles.orderModifyTextButton} dataSet={{ media: ids.orderModifyTextButton }}>
                        <Text style={styles.orderModifyBlueText} dataSet={{ media: ids.orderModifyBlueText }} >Modify Subscription Order</Text>
                    </Pressable> : <View></View>}
                    {/* end of MODIFY SUBSCRIPTION ORDER */}

                    {/* vacation history */}
                    {item.pause_dates.length != 0 ? <Pressable onPress={() => {
                        // setProductList(item.line_items);
                        CustomConsole(item);
                        showVacationHistoryModal(item.pause_dates);
                    }}
                        style={styles.orderModifyTextButton} dataSet={{ media: ids.orderModifyTextButton }}>
                        <Text style={styles.orderModifyBlueText} dataSet={{ media: ids.orderModifyBlueText }} >Vacation history</Text>
                    </Pressable> : null}
                    {/* end of vacation history */}

                </View>



                {/* {openQtyShowView == index ? <View style={styles.orderOneTimeQtyUpdateView} dataSet={{ media: ids.orderOneTimeQtyUpdateView }}>
                    {item.list_items_arr.map((item1, index1) => {

                        return (
                            <>
                                <View style={(item.list_items_arr.length - 1) == index1 ? styles.orderOneTimeQtyUpdateSubViewWithoutBorder : styles.orderOneTimeQtyUpdateSubViewWithBorder}
                                    dataSet={{ media: (item.list_items_arr.length - 1) == index1 ? ids.orderOneTimeQtyUpdateSubViewWithoutBorder : ids.orderOneTimeQtyUpdateSubViewWithBorder }}>
                                    <Text style={styles.orderItemProductName} dataSet={{ media: ids.orderItemProductName }} numberOfLines={2}>{item1.productName}</Text>

                                    {item1.dateArr.map((item2, index2) => {
                                        return (
                                            <View style={styles.orderOneTimeQtyView} dataSet={{ media: ids.orderOneTimeQtyView }}>
                                                <Text style={styles.orderOneTimeQtyText} dataSet={{ media: ids.orderOneTimeQtyText }}>{item2.startDate}</Text>
                                                <Text style={styles.orderOneTimeQtyText} dataSet={{ media: ids.orderOneTimeQtyText }}>Pause: {item2.isPause}</Text>
                                                <Text style={styles.orderOneTimeQtyText} dataSet={{ media: ids.orderOneTimeQtyText }}>Quantity : {item2.quantity}</Text>

                                                <View style={styles.orderOneTimeQtyChangeView} dataSet={{ media: ids.orderOneTimeQtyChangeView }}>
                                                    <View style={styles.modifyQtySubView} dataSet={{ media: ids.modifyQtySubView }}>

                                                        <Pressable onPress={() => {
                                                            let dummyOrderlist = [...orderList];

                                                            if ((dummyOrderlist[index].list_items_arr[index1].dateArr[index2].addToCartQty - 1) <= 0) {
                                                                dummyOrderlist[index].list_items_arr[index1].dateArr[index2].addToCartQty = 1;
                                                            } else {
                                                                dummyOrderlist[index].list_items_arr[index1].dateArr[index2].addToCartQty = dummyOrderlist[index].list_items_arr[index1].dateArr[index2].addToCartQty - 1;
                                                            }

                                                            setOrderList(dummyOrderlist);
                                                        }}
                                                            style={styles.modifyQtyPresseble}>
                                                            <Text style={styles.modifyQtyPressebleText}>-</Text>
                                                        </Pressable>
                                                        <Text style={styles.modifyQtyText}>{item2.addToCartQty}</Text>
                                                        <Pressable onPress={() => {
                                                            let dummyOrderlist = [...orderList];

                                                            dummyOrderlist[index].list_items_arr[index1].dateArr[index2].addToCartQty = dummyOrderlist[index].list_items_arr[index1].dateArr[index2].addToCartQty + 1;

                                                            setOrderList(dummyOrderlist);
                                                        }}
                                                            style={styles.modifyQtyPresseble}>
                                                            <Text style={styles.modifyQtyPressebleText}>+</Text>
                                                        </Pressable>
                                                    </View>

                                                </View>

                                                <View style={styles.orderOneTimeQtyChangeButtonView} dataSet={{ media: ids.orderOneTimeQtyChangeButtonView }}>
                                                    <Pressable onPress={() => {
                                                        var raw = JSON.stringify({
                                                            "cart": [
                                                                {
                                                                    "product_id": item1.productId,
                                                                    "quantity": item2.addToCartQty,
                                                                    "delivery_date": formatDate(new Date(item2.startDate)),
                                                                    "option": {
                                                                        [item1.productOptionId]: item1.productOptionValueId
                                                                    },
                                                                }
                                                            ],
                                                            "customer_id": userId
                                                        });

                                                        CustomConsole(ADDTOCART + "\n" + raw);

                                                        var requestOptions = {
                                                            method: 'POST',
                                                            body: raw
                                                        };

                                                        setLoading(true);
                                                        fetch(ADDTOCART, requestOptions)
                                                            .then(response => response.json())
                                                            .then((json) => {
                                                                CustomConsole(json);
                                                                if (json.success == 1) {
                                                                    setLoading(false);
                                                                    navigation.navigate("CartScreen");
                                                                } else {
                                                                    setLoading(false);
                                                                    if (json.error.length > 0) {
                                                                        showAlert(json.error[0]);
                                                                    }
                                                                }

                                                            })
                                                            .catch((error) => {
                                                                setLoading(false);
                                                                CustomConsole("Error=>" + error);
                                                            });


                                                    }} style={styles.orderCloseButton} dataSet={{ media: ids.orderCloseButton }}>
                                                        <Text style={styles.orderModifyButtonText} dataSet={{ media: ids.orderModifyButtonText }}>Add to cart</Text>
                                                    </Pressable>
                                                    <Pressable onPress={() => {

                                                    }} style={styles.orderCloseButton} dataSet={{ media: ids.orderCloseButton }}>
                                                        <Text style={styles.orderModifyButtonText} dataSet={{ media: ids.orderModifyButtonText }}>Pause</Text>
                                                    </Pressable>
                                                </View>

                                            </View>
                                        )
                                    })}

                                </View>

                            </>

                        );
                    })}
                </View> : null}  */}


            </View>
        );
    }

    return (
        <View>
            <StatusBar barStyle="dark-content" />
            <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                <View>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent(cartLength, navigation, userId)}
                    {/* end of Header view */}

                    {/* <Pressable onPress={() => navigation.goBack()} style={{ alignSelf: "flex-start", marginLeft: 20, marginTop: 20 }}>
                        <Image source={require("../assets/back_arrow.png")} style={{ width: 30, height: 30, flexDirection: 'row-reverse', }} />
                    </Pressable> */}


                    {/* main view */}
                    <View style={styles.orderListMainView} dataSet={{ media: ids.orderListMainView }}>

                        <View style={styles.orderHeadingView} dataSet={{ media: ids.orderHeadingView }}>
                            <Text style={styles.orderHeadingText}>{"Subscriptions"}</Text>
                            <View style={styles.orderHeadingSubContainer}>
                                {orderList.length != 0 ? <Pressable onPress={() => navigation.navigate("CancelSubscriptionListScreen")}
                                    style={styles.cancelButtonView} dataSet={{ media: ids.cancelButtonView }}>
                                    <Text style={styles.cancelButtonText}>{"Inactive Subscriptions List"}</Text>
                                </Pressable> : null}
                                {orderList.length != 0 ? <Pressable onPress={() => navigation.navigate("LogSubscriptionListScreen")}
                                    style={styles.logButtonView} dataSet={{ media: ids.logButtonView }}>
                                    <Text style={styles.cancelButtonText}>{"Modify Log"}</Text>
                                </Pressable> : null}
                            </View>
                        </View>

                        {/* order list view */}
                        {orderList.length != 0 ?
                            <FlatList
                                style={styles.orderFlatListView}
                                data={orderList}
                                extraData={orderList}
                                keyExtractor={(item, id) => String(id)}
                                ItemSeparatorComponent={<View style={styles.orderItemSeperator} />}
                                renderItem={({ item, index }) => orderItem(item, index)} />
                            :
                            !loading ?
                                <View style={styles.orderEmptyView}>
                                    <Text style={styles.orderEmptyText}>Subscriptions is not available</Text>
                                </View> : null
                        }

                    </View>
                    {/* end of main view */}

                    {/* Modify all modal view*/}
                    <Modal
                        onRequestClose={hideModal}
                        transparent
                        visible={visible}
                        animationType={'slide'}
                    >
                        <View style={styles.orderModifyModalMainView} dataSet={{ media: ids.orderModifyModalMainView }}>
                            <View style={[styles.orderModifyModalSubView, offset]} dataSet={{ media: ids.orderModifyModalSubView }}>
                                <ScrollView>
                                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", }}>
                                        <Text style={styles.orderModifyText}>{"Modify Subscription Order"}</Text>
                                        <Pressable onPress={hideModal} style={{
                                            justifyContent: "center",
                                            paddingVertical: 10,
                                            paddingHorizontal: 15,
                                            borderRadius: 5,
                                        }}>
                                            <Image source={require("../assets/header/close.png")} style={styles.orderModifyCloseIcon} />
                                        </Pressable>
                                    </View>

                                    <FlatList style={{}}
                                        showsVerticalScrollIndicator={false}
                                        data={productList}
                                        keyExtractor={(item, id) => String(id)}
                                        renderItem={({ item, index }) => {
                                            CustomConsole(item.product_data)
                                            return (
                                                <View style={(productList.length - 1) == index ? styles.modifyProductDetailsViewWithBorder : styles.modifyProductItemMainView} dataSet={{ media: ids.modifyProductItemMainView }}>

                                                    {/* product details */}
                                                    <View style={styles.modifyProductDetailsViewWithoutBorder}>
                                                        <Image source={{ uri: item.product_data.image }} style={styles.modifyProductItemImage} />
                                                        <View style={styles.modifyProductItemDetailsView}>
                                                            <Text style={styles.modifyProductName} numberOfLines={2}>{item.name}</Text>
                                                            <Text style={styles.modifyProductQty}>{CustomDigits(item.price)} x {item.quantity}</Text>
                                                            <Text style={styles.modifyProductTotal}>Total: {CustomDigits(item.total)}</Text>
                                                        </View>
                                                    </View>
                                                    {/* end of product details */}

                                                    <View style={styles.modifyQtyMainView}>
                                                        {/* qty view */}
                                                        <View style={styles.modifyQtySubView}>

                                                            <Pressable onPress={() => {
                                                                let dummyArr = [...modifyProductQty];
                                                                if ((dummyArr[index].product_qty - 1) <= 0) {
                                                                    dummyArr[index].product_qty = 1;
                                                                } else {
                                                                    dummyArr[index].product_qty = dummyArr[index].product_qty - 1;
                                                                }
                                                                setModifyProductQty(dummyArr);
                                                            }}
                                                                style={styles.modifyQtyPresseble}>
                                                                <Text style={styles.modifyQtyPressebleText}>-</Text>
                                                            </Pressable>
                                                            <Text style={styles.modifyQtyText}>{modifyProductQty[index].product_qty}</Text>
                                                            <Pressable onPress={() => {
                                                                let dummyArr = [...modifyProductQty];
                                                                dummyArr[index].product_qty = dummyArr[index].product_qty + 1;
                                                                setModifyProductQty(dummyArr);
                                                            }}
                                                                style={styles.modifyQtyPresseble}>
                                                                <Text style={styles.modifyQtyPressebleText}>+</Text>
                                                            </Pressable>
                                                        </View>
                                                        {/* end of qty view */}

                                                        {/* qty2 view */}
                                                        {item.product_data.subscription.subscription_type == "varying" ? <View style={styles.modifyQtySubView}>

                                                            <Pressable onPress={() => {
                                                                let dummyArr = [...modifyProductQty];
                                                                if ((dummyArr[index].product_qty2 - 1) <= 0) {
                                                                    dummyArr[index].product_qty2 = 1;
                                                                } else {
                                                                    dummyArr[index].product_qty2 = dummyArr[index].product_qty2 - 1;
                                                                }
                                                                setModifyProductQty(dummyArr);
                                                            }}
                                                                style={styles.modifyQtyPresseble}>
                                                                <Text style={styles.modifyQtyPressebleText}>-</Text>
                                                            </Pressable>
                                                            <Text style={styles.modifyQtyText}>{modifyProductQty[index].product_qty2}</Text>
                                                            <Pressable onPress={() => {
                                                                let dummyArr = [...modifyProductQty];
                                                                dummyArr[index].product_qty2 = dummyArr[index].product_qty2 + 1;
                                                                setModifyProductQty(dummyArr);
                                                            }}
                                                                style={styles.modifyQtyPresseble}>
                                                                <Text style={styles.modifyQtyPressebleText}>+</Text>
                                                            </Pressable>
                                                        </View> : null}
                                                        {/* end of qty2 view */}
                                                    </View>

                                                </View>
                                            );
                                        }} />
                                </ScrollView>

                                <View style={styles.orderModifyModalButtonView} dataSet={{ media: ids.orderModifyModalButtonView }}>
                                    {subscriptionStatus != "Pause" ? <Pressable
                                        onPress={() => { showConfirmCancelAlert() }}
                                        style={styles.orderItemCancelButton}>
                                        <Text style={styles.orderItemCancelButtonText}>Cancel Subscription</Text>
                                    </Pressable> : null}
                                    <Pressable
                                        onPress={() => {

                                            var requestOptionsTimezone = {
                                                method: 'GET'
                                            };
                                            CustomConsole(TIMEZONE);
                                            fetch(TIMEZONE, requestOptionsTimezone)
                                                .then(responseTimezone => responseTimezone.json())
                                                .then((jsonTimezone) => {
                                                    CustomConsole(jsonTimezone);
                                                    if (jsonTimezone.success == 1) {
                                                        CustomConsole(jsonTimezone.data.current_time);

                                                        if (jsonTimezone.data.current_time >= "22:00:00") {
                                                            showAlert("You can't modify subscription after 10:00 PM")
                                                        } else {
                                                            var itemModify = 0;
                                                            for (let i = 0; i < productList.length; i++) {
                                                                CustomConsole("qty====>" + productList[i].product_data.subscription.subscription_type)

                                                                var start = moment(productList[i].product_data.subscription.start_date);
                                                                var end = moment(productList[i].product_data.subscription.end_date);
                                                                const diff = end.diff(start, "days")

                                                                var qty = 0;
                                                                var qty2 = 0;

                                                                if (productList[i].product_data.subscription.subscription_type == "daily") {
                                                                    // qty = (parseFloat(productList[i].quantity) / diff);
                                                                    qty = parseFloat((productList[i].product_data.subscription.subscription_value));
                                                                    if (qty == modifyProductQty[i].product_qty) {
                                                                        itemModify = 0;
                                                                    } else {
                                                                        itemModify = 1;
                                                                    }

                                                                } else if (productList[i].product_data.subscription.subscription_type == "alternate") {
                                                                    // qty = (diff / 2) / parseFloat(productList[i].quantity);
                                                                    qty = parseFloat((productList[i].product_data.subscription.subscription_value));
                                                                    if (qty == modifyProductQty[i].product_qty) {
                                                                        itemModify = 0;
                                                                    } else {
                                                                        itemModify = 1;
                                                                    }
                                                                } else if (productList[i].product_data.subscription.subscription_type == "trial") {
                                                                    // qty = (diff / 2) / parseFloat(productList[i].quantity);
                                                                    qty = parseFloat((productList[i].product_data.subscription.subscription_value));
                                                                    if (qty == modifyProductQty[i].product_qty) {
                                                                        itemModify = 0;
                                                                    } else {
                                                                        itemModify = 1;
                                                                    }
                                                                }
                                                                else {
                                                                    qty = parseFloat((productList[i].product_data.subscription.subscription_value).split(",")[0]);
                                                                    qty2 = parseFloat((productList[i].product_data.subscription.subscription_value).split(",")[1]);
                                                                    if (qty == modifyProductQty[i].product_qty || qty2 == modifyProductQty[i].product_qty2) {
                                                                        itemModify = 0;
                                                                    } else {
                                                                        itemModify = 1;
                                                                    }
                                                                }

                                                                // CustomConsole("Qty1 =>" + qty);
                                                                // CustomConsole("Qty2 =>" + qty2);
                                                            }

                                                            if (itemModify == 1) {

                                                                var raw = JSON.stringify({
                                                                    "customer_id": userId
                                                                });

                                                                var requestOptions = {
                                                                    method: 'POST',
                                                                    body: raw,
                                                                };

                                                                CustomConsole(EMPTYCART + "\n" + raw);

                                                                setLoading(true);
                                                                fetch(EMPTYCART, requestOptions)
                                                                    .then(response => response.json())
                                                                    .then((json) => {
                                                                        CustomConsole(json);
                                                                        if (json.success == "1") {
                                                                            modifyApi(0);
                                                                        } else {
                                                                            if (json.error.length > 0) {
                                                                                showAlert(json.error[0]);
                                                                            }
                                                                        }
                                                                        setLoading(false);
                                                                    })
                                                                    .catch((error) => {
                                                                        setLoading(false);
                                                                        CustomConsole("Empty Api Error=>" + error);
                                                                    });
                                                            } else {
                                                                showAlert("Please modify quantity.");
                                                            }
                                                        }
                                                    }
                                                })
                                                .catch((errorTimezone) => {
                                                    CustomConsole("Timezone Api Error=>" + errorTimezone);
                                                });
                                        }}
                                        style={styles.orderModifyButton}>
                                        <Text style={styles.orderModifyButtonText}>Save</Text>
                                    </Pressable>

                                </View>

                            </View>
                        </View>
                    </Modal>
                    {/* end of Modify all modal view */}

                    {/* Modify modal view */}
                    <Modal
                        onRequestClose={hideSubscriptionModifyModal}
                        transparent
                        visible={subscriptionModifyModal}
                        animationType={'slide'}
                    >
                        <View style={styles.orderModifyModalMainView} dataSet={{ media: ids.orderModifyModalMainView }}>
                            <View style={[styles.orderSingleModifyModalSubView, offset]} dataSet={{ media: ids.orderSingleModifyModalSubView }}>
                                <ScrollView>
                                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", }}>
                                        <Text style={styles.orderModifyText}>{"Modify"}</Text>
                                        <Pressable onPress={hideSubscriptionModifyModal} style={{
                                            justifyContent: "center",
                                            paddingVertical: 10,
                                            paddingHorizontal: 15,
                                            borderRadius: 5,
                                        }}>
                                            <Image source={require("../assets/header/close.png")} style={styles.orderModifyCloseIcon} />
                                        </Pressable>
                                    </View>

                                    <FlatList style={{}}
                                        showsVerticalScrollIndicator={false}
                                        data={subscribe_list_items_arr}
                                        keyExtractor={(item, id) => String(id)}
                                        renderItem={({ item, index }) => {
                                            CustomConsole(item)
                                            return (
                                                <>
                                                    <View style={(subscribe_list_items_arr.length - 1) == index ? styles.orderOneTimeQtyUpdateSubViewWithoutBorder : styles.orderOneTimeQtyUpdateSubViewWithBorder}
                                                        dataSet={{ media: (subscribe_list_items_arr.length - 1) == index ? ids.orderOneTimeQtyUpdateSubViewWithoutBorder : ids.orderOneTimeQtyUpdateSubViewWithBorder }}>
                                                        <Text style={styles.orderItemProductName} dataSet={{ media: ids.orderItemProductName }} numberOfLines={2}>{item.productName}</Text>

                                                        {item.dateArr.map((item2, index2) => {
                                                            return (
                                                                <View style={styles.orderOneTimeQtyView} dataSet={{ media: ids.orderOneTimeQtyView }}>
                                                                    <Text style={styles.orderOneTimeQtyText} dataSet={{ media: ids.orderOneTimeQtyText }}>{item2.startDate}</Text>
                                                                    <Text style={styles.orderOneTimeQtyText} dataSet={{ media: ids.orderOneTimeQtyText }}>Quantity : {item2.isPause == 1 ? "0" : item2.quantity}</Text>
                                                                    {item2.isPause != 1 ? <View style={styles.orderOneTimePickerConatiner} dataSet={{ media: ids.orderOneTimePickerConatiner }}>
                                                                        <View style={styles.orderOneTimePickerCardView} dataSet={{ media: ids.orderOneTimePickerCardView }}>
                                                                            <Picker
                                                                                style={styles.orderOneTimeCardPicker} dataSet={{ media: ids.orderOneTimeCardPicker }}
                                                                                selectedValue={item2.selected_option_value_id}
                                                                                onValueChange={(itemValue, itemIndex) => {
                                                                                    let tempSelectedSize = [...subscribe_list_items_arr];
                                                                                    tempSelectedSize[index].dateArr[index2].selected_option_value_id = itemValue;
                                                                                    setList_items_arr(tempSelectedSize);
                                                                                }}>
                                                                                {item2.all_options[0].product_option_value.map((item1, index1) => {
                                                                                    return (<Picker.Item label={item1.name} value={item1.product_option_value_id} />);
                                                                                })}
                                                                            </Picker>
                                                                        </View>
                                                                    </View> :
                                                                        <View style={styles.orderOneTimePickerConatiner} dataSet={{ media: ids.orderOneTimePickerConatiner }}>
                                                                        </View>}
                                                                    {item2.isPause != 1 ? <View style={styles.orderOneTimeQtyChangeView} dataSet={{ media: ids.orderOneTimeQtyChangeView }}>
                                                                        <View style={styles.modifyQtySubView} dataSet={{ media: ids.modifyQtySubView }}>

                                                                            <Pressable onPress={() => {
                                                                                let dummyOrderlist = [...subscribe_list_items_arr];

                                                                                if ((dummyOrderlist[index].dateArr[index2].addToCartQty - 1) <= 0) {
                                                                                    dummyOrderlist[index].dateArr[index2].addToCartQty = 1;
                                                                                } else {
                                                                                    dummyOrderlist[index].dateArr[index2].addToCartQty = dummyOrderlist[index].dateArr[index2].addToCartQty - 1;
                                                                                }

                                                                                setList_items_arr(dummyOrderlist);
                                                                            }}
                                                                                style={styles.modifyQtyPresseble}>
                                                                                <Text style={styles.modifyQtyPressebleText}>-</Text>
                                                                            </Pressable>
                                                                            <Text style={styles.modifyQtyText}>{item2.addToCartQty}</Text>
                                                                            <Pressable onPress={() => {
                                                                                let dummyOrderlist = [...subscribe_list_items_arr];

                                                                                dummyOrderlist[index].dateArr[index2].addToCartQty = dummyOrderlist[index].dateArr[index2].addToCartQty + 1;

                                                                                setList_items_arr(dummyOrderlist);
                                                                            }}
                                                                                style={styles.modifyQtyPresseble}>
                                                                                <Text style={styles.modifyQtyPressebleText}>+</Text>
                                                                            </Pressable>
                                                                        </View>

                                                                    </View> :
                                                                        <View style={styles.orderOneTimeQtyChangeView} dataSet={{ media: ids.orderOneTimeQtyChangeView }}>
                                                                        </View>}
                                                                    <View style={styles.orderOneTimeQtyChangeButtonView} dataSet={{ media: ids.orderOneTimeQtyChangeButtonView }}>

                                                                        {/* single qty and option modify button */}
                                                                        {item2.isPause != 1 ? <Pressable onPress={() => {
                                                                            // CustomConsole({
                                                                            //     "customer_id": userId,
                                                                            //     "subscription_id": item2.subscription_id,
                                                                            //     "order_id": item.orderId,
                                                                            //     "product_id": item.productId,
                                                                            //     "product_option_id": item2.selected_option_id,
                                                                            //     "product_option_value_id": item2.selected_option_value_id,
                                                                            //     "quantity": item2.addToCartQty,
                                                                            //     "date": item2.startDate
                                                                            // })
                                                                            modifySingleDayQty(item2.subscription_id, item.orderId, item.productId, item2.selected_option_id, item2.selected_option_value_id, item2.addToCartQty, item2.startDate)

                                                                        }} style={styles.orderModifyPauseButtonView} dataSet={{ media: ids.orderModifyPauseButtonView }}>
                                                                            <Text style={styles.orderModifyButtonText} dataSet={{ media: ids.orderModifyButtonText }}>Update</Text>
                                                                        </Pressable> : null}
                                                                        {/* end of single qty and option modify button */}



                                                                        {/* add to cart button */}
                                                                        {/* {item2.isPause != 1 ? <Pressable onPress={() => {
                                                                            var raw = JSON.stringify({
                                                                                "cart": [
                                                                                    {
                                                                                        "product_id": item.productId,
                                                                                        "quantity": item2.addToCartQty,
                                                                                        "delivery_date": formatDate(new Date(item2.startDate)),
                                                                                        "option": {
                                                                                            [item.productOptionId]: item.productOptionValueId
                                                                                        },
                                                                                    }
                                                                                ],
                                                                                "customer_id": userId
                                                                            });

                                                                            CustomConsole(ADDTOCART + "\n" + raw);

                                                                            var requestOptions = {
                                                                                method: 'POST',
                                                                                body: raw
                                                                            };

                                                                            setLoading(true);
                                                                            fetch(ADDTOCART, requestOptions)
                                                                                .then(response => response.json())
                                                                                .then((json) => {
                                                                                    CustomConsole(json);
                                                                                    if (json.success == 1) {
                                                                                        setLoading(false);
                                                                                        hideSubscriptionModifyModal();
                                                                                        navigation.navigate("CartScreen");
                                                                                    } else {
                                                                                        setLoading(false);
                                                                                        if (json.error.length > 0) {
                                                                                            showAlert(json.error[0]);
                                                                                        }
                                                                                    }

                                                                                })
                                                                                .catch((error) => {
                                                                                    setLoading(false);
                                                                                    CustomConsole("Error=>" + error);
                                                                                });


                                                                        }} style={styles.orderModifyPauseButtonView} dataSet={{ media: ids.orderModifyPauseButtonView }}>
                                                                            <Text style={styles.orderModifyButtonText} dataSet={{ media: ids.orderModifyButtonText }}>Add to cart</Text>
                                                                        </Pressable> : null} */}
                                                                        {/* end of add to cart button */}

                                                                        {/* pause button */}
                                                                        {item2.isPause != 1 ? <Pressable onPress={() => {
                                                                            pauseApi(item2.subscription_id, item2.startDate, item2.startDate);
                                                                        }} style={styles.orderModifyPauseButtonView} dataSet={{ media: ids.orderModifyPauseButtonView }}>
                                                                            <Text style={styles.orderModifyButtonText} dataSet={{ media: ids.orderModifyButtonText }}>Pause</Text>
                                                                        </Pressable> : null}
                                                                        {/* end of pause button */}

                                                                        {/* <Pressable onPress={() => {
                                                                            resumeApi(subscriptionId,  formatDateYMD(new Date(item2.startDate).minusDays(1)))
                                                                        }} style={styles.orderModifyPauseButtonView} dataSet={{ media: ids.orderModifyPauseButtonView }}>
                                                                            <Text style={styles.orderModifyButtonText} dataSet={{ media: ids.orderModifyButtonText }}>Resume</Text>
                                                                        </Pressable> */}
                                                                        {/* <Pressable onPress={() => {
                                                                            CustomConsole(item2)
                                                                        }} style={styles.orderModifyPauseButtonView} dataSet={{ media: ids.orderModifyPauseButtonView }}>
                                                                            <Text style={styles.orderModifyButtonText} dataSet={{ media: ids.orderModifyButtonText }}>Console</Text>
                                                                        </Pressable> */}
                                                                    </View>
                                                                </View>
                                                            );
                                                        })}
                                                    </View>
                                                </>
                                            );
                                        }} />
                                </ScrollView>


                            </View>
                        </View>
                    </Modal>
                    {/* end of modify modal view */}

                    {/* Pause subscription modal view */}
                    <Modal
                        onRequestClose={hideSubscriptionPauseModal}
                        transparent
                        visible={pauseSubscriptionVisible}
                        animationType={'slide'}
                    >
                        <View style={styles.orderModifyModalMainView}>
                            <View style={[styles.orderPauseModalSubView, offset]} dataSet={{ media: ids.orderModifyModalSubView }}>
                                <ScrollView>
                                    <Text style={styles.orderModifyText}>{"Pause Subscription"}</Text>

                                    <View style={styles.pauseDateContainer}>
                                        <DateRangePicker
                                            onSelectDateRange={(range) => {
                                                CustomConsole(range)
                                                setRange(range);
                                                setStartDate(range.firstDate);
                                                setEndDate(range.secondDate);
                                            }}
                                            confirmBtnTitle=""
                                            blockSingleDateSelection={true}
                                            responseFormat="YYYY-MM-DD"
                                            minDate={moment()}
                                            onClear={() => {
                                                setRange({});
                                                setStartDate(null);
                                                setEndDate(null);
                                            }}
                                            selectedDateContainerStyle={styles.selectedDateContainerStyle}
                                            selectedDateStyle={styles.selectedDateStyle}
                                        />
                                    </View>


                                    {/* <View style={styles.startDateContainer}>
                                        <Text style={styles.startDateText} dataSet={{ media: ids.startDateText }}>Select Start Date</Text>
                                        <input style={styles.startDateInput}
                                            type="date"
                                            id="startDate"
                                            name=""
                                            value={startDate}
                                            min={formatDateYMD(new Date(Date.now()))}
                                            onChange={(e) => {
                                                // console.log(e.target.value);
                                                setStartDate(e.target.value);
                                            }}
                                        />
                                    </View>
                                    <View style={styles.startDateContainer}>
                                        <Text style={styles.startDateText} dataSet={{ media: ids.startDateText }}>Select End Date</Text>
                                        <input style={styles.startDateInput}
                                            type="date"
                                            id="endDate"
                                            name=""
                                            value={endDate}
                                            min={formatDateYMD(new Date(startDate).addDays(1))}
                                            onChange={(e) => {
                                                // console.log(e.target.value);
                                                setEndDate(e.target.value);
                                            }}
                                        />
                                    </View> */}

                                </ScrollView>

                                <View style={styles.orderModifyModalButtonView} dataSet={{ media: ids.orderModifyModalButtonView }}>
                                    <Pressable
                                        onPress={() => {
                                            CustomConsole(startDate);
                                            CustomConsole(endDate);
                                            CustomConsole(Object.keys(selectedRange).length);
                                            if (Object.keys(selectedRange).length == 0) {
                                                showAlert("Please select date range");
                                            }
                                            // else if (endDate == null) {
                                            //     showAlert("Please select end date");
                                            // } 
                                            else {
                                                pauseApi(subscriptionId, startDate, endDate);
                                            }
                                        }}
                                        style={styles.orderModifyButton}>
                                        <Text style={styles.orderModifyButtonText}>Pause</Text>
                                    </Pressable>
                                    <Pressable onPress={hideSubscriptionPauseModal} style={styles.orderCloseButton}>
                                        <Text style={styles.orderModifyButtonText}>Close</Text>
                                    </Pressable>
                                </View>

                            </View>
                        </View>
                    </Modal>
                    {/* End of pause subscription modal view */}

                    {/* common modal */}
                    <Modal
                        onRequestClose={hideAlert}
                        transparent
                        visible={alertShow}
                        animationType={'slide'}
                    >
                        <View style={styles.commonModalMainView} dataSet={{ media: ids.commonModalMainView }}>
                            <View style={[styles.commonModalViewContainer, offset]} dataSet={{ media: ids.commonModalViewContainer }}>

                                <View>
                                    <Text style={styles.commonModalText}>{alertMsg}</Text>
                                </View>
                                <Pressable
                                    onPress={hideAlert}
                                    style={styles.commonModalButton}>
                                    <Text style={styles.commonModalButtonText}>Ok, Got it</Text>
                                </Pressable>
                            </View>
                        </View>
                    </Modal>
                    {/* end of common modal */}

                    {/* confirm cancel modal */}
                    <Modal
                        onRequestClose={hideConfirmCancelAlert}
                        transparent
                        visible={confirmCancelAlertShow}
                        animationType={'slide'}
                    >
                        <View style={styles.commonModalMainView} dataSet={{ media: ids.commonModalMainView }}>
                            <View style={[styles.commonModalViewContainer, offset]} dataSet={{ media: ids.commonModalViewContainer }}>

                                <View>
                                    <Text style={styles.commonModalText}>{"Are you sure you want to cancel your subscription?"}</Text>
                                </View>
                                {/* <Pressable
                                    onPress={hideConfirmCancelAlert}
                                    style={styles.commonModalButton}>
                                    <Text style={styles.commonModalButtonText}>Ok, Got it</Text>
                                </Pressable> */}
                                <View style={styles.orderConfirmCancelModalButtonView} dataSet={{ media: ids.orderConfirmCancelModalButtonView }}>
                                    <Pressable
                                        onPress={() => { cancelApi(subscriptionId) }}
                                        style={styles.orderModifyButton}>
                                        <Text style={styles.orderModifyButtonText}>Yes</Text>
                                    </Pressable>
                                    <Pressable onPress={hideConfirmCancelAlert} style={styles.orderCloseButton}>
                                        <Text style={styles.orderModifyButtonText}>No</Text>
                                    </Pressable>
                                </View>
                            </View>
                        </View>
                    </Modal>
                    {/* end of confirm cancel modal */}

                    {/* vacation history modal */}
                    <Modal
                        onRequestClose={hideVacationHistoryModal}
                        transparent
                        visible={vacationModalVisible}
                        animationType={'slide'}
                    >
                        <View style={styles.orderModifyModalMainView} dataSet={{ media: ids.orderModifyModalMainView }}>
                            <View style={[styles.orderVacationHistoryModalSubView, offset]} dataSet={{ media: ids.orderVacationHistoryModalSubView }}>
                                <ScrollView>
                                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", }}>
                                        <Text style={styles.orderModifyText}>{"Vacation History"}</Text>
                                        <Pressable onPress={hideVacationHistoryModal} style={{
                                            justifyContent: "center",
                                            paddingVertical: 10,
                                            paddingHorizontal: 15,
                                            borderRadius: 5,
                                        }}>
                                            <Image source={require("../assets/header/close.png")} style={styles.orderModifyCloseIcon} />
                                        </Pressable>
                                    </View>

                                    <FlatList style={{}}
                                        showsVerticalScrollIndicator={false}
                                        data={vacation_history}
                                        keyExtractor={(item, id) => String(id)}
                                        renderItem={({ item, index }) => {
                                            CustomConsole(item)
                                            return (
                                                <>
                                                    <View style={(vacation_history.length - 1) == index ? styles.orderVacationHistorySubViewWithoutBorder : styles.orderVacationHistorySubViewWithBorder}
                                                        dataSet={{ media: (vacation_history.length - 1) == index ? ids.orderVacationHistorySubViewWithoutBorder : ids.orderVacationHistorySubViewWithBorder }}>
                                                        <Text style={styles.orderHistoryItemText} dataSet={{ media: ids.orderHistoryItemText }} >Vacation from {formatDateDMY(item.pause_start_date)} to  {formatDateDMY(item.pause_end_date)}</Text>
                                                    </View>
                                                </>
                                            );
                                        }} />
                                </ScrollView>


                            </View>
                        </View>
                    </Modal>
                    {/* end of vacation history modal */}

                    {/* Footer view */}
                    {/* {FooterComponent(navigation, userId)}*/}
                    {/* end of Footer view */}

                </View>
            </ScrollView>
        </View>
    );
};

export default SubscribeListScreen;
