import React, { useState } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, useWindowDimensions, Linking } from 'react-native';
import { colors } from '../common/color';
import { CustomConsole, HeaderComponent, FooterComponent, getDMSansBoldFont, getDMSansLightFont, validateEmail, getDMSansRegularFont, scrollToTop, getDMSansSemiBoldFont, getDMSansMediumFont, CustomProgressBar } from '../common/utils';
import { CARTLIST, FAQ, GETFAQ, STORY, } from '../common/webUtils';
import { USER_ID, saveSession, getSession } from '../common/LocalStorage';
import { Image } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { FlatList } from 'react-native';
import RenderHTML from 'react-native-render-html';
import { styles, ids } from '../common/responsive.js';

function StoriesScreen({ navigation }) {

    const { width } = useWindowDimensions();

    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [mainFaqList, setMainFaqList] = useState([]);
    const [faqList, setfaqList] = useState([]);
    const [selectedFaq, setSelectedFaq] = useState('');
    const [storyList, setStoryList] = useState([]);
    const [cartLength, setcartLength] = React.useState('0');

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            getStoryListApi();
            cartListApi();
        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);

            // setLoading(true);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                    // setLoading(false);
                })
                .catch((error) => {
                    // setLoading(false);
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    // story list
    const getStoryListApi = async (id) => {
        try {

            var requestOptions = {
                method: 'GET',
                redirect: 'follow',
            };

            CustomConsole(STORY + "&product_id=");

            setLoading(true);
            fetch(STORY + "&product_id=", requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    storyList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {
                        storyList.push({
                            index: i,
                            story: (json.data[i].story).replace(/&lt;/g, '<').replace(/&gt;/g, '>'),
                            image: json.data[i].image,
                            date: (json.data[i].date).replace(/\-/g, '.'),
                            link: json.data[i].link,

                        });
                    }

                    setStoryList(storyList);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Banner Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Banner Exception=>" + e + "");
        }
    }

    // story item view
    const storyItem = (item, index) => {
        return (
            <View style={styles.storyItemMainView} dataSet={{ media: ids.storyItemMainView }}>
                <View style={styles.storyTitleView} dataSet={{ media: ids.storyTitleView }}>
                    <Text style={styles.storyTitleText} dataSet={{ media: ids.storyTitleText }}>{item.date}</Text>
                </View>
                <View style={styles.storyItemSubView} dataSet={{ media: ids.storyItemSubView }}>
                    <RenderHTML
                        contentWidth={width}
                        source={{ html: item.story }}
                        tagsStyles={tagsStyles3}
                        baseStyle={styles.storyItemDetailText}
                        systemFonts={[getDMSansMediumFont()]}
                    />
                    <Pressable onPress={() => Linking.openURL(item.link)}
                        style={styles.storyItemReadMoreButtonView} dataSet={{ media: ids.storyItemReadMoreButtonView }}>
                        <Text style={styles.storyItemReadMoreText} dataSet={{ media: ids.storyItemReadMoreText }}>Read More</Text>
                    </Pressable>
                </View>
                <View style={styles.storyItemImgView} dataSet={{ media: ids.storyItemImgView }}>
                    <Image source={item.image != "" ? { uri: item.image } : require("../assets/header/footer-logo.png")}
                        style={styles.storyItemImg} dataSet={{ media: ids.storyItemImg }}/>
                </View>
            </View>
        );
    }

    return (
        <View>
            <StatusBar barStyle="dark-content" />
            <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent(cartLength, navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View style={styles.storiesScreenMainView}>
                        {/* faq main list */}
                        <FlatList
                            style={{}}
                            showsVerticalScrollIndicator={false}
                            data={storyList}
                            keyExtractor={(item, id) => String(id)}
                            renderItem={({ item, index }) => storyItem(item, index)} />
                    </View>
                    {/* end of main view */}

                    {/* Footer view */}
                    {/* {FooterComponent(navigation, userId)}*/}
                    {/* end of Footer view */}

                </View>

            </ScrollView>
        </View>
    );
};

export default StoriesScreen;

const tagsStyles3 = {
    p: styles.pTagWithBlackMediumText,

}