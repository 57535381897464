import React, { useState } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert } from 'react-native';
import { colors } from '../common/color';
import { APP_NAME } from '../common/string';
import { CustomConsole, HeaderComponent, FooterComponent, getDMSansBoldFont, getDMSansLightFont, validateEmail, getDMSansRegularFont, scrollToTop, CustomProgressBar } from '../common/utils';
import { CARTLIST, LOGIN, SENDOTP } from '../common/webUtils';
import { EMAIL, FIRST_NAME, LAST_NAME, OTP_VERIFY, PASSWORD, PHONE, USER_ID, saveSession, getSession, REFERRAL_CODE } from '../common/LocalStorage';
import { Image } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { Divider } from 'react-native-paper';
import { styles, ids } from '../common/responsive.js';

function ThankyouScreen({ navigation }) {

    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [cartLength, setcartLength] = React.useState('0');

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            cartListApi();
        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);

            // setLoading(true);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                    // setLoading(false);
                })
                .catch((error) => {
                    // setLoading(false);
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    return (
        <View>
            <StatusBar barStyle="dark-content" />
            <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent(cartLength, navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View style={styles.thankYouMainView}>
                        <View style={styles.thankYouSubView}>
                            <Text style={styles.thankYouText}>Thank You</Text>
                            <Text style={styles.thankYouSubText}>Your Order has been placed and is being processed.</Text>
                            <Pressable onPress={() => {
                                navigation.navigate("OrderListScreen");
                                navigation.reset({
                                    index: 0,
                                    routes: [{ name: 'OrderListScreen' }],
                                  });
                            }}
                                style={styles.goToOrdersButton} >
                                <Text style={styles.loginText}>Go to orders</Text>
                            </Pressable>
                        </View>
                    </View>
                    {/* end of main view */}

                    {/* Footer view */}
                    {/* {FooterComponent(navigation, userId)}*/}
                    {/* end of Footer view */}

                </View>

            </ScrollView>
        </View>
    );
};

export default ThankyouScreen;
