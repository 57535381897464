import React, { useState, useMemo, useRef } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, Modal } from 'react-native';
import { colors } from '../common/color';
import { CustomConsole, CustomProgressBar, FooterComponent, HeaderComponent, getDMSansRegularFont, getDMSansSemiBoldFont, scrollToTop, validateEmail } from '../common/utils';
import { ADDSHIPPING, CARTLIST, CITY, COUNTRY, REGISTER, SHIPPINGLIST, STATE } from '../common/webUtils';
import { CITY_NAME, COUNTRY_ID, EMAIL, FIRST_NAME, LAST_NAME, OTP_VERIFY, PHONE, STATE_ID, USER_ID, getSession, saveSession } from '../common/LocalStorage';
import { Image } from 'react-native';
import { RadioButton, Checkbox } from 'react-native-paper';
import { Picker } from '@react-native-picker/picker';
import { useIsFocused } from '@react-navigation/native';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { styles, ids } from '../common/responsive.js';
import Autocomplete from "react-google-autocomplete";
import { MAP_API_KEY } from '../common/string.js';

// const containerStyle = {
//     width: '500px',
//     height: '500px'
// };

function AddShippingAddressScreen({ navigation, route }) {

    const [isLoad, setLoad] = useState(false);
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [selectedLatitude, setSelectedLatitude] = useState(0);
    const [selectedLongitude, setSelectedLongitude] = useState(0);
    const [center, setCenter] = useState({
        lat: 0,
        lng: 0
    });
    const [markers, setMarkers] = useState([]);
    const [map, setMap] = useState(null);
    const [defaultChecked, setDefaultChecked] = useState(false);

    var temp_map = null;

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: MAP_API_KEY,
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=" + MAP_API_KEY + "&v=3.exp&libraries=geometry,drawing,places",
    })


    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        // console.log("Load    1" + map)
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map);

        temp_map = map;

        position1(map);
    }, [])


    const onUnmount = React.useCallback(function callback(map) {
        // console.log("Load    2")
        // setMap(null)
    }, [])

    const onMapClick = (e) => {
        if (markers.length == 0) {
            setMarkers((current) => [
                ...current,
                {
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng()
                }
            ]);
            setSelectedLatitude(e.latLng.lat());
            setSelectedLongitude(e.latLng.lng());
        } else {
            markers.length = 0;
            setMarkers((current) => [
                ...current,
                {
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng()
                }
            ]);
            setSelectedLatitude(e.latLng.lat());
            setSelectedLongitude(e.latLng.lng());
        }
    };

    const position1 = async (map) => {
        // console.log("Load    3 " + map)
        // await navigator.geolocation.getCurrentPosition(
        //   position => this.setState({ 
        //     latitude: position.coords.latitude, 
        //     longitude: position.coords.longitude
        //   }), 
        //   err => console.log(err)
        // );
        // console.log(latitude)
        await navigator.geolocation.getCurrentPosition(
            position => {


                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);

                setSelectedLatitude(position.coords.latitude);
                setSelectedLongitude(position.coords.longitude);

                setCenter({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });

                setMarkers([
                    {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    }
                ]);

                if (map !== null) {
                    CustomConsole("latitude======> " + position.coords.latitude)
                    CustomConsole("longitude====> " + position.coords.longitude)

                    const bounds = new window.google.maps.LatLngBounds({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                    map.fitBounds(bounds);
                    const test = map;
                    setMap(map => ({ ...test, map }));
                    temp_map = map;
                    setLoad(true);
                } else {
                    position1(map);
                    setLoad(false);
                }


            },
            err => CustomConsole(err)
        );
        //   console.log(latitude)
    }

    var onlyDigitReg = /^\d+$/;

    const WIGGLE_ROOM = 128;

    const offset = useMemo(() => {
        return {
            top: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2,
            left: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2
        };
    }, []);

    const [alertShow, setAlertShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    // modal hide/show
    const showAlert = (text) => {
        setAlertShow(true);
        setAlertMsg(text)
    };
    const hideAlert = () => setAlertShow(false);

    const [loading, setLoading] = useState(false);
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [block, setBlock] = useState('');
    const [apartment, setApartment] = useState('');
    const [postcode, setPostcode] = useState('');
    const [city, setCity] = useState('');
    const [zone_id, setZoneId] = useState('');
    const [zone, setZone] = useState('');
    const [zone_code, setZoneCode] = useState('');
    const [country_id, setCountryId] = useState('');
    const [address_type, setAddressType] = useState('home');
    const [country, setCountry] = useState('');
    const [iso_code_2, setIsoCode2] = useState('');
    const [iso_code_3, setIsoCode3] = useState('');
    const [address_format, setAddressFormat] = useState('');
    const [address_1, setAddress1] = useState('');
    const [address_2, setAddress2] = useState('');
    const [address_id, setAddressId] = useState('');
    const [location, setLocation] = useState('');
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [userId, setUserId] = useState('');
    const [cartLength, setcartLength] = useState('0');
    const [firstnameError, setFirstNameError] = useState(false);
    const [lastnameError, setLastNameError] = useState(false);
    const [countryError, setCountryError] = useState(false);
    const [zoneError, setZoneError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [address_1Error, setAddress1Error] = useState(false);
    const [address_2Error, setAddress2Error] = useState(false);
    const [postcodeError, setPostCodeError] = useState(false);
    const [postcodeOnlyDigitError, setPostCodeOnlyDigitError] = useState(false);
    const [postcodeDigitError, setPostCodeDigitError] = useState(false);
    const [addressTypeError, setAddressTypeError] = useState(false);

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();

    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            const pre_first_name = await getSession(FIRST_NAME);
            const pre_last_name = await getSession(LAST_NAME);
            const city = await getSession(CITY_NAME);
            const state_id = await getSession(STATE_ID);
            const country_id = await getSession(COUNTRY_ID);

            setUserId(user_id);
            countryListApi();
            cartListApi();
            if (route.params) {
                if (route.params.paramFrom == "AddShipping") {
                    setFirstName(pre_first_name);
                    setLastName(pre_last_name);
                    setCity(city);
                    setCountryId(country_id);
                    stateListApi(country_id);
                    setZoneId(state_id);
                } else {

                    shippingAddressListApi(route.params.paramIndex);
                }
                // stateListApi(route.params.paramItem.country_id);

                // setAddressId(route.params.paramItem.address_id);
                // setFirstName(route.params.paramItem.firstname);
                // setLastName(route.params.paramItem.lastname);
                // setAddress1(route.params.paramItem.address_1);
                // setAddress2(route.params.paramItem.address_2);
                // setCountry(route.params.paramItem.country);
                // setCountryId(route.params.paramItem.country_id);
                // setZone(route.params.paramItem.zone);
                // setZoneCode(route.params.paramItem.zone_code);
                // setZoneId(route.params.paramItem.zone_id);
                // setCity(route.params.paramItem.city);
                // setBlock(route.params.paramItem.block);
                // setCompany(route.params.paramItem.company);
                // setApartment(route.params.paramItem.apartment);
                // setAddressType(route.params.paramItem.address_type);
                // setPostcode(route.params.paramItem.postcode);
            } else {

            }


        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);

            // setLoading(true);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                    // setLoading(false);
                })
                .catch((error) => {
                    // setLoading(false);
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    // shipping address list api
    const shippingAddressListApi = async (addressId) => {
        try {
            const user_id = await getSession(USER_ID);

            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(SHIPPINGLIST + "&customer_id=" + user_id);

            setLoading(true);
            fetch(SHIPPINGLIST + "&customer_id=" + user_id, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    // shippingAddressList.length = 0;
                    // for (let i = 0; i < json.data.length; i++) {
                    //     shippingAddressList.push({
                    //         index: i,
                    //         address_id: json.data[i].address_id,
                    //         firstname: json.data[i].firstname,
                    //         lastname: json.data[i].lastname,
                    //         company: json.data[i].company,
                    //         block: json.data[i].block,
                    //         apartment: json.data[i].apartment,
                    //         address_1: json.data[i].address_1,
                    //         address_2: json.data[i].address_2,
                    //         postcode: json.data[i].postcode,
                    //         city: json.data[i].city,
                    //         zone_id: json.data[i].zone_id,
                    //         zone: json.data[i].zone,
                    //         zone_code: json.data[i].zone_code,
                    //         country_id: json.data[i].country_id,
                    //         address_type: json.data[i].address_type,
                    //         country: json.data[i].country,
                    //         iso_code_2: json.data[i].iso_code_2,
                    //         iso_code_3: json.data[i].iso_code_3,
                    //         address_format: json.data[i].address_format,
                    //     });
                    // }

                    // setShippingAddressList(shippingAddressList);
                    if (json.data.length != 0) {

                        const address_data = json.data.find(element => element.address_id == addressId)

                        stateListApi(address_data?.country_id);

                        setAddressId(address_data?.address_id);
                        setFirstName(address_data?.firstname);
                        setLastName(address_data?.lastname);
                        setAddress1(address_data?.address_1);
                        setAddress2(address_data?.address_2);
                        setCountry(address_data?.country);
                        setCountryId(address_data?.country_id);
                        setZone(address_data?.zone);
                        setZoneCode(address_data?.zone_code);
                        setZoneId(address_data?.zone_id);
                        setCity(address_data?.city);
                        setBlock(address_data?.block);
                        setCompany(address_data?.company);
                        setApartment(address_data?.apartment);
                        setAddressType(address_data?.address_type);
                        setPostcode(address_data?.postcode);
                        setSelectedLatitude(address_data?.latitude);
                        setSelectedLongitude(address_data?.longitude);
                        setLocation(address_data?.location);

                        var temp = {
                            lat: parseFloat(address_data?.latitude),
                            lng: parseFloat(address_data?.longitude)
                        };

                        setCenter(center => ({ ...temp, center }));

                        setMarkers([
                            {
                                lat: parseFloat(address_data?.latitude),
                                lng: parseFloat(address_data?.longitude)
                            }
                        ]);
                        setLoading(false);
                    } else {
                        setLoading(false);
                    }


                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Shipping Address Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Shipping Address Exception=>" + e + "");
        }
    }

    // country list api
    const countryListApi = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(COUNTRY);

            setLoading(true);
            fetch(COUNTRY, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    countryList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {
                        countryList.push({
                            index: i,
                            country_id: json.data[i].country_id,
                            name: json.data[i].name,
                            iso_code_2: json.data[i].iso_code_2,
                            iso_code_3: json.data[i].iso_code_3,
                            address_format_id: json.data[i].address_format_id,
                            postcode_required: json.data[i].postcode_required,
                            status: json.data[i].status,
                        });
                    }

                    setCountryList(countryList);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Country list Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Country list Exception=>" + e + "");
        }
    }

    // state list api
    const stateListApi = async (country_id) => {
        try {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(STATE + "&countryId=" + country_id + "&lang=en&currency=INR");

            // setLoading(true);
            fetch(STATE + "&countryId=" + country_id + "&lang=en&currency=INR", requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    stateList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {
                        stateList.push({
                            index: i,
                            zone_id: json.data[i].zone_id,
                            country_id: json.data[i].country_id,
                            name: json.data[i].name,
                            code: json.data[i].code,
                            status: json.data[i].status,
                        });
                    }

                    setStateList([...stateList]);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Country list Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Country list Exception=>" + e + "");
        }
    }

    // map state list api
    const mapStateListApi = async (country_id, zone_name) => {
        try {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(STATE + "&countryId=" + country_id + "&lang=en&currency=INR");

            // setLoading(true);
            fetch(STATE + "&countryId=" + country_id + "&lang=en&currency=INR", requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    stateList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {
                        stateList.push({
                            index: i,
                            zone_id: json.data[i].zone_id,
                            country_id: json.data[i].country_id,
                            name: json.data[i].name,
                            code: json.data[i].code,
                            status: json.data[i].status,
                        });
                    }

                    setStateList([...stateList]);

                    let Data_state = json.data.find(element => element.name == zone_name);
                    setZoneId(Data_state.zone_id);
                    setZone(zone_name);

                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Country list Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Country list Exception=>" + e + "");
        }
    }

    // add address & edit address
    const AddShippingAddress = async () => {

        if (first_name.trim().length == 0) {
            // alert("Enter first name");
            setFirstNameError(true);
        }
        else if (last_name.trim().length == 0) {
            // alert("Enter last name");
            setLastNameError(true);
        }
        else if (country_id.length == 0) {
            // alert("Select country");
            setCountryError(true);
        }
        else if (zone_id.length == 0) {
            // alert("Select region/state");
            setZoneError(true);
        }
        else if (city.trim().length == 0) {
            // alert("Enter city");
            setCityError(true);
        }
        else if (address_1.trim().length == 0) {
            // alert("Enter apartment/suits/blocks etc.");
            setAddress1Error(true);
        }
        // else if (block.trim().length == 0) {
        //     alert("Enter block");
        // }
        else if (address_2.trim().length == 0) {
            // alert("Enter Address");
            setAddress2Error(true);
        }
        else if (postcode.trim().length == 0) {
            // alert("Enter zip code");
            setPostCodeError(true);
        }
        else if (!onlyDigitReg.test(postcode)) {
            // alert("Zip code should be only digits");
            setPostCodeOnlyDigitError(true);
        }
        else if (postcode.trim().length != 6) {
            // alert("Enter 6-digit zip code");
            setPostCodeDigitError(true);
        }
        else if (address_type.trim().length == 0) {
            // alert("Select address type");
            setAddressTypeError(true);
        }
        else if (selectedLatitude == 0 && selectedLongitude == 0) {
            showAlert("Please select location.");
        }
        else {
            try {
                const user_id = await getSession(USER_ID);

                var raw = JSON.stringify({
                    "zone_id": zone_id,
                    "country_id": country_id,
                    "address_1": address_1,
                    "address_2": address_2,
                    "company": company,
                    "postcode": postcode,
                    "city": city,
                    "firstname": first_name,
                    "lastname": last_name,
                    "customer_id": user_id,
                    "address_type": address_type,
                    "address_id": address_id,
                    "block": block,
                    "apartment": apartment,
                    "gst_no": "",
                    "latitude": selectedLatitude,
                    "longitude": selectedLongitude,
                    "location": location
                });
                var requestOptions = {
                    method: 'POST',
                    body: raw,
                    redirect: 'follow'
                };

                CustomConsole(ADDSHIPPING + "\n" + raw);

                setLoading(true);
                fetch(ADDSHIPPING, requestOptions)
                    .then(response => response.json())
                    .then((json) => {

                        CustomConsole(json);
                        if (json.success == 1) {
                            setLoading(false);
                            setAddressId("");
                            if (address_id == "") {
                                // alert("Address added successfully.");
                                setAddressId("");
                                setFirstName("");
                                setLastName("");
                                setAddress1("");
                                setAddress2("");
                                setCountry("");
                                setCountryId("");
                                setZone("");
                                setZoneCode("");
                                setZoneId("");
                                setCity("");
                                setBlock("");
                                setCompany("");
                                setApartment("");
                                setAddressType("");
                                setPostcode("");
                                setSelectedLatitude("");
                                setSelectedLongitude("");
                                setLocation("");

                                navigation.goBack();

                            } else {
                                navigation.goBack();
                                // alert("update Success");
                            }

                        } else {
                            setLoading(false);
                            // alert("Invalid Email and Password");
                            if (json.error.length > 0) {
                                // alert(json.error[0]);
                                showAlert(json.error[0]);
                            }
                        }

                    })
                    .catch((error) => {
                        setLoading(false);
                        CustomConsole("Error=>" + error);
                    });
            } catch (e) {
                setLoading(false);
                CustomConsole("Exception=>" + e + "");
            }

        }
    }

    return (
        <View>
            <StatusBar barStyle="dark-content" />
            <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                <View>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent(cartLength, navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View style={styles.addShippingAddMainView} dataSet={{ media: ids.addShippingAddMainView }}>

                        <View style={styles.fillAddressTextView} dataSet={{ media: ids.fillAddressTextView }}>
                            <Text style={styles.fillAddressText}>{"Fill Address Details"}</Text>
                        </View>


                        <View style={styles.googleMapView} dataSet={{ media: ids.googleMapView }}>
                            {/* address type view */}
                            <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Select Location</Text>

                            <Autocomplete
                                apiKey={MAP_API_KEY}
                                options={{
                                    types: ["geocode", "establishment"],
                                }}
                                onPlaceSelected={(place) => {
                                    setLoad(false);
                                    if (place !== undefined && place.geometry !== undefined) {
                                        CustomConsole("----->" + place.geometry.location.toJSON().lat)
                                        // place = JSON.stringify(place);
                                        CustomConsole(place);
                                        // console.log("=======>"+place.geometry.location.lat);
                                        setLatitude(parseFloat(place.geometry.location.toJSON().lat));
                                        setLongitude(parseFloat(place.geometry.location.toJSON().lng));

                                        var temp = {
                                            lat: place.geometry.location.toJSON().lat,
                                            lng: place.geometry.location.toJSON().lng
                                        };

                                        setCenter(center => ({ ...temp, center }));
                                        // setCenter({
                                        //     lat: parseFloat(place.geometry.location.lat),
                                        //     lng: parseFloat(place.geometry.location.lng)
                                        // });


                                        CustomConsole("latitude======> " + place.geometry.location.toJSON().lat)
                                        CustomConsole("longitude====> " + place.geometry.location.toJSON().lng)

                                        setAddress2(place.formatted_address);
                                        setLocation(place.formatted_address);
                                        setCity(place.address_components.find(address => address.types[0] === 'locality').long_name == undefined ? "" : place.address_components.find(address => address.types[0] === 'locality').long_name);

                                        CustomConsole("is postcode there=====>", place.address_components.some(address => address.types[0] === 'postal_code'));
                                        if (place.address_components.some(address => address.types[0] === 'postal_code')) {
                                            setPostcode(place.address_components.find(address => address.types[0] === 'postal_code').long_name == undefined ? "" : place.address_components.find(address => address.types[0] === 'postal_code').long_name);
                                        }

                                        if (place.address_components.find(address => address.types[0] === 'country').long_name) {
                                            let Data = countryList.find(element => element.name == place.address_components.find(address => address.types[0] === 'country').long_name);
                                            setCountryId(Data.country_id);
                                            setCountry(place.address_components.find(address => address.types[0] === 'country').long_name);

                                            if (place.address_components.find(address => address.types[0] === 'administrative_area_level_1').long_name) {
                                                mapStateListApi(Data.country_id, place.address_components.find(address => address.types[0] === 'administrative_area_level_1').long_name);
                                            }
                                        }

                                        // console.log("pincode======> " + place.address_components.find(address => address.types[0] === 'country').long_name);
                                        // setMap(map => ({ ...temp_map, map }));

                                        // const bounds = new window.google.maps.LatLngBounds({
                                        //     lat: parseFloat(place.geometry.location.lat),
                                        //     lng: parseFloat(place.geometry.location.lng)
                                        // });
                                        // map.fitBounds(bounds);
                                        // const test = map;
                                        // setMap(map => ({ ...test, map }));
                                        setLoad(true);
                                    }
                                }}
                                style={styles.autoCompletePlace}
                                dataSet={{ media: ids.autoCompletePlace }}
                                defaultValue={location}
                            />
                            {!isLoaded ? (
                                <h1>Loading...</h1>
                            ) : (
                                <View style={styles.containerStyle} dataSet={{ media: ids.containerStyle }}>
                                    <GoogleMap
                                        defaultZoom={5}
                                        defaultCenter={center}
                                        center={center}
                                        mapContainerStyle={styles.containerStyle}
                                        dataSet={{ media: ids.containerStyle }}
                                        onLoad={onLoad}
                                        onUnmount={onUnmount}
                                        zoom={11}
                                        onClick={onMapClick}
                                    >
                                        {markers.map((marker) => (
                                            <Marker
                                                key={marker.lat}
                                                position={{
                                                    lat: marker.lat,
                                                    lng: marker.lng
                                                }} />
                                        ))}
                                    </GoogleMap>
                                </View>
                            )}
                        </View>

                        <View style={styles.addressFlexRowView} dataSet={{ media: ids.addressFlexRowView }}>
                            <View style={styles.addressFlexOneView} dataSet={{ media: ids.addressFlexOneView }}>
                                {/* first name view */}
                                <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>First Name</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    dataSet={{ media: ids.loginTextInput }}
                                    value={first_name}
                                    onChangeText={txt => { setFirstName(txt); setFirstNameError(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter First Name" />
                                {firstnameError ? <Text style={styles.textInputError}>Enter first name</Text> : null}
                            </View>
                            <View style={styles.addressFlexOneView} dataSet={{ media: ids.addressFlexOneView }}>
                                {/* last name view */}
                                <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Last Name</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    dataSet={{ media: ids.loginTextInput }}
                                    value={last_name}
                                    onChangeText={txt => { setLastName(txt); setLastNameError(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter Last Name" />
                                {lastnameError ? <Text style={styles.textInputError}>Enter last name</Text> : null}
                            </View>
                        </View>

                        <View style={styles.addressFlexRowView} dataSet={{ media: ids.addressFlexRowView }}>
                            <View style={styles.addressFlexOneView} dataSet={{ media: ids.addressFlexOneView }}>
                                {/* country view */}
                                <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Country</Text>
                                <Picker
                                    style={styles.addressCountryPicker}
                                    dataSet={{ media: ids.addressCountryPicker }}
                                    selectedValue={country_id}
                                    onValueChange={(itemValue, itemIndex) => {
                                        setCountryError(false);
                                        setZoneId("");
                                        if (itemValue == "") {
                                            setCountry("")
                                            setCountryId("")
                                            setStateList([]);
                                        } else {
                                            let Data = countryList.find(element => element.country_id == itemValue);
                                            setCountryId(itemValue);
                                            setCountry(Data.name);
                                            stateListApi(Data.country_id);
                                        }
                                    }}>
                                    <Picker.Item label="Please Select" value="" />
                                    {countryList.map((item) => (
                                        <Picker.Item label={item.name} value={item.country_id} key={item.name} />
                                    ))}
                                </Picker>
                                {countryError ? <Text style={styles.textInputError}>Select country</Text> : null}
                            </View>
                            <View style={styles.addressFlexOneView} dataSet={{ media: ids.addressFlexOneView }}>
                                {/* region/state view */}
                                <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Province/State</Text>
                                <Picker
                                    style={styles.addressCountryPicker}
                                    dataSet={{ media: ids.addressCountryPicker }}
                                    selectedValue={zone_id}
                                    onValueChange={(itemValue, itemIndex) => {
                                        setZoneError(false);
                                        if (itemValue == "") {
                                            setZone("")
                                            setZoneId("")
                                        } else {
                                            let Data = stateList.find(element => element.zone_id == itemValue);
                                            setZoneId(itemValue)
                                            setZone(Data.name);
                                        }
                                    }}>
                                    <Picker.Item label="Please Select" value="" />
                                    {stateList.map((item) => (
                                        <Picker.Item label={item.name} value={item.zone_id} key={item.name} />
                                    ))}
                                </Picker>
                                {zoneError ? <Text style={styles.textInputError}>Select province/state</Text> : null}
                            </View>
                        </View>

                        <View style={styles.addressFlexRowView} dataSet={{ media: ids.addressFlexRowView }}>
                            <View style={styles.addressFlexOneView} dataSet={{ media: ids.addressFlexOneView }}>
                                {/* city view */}
                                <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>City</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    dataSet={{ media: ids.loginTextInput }}
                                    value={city}
                                    onChangeText={txt => { setCity(txt); setCityError(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter City" />
                                {cityError ? <Text style={styles.textInputError}>Enter city</Text> : null}
                            </View>
                            <View style={styles.addressFlexOneView} dataSet={{ media: ids.addressFlexOneView }}>
                                {/* apartment view */}
                                <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Apartment/Suits/Blocks etc.</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    dataSet={{ media: ids.loginTextInput }}
                                    value={address_1}
                                    onChangeText={txt => { setAddress1(txt); setAddress1Error(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter Apartment/Suits/Blocks etc." />
                                {address_1Error ? <Text style={styles.textInputError}>Enter apartment/suits/blocks etc.</Text> : null}
                            </View>
                        </View>

                        <View style={styles.addressFlexRowView} dataSet={{ media: ids.addressFlexRowView }}>
                            {/* <View style={styles.addressFlexOneView} dataSet={{media:ids.addressFlexOneView}}>
                                {/* block view 
                                <Text style={styles.textInputTitle} dataSet={{media:ids.textInputTitle}}>Block</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    dataSet={{media:ids.loginTextInput}}
                                    value={block}
                                    onChangeText={txt => { setBlock(txt) }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter Block" />
                            </View> */}
                            <View style={styles.addressFlexOneView} dataSet={{ media: ids.addressFlexOneView }}>
                                {/* street view */}
                                <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Address</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    dataSet={{ media: ids.loginTextInput }}
                                    value={address_2}
                                    onChangeText={txt => { setAddress2(txt); setAddress2Error(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter Address" />
                                {address_2Error ? <Text style={styles.textInputError}>Enter address</Text> : null}
                            </View>
                            <View style={styles.addressFlexOneView} dataSet={{ media: ids.addressFlexOneView }}>
                                {/* zip code view */}
                                <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Zip Code</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    dataSet={{ media: ids.loginTextInput }}
                                    value={postcode}
                                    onChangeText={txt => { setPostcode(txt.replace(/[^0-9]/g, '')); setPostCodeError(false); setPostCodeOnlyDigitError(false); setPostCodeDigitError(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    maxLength={6}
                                    placeholder="Enter Zipcode" />
                                {postcodeError ? <Text style={styles.textInputError}>Enter zip code</Text> : null}
                                {postcodeOnlyDigitError ? <Text style={styles.textInputError}>Zip code should be only digits</Text> : null}
                                {postcodeDigitError ? <Text style={styles.textInputError}>Enter 6-digit zip code</Text> : null}
                            </View>
                        </View>

                        {/* <View style={styles.addressFlexRowView}>
                            <View style={styles.addressFlexOneView}>
                                {/* zip code view 
                                <Text style={styles.textInputTitle} dataSet={{media:ids.textInputTitle}}>Zip Code</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    dataSet={{media:ids.loginTextInput}}
                                    value={postcode}
                                    onChangeText={txt => { setPostcode(txt.replace(/[^0-9]/g, '')) }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    maxLength={6}
                                    placeholder="Enter Zipcode" />
                            </View>
                            <View style={styles.addressFlexOneView}>

                            </View>
                        </View> */}

                        <View style={styles.addressTypeView} dataSet={{ media: ids.addressTypeView }}>
                            {/* address type view */}
                            <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Address Type</Text>

                            <View style={styles.addressTypeSubView}>
                                <View style={styles.addressTypeSubSubView}>
                                    <RadioButton
                                        value={address_type}
                                        color={colors.buttonColor}
                                        status={address_type === 'home' ? 'checked' : 'unchecked'}
                                        onPress={() => { setAddressType('home'); setAddressTypeError(false); }}
                                    />
                                    <Text style={styles.addressTypeText}>Home</Text>
                                </View>

                                <View style={styles.addressTypeSubSubView}>
                                    <RadioButton
                                        value={address_type}
                                        color={colors.buttonColor}
                                        status={address_type === 'office' ? 'checked' : 'unchecked'}
                                        onPress={() => { setAddressType('office'); setAddressTypeError(false); }}
                                    />
                                    <Text style={styles.addressTypeText}>Office</Text>
                                </View>

                                <View style={styles.addressTypeSubSubView}>
                                    <RadioButton
                                        value={address_type}
                                        color={colors.buttonColor}
                                        status={address_type === 'other' ? 'checked' : 'unchecked'}
                                        onPress={() => { setAddressType('other'); setAddressTypeError(false); }}
                                    />
                                    <Text style={styles.addressTypeText}>Other</Text>
                                </View>
                            </View>
                            {addressTypeError ? <Text style={styles.textInputError}>Select address type</Text> : null}
                        </View>

                        {/* <View style={[styles.addressTypeView,{marginTop:10}]} dataSet={{ media: ids.addressTypeView }}>
                            <View style={styles.addressTypeSubView}>
                                <View style={styles.addressTypeSubSubView}>
                                    <input type="checkbox"
                                        checked={defaultChecked}
                                        onChange={() => {
                                            setDefaultChecked(!defaultChecked);
                                        }}
                                        onValueChange={setDefaultChecked}
                                    />
                                    <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Is default address</Text>
                                </View>
                            </View>
                        </View> */}

                        <View style={styles.saveAddressView}>
                            {/* sign in button view */}
                            <Pressable style={styles.saveAddressButton} onPress={AddShippingAddress}>
                                <Text style={styles.loginText}>Save Address</Text>
                            </Pressable>
                        </View>
                    </View>

                    {/* common modal */}
                    <Modal
                        onRequestClose={hideAlert}
                        transparent
                        visible={alertShow}
                        animationType={'slide'}
                    >
                        <View style={styles.commonModalMainView} dataSet={{ media: ids.commonModalMainView }}>
                            <View style={[styles.commonModalViewContainer, offset]} dataSet={{ media: ids.commonModalViewContainer }}>

                                <View>
                                    <Text style={styles.commonModalText}>{alertMsg}</Text>
                                </View>
                                <Pressable
                                    onPress={hideAlert}
                                    style={styles.commonModalButton}>
                                    <Text style={styles.commonModalButtonText}>Ok, Got it</Text>
                                </Pressable>
                            </View>
                        </View>
                    </Modal>
                    {/* end of common modal */}


                    {/* Footer view */}
                    {/* {/* {FooterComponent(navigation, userId)}*/}
                    {/* end of Footer view */}

                </View>
            </ScrollView>
        </View>
    );
};

export default AddShippingAddressScreen;
