import React, { useState, useRef, useMemo } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, useWindowDimensions, Modal } from 'react-native';
import { colors } from '../common/color';
import { CustomConsole, HeaderComponent, FooterComponent, getDMSansBoldFont, getDMSansLightFont, validateEmail, getDMSansRegularFont, scrollToTop, CustomProgressBar } from '../common/utils';
import { CARTLIST, CMSPAGE, INQUIRY, LOGIN, SENDOTP } from '../common/webUtils';
import { EMAIL, FIRST_NAME, LAST_NAME, OTP_VERIFY, PASSWORD, PHONE, USER_ID, saveSession, getSession } from '../common/LocalStorage';
import { Image } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { Divider } from 'react-native-paper';
import RenderHTML from 'react-native-render-html';
import { styles, ids } from '../common/responsive.js';

function ContactScreen({ navigation, route }) {

    const { width } = useWindowDimensions();
    var onlyDigitReg = /^\d+$/;

    const scrollRef = useRef();

    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [cartLength, setcartLength] = useState('0');
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [description, setDescription] = useState('');
    const [descriptionError, setDescriptionError] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [invalidEmailError, setInvalidEmailError] = useState(false);
    const [phoneNo, setPhoneNo] = useState('');
    const [phoneNoError, setPhoneNoError] = useState(false);
    const [phoneNoDigitsError, setPhoneNoDigitsError] = useState(false);
    const [phoneOnlyDigitError, setPhoneNoOnlyDigitError] = useState(false);

    const WIGGLE_ROOM = 128;

    const offset = useMemo(() => {
        return {
            top: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2,
            left: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2
        };
    }, []);

    const [alertShow, setAlertShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    // modal hide/show
    const showAlert = (text) => {
        setAlertShow(true);
        setAlertMsg(text)
    };
    const hideAlert = () => setAlertShow(false);

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            cartListApi();
        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);

            // setLoading(true);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                    // setLoading(false);
                })
                .catch((error) => {
                    // setLoading(false);
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    // inquiry api
    const inquiryApi = async () => {
        try {

            if (name.trim().length == 0) {
                setNameError(true);
            }
            else if (description.trim().length == 0) {
                setDescriptionError(true);
            }
            else if (email.trim().length == 0) {
                setEmailError(true);
            }
            else if (!validateEmail(email.trim())) {
                setInvalidEmailError(true);
            }
            else if (phoneNo.trim().length == 0) {
                setPhoneNoError(true);
            }
            else if (phoneNo.length != 10) {
                setPhoneNoDigitsError(true);
            }
            else if (!onlyDigitReg.test(phoneNo)) {
                setPhoneNoOnlyDigitError(true);
            }
            else {
                try {

                    var raw = JSON.stringify({
                        "name": name,
                        "description": description,
                        "email": email,
                        "phoneNo": phoneNo,
                        "subject": "contact"
                    });

                    var requestOptions = {
                        method: 'POST',
                        body: raw,
                        redirect: 'follow'
                    };

                    CustomConsole(INQUIRY + "\n" + raw);

                    setLoading(true);
                    fetch(INQUIRY, requestOptions)
                        .then(response => response.json())
                        .then((json) => {
                            CustomConsole(json);

                            if (json.success == 1) {
                                setLoading(false);
                                showAlert(json.message);
                                setName("");
                                setEmail("");
                                setDescription("");
                                setPhoneNo("");

                            } else {
                                setLoading(false);
                                if (json.error.length > 0) {
                                    showAlert(json.error[0]);
                                }
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            CustomConsole("Error=>" + error);
                        });

                } catch (e) {
                    setLoading(false);
                    CustomConsole("Exception=>" + e + "");
                }
            }
        } catch (e) {
            setLoading(false);
            CustomConsole("Cms Page Exception=>" + e + "");
        }
    }

    return (
        <View>
            <StatusBar barStyle="dark-content" />
            <ScrollView ref={scrollRef} style={styles.container} dataSet={{ media: ids.container }} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent(cartLength, navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View style={styles.loginMainView} dataSet={{ media: ids.loginMainView }}>
                        <View style={styles.loginSubView} dataSet={{ media: ids.loginSubView }}>

                            {/* welcome to text view */}
                            <View style={styles.registerWelcomeText} dataSet={{ media: ids.registerWelcomeText }}>
                                <View>
                                    <Image source={require("../assets/header/register.png")}
                                        style={styles.loginImageIcon} dataSet={{ media: ids.loginImageIcon }} />
                                    <Text style={styles.loginWelcomeText} dataSet={{ media: ids.loginWelcomeText }}>{"Conatct Us"}</Text>
                                </View>
                                <Image source={require("../assets/header/register-img.png")}
                                    style={styles.loginBackgroundImage} dataSet={{ media: ids.loginBackgroundImage }} />
                            </View>
                            {/* end of welcome to text view */}

                            {/* form view */}
                            <View style={styles.loginTextInputView} dataSet={{ media: ids.loginTextInputView }}>
                                {/* <Text style={styles.loginFillDetailsText} dataSet={{ media: ids.loginFillDetailsText }}>Fill the Details to Sign in</Text> */}

                                {/* name view */}
                                <Text style={styles.loginLabelText} dataSet={{ media: ids.loginLabelText }}>Name</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    dataSet={{ media: ids.loginTextInput }}
                                    value={name}
                                    onChangeText={txt => { setName(txt); setNameError(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter Name" />
                                {nameError ? <Text style={styles.textInputError}>Enter name</Text> : null}


                                {/* description view */}
                                <Text style={styles.loginLabelText} dataSet={{ media: ids.loginLabelText }}>Description</Text>
                                <TextInput
                                    style={[styles.loginTextInput, { height: 100, padding: 10 }]}
                                    dataSet={{ media: ids.loginTextInput }}
                                    value={description}
                                    multiline
                                    onChangeText={txt => { setDescription(txt); setDescriptionError(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter description" />
                                {descriptionError ? <Text style={styles.textInputError}>Enter description</Text> : null}

                                {/* email address view */}
                                <Text style={styles.loginLabelText} dataSet={{ media: ids.loginLabelText }}>Email ID</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    dataSet={{ media: ids.loginTextInput }}
                                    value={email}
                                    onChangeText={txt => { setEmail(txt); setEmailError(false); setInvalidEmailError(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter Email address" />
                                {emailError ? <Text style={styles.textInputError}>Enter email address</Text> : null}
                                {invalidEmailError ? <Text style={styles.textInputError}>Invalid email address</Text> : null}

                                {/* phone no view */}
                                <Text style={styles.loginLabelText} dataSet={{ media: ids.loginLabelText }}>Phone No</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    dataSet={{ media: ids.loginTextInput }}
                                    value={phoneNo}
                                    onChangeText={txt => { setPhoneNo(txt.replace(/[^0-9]/g, '')); setPhoneNoError(false); setPhoneNoDigitsError(false); setPhoneNoOnlyDigitError(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter Phone No"
                                    keyboardType='numeric'
                                    inputMode='numeric'
                                    maxLength={10} />
                                {phoneNoError ? <Text style={styles.textInputError}>Enter phone no</Text> : null}
                                {phoneNoDigitsError ? <Text style={styles.textInputError}>Enter 10-digit phone no</Text> : null}
                                {phoneOnlyDigitError ? <Text style={styles.textInputError}>Phone no should be only digits</Text> : null}

                                {/* submit button view */}
                                <Pressable style={styles.loginBtn} onPress={inquiryApi}>
                                    <Text style={styles.loginText}>Submit</Text>
                                </Pressable>
                                {/* end of submit button view */}

                            </View>
                            {/* end of form view */}

                        </View>
                    </View>
                    {/* end of main view */}

                    {/* common modal */}
                    <Modal
                        onRequestClose={hideAlert}
                        transparent
                        visible={alertShow}
                        animationType={'slide'}
                    >
                        <View style={styles.commonModalMainView} dataSet={{ media: ids.commonModalMainView }}>
                            <View style={[styles.commonModalViewContainer, offset]} dataSet={{ media: ids.commonModalViewContainer }}>

                                <View>
                                    <Text style={styles.commonModalText}>{alertMsg}</Text>
                                </View>
                                <Pressable
                                    onPress={hideAlert}
                                    style={styles.commonModalButton}>
                                    <Text style={styles.commonModalButtonText}>Ok, Got it</Text>
                                </Pressable>
                            </View>
                        </View>
                    </Modal>
                    {/* end of common modal */}

                    {/* Footer view */}
                    {/* {FooterComponent(navigation, userId)}*/}
                    {/* end of Footer view */}

                </View>

            </ScrollView>
        </View>
    );
};

export default ContactScreen;

const tagsStyles = {
    p: styles.pTagWithBlackText,
    div: styles.divTag
}
