import React, { useState, useMemo } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, Image, Dimensions, FlatList, Modal } from 'react-native';
import { colors } from '../common/color';
import { APP_NAME } from '../common/string';
import { CustomConsole, CustomProgressBar, FooterComponent, HeaderComponent, getDMSansRegularFont, getDMSansSemiBoldFont, scrollToTop, validateEmail } from '../common/utils';
import { CARTLIST, DELETESHIPPING, LOGIN, ORDERLIST, SHIPPINGLIST } from '../common/webUtils';
import { useIsFocused } from '@react-navigation/native';
import { USER_ID, getSession } from '../common/LocalStorage';
import { Button, Dialog, Portal, PaperProvider, } from 'react-native-paper';
import { styles, ids } from '../common/responsive.js';

function ShippingAddressScreen({ navigation }) {

    const WIGGLE_ROOM = 128;

    const offset = useMemo(() => {
        return {
            top: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2,
            left: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2
        };
    }, []);

    const [alertShow, setAlertShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    // modal hide/show
    const showAlert = (text) => {
        setAlertShow(true);
        setAlertMsg(text)
    };
    const hideAlert = () => setAlertShow(false);

    // for dialog
    const [visible, setVisible] = React.useState(false);
    const showDialog = () => setVisible(true);
    const hideDialog = () => setVisible(false);

    const [loading, setLoading] = useState(false);
    const [addressId, setAddressId] = useState('');
    const [shippingAddressList, setShippingAddressList] = useState([]);
    const [userId, setUserId] = useState('');
    const [cartLength, setcartLength] = React.useState('0');

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            shippingAddressListApi();
            cartListApi();
        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);

            // setLoading(true);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                    // setLoading(false);
                })
                .catch((error) => {
                    // setLoading(false);
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    // shipping address list api
    const shippingAddressListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);

            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(SHIPPINGLIST + "&customer_id=" + user_id);

            setLoading(true);
            fetch(SHIPPINGLIST + "&customer_id=" + user_id, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    shippingAddressList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {
                        shippingAddressList.push({
                            index: i,
                            address_id: json.data[i].address_id,
                            firstname: json.data[i].firstname,
                            lastname: json.data[i].lastname,
                            company: json.data[i].company,
                            block: json.data[i].block,
                            apartment: json.data[i].apartment,
                            address_1: json.data[i].address_1,
                            address_2: json.data[i].address_2,
                            postcode: json.data[i].postcode,
                            city: json.data[i].city,
                            zone_id: json.data[i].zone_id,
                            zone: json.data[i].zone,
                            zone_code: json.data[i].zone_code,
                            country_id: json.data[i].country_id,
                            address_type: json.data[i].address_type,
                            country: json.data[i].country,
                            iso_code_2: json.data[i].iso_code_2,
                            iso_code_3: json.data[i].iso_code_3,
                            address_format: json.data[i].address_format,
                        });
                    }

                    setShippingAddressList(shippingAddressList);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Shipping Address Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Shipping Address Exception=>" + e + "");
        }
    }

    // address item view
    const shippingAddressItem = (item, index) => {
        return (
            <View style={styles.shippingAddItemView} dataSet={{ media: ids.shippingAddItemView }}>
                <View>
                    <Text style={styles.myAddTitleText}>{item.address_type} </Text>
                    <Text style={styles.myAddInfoName}>{item.firstname}  {item.lastname}</Text>
                    <Text style={styles.myAddInfoText}>{item.address_1},</Text>
                    <Text style={styles.myAddInfoText}>{item.address_2}, {item.postcode}</Text>
                    <Text style={styles.myAddInfoText}>{item.city}, {item.zone}, {item.country}</Text>
                </View>

                <View style={styles.myAddButtonView}>
                    <Pressable style={styles.myAddEditButton}
                        onPress={() => {
                            navigation.navigate("AddShippingAddressScreen", {
                                // paramItem: item,
                                paramFrom: "EditShipping", paramIndex: item.address_id
                            });
                        }}>
                        <Text style={styles.loginText}>Edit</Text>
                    </Pressable>
                    <Pressable style={styles.myAddDeleteButton} onPress={() => {
                        setAddressId(item.address_id)
                        showDialog()
                    }}>
                        <Text style={styles.loginText}>Delete</Text>
                    </Pressable>
                </View>
            </View>
        );
    }

    // delete shipping address api
    const deleteShippingAddressApi = async (address_id) => {
        try {

            const user_id = await getSession(USER_ID);

            var raw = JSON.stringify({
                "customer_id": user_id,
                "address_id": address_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
                redirect: 'follow'
            };

            CustomConsole(DELETESHIPPING + "\n");

            setLoading(true);
            fetch(DELETESHIPPING, requestOptions)
                .then(response => response.json())
                .then((json) => {

                    CustomConsole(json);
                    if (json.success == 1) {
                        setLoading(false);
                        // alert("Success");
                        hideDialog();
                        shippingAddressListApi();

                    } else {
                        setLoading(false);
                        if (json.error.length > 0) {
                            showAlert(json.error[0]);
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Error=>" + error);
                });

        } catch (e) {
            setLoading(false);
            CustomConsole("Shipping Address Exception=>" + e + "");
        }
    }

    return (
        <PaperProvider>
            <View style={{}}>
                <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                    <View style={{}}>

                        {/* progress view */}
                        {loading ? CustomProgressBar(loading) : null}
                        {/* end of progress view */}

                        {/* Header view */}
                        {HeaderComponent(cartLength, navigation, userId)}
                        {/* end of Header view */}

                        {/* main view */}
                        <View style={styles.myAddressMainView} dataSet={{ media: ids.myAddressMainView }}>

                            <View style={styles.myAddTitleView} dataSet={{ media: ids.myAddTitleView }}>
                                <Text style={styles.myAddTitle}>{"Address Management"}</Text>
                                {/* {shippingAddressList.length == 0 ? */}
                                <Pressable style={styles.myAddressAddButton}
                                    onPress={() => {
                                        // if (shippingAddressList.length != 0) {
                                        //     showAlert('You can only add one address for now. We will be opening up that provision very soon!');
                                        // } else {
                                        navigation.navigate("AddShippingAddressScreen", { paramFrom: "AddShipping" })
                                        // }
                                    }}>
                                    <Text style={styles.loginText}>Add Address</Text>
                                </Pressable>
                                {/* : null} */}
                            </View>

                            {/* shipping list view */}
                            {shippingAddressList.length == 0 ?
                                <View style={styles.noAddMsg}>
                                    <Text style={styles.noAddText}>{"No Addresses Found"}</Text>
                                </View> :
                                <FlatList style={styles.shippingFlat} dataSet={{ media: ids.shippingFlat }}
                                    showsVerticalScrollIndicator={false}
                                    data={shippingAddressList}
                                    keyExtractor={(item, id) => String(id)}
                                    ItemSeparatorComponent={<View style={styles.shippingFlatSeparator} />}
                                    renderItem={({ item, index }) => shippingAddressItem(item, index)} />
                            }

                            {/* <Pressable onPress={() => navigation.navigate("AddShippingAddressScreen", { paramFrom: "AddShipping" })} style={{ width: 45, height: 45, backgroundColor: colors.buttonColor, justifyContent: "center", alignItems: "center", borderRadius: 45 / 2, alignSelf: "flex-end", marginRight: 50, marginBottom: 50, position: "absolute", bottom: 0, end: 0 }}>
                                <Image source={require("../assets/plus.png")}
                                    style={{ width: 20, height: 20, tintColor: "white" }} resizeMode="contain" />
                            </Pressable> */}
                        </View>

                        {/* Footer view */}
                        {/* {FooterComponent(navigation, userId)}*/}
                        {/* end of Footer view */}

                    </View>

                </ScrollView>
                {/* <Portal>
                    <Dialog visible={visible} onDismiss={hideDialog} style={{ marginTop: Dimensions.get("window").height * 0.8, backgroundColor: colors.themeColor, alignSelf: "center" }}>
                        <Dialog.Title style={{ color: colors.white }}>Alert</Dialog.Title>
                        <Dialog.Content>
                            <Text variant="bodyMedium" style={{ color: colors.white }}>Are you sure to delete this address?</Text>
                        </Dialog.Content>
                        <View style={{ flexDirection: "row", alignSelf: "flex-end" }}>
                            <Dialog.Actions>
                                <Button onPress={hideDialog} textColor={colors.white}>No</Button>
                            </Dialog.Actions>
                            <Dialog.Actions>
                                <Button onPress={() => deleteShippingAddressApi(addressId)} textColor={colors.white}>Yes</Button>
                            </Dialog.Actions>
                        </View>
                    </Dialog>
                </Portal> */}


                <Portal>
                    <Dialog visible={visible} onDismiss={hideDialog} style={styles.myAccountDialogMainView}>
                        <Dialog.Title style={styles.myAccountDialogAlertText}>Alert</Dialog.Title>
                        <Dialog.Content>
                            <Text variant="bodyMedium" style={styles.myAccountDialogAlertText}>Are you sure to delete this address?</Text>
                        </Dialog.Content>
                        <View style={styles.myAccountDialogButtonView}>
                            <Dialog.Actions>
                                <Button onPress={hideDialog} textColor={colors.white} style={styles.myAccountDialogButtonText}>No</Button>
                            </Dialog.Actions>
                            <Dialog.Actions>
                                <Button onPress={() => deleteShippingAddressApi(addressId)} textColor={colors.white} style={styles.myAccountDialogButtonText}>Yes</Button>
                            </Dialog.Actions>
                        </View>
                    </Dialog>
                </Portal>

                {/* common modal */}
                <Modal
                    onRequestClose={hideAlert}
                    transparent
                    visible={alertShow}
                    animationType={'slide'}
                >
                    <View style={styles.commonModalMainView} dataSet={{ media: ids.commonModalMainView }}>
                        <View style={[styles.commonModalViewContainer, offset]} dataSet={{ media: ids.commonModalViewContainer }}>

                            <View>
                                <Text style={styles.commonModalText}>{alertMsg}</Text>
                            </View>
                            <Pressable
                                onPress={hideAlert}
                                style={styles.commonModalButton}>
                                <Text style={styles.commonModalButtonText}>Ok, Got it</Text>
                            </Pressable>
                        </View>
                    </View>
                </Modal>
                {/* end of common modal */}

            </View>
        </PaperProvider>
    );
};

export default ShippingAddressScreen;