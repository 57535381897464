import { Dimensions, } from 'react-native';
import { colors } from './color';
// import { getDMSansBoldFont, getDMSansLightFont, getDMSansRegularFont, getDMSansSemiBoldFont } from './utils';
import StyleSheet from 'react-native-media-query';

// custom fonts
export const getDMSansBoldFont = () => {
    return "DMSans-Bold"
}
export const getDMSansExtraBoldFont = () => {
    return "DMSans-ExtraBold"
}
export const getDMSansLightFont = () => {
    return "DMSans-Light"
}
export const getDMSansRegularFont = () => {
    return "DMSans-Regular"
}
export const getDMSansMediumFont = () => {
    return "DMSans-Medium"
}
export const getDMSansSemiBoldFont = () => {
    return "DMSans-SemiBold"
}
export const getDMSansThinFont = () => {
    return "DMSans-Thin"
}
export const getRobotoSlabBlackFont = () => {
    return "RobotoSlab-Black"
}
export const getRobotoSlabExtraBoldFont = () => {
    return "RobotoSlab-ExtraBold"
}
export const getRobotoSlabLightFont = () => {
    return "RobotoSlab-Light"
}
export const getRobotoSlabMediumFont = () => {
    return "RobotoSlab-Medium"
}
export const getRobotoSlabSemiBoldFont = () => {
    return "RobotoSlab-SemiBold"
}
export const getRobotoSlabThinFont = () => {
    return "RobotoSlab-Thin"
}

const { ids, styles } = StyleSheet.create({

    selectedDateContainerStyle: {
        height: 35,
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.buttonColor,
    },
    selectedDateStyle: {
        fontWeight: "bold",
        color: colors.white,
    },
    // drawer
    headerModalLinkText: {
        fontSize: 22, color: colors.black, paddingVertical: 8, fontFamily: getDMSansRegularFont()
    },
    drawerMainView: {
        // backgroundColor: '#ffffffab',
        backgroundColor: 'transparent',
    },
    drawerSubView: {
        // backgroundColor: '#ffffffab',
        // backgroundColor: colors.headerBackgroundcolor,
        width: Dimensions.get("window").width - 1000,
        height: Dimensions.get("window").height,
        alignSelf: "flex-end",
        right: -100,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            // right : 0,
            width: Dimensions.get("window").width
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            // right: 0,
            width: Dimensions.get("window").width
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            // right: 0,
            width: Dimensions.get("window").width
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            // right: 0,
            width: Dimensions.get("window").width
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            // right: 0,
            width: Dimensions.get("window").width
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            // right: 0,
            width: Dimensions.get("window").width
        },
    },
    drawerSubSubView: {
        backgroundColor: colors.headerBackgroundcolor, flex: 1, paddingLeft: 30, justifyContent: "space-between"
    },
    referCodeShareView: {
        alignSelf: "flex-start", flexDirection: "row", alignItems: "center",
    },
    logoutButtonsView: {
        flexDirection: "row", alignItems: "center", alignSelf: "center",
    },
    logoutNoButtonView: {
        alignSelf: "center", backgroundColor: colors.buttonColor, flexDirection: "row", alignItems: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 15, borderRadius: 5, marginTop: 20, marginRight: 15
    },
    drawerLogoMainView: {
        marginBottom: 100,
        // justifyContent: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            marginBottom: 50,
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            marginBottom: 50,
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            marginBottom: 50,
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginBottom: 50,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginBottom: 50,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginBottom: 50,
        },
    },
    drawerLogo: {
        height: 100,
        width: 100,
        alignSelf: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            alignSelf: "flex-start",
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            alignSelf: "flex-start",
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            alignSelf: "flex-start",
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            alignSelf: "flex-start",
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            alignSelf: "flex-start",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            alignSelf: "flex-start",
        },
    },
    headerModalLinkTextView: {
        paddingBottom: 10, paddingTop: 10
    },
    headerModalLinkBorder: {
        borderBottomWidth: 1, borderBottomColor: colors.borderColor,
    },
    //common
    container: {
        flex: 1, backgroundColor: colors.white
    },
    containerStyle: {
        width: '100%',
        height: '500px',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '100%',
        },
    },
    googleMapView: {
        marginTop: 10,
        marginBottom: 20,
        '@media only screen and (min-width:1399px)': {
            width: "60%", alignSelf: "center"
        },
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '100%',
        },
    },
    autoCompletePlace: {
        height: 47,
        backgroundColor: colors.white,
        borderWidth: 1,
        borderColor: "#9A9A9A",
        borderRadius: 5,
        fontSize: 12,
        fontWeight: "500",
        color: colors.textInputTextColor,
        lineHeight: 15,
        marginTop: 5,
        paddingLeft: 18,
        fontSize: 16,
        fontFamily: getDMSansRegularFont(), marginBottom: '15px',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '100%',
        },
    },
    textInputTitle: {
        fontFamily: getDMSansRegularFont(), fontSize: 16, display: 'block',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {

        },
    },
    textInputError: {
        fontFamily: getDMSansRegularFont(), fontSize: 14, display: 'block', color: "red", marginBottom: "10px",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {

        },
    },
    productHoverDetailsText: {
        fontSize: 16,
        width: "96%"
    },
    democstyle: {

    },

    // loginscreen
    loginPasswordView: {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: colors.white,
        borderWidth: 1,
        borderColor: "#9A9A9A",
        borderRadius: 5,
        marginTop: 5,
        paddingHorizontal: 18,
        marginBottom: '15px',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '100%',
        },
    },
    loginPasswordTextInput: {
        height: 47,
        fontSize: 12,
        fontWeight: "500",
        color: colors.textInputTextColor,
        lineHeight: 15,
        fontSize: 16,
        fontFamily: getDMSansRegularFont(),
        flex: 1,
        outline: "none"
    },
    loginPasswordIcon: {
        height: 30, width: 30, resizeMode: "contain", tintColor: "grey"
    },
    loginTextInput: {
        height: 47,
        backgroundColor: colors.white,
        borderWidth: 1,
        borderColor: "#9A9A9A",
        borderRadius: 5,
        fontSize: 12,
        fontWeight: "500",
        color: colors.textInputTextColor,
        lineHeight: 15,
        marginTop: 5,
        paddingHorizontal: 18,
        fontSize: 16,
        fontFamily: getDMSansRegularFont(), marginBottom: '15px',
        outline: "none",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '100%',
        },
    },
    loginBtn:
    {
        borderRadius: 10,
        height: 47,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.buttonColor,
        marginTop: 22
    },
    loginText: {
        color: colors.white,
        fontSize: 14,
        fontWeight: "600",
        textAlign: 'center',
        textTransform: "uppercase",
        fontFamily: getDMSansSemiBoldFont()
    },
    line: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: 20
    },
    lineText: {
        fontFamily: getDMSansSemiBoldFont(),
        fontSize: 11,
        lineHeight: 14,
        textAlign: 'center',
        color: "rgba(0, 0, 0, 0.5)",
        marginHorizontal: 13.3,
    },
    divider: {
        height: 1.07,
        width: 109.43,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        marginTop: 3
    },
    bottomContainer: {
        flexDirection: "row",
        alignSelf: "center",
        marginTop: 30,
    },
    colorBlackText: {
        fontWeight: "400",
        fontSize: 16,
        lineHeight: 18,
        color: "rgba(0, 0, 0, 0.5)",
        fontFamily: getDMSansRegularFont()
    },
    createAccountText: {
        fontWeight: "400",
        fontSize: 14,
        lineHeight: 18,
        color: colors.buttonColor,
        textDecorationLine: "underline",
        fontFamily: getDMSansRegularFont()
    },
    forgotPasswordContainer: {
        alignSelf: "flex-end",
        marginTop: '-5px'
    },
    forgotPasswordText: {
        color: colors.buttonColor,
        fontWeight: "400",
        fontSize: 14,
        lineHeight: 14,
        fontFamily: getDMSansRegularFont()
    },
    loginMainView: {
        borderWidth: 1, marginVertical: "5%", marginHorizontal: "25%", borderRadius: 30,
        '@media (max-width: 1399px)': {
            marginRight: '15%',
            marginLeft: '15%',
        },
        '@media (max-width: 1199px)': {
            marginRight: '15%',
            marginLeft: '15%',
        },
        '@media (max-width: 991px)': {
            marginRight: 15,
            marginLeft: 15,
        },
        '@media (max-width: 767px)': {
            marginRight: 15,
            marginLeft: 15,
        },
        '@media (max-width: 639px)': {
            marginRight: 15,
            marginLeft: 15,
        },
        '@media (max-width: 479px)': {
            marginRight: 15,
            marginLeft: 15,
        },
    },
    loginSubView: {
        flexDirection: "row",
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {
            flexDirection: "column",
        },
        '@media (max-width: 479px)': {
            flexDirection: "column"
        },
    },
    loginWelcomeTextView: {
        flex: 1.3, backgroundColor: colors.buttonColor, overflow: 'hidden', borderTopLeftRadius: 30, borderBottomLeftRadius: 30,
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {
            borderTopLeftRadius: 30, borderTopRightRadius: 30, borderBottomLeftRadius: 0, padding: 15,
        },
        '@media (max-width: 479px)': {
            borderTopLeftRadius: 30, borderTopRightRadius: 30, borderBottomLeftRadius: 0, padding: 15,
            flex: 'inherit'
        },
        '@media (max-width: 479px)': {
            borderTopLeftRadius: 30, borderTopRightRadius: 30, borderBottomLeftRadius: 0, padding: 15,
            flex: 'inherit'
        },
    },
    loginImageIcon: {
        width: 50, height: 50, marginLeft: 30, marginTop: 30,
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {
            marginLeft: 0, marginTop: 0,
        },
        '@media (max-width: 479px)': {
            marginLeft: 0, marginTop: 0,
        },
    },
    loginWelcomeText: {
        marginLeft: 30, color: colors.white, fontSize: 30, marginTop: 10, fontFamily: getDMSansBoldFont(),
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {
            marginLeft: 0,
        },
        '@media (max-width: 479px)': {
            marginLeft: 0,
        },
    },
    loginBackgroundImage: {
        width: "110%", height: 300, resizeMode: "cover", marginTop: 30, marginLeft: -30, bottom: -10,
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {
            display: 'none'
        },
        '@media (max-width: 479px)': {
            display: 'none'
        },
    },
    loginTextInputView: {
        flex: 1, paddingHorizontal: "5%", paddingVertical: "5%", justifyContent: "center"
    },
    loginFillDetailsText: {
        fontSize: 20, fontFamily: getDMSansBoldFont(), marginBottom: 15
    },
    loginLabelText: {
        fontFamily: getDMSansRegularFont(), fontSize: 16
    },
    changePasswordLabelText: {
        marginTop: 15, fontFamily: getDMSansRegularFont(), fontSize: 16
    },

    // register screen
    registerWelcomeText: {
        justifyContent: "space-between", flex: 1.3, backgroundColor: colors.buttonColor, overflow: 'hidden', borderTopLeftRadius: 30, borderBottomLeftRadius: 30,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            borderTopLeftRadius: 30, borderTopRightRadius: 30, borderBottomLeftRadius: 0, padding: 15, flex: 'inherit',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            borderTopLeftRadius: 30, borderTopRightRadius: 30, borderBottomLeftRadius: 0, padding: 15, flex: 'inherit',
        },
    },
    cityPicker: {
        marginTop: 5, paddingHorizontal: 10, backgroundColor: colors.white, height: 40, borderWidth: 1, borderRadius: 5, borderColor: '#9A9A9A', fontFamily: getDMSansRegularFont(), marginBottom: 10, textTransform: "capitalize"
    },
    cityItemPicker: {
        textTransform: "capitalize"
    },
    termNconditionView: {
        flexDirection: "row", alignItems: "center"
    },
    termNconditionText: {
        fontFamily: getDMSansSemiBoldFont(), fontSize: 15, marginLeft: 10, textDecorationLine: "underline"
    },

    // dashboard
    productItemWrapper: {
        padding: 25, width: "auto", height: 450, margin: 5, borderWidth: 1, borderRadius: 20, borderColor: colors.soothBorder2, backgroundColor: colors.soothBackground,
    },
    productItemText: {
        alignSelf: 'center', fontSize: 20,
    },
    productItemImg: {
        height: 100,
        width: 100,
    },
    //small banner item
    smallBannerActiveItemView: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: 'flex-end',
        borderWidth: 1,
        borderColor: colors.white,
        borderRadius: 360,
        padding: 5,
        backgroundColor: colors.imageBackgroundColor,
        width: 300,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            flexDirection: "column", width: '25%', justifyContent: 'flex-start',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flexDirection: "column", width: '25%', justifyContent: 'flex-start',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column", width: '25%', justifyContent: 'flex-start',
        },
    },
    smallBannerItemTitle: {
        fontSize: 16, fontWeight: 700, marginRight: '10px',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginRight: '0',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginRight: '0', textAlign: 'center', fontSize: 14, minHeight: '38px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginRight: '0', textAlign: 'center', fontSize: 14,
        },
    },
    smallBannerInActiveItemView: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: 'flex-end',
        borderWidth: 0,
        borderColor: colors.white,
        borderRadius: 360,
        padding: 5,
        backgroundColor: "transparent",
        width: 300,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            flexDirection: "column", width: '25%', justifyContent: 'flex-start', minHeight: '190px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flexDirection: "column", width: '25%', justifyContent: 'flex-start',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column", width: '25%', justifyContent: 'flex-start',
        },
    },
    smallBannerItemImageView: {
        borderRadius: 360, backgroundColor: colors.white, margin: '0 auto', width: '105px', height: '105px', padding: '22px',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            order: '-1', padding: '20px', marginBottom: '10px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            order: '-1', width: '90px', height: '90px', padding: '20px', marginBottom: '6px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            order: '-1', width: '70px', height: '70px', padding: '20px', marginBottom: '6px',
        },
    },
    smallBannerItemImage: {
        height: 60,
        width: 60,
        resizeMode: "contain",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            height: 50, width: 50,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            height: 30, width: 30,
        },
    },
    // question Item view
    questionActiveItemView: {
        backgroundColor: colors.buttonColor,
        borderWidth: 1,
        borderRadius: 30,
        paddingLeft: 5,
        paddingVertical: 5,
        marginRight: 20,
        marginBottom: 20,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            maxWidth: '610px', overflow: "hidden"
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            maxWidth: '445px', overflow: "hidden"
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            maxWidth: '290px', overflow: "hidden"
        },

    },
    questionInactiveItemView: {
        backgroundColor: colors.white,
        borderWidth: 1,
        borderRadius: 30,
        paddingLeft: 5,
        paddingVertical: 5,
        marginRight: 20,
        marginBottom: 20,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            maxWidth: '610px', overflow: "hidden"
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            maxWidth: '445px', fontSize: 10
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            maxWidth: '290px', fontSize: 10
        },
    },
    questionActiveItemText: {
        color: colors.white,
        fontSize: 20,
        fontFamily: getDMSansSemiBoldFont(),
    },
    questionInactiveItemText: {
        color: colors.black,
        fontSize: 20,
        fontFamily: getDMSansSemiBoldFont(),
    },
    questionItemIconView: {
        backgroundColor: colors.questionIconBackgroundColor, height: 27, width: 27, borderRadius: 20, justifyContent: "center", alignItems: "center"
    },
    questionItemIcon: {
        height: 15,
        width: 15, resizeMode: "contain"
    },
    questionActiveItemRightArrow: {
        marginHorizontal: 9,
        left: 5,
        height: 10,
        width: 10,
        resizeMode: "contain",
        tintColor: colors.white,
    },
    questionInactiveItemRightArrow: {
        marginHorizontal: 9,
        left: 5,
        height: 10,
        width: 10,
        resizeMode: "contain",
        tintColor: colors.questionRightArrowColor
    },
    // review item dashboard
    dashboardReviewItemMainFlexEndView: {
        width: "40%",
        borderRadius: 30,
        paddingHorizontal: 20,
        paddingVertical: 20,
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        marginBottom: 5,
        // height: 250,
    },
    dashboardReviewItemMainFlexEndViewMobile: {
        width: "100%",
        borderRadius: 10,
        paddingHorizontal: 20,
        paddingVertical: 20,
        backgroundColor: colors.white,
        marginBottom: 5,
        // height: 250,
    },
    dashboardReviewItemSubView: {
        flexDirection: "row", alignItems: "center",
    },
    dashboardReviewItemAutherView: {
        backgroundColor: colors.buttonColor + "33", height: 57, width: 57, borderRadius: 360, justifyContent: "center", alignItems: "center", borderWidth: 1, borderColor: colors.buttonColor
    },
    dashboardReviewItemAutherText: {
        fontSize: 20, color: colors.buttonColor, textAlign: "center", fontFamily: getDMSansBoldFont(), alignSelf: "center"
    },
    dashboardReviewItemReviewStarView: {
        marginLeft: 15, flex: 1
    },
    dashboardReviewItemAutherSecondText: {
        fontSize: 20, color: colors.black, textAlign: "left", fontFamily: getDMSansBoldFont()
    },
    dashboardReviewItemReviewStarSubView: {
        flexDirection: "row"
    },
    dashboardReviewActiveStar: {
        height: 15, width: 15, resizeMode: "contain",
        tintColor: colors.reviewStar
    },
    dashboardReviewInactiveStar: {
        height: 15, width: 15, resizeMode: "contain",
        tintColor: colors.borderColor
    },
    dashboardReviewQuoteImage: {
        height: 40,
        width: 40, resizeMode: "contain"
    },
    dashboardReviewText: {
        fontSize: 16, color: colors.black, textAlign: "left", marginTop: "5%", fontFamily: getDMSansRegularFont(), width: 900
    },
    // Story Item 

    storyItemMainView: {
        flexDirection: "row", paddingTop: '5%', paddingBottom: '5%', borderBottomWidth: 1, borderBottomColor: colors.blogBorderColor,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            flexDirection: "column",
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flexDirection: "column",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column",
        },
    },
    storyTitleView: {
        flex: 0.3,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            flex: 'inherit',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flex: 'inherit',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flex: 'inherit',
        },
    },
    storyTitleText: {
        fontSize: 15, color: colors.black, fontFamily: getDMSansRegularFont(),
    },

    storyItemImgView: {
        flex: 0.5, borderRadius: 20,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            order: -1, flex: 1, marginBottom: 15
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            order: -1, flex: 1, marginBottom: 15
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            order: -1, flex: 1, marginBottom: 15
        },
    },
    storyItemImg: {
        height: 200,
        width: "85%",
        // resizeMode: "contain",
        borderRadius: 20,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            width: "100%",
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: "100%",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: "100%",
        },
    },
    storyItemSubView: {
        flex: 1,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            flex: 'inherit',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flex: 'inherit',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flex: 'inherit',
        },
    },
    storyItemDetailText: {
        fontFamily: getDMSansMediumFont(), fontSize: 18, marginTop: -15
    },
    storyItemReadMoreButtonView: {
        marginTop: "13%", width: 150, backgroundColor: colors.buttonColor, flexDirection: "row", alignItems: "center", justifyContent: "center", paddingVertical: 15, paddingHorizontal: 30, borderRadius: 15
    },
    storyItemReadMoreText: {
        textAlign: 'center', fontSize: 16, color: colors.white, textTransform: "uppercase", fontFamily: getDMSansSemiBoldFont()
    },
    dashboardMainView: {
        backgroundColor: colors.backgroundColor
    },
    dashboardBannerListView: {
        backgroundColor: colors.backgroundColor, paddingVertical: "3%",
    },
    dashboardBannerListContainer: {
        flexDirection: "row",
        alignItems: 'center',
        paddingHorizontal: "15%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
            flexDirection: "column",
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
            flexDirection: "column",
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
            flexDirection: "column",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
            flexDirection: "column",
        },
    },
    dashboardBannerListWrapper: {
        width: '30%',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            width: '100%',
            display: 'inline-block'
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            width: '100%',
            display: 'inline-block'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: '100%',
            display: 'inline-block'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '100%',
            display: 'inline-block'
        },
    },
    dashboardBannerListTitle: {
        fontSize: 70, color: colors.buttonColor, fontFamily: getDMSansBoldFont(), lineHeight: '70px',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            display: 'block',
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            display: 'block', fontSize: 40, lineHeight: '40px',
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            display: 'block',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            display: 'block',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            display: 'block', fontSize: 50, lineHeight: '50px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            display: 'block', fontSize: 50, lineHeight: '50px',
        },
    },
    dashboardBannerListSubTitle: {
        fontSize: 25, color: colors.buttonColor, fontFamily: getDMSansSemiBoldFont(),
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            display: 'block',
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            display: 'block',
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            display: 'block',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            display: 'block',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            display: 'block',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            display: 'block',
        },
    },
    dashboardBannerViewItems: {
        borderLeftWidth: 3,
        marginTop: 30,
        paddingLeft: 10,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            width: '39.33%', display: 'inline-block', textAlign: 'left',
            //width:'33.33%' to width: '39.33%'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: '39.33%', display: 'inline-block', textAlign: 'left',
            //width:'33.33%' to width: '39.33%'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '39.33%', display: 'inline-block', textAlign: 'left',
            //width:'33.33%' to width: '39.33%'
        },
    },
    dashboardBannerViewItems1: {
        borderLeftWidth: 3,
        marginTop: 30,
        paddingLeft: 10,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            width: '20.33%', display: 'inline-block', textAlign: 'left',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: '20.33%', display: 'inline-block', textAlign: 'left',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '20.33%', display: 'inline-block', textAlign: 'left',
        },
    },

    dashboardBannerViewText: {
        fontSize: 16,
        color: colors.black,
        fontFamily: getDMSansMediumFont(), display: 'block',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            fontSize: 14,
        },
    },
    dashboardBannerViewTitleText: {
        fontSize: 35, color: colors.black, fontFamily: getDMSansMediumFont(),
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 21,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            fontSize: 21,
        },
    },
    dashboardBannerImage: {
        height: 500,
        width: '40%',
        resizeMode: "contain",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            height: 360,
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            height: 300,
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            height: 260, marginBottom: '30px', marginTop: '30px',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            height: 500,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            height: 400,
        },
    },
    dashboardFlatlist: {
        marginTop: "3%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            width: '100%'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: '100%'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '100%'
        },
    },
    dashboardSmallBannerList: {
        // flex: 1, alignItems: "flex-end",
        flexDirection: "column", width: '30%', alignItems: 'end',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            width: '100%',
            flexDirection: "row", alignItems: "flex-start"
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            width: '100%',
            flexDirection: "row", alignItems: "flex-start"
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: '100%',
            flexDirection: "row", alignItems: "flex-start"
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '100%',
            flexDirection: "row", alignItems: "flex-start"
        },
    },
    dashboardSmallBannerSeperator: {
        marginTop: 20
    },
    //Product List View
    productListMainView: {
        backgroundColor: colors.white,
        paddingVertical: "80px",
        paddingHorizontal: "15%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            paddingLeft: '14px',
            paddingRight: '14px',
            paddingTop: '40px',
            paddingBottom: '40px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            paddingLeft: '14px',
            paddingRight: '14px',
            paddingTop: '40px',
            paddingBottom: '40px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            paddingLeft: '14px',
            paddingRight: '14px',
            paddingTop: '40px',
            paddingBottom: '40px',
        },
    },
    productListMainTitle: {
        fontSize: 50, color: colors.black,
        textAlign: "center",
        fontFamily: getDMSansBoldFont(),
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 30,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            fontSize: 30,
        },
    },
    productListMainText: {
        fontSize: 16,
        color: colors.black,
        textAlign: "center",
        marginTop: "1%",
        paddingHorizontal: "15%",
        fontFamily: getDMSansRegularFont(),
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            paddingLeft: '0',
            paddingRight: '0',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            paddingLeft: '0',
            paddingRight: '0',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            paddingLeft: '0',
            paddingRight: '0',
        },
    },
    productListMainContainer: {
        flexDirection: "row",
        alignItems: "center",
        // alignSelf:"center",
        marginTop: 20,
        left: 20,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            flexDirection: "column",
            left: 0,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flexDirection: "column",
            left: 0,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column",
            left: 0,
        },
    },
    productListImageContainer: {
        flexDirection: "row",
        marginTop: 5,
    },
    productCartView: {
        flexDirection: "row", alignItems: "center", marginTop: "5%",
    },
    productNameText: {
        fontSize: 18, color: colors.white, fontFamily: getDMSansSemiBoldFont(),
    },
    productPriceText: {
        fontSize: 18, color: colors.white, marginLeft: 15, fontFamily: getDMSansSemiBoldFont()
    },
    addToCartView: {
        alignItems: 'center',
        marginLeft: 15,
    },
    addToCartButtonView: {
        backgroundColor: colors.white, flexDirection: "row", alignItems: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 15, borderRadius: 10,
    },
    addToCartButtonText: {
        textAlign: 'center', fontSize: 12, color: colors.black, textTransform: "uppercase", fontFamily: getDMSansSemiBoldFont(),
    },
    productListEnd: {
        marginTop: "3%", alignItems: 'center',
    },
    productListEndButton: {
        width: 200, backgroundColor: colors.buttonColor, flexDirection: "row", alignItems: "center", justifyContent: "center", paddingVertical: 15, paddingHorizontal: 30, borderRadius: 15,
    },
    productActiveReviewStar: {
        height: 10,
        width: 10, resizeMode: "contain",
        tintColor: colors.reviewStar
    },
    productInactiveReviewStar: {
        height: 10,
        width: 10, resizeMode: "contain",
        tintColor: colors.borderColor
    },
    productItemImageView: {
        position: "absolute",
        bottom: 0,
    },
    allproductButtonView: {
        marginTop: "3%", alignItems: 'center'
    },
    allproductButton: {
        width: 200, backgroundColor: colors.buttonColor, flexDirection: "row", alignItems: "center", justifyContent: "center", paddingVertical: 15, paddingHorizontal: 30, borderRadius: 15
    },
    allproductButtonText: {
        textAlign: 'center', fontSize: 16, color: colors.white, textTransform: "uppercase", fontFamily: getDMSansBoldFont()
    },
    //Sooth View 
    soothViewMain: {
        backgroundColor: colors.white, paddingVertical: "80px", paddingHorizontal: "15px", justifyContent: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            padding: '40px 15px'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            padding: '40px 15px'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            padding: '40px 15px'
        },
    },
    soothView: {
        flexDirection: "row", marginHorizontal: "1%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            marginLeft: '0',
            marginRight: '0',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginLeft: '0',
            marginRight: '0',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginLeft: '0',
            marginRight: '0',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column",
            marginLeft: '0',
            marginRight: '0',
        },
    },
    soothLetterS: {
        marginTop: "2%", marginHorizontal: "1%", width: Dimensions.get("window").width / 7, height: 350, backgroundColor: colors.white, borderWidth: 1, borderRadius: 360, borderColor: colors.soothBorder1, alignItems: "center", justifyContent: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            display: 'none'
        },
    },
    soothLetterTitle: {
        fontSize: 200, color: colors.soothFont, textAlign: "center", textTransform: "uppercase", fontFamily: getRobotoSlabExtraBoldFont(),
    },
    soothImageContainer1: {
        marginTop: "-5%", marginHorizontal: "1%", width: Dimensions.get("window").width / 7, height: 350, backgroundColor: colors.soothBackground, borderWidth: 1, borderRadius: 360, borderColor: colors.soothBorder2, alignItems: "center", justifyContent: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            marginLeft: '1%', marginRight: '1%', width: '48%', marginBottom: '20px', height: 240,
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginLeft: '1%', marginRight: '1%', width: '48%', marginBottom: '20px', height: 240,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginLeft: '1%', marginRight: '1%', width: '48%', marginBottom: '20px', height: 240,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginLeft: '0', marginRight: '0', width: '100%', marginBottom: '20px', height: 'auto', padding: '20px 60px',
        },
    },

    soothImageWrapper1: {
        backgroundColor: colors.buttonColor, height: 70, width: 70, borderRadius: 360, justifyContent: "center", alignItems: "center",
    },
    soothImage1: {
        height: 40,
        width: 40,
        resizeMode: "contain",
    },
    soothImageText1: {
        fontFamily: getDMSansBoldFont(), fontSize: 22, color: colors.black, textAlign: "center", marginTop: "1.5%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            fontSize: 16,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 16,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            fontSize: 16,
        },
    },
    soothLetterO: {
        marginTop: "1%", marginHorizontal: "1%", width: Dimensions.get("window").width / 7, height: 350, backgroundColor: colors.white, borderWidth: 1, borderRadius: 360, borderColor: colors.soothBorder1, alignItems: "center", justifyContent: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            display: 'none'
        },
    },
    soothLetterOText: {
        fontSize: 200, color: colors.soothFont, textAlign: "center", textTransform: "uppercase", fontFamily: getRobotoSlabExtraBoldFont()
    },

    soothImageContainer2: {
        marginTop: "0%", marginHorizontal: "1%", width: Dimensions.get("window").width / 7, height: 350, backgroundColor: colors.soothBackground, borderWidth: 1, borderRadius: 360, borderColor: colors.soothBorder2, alignItems: "center", justifyContent: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            marginLeft: '1%', marginRight: '1%', width: '48%', marginBottom: '20px', height: 240,
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginLeft: '1%', marginRight: '1%', width: '48%', marginBottom: '20px', height: 240,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginLeft: '1%', marginRight: '1%', width: '48%', marginBottom: '20px', height: 240,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginLeft: '0', marginRight: '0', width: '100%', marginBottom: '20px', height: 'auto', padding: '20px 60px',
        },
    },
    soothImageWrapper2: {
        backgroundColor: colors.buttonColor, height: 70, width: 70, borderRadius: 360, justifyContent: "center", alignItems: "center",
    },
    soothImage2: {
        height: 40,
        width: 40,
        resizeMode: "contain",
    },
    soothImageText2: {
        fontFamily: getDMSansBoldFont(), fontSize: 22, color: colors.black, textAlign: "center", marginTop: "1.5%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            fontSize: 16,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 16,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            fontSize: 16,
        },
    },

    soothLetterH: {
        marginTop: "-5%", marginHorizontal: "1%", width: Dimensions.get("window").width / 7, height: 350, backgroundColor: colors.white, borderWidth: 1, borderRadius: 360, borderColor: colors.soothBorder1, alignItems: "center", justifyContent: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            display: 'none'
        },
    },
    soothImageContainer3: {
        marginTop: "-2%", marginHorizontal: "1%", width: Dimensions.get("window").width / 7, height: 350, backgroundColor: colors.white, borderRadius: 360, alignItems: "center", justifyContent: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            display: 'none'
        },
    },
    soothImage3: {
        height: "100%",
        width: "100%",
        resizeMode: "contain",
    },
    //sooth View 2
    soothView2: {
        flexDirection: "row", marginHorizontal: "1%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            marginLeft: '0',
            marginRight: '0',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginLeft: '0',
            marginRight: '0',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginLeft: '0',
            marginRight: '0',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column",
            marginLeft: '0',
            marginRight: '0',
        },
    },
    soothImageContainer4: {
        marginTop: "2%", marginHorizontal: "1%", width: Dimensions.get("window").width / 7, height: 350, backgroundColor: colors.white, borderRadius: 360, alignItems: "center", justifyContent: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            display: 'none'
        },
    },
    soothImage4: {
        height: "100%",
        width: "100%",
        resizeMode: "contain",
    },
    soothLetterO2: {
        marginTop: "-2%", marginHorizontal: "1%", width: Dimensions.get("window").width / 7, height: 350, backgroundColor: colors.white, borderWidth: 1, borderRadius: 360, borderColor: colors.soothBorder1, alignItems: "center", justifyContent: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            display: 'none'
        },
    },
    soothImageContainer5: {
        marginTop: "2%", marginHorizontal: "1%", width: Dimensions.get("window").width / 7, height: 350, backgroundColor: colors.soothBackground, borderWidth: 1, borderRadius: 360, borderColor: colors.soothBorder2, alignItems: "center", justifyContent: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            marginLeft: '1%', marginRight: '1%', width: '48%', marginBottom: '20px', height: 240,
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginLeft: '1%', marginRight: '1%', width: '48%', marginBottom: '20px', height: 240,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginLeft: '1%', marginRight: '1%', width: '48%', marginBottom: '20px', height: 240,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginLeft: '0', marginRight: '0', width: '100%', marginBottom: '20px', height: 'auto', padding: '20px 60px',
        },
    },
    soothImageWrapper5: {
        backgroundColor: colors.buttonColor, height: 70, width: 70, borderRadius: 360, justifyContent: "center", alignItems: "center",
    },
    soothImage5: {
        height: 40,
        width: 40,
        resizeMode: "contain",
    },
    soothImageText5: {
        fontFamily: getDMSansBoldFont(), fontSize: 22, color: colors.black, textAlign: "center", marginTop: "1.5%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            fontSize: 16,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 16,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            fontSize: 16,
        },
    },
    soothLetterT: {
        marginTop: "1%", marginHorizontal: "1%", width: Dimensions.get("window").width / 7, height: 350, backgroundColor: colors.white, borderWidth: 1, borderRadius: 360, borderColor: colors.soothBorder1, alignItems: "center", justifyContent: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            display: 'none'
        },
    },
    soothImageContainer6: {
        marginTop: "5%", marginHorizontal: "1%", width: Dimensions.get("window").width / 7, height: 350, backgroundColor: colors.soothBackground, borderWidth: 1, borderRadius: 360, borderColor: colors.soothBorder2, alignItems: "center", justifyContent: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            marginLeft: '1%', marginRight: '1%', width: '48%', marginBottom: '20px', height: 240,
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginLeft: '1%', marginRight: '1%', width: '48%', marginBottom: '20px', height: 240,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginLeft: '1%', marginRight: '1%', width: '48%', marginBottom: '20px', height: 240,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginLeft: '0', marginRight: '0', width: '100%', marginBottom: '20px', height: 'auto', padding: '20px 60px',
        },
    },
    soothImageWrapper6: {
        backgroundColor: colors.buttonColor, height: 70, width: 70, borderRadius: 360, justifyContent: "center", alignItems: "center",
    },
    soothImage6: {
        height: 40,
        width: 40,
        resizeMode: "contain",
    },
    soothImageText6: {
        fontFamily: getDMSansBoldFont(), fontSize: 22, color: colors.black, textAlign: "center", marginTop: "1.5%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            fontSize: 16,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 16,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            fontSize: 16,
        },
    },
    soothLetterE: {
        marginTop: "1%", marginHorizontal: "1%", width: Dimensions.get("window").width / 7, height: 350, backgroundColor: colors.white, borderWidth: 1, borderRadius: 360, borderColor: colors.soothBorder1, alignItems: "center", justifyContent: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            display: 'none'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            display: 'none', height: 'auto',
        },
    },
    //Review View
    reviewViewContainer: {
        height: 860, backgroundColor: colors.backgroundColor, paddingVertical: "60px", paddingHorizontal: "15%", justifyContent: "center", alignItems: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            padding: '40px 15px'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            padding: '40px 15px'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            padding: '40px 15px'
        },
    },
    titleWrapper: {
        position: "absolute", alignItems: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            position: "relative",
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            position: "relative",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            position: "relative",
        },
    },
    titleText: {
        fontSize: Dimensions.get("window").height * 0.2, color: colors.buttonColor, textAlign: "center", textTransform: "uppercase", fontFamily: getDMSansBoldFont(),
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            fontSize: 60,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 60,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            fontSize: 60,
        },
    },
    //Image of Google 
    reviewImgWrapper: {
        flexDirection: "row", alignItems: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {

        },
    },
    reviewImgText: {
        fontSize: 15, color: colors.black, textAlign: "center", marginRight: 10, textAlignVertical: "center", fontFamily: getDMSansRegularFont(),
    },
    reviewImg: {
        height: 40,
        width: 70,
        // backgroundColor:"blue",
        resizeMode: "contain",
    },
    reviewFlatList: {
        marginTop: "3%", backgroundColor: 'transparent', width: "100%"
    },
    //FAQ view
    faqViewMain: {
        backgroundColor: colors.white, paddingVertical: "80px", paddingHorizontal: "15%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            paddingTop: '40px',
            paddingBottom: '40px',
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            paddingTop: '40px',
            paddingBottom: '40px',
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            paddingTop: '40px',
            paddingBottom: '40px',
            paddingLeft: '15px',
            paddingRight: '15px',
        },
    },
    faqText1: {
        fontSize: 50, color: colors.black, textAlign: "center", fontFamily: getDMSansBoldFont(),
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 30,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            fontSize: 30,
        },
    },
    faqText2: {
        fontSize: 16, color: colors.black, textAlign: "center", marginTop: "1%", paddingHorizontal: "15%", fontFamily: getDMSansRegularFont(),
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            paddingLeft: '15px', paddingRight: '15px', marginBottom: '20px',
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            paddingLeft: '15px', paddingRight: '15px', marginBottom: '20px',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            paddingLeft: '15px', paddingRight: '15px', marginBottom: '20px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            paddingLeft: '15px', paddingRight: '15px', marginBottom: '20px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            paddingLeft: '15px', paddingRight: '15px', marginBottom: '20px',
        },
    },
    faqFlatList: {
        flexDirection: 'row', flexWrap: 'wrap', marginTop: "3%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            flexDirection: 'column',
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            flexDirection: 'column',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            flexDirection: 'column',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flexDirection: 'column',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: 'column',
        },
    },
    faqButton: {
        marginTop: "3%", alignItems: 'center',
    },
    faqButtonText: {
        textAlign: 'center', fontSize: 16, color: colors.white, textTransform: "uppercase", fontFamily: getDMSansSemiBoldFont(),
    },

    //Blog view 
    blogViewMain: {
        backgroundColor: colors.blogBackgroundColor, paddingVertical: "80px", paddingHorizontal: "15%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            paddingTop: '40px',
            paddingBottom: '40px',
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            paddingTop: '40px',
            paddingBottom: '40px',
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            paddingTop: '40px',
            paddingBottom: '40px',
            paddingLeft: '15px',
            paddingRight: '15px',
        },
    },
    blogText1: {
        fontSize: 50, color: colors.black, textAlign: "center", fontFamily: getDMSansBoldFont(),
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 30,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            fontSize: 30,
        },
    },
    blogText2: {
        fontSize: 16, color: colors.black, textAlign: "center", marginTop: "1%", paddingHorizontal: "15%", fontFamily: getDMSansRegularFont(),
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            paddingLeft: '0',
            paddingRight: '0',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            paddingLeft: '0',
            paddingRight: '0',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            paddingLeft: '0',
            paddingRight: '0',
        },
    },
    blogFlatList: {
        marginTop: "3%",
    },
    blogButtonView: {
        marginTop: "3%", alignItems: 'center',
    },
    blogButton: {
        width: 200, backgroundColor: colors.buttonColor, flexDirection: "row", alignItems: "center", justifyContent: "center", paddingVertical: 15, paddingHorizontal: 30, borderRadius: 15,
    },
    blogButtonText: {
        textAlign: 'center', fontSize: 16, color: colors.white, textTransform: "uppercase", fontFamily: getDMSansSemiBoldFont(),
    },

    // common modal view

    commonModalMainView: {
        flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.5)',
    },
    commonModalViewContainer: {
        // alignItems: 'center',
        backgroundColor: 'white',
        borderColor: '#eee',
        borderRadius: 10,
        borderWidth: 1,
        justifyContent: 'space-between',
        margin: 'auto',
        padding: 30,
        width: "40%",
        left: 0,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            width: "90%", left: 0,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: "90%", left: 0,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: "90%", left: 0,
        },
    },
    commonModalText: {
        fontFamily: getDMSansMediumFont(), fontSize: 20, textAlign: "center"
    },
    commonModalButton: {
        alignSelf: "center", backgroundColor: colors.buttonColor, flexDirection: "row", alignItems: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 15, borderRadius: 5, marginTop: 20
    },
    commonModalButtonText: {
        textAlign: 'center', fontSize: 12, color: colors.white, textTransform: "uppercase", fontFamily: getDMSansSemiBoldFont(),
    },

    // modal view

    modalMainView: {
        flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalViewContainer: {
        alignItems: 'center',
        backgroundColor: 'white',
        borderColor: '#eee',
        borderRadius: 10,
        borderWidth: 1,
        justifyContent: 'space-between',
        margin: 'auto',
        padding: 30,
        width: "40%",
        left: 0,
        height: "auto",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            width: '90%', left: 0
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: '90%', left: 0
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '90%', left: 0, height: "500px",
        },

    },
    cardTitle: {
        fontFamily: getDMSansMediumFont(), fontSize: 20,
    },
    modalButton: {
        alignSelf: "center", backgroundColor: colors.buttonColor, flexDirection: "row", alignItems: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 15, borderRadius: 5,
    },
    modalButtonText: {
        textAlign: 'center', fontSize: 12, color: colors.white, textTransform: "uppercase", fontFamily: getDMSansSemiBoldFont(),
    },
    modalRenderHtmlView: {
        marginTop: 15, marginBottom: 15
    },
    modalRenderHtmlText: {
        fontSize: 17, fontFamily: getDMSansRegularFont()
    },
    //render html common styles
    pTagWithWhiteText: {
        color: 'white',
        fontFamily: getDMSansRegularFont(),
    },
    divTag: {
        width: '500px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    pTagWithBlackText: {
        color: 'black',
        fontFamily: getDMSansRegularFont(),
    },
    pTagWithBlackMediumText: {
        color: 'black',
        fontFamily: getDMSansMediumFont(),
    },
    common_edittext: {
        borderColor: 'gray',
        borderRadius: 5,
        borderWidth: 1,
        paddingLeft: 10,
        paddingRight: 10,
        fontSize: 18,
        paddingVertical: 10,
    },

    withoutHoverViewStyle: {
        left: -100,
        padding: 25,
        width: Dimensions.get("window").width / 5 - 20,
        height: 450,
        margin: 5,
        borderWidth: 1,
        borderRadius: 20,
        borderColor: colors.soothBorder2,
        backgroundColor: colors.soothBackground,
        flexDirection: "column",
        overflow: "hidden",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            width: '100%', left: 0,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: '100%', left: 0,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '100%', left: 0,
        },
    },
    hoverViewStyle: {
        left: -100,
        padding: 25,
        width: Dimensions.get("window").width / 5 + 100,
        height: 450,
        margin: 5,
        borderWidth: 1,
        borderRadius: 20,
        borderColor: colors.soothBorder2,
        backgroundColor: colors.buttonColor,
        flexDirection: "column",
        overflow: "hidden",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            width: '100%', left: 0,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: '100%', left: 0,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '100%', left: 0,
        },
    },
    withoutHoverTextStyle: {
        fontSize: 18,
        color: colors.black,
        fontFamily: getDMSansSemiBoldFont()
    },
    hoverTextStyle: {
        fontSize: 18,
        color: colors.white,
        fontFamily: getDMSansSemiBoldFont()
    },
    withoutHoverImageStyle: {
        height: 350,
        width: 190,
        resizeMode: "contain",
        position: "absolute",
        bottom: -40, marginTop: '20px',
    },
    hoverImageStyle: {
        height: 350,
        width: 190,
        resizeMode: "contain",
        position: "absolute",
        bottom: -130,

    },

    //Product list screen

    productScreenMainView: {
        //width: Dimensions.get("window").width / 4 - 20, 
        alignItems: "center", borderWidth: 0, width: '33.33%', marginBottom: '50px',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            width: '50%'
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            width: '100%'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: '100%'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '100%'
        },
    },

    productScreenLogoContainer: {
        alignItems: "center", justifyContent: "center",
    },
    productScreenLogoWrapper: {
        width: 230, height: 230, backgroundColor: colors.blogBackgroundColor, borderRadius: 360, position: "absolute", alignSelf: "center",
    },

    productReviewItemView: {
        borderBottomWidth: 1, paddingBottom: 30, borderBottomColor: colors.borderColor, paddingTop: 30,
    },
    productScreenItemListView: {
        marginTop: "10%", alignItems: "center",
    },
    listItemTitle: {
        fontSize: 20, marginBottom: 10, fontFamily: getDMSansMediumFont(),
    },
    listItemContainer: {
        flexDirection: "row", marginTop: 5, marginBottom: 10,
    },

    // related products list
    productDetailsScreenMainView: {
        width: Dimensions.get("window").width / 4 - 60, marginHorizontal: 10,
        alignItems: "center", borderWidth: 0, marginBottom: '50px',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            width: '100%'
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            width: '100%'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: '100%'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '100%'
        },
    },


    //Product Items Cards
    itemsCardView: {
        fontSize: 18, height: 43, flexDirection: "row", borderWidth: 1, borderColor: colors.soothBorder1, borderRadius: 10, marginTop: 20, marginBottom: 20, alignItems: "center",
    },
    itemsCardPicker: {
        paddingHorizontal: 10, backgroundColor: colors.white, height: 40, borderWidth: 0, borderRightWidth: 1, borderRadius: 10, borderColor: colors.soothBorder1, fontSize: 18,
        fontWeight: 400, color: '#858585',
    },
    itemsCardLines: {
        marginHorizontal: 10, fontSize: 18, fontFamily: getDMSansMediumFont(),
    },

    itemsCardCartButton: {
        backgroundColor: colors.buttonColor, flexDirection: "row", alignItems: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 18, borderRadius: 7, marginVertical: 15,
    },
    itemsCardCartText: {
        textAlign: 'center', fontSize: 18, color: colors.white, textTransform: "uppercase", fontFamily: getDMSansMediumFont(),
    },
    itemsCardDummyImage: {
        height: 290,
        width: 150,
        resizeMode: "contain"
    },
    itemCardRealImage: {
        height: '371px',
        width: 150,
        resizeMode: "contain"
    },
    itemReviewActiveStar: {
        height: 18,
        width: 18,
        resizeMode: "contain",
        tintColor: colors.reviewStar
    },
    itemReviewInactiveStar: {
        height: 18,
        width: 18,
        resizeMode: "contain",
        tintColor: colors.borderColor
    },
    mainProductListContainer: {
        backgroundColor: '#d6d1b3',
    },
    mainProductTextView: {
        backgroundColor: '#d6d1b3', paddingVertical: "3%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {

        },
    },
    mainProductText1: {
        fontSize: 40, color: colors.buttonColor, textAlign: "center", fontFamily: getDMSansBoldFont(),
    },
    mainProductText2: {
        fontSize: 18, color: colors.black, textAlign: "center", marginTop: "1%", paddingHorizontal: "30%", fontFamily: getDMSansRegularFont(),
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            paddingRight: '15px',
            paddingLeft: '15px'
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            paddingRight: '15px',
            paddingLeft: '15px'
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            paddingRight: '15px',
            paddingLeft: '15px'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            paddingRight: '15px',
            paddingLeft: '15px'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            paddingLeft: '15px',
            paddingRight: '15px'
        },
    },
    mainProductFlatList: {
        backgroundColor: colors.white, paddingHorizontal: "15%", paddingTop: 80, paddingBottom: 80,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            padding: '80px 15px'
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            padding: '80px 15px'
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            padding: '80px 15px'
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            padding: '40px 15px'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            padding: '40px 15px'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            padding: '40px 15px'
        },
    },

    mainProductLoadingView: {
        height: 200, justifyContent: "center",
    },
    mainProductLoadingText: {
        textAlign: 'center', fontSize: 20, color: colors.black, textTransform: "uppercase", fontFamily: getDMSansBoldFont(),
    },
    loadingTextBottomView: {
        height: 200,
    },

    // product details
    productDetailMainView: {
        paddingLeft: '14%', paddingRight: '14%', paddingBottom: '80px', paddingTop: '80px',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            padding: '40px 15px',
        },
    },

    productDetailSlider: {
        flexDirection: "row",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            flexDirection: "column",
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            flexDirection: "column",
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flexDirection: "column",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column",
        },
    },

    verticalImgList: {
        height: 400, width: '10%', overflow: 'auto',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            width: '100%', flexDirection: "row", height: 80, overflow: 'auto', marginBottom: '20px',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            width: '100%', flexDirection: "row", height: 80, overflow: 'auto', marginBottom: '20px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: '100%', flexDirection: "row", height: 80, overflow: 'auto', marginBottom: '20px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '100%', flexDirection: "row", height: 80, overflow: 'auto', marginBottom: '20px',
        },
    },
    singleImgContainer: {
        height: 400, width: '45%', backgroundColor: colors.white, justifyContent: "center", alignItems: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            order: '-1', width: '100%',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            order: '-1', width: '100%',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            order: '-1', width: '100%',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            order: '-1', width: '100%',
        },
    },
    singleImgWrapper: {
        alignItems: "center", justifyContent: "center",
    },
    singleImgView: {
        width: 270, height: 270, backgroundColor: colors.blogBackgroundColor, borderRadius: 360, position: "absolute", alignSelf: "center",
    },
    singleActiveImg: {
        height: '541px',
        width: 180,
        resizeMode: "contain",
    },
    singleInActiveImg: {
        height: 400,
        width: 180,
        resizeMode: "contain"
    },
    singleProductReviewActiveStar: {
        height: 10,
        width: 10,
        resizeMode: "contain",
        tintColor: colors.reviewStar
    },
    singleProductReviewInactiveStar: {
        height: 10,
        width: 10,
        resizeMode: "contain",
        tintColor: colors.borderColor
    },
    productDetailActiveTabView: {
        backgroundColor: colors.buttonColor,
        borderRadius: 7,
        paddingVertical: 10,
        paddingHorizontal: 20,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            paddingLeft: '10px', paddingRight: '10px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            paddingLeft: '10px', paddingRight: '10px',
        },
    },
    productDetailInactiveTabView: {
        backgroundColor: "transparent",
        borderRadius: 7,
        paddingVertical: 10,
        paddingHorizontal: 20,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            paddingLeft: '10px', paddingRight: '10px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            paddingLeft: '10px', paddingRight: '10px',
        },
    },
    productDetailActiveTabText: {
        textTransform: "uppercase",
        color: colors.white,
        fontFamily: getDMSansSemiBoldFont(),
        fontSize: 18,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 13,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            fontSize: 13,
        },
    },
    productDetailInactiveTabText: {
        textTransform: "uppercase",
        color: colors.black,
        fontFamily: getDMSansSemiBoldFont(),
        fontSize: 18,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 13,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            fontSize: 13,
        },
    },
    productDetailMainView1: {
        width: '45%',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            width: '100%',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            width: '100%',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: '100%',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '100%',
        },
    },
    productDetailName: {
        fontSize: 40, fontFamily: getDMSansBoldFont(),
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 30,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            fontSize: 30,
        },
    },
    productDetailContainer: {
        flexDirection: "row", alignItems: "center",
    },
    productDetailWrapper: {
        flexDirection: "row",
    },
    productDetailText: {
        fontSize: 16, fontFamily: getDMSansRegularFont(), marginLeft: 10, color: "grey",
    },

    productDetailPrice: {
        fontSize: 20, color: colors.black, marginTop: 15, fontFamily: getDMSansBoldFont(),
    },

    productSizeList: {
        flexDirection: "row", alignItems: "center", marginTop: 15,
    },

    productDescription: {
        marginTop: 20,
    },
    productOrderContainer: {
        flexDirection: "row", alignItems: "center", marginTop: 20,
    },
    productOrderButton: {
        backgroundColor: colors.buttonColor, flexDirection: "row", alignItems: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 30, borderRadius: 10,
    },
    productOrderText: {
        textAlign: 'center', fontSize: 16, color: colors.white, textTransform: "uppercase", fontWeight: "bold",
    },
    productShareButton: {
        // backgroundColor: colors.buttonColor, 
        backgroundColor: "#1550ba",
        padding: 12, borderRadius: 10, marginLeft: 10,
    },
    productShareImg: {
        height: 15,
        width: 15,
        resizeMode: "contain"
    },
    productMultipleShareBtnView: {
        alignSelf: "flex-start", marginTop: 15, flexDirection: "row", alignItems: "center", borderWidth: 1,
        borderColor: colors.darkGrey, borderRadius: 15
    },
    productMultipleShareButton: {
        margin: 5
    },
    //product description

    productDescriptionMainView: {
        borderWidth: 1, borderRadius: 20, borderColor: colors.borderColor, paddingBottom: 50, paddingHorizontal: 50, paddingTop: 30, marginTop: 70,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginTop: 40, padding: '20px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginTop: 40, padding: '20px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginTop: 40, padding: '10px',
        },
    },

    productDescriptionContainer: {
        alignSelf: "center", backgroundColor: colors.soothBackground, borderColor: colors.blogBorderColor, borderWidth: 1, padding: 2, borderRadius: 10, marginTop: 5, marginBottom: 20, flexDirection: 'row', alignItems: "center"
    },

    productDesText1: {
        fontSize: 28, color: colors.black, textAlign: "left", fontFamily: getDMSansSemiBoldFont()
    },
    productDesView: {
        marginTop: 20,
    },
    productInsideText: {
        fontSize: 15,
        fontFamily: getDMSansRegularFont()
    },
    productReceipeText: {
        fontSize: 15,
        fontFamily: getDMSansRegularFont()
    },
    productDescriptionText: {
        fontSize: 18,
        fontFamily: getDMSansRegularFont()
    },
    productLongDescriptionText: {
        fontSize: 18,
        fontFamily: getDMSansRegularFont()
    },
    productDesTextView: {
        justifyContent: "center"
    },
    productDesText2: {
        textAlign: 'center', fontSize: 20, color: colors.black, fontFamily: getDMSansRegularFont()
    },
    //product Reviews
    productRevMainView: {
        borderWidth: 1, borderRadius: 20, borderColor: colors.borderColor, paddingBottom: 50, paddingHorizontal: 50, paddingTop: 30, marginTop: 70,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            padding: '20px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            padding: '20px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            padding: '20px',
        },
    },
    productRevContainer: {
        alignSelf: "center", backgroundColor: colors.soothBackground, borderColor: colors.blogBorderColor, borderWidth: 1, padding: 2, borderRadius: 10, marginTop: 5, marginBottom: 20, flexDirection: 'row', alignItems: "center"
    },
    productRevFlatlist: {
        marginTop: 50, height: 500
    },
    productRevTextView: {
        height: 200, justifyContent: "center"
    },
    productRevText: {
        textAlign: 'center', fontSize: 20, color: colors.black, fontFamily: getDMSansRegularFont()
    },
    productRevImgView: {
        flexDirection: "row", alignSelf: "center"
    },

    //product rating
    addRatingTitle: {
        textAlign: 'center', fontSize: 13, color: colors.darkGrey, fontFamily: getDMSansRegularFont(), marginTop: 10, marginBottom: 20
    },
    clearRatingButton: {
        marginTop: 10, marginBottom: 20
    },
    clearRatingText: {
        textAlign: 'center', fontSize: 17, color: colors.buttonColor, fontFamily: getDMSansRegularFont()
    },
    addReview: {
        height: 100,
        backgroundColor: colors.white,
        borderWidth: 1,
        borderColor: "#9A9A9A",
        borderRadius: 5,
        padding: 5,
        fontSize: 14,
        fontWeight: "500",
        color: colors.textInputTextColor,
        lineHeight: 15,
        marginTop: 5,
        marginBottom: 10,
        paddingHorizontal: 18,
        fontFamily: getDMSansRegularFont(),
        verticalAlign: "top",
        outline: "none"
    },
    addReviewButton: {
        backgroundColor: colors.buttonColor, flexDirection: "row", alignItems: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 30, borderRadius: 10, marginHorizontal: 10, alignSelf: "center", marginTop: 20
    },
    addReviewText: {
        textAlign: 'center', fontSize: 12, color: colors.white, textTransform: "uppercase", fontFamily: getDMSansBoldFont()
    },

    //RELATED PRODUCTS 
    relatedProductView: {
        marginTop: 70
    },
    relatedProductTitle: {

        fontSize: 20, color: colors.black, textAlign: "center", fontFamily: getDMSansSemiBoldFont()
    },
    relatedProductContainer: {
        flexDirection: 'row', alignItems: 'center'
    },
    relatedImg1: {
        width: 30, height: 30, borderWidth: 1, borderRadius: 360, borderColor: colors.borderColor
    },
    relatedFlatList: {
        marginTop: 50,
    },
    relatedImg2: {
        width: 30, height: 30, borderWidth: 1, borderRadius: 360, borderColor: colors.borderColor
    },
    productDetailsActiveImage: {
        borderRadius: 360,
        backgroundColor: colors.white,
        padding: 15,
        borderWidth: 1,
        height: 80,
        width: 80,
        borderColor: colors.buttonColor,
        alignItems: "center", justifyContent: "center", alignSelf: "center", margin: 10,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            display: 'inline-block'
        },
    },
    productDetailsInactiveImage: {
        borderRadius: 360,
        backgroundColor: colors.white,
        padding: 15,
        borderWidth: 1,
        height: 80,
        width: 80,
        borderColor: colors.borderColor,
        alignItems: "center", justifyContent: "center", alignSelf: "center", margin: 10
    },
    productImageItem: {
        height: 60,
        width: 60,
        resizeMode: "contain",
        borderRadius: 360,
    },
    //product Review item view 

    productReviewItemView: {
        borderBottomWidth: 1, paddingBottom: 30, borderBottomColor: colors.borderColor, paddingTop: 30,
    },
    productReviewItemContainer: {
        flexDirection: "row",
    },

    productReviewImgContainer: {
        marginLeft: 10, flex: 1
    },
    productReviewImgTitle: {
        fontSize: 15, color: colors.black, textAlign: "left", fontFamily: getDMSansBoldFont(),
    },
    productReviewImgList: {
        flexDirection: "row",
    },
    productReviewDate: {
        fontSize: 11, fontFamily: getDMSansRegularFont(), marginLeft: 10, color: "grey",
    },
    productReviewLines: {
        fontSize: 14, color: colors.black, textAlign: "left", marginTop: 10, fontFamily: getDMSansRegularFont(),
    },
    productReviewSubView: {
        flexDirection: "row",
    },
    productReviewAutherFirstLetterView: {
        height: 35, width: 35, borderRadius: 20, justifyContent: "center", alignItems: "center", borderWidth: 1,
    },
    productReviewAutherFirstLetterText: {
        fontSize: 15, textAlign: "center", fontFamily: getDMSansBoldFont(), alignSelf: "center"
    },
    productDetailsReviewActiveStar: {
        height: 13,
        width: 13, resizeMode: "contain", tintColor: colors.reviewStar
    },
    productDetailsReviewInactiveStar: {
        height: 13,
        width: 13, resizeMode: "contain", tintColor: colors.borderColor
    },
    productDetailsActiveSizeView: {
        marginRight: 15,
        backgroundColor: colors.blogBackgroundColor,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: colors.buttonColor,
        paddingVertical: 10,
        paddingHorizontal: 20,
        width: 100,
        alignItems: "center"
    },
    productDetailsInactiveSizeView: {
        marginRight: 15,
        backgroundColor: colors.white,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: colors.darkGrey,
        paddingVertical: 10,
        paddingHorizontal: 20,
        width: 100,
        alignItems: "center"
    },
    productDetailsActiveSizeText: {
        textTransform: "uppercase",
        color: colors.buttonColor,
        fontFamily: getDMSansSemiBoldFont(),
        fontSize: 11
    },
    productDetailsInactiveSizeText: {
        textTransform: "uppercase",
        color: colors.darkGrey,
        fontFamily: getDMSansSemiBoldFont(),
        fontSize: 11
    },
    addReviewActiveStar: {
        height: 30,
        width: 30,
        resizeMode: "contain",
        tintColor: colors.reviewStar
    },
    addReviewInactiveStar: {
        height: 30,
        width: 30,
        resizeMode: "contain",
        tintColor: colors.borderColor
    },
    relatedProductSubContainer: {
        padding: 10,
    },
    addWalletView: {
        paddingVertical: 10, marginTop: 20, justifyContent: "space-between", flexDirection: "row", alignItems: "center"
    },
    addWalletText: {
        fontSize: 25, color: colors.black, textAlign: "left", fontFamily: getDMSansSemiBoldFont()
    },
    addWalletTextInput: {
        height: 50,
        backgroundColor: colors.white,
        borderWidth: 1,
        borderColor: "#9A9A9A",
        borderRadius: 5,
        padding: 5,
        fontSize: 14,
        fontWeight: "500",
        color: colors.textInputTextColor,
        lineHeight: 15,
        marginTop: 5,
        marginBottom: 10,
        paddingHorizontal: 18,
        fontFamily: getDMSansRegularFont(),
        width: "50%",
        alignSelf: "center",
        outline: "none",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            width: "100%"
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            width: "100%"
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: "100%"
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: "100%"
        },
    },
    addWalletButtonView: {
        backgroundColor: colors.buttonColor, flexDirection: "row", alignItems: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 30, borderRadius: 10, marginHorizontal: 10, alignSelf: "center", marginTop: 20
    },
    addWalletButtonText: {
        textAlign: 'center', fontSize: 12, color: colors.white, textTransform: "uppercase", fontFamily: getDMSansBoldFont()
    },

    // header
    headerMainStyle: {
        flexDirection: "row", alignItems: "center", justifyContent: "space-between", backgroundColor: colors.headerBackgroundcolor, paddingHorizontal: "14%", paddingVertical: 22,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            paddingLeft: "15px",
            paddingRight: "15px",
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            paddingLeft: "15px",
            paddingRight: "15px",
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            paddingLeft: "15px",
            paddingRight: "15px",
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            paddingLeft: "15px",
            paddingRight: "15px",
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            paddingLeft: "15px",
            paddingRight: "15px",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            paddingLeft: "15px",
            paddingRight: "15px",
        },
    },
    logoMainView: {
        alignItems: "center"
    },
    logoSubView: {
        marginTop: -90, height: 110, width: 110, backgroundColor: colors.headerBackgroundcolor, borderRadius: 360, alignItems: "center", justifyContent: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginTop: -30,
        },

    },
    logoImage: {
        height: 90,
        width: 90,
    },
    headerProductView: {
        backgroundColor: colors.buttonColor, flexDirection: "row", alignItems: "center", paddingVertical: 12, paddingHorizontal: 30, borderRadius: 15,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            paddingLeft: "10px",
            paddingRight: "10px",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            paddingLeft: "10px",
            paddingRight: "10px",
        },
    },
    headerOurProductText: {
        alignSelf: 'center', fontSize: 12, color: colors.white, textTransform: "uppercase", fontFamily: getDMSansBoldFont(),
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 11,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            fontSize: 11,
        },
    },
    headerOurProductIcon: {
        marginLeft: 5,
        height: 10,
        width: 10,
        resizeMode: "contain",
        tintColor: colors.white
    },
    topIconView: {
        flexDirection: 'row', alignItems: 'center'
    },
    topIconSubView: {
        marginHorizontal: 15,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginLeft: "8px",
            marginRight: "8px",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginLeft: "8px",
            marginRight: "8px",
        },
    },
    topIcon: {
        height: 25,
        width: 25, resizeMode: "contain"
    },
    cartTextView: {
        backgroundColor: colors.buttonColor, borderRadius: 12, width: 15, height: 15, justifyContent: "center", position: "absolute", right: -10, top: -10
    },
    cartText: {
        alignSelf: 'center', fontSize: 10, color: colors.white, textTransform: "uppercase", fontWeight: "bold"
    },
    topMenuIconView: {
        marginHorizontal: 15, marginLeft: 20,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginLeft: "8px",
            marginRight: "8px",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginLeft: "8px",
            marginRight: "8px",
        },
    },
    topMenuIcon: {
        height: 40,
        width: 40, resizeMode: "contain"
    },
    // footer
    footerMainView: {
        backgroundColor: colors.footerBackgroundColor,
    },
    footerSubView: {
        backgroundColor: colors.footerSubViewColor, marginTop: 10, paddingHorizontal: "15%", paddingTop: 30, paddingBottom: "7%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            paddingLeft: "15px",
            paddingRight: "15px",
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            paddingLeft: "15px",
            paddingRight: "15px",
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            paddingLeft: "15px",
            paddingRight: "15px",
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            paddingLeft: "15px",
            paddingRight: "15px",
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            paddingLeft: "15px",
            paddingRight: "15px",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            paddingLeft: "15px",
            paddingRight: "15px",
        },
    },
    footerFirstSubView: {
        flexDirection: "row", alignItems: "center", borderBottomWidth: 2, borderBottomColor: colors.footerBorderColor, paddingBottom: "3%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            flexDirection: "column",
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flexDirection: "column",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column",
        },
    },
    footerLogoView: {
        flex: 0.3, alignItems: "flex-start",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginBottom: '20px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginBottom: '20px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginBottom: '20px',
        },
    },
    footerLogo: {
        height: 100,
        width: 100,
    },
    footerGeneralInfoView: {
        flexDirection: 'row', alignItems: "center", flex: 1,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            flexDirection: "column",
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flexDirection: "column",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column",
        },
    },
    footerGeneralInfoSubView: {
        flexDirection: 'row', alignItems: "center", flex: 1,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            flexDirection: "column",
            marginBottom: '50px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flexDirection: "column",
            marginBottom: '50px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column",
            marginBottom: '50px',
        },
    },
    footerGeneralInfoIconView: {
        backgroundColor: colors.footerIconBackgroundColor, height: 47, width: 47, borderRadius: '50%', justifyContent: "center", alignItems: "center", borderColor: 'white', borderWidth: '1px',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginBottom: '20px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginBottom: '20px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginBottom: '20px',
        },
    },
    footerGeneralInfoIcon: {
        height: 20,
        width: 20, resizeMode: "contain"
    },
    footerGeneralInfoIconText: {
        marginLeft: 10, alignSelf: 'center', fontSize: 16, color: colors.footerFontColor, fontFamily: getDMSansMediumFont(), paddingRight: '20px',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginLeft: '0',
            textAlign: 'center',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginLeft: '0',
            textAlign: 'center',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginLeft: '0',
            textAlign: 'center',
        },
    },
    footerSecondSubView: {
        flexDirection: "row", alignItems: "center", paddingTop: "7%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            flexDirection: "column",
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flexDirection: "column",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column",
        },
    },
    footerSecondSection: {
        flex: 1, borderRightWidth: 2, borderRightColor: colors.footerBorderColor,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            borderRightWidth: 0,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            borderRightWidth: 0,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            borderRightWidth: 0,
        },
    },
    footerSecondSectionWithMargin: {
        flex: 1, borderRightWidth: 2, borderRightColor: colors.footerBorderColor, paddingLeft: "3%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            borderRightWidth: 0, paddingLeft: "0%",
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            borderRightWidth: 0, paddingLeft: "0%",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            borderRightWidth: 0, paddingLeft: "0%",
        },
    },
    footerSecondSectionWithoutBorder: {
        flex: 1, paddingLeft: "3%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            paddingLeft: "0%",
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            paddingLeft: "0%",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            paddingLeft: "0%",
        },
    },
    footerSectionTitle: {
        fontSize: 18, color: colors.white, marginBottom: 20, textTransform: "uppercase", fontFamily: getDMSansBoldFont(),
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            textAlign: 'center'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            textAlign: 'center'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            textAlign: 'center'
        },
    },
    footerSectionText: {
        fontSize: 16, color: colors.footerFontColor, marginBottom: 20, fontFamily: getDMSansMediumFont(),
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            textAlign: 'center'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            textAlign: 'center'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            textAlign: 'center'
        },
    },
    footerSocialIconView: {
        flexDirection: "row", alignItems: "center", marginBottom: 20
    },
    footerSocialIcon: {
        height: 15,
        width: 15, resizeMode: "contain", tintColor: colors.footerFontColor
    },
    footerSocialText: {
        fontSize: 14, color: colors.footerFontColor, fontFamily: getDMSansMediumFont(), marginLeft: 10
    },
    copyRightView: {
        flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingVertical: 20, paddingHorizontal: "15%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            paddingLeft: '15px',
            paddingRight: '15px'
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            paddingLeft: '15px',
            paddingRight: '15px'
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            paddingLeft: '15px',
            paddingRight: '15px'
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            flexDirection: "column",
            paddingLeft: '15px',
            paddingRight: '15px'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flexDirection: "column",
            paddingLeft: '15px',
            paddingRight: '15px'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column",
            paddingLeft: '15px',
            paddingRight: '15px'
        },
    },
    copyRightText: {
        alignSelf: 'center', fontSize: 16, color: colors.black, fontFamily: getDMSansRegularFont()
    },
    // custom progress bar
    customProgressbarView: {
        flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.5)'
    },
    customProgressbarSubView: {
        alignItems: 'center',
        borderColor: '#eee',
        borderRadius: 10,
        height: 300,
        margin: 'auto',
        padding: 30,
        width: 300
    },
    customProgressbarLogo: {
        height: 100,
        width: 100,
    },
    loadingText: {
        fontWeight: "400",
        fontSize: 30,
        lineHeight: 18,
        color: colors.white,
        textAlign: "center",
        marginTop: 20,
        marginLeft: 10,
        fontFamily: getDMSansBoldFont()
    },
    // CHECKOUT SCREEN

    shippingAddressView: {
        width: "50%", margin: 5, flexDirection: "row", borderWidth: 1, borderRadius: 10, borderColor: colors.borderColor, padding: 10,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            width: "95%"
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            width: "95%"
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            width: "95%"
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            width: "95%"
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: "95%"
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: "95%"
        },

    },
    addressDetailsSubView: {
        flex: 1
    },
    addressType: {
        fontSize: 20, marginLeft: 20, fontFamily: getDMSansBoldFont()
    },
    addressLineText: {
        fontSize: 15, marginLeft: 20, fontFamily: getDMSansRegularFont()
    },
    availableAddressLineText: {
        fontSize: 15, marginLeft: 5, fontFamily: getDMSansRegularFont()
    },
    //productInfo
    csProductMainView1: {
        borderWidth: 1, borderColor: colors.blogBorderColor, marginVertical: "80px", marginHorizontal: "30%", borderRadius: 30, paddingHorizontal: 30,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            marginLeft: '12%',
            marginRight: '12%',
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            marginLeft: '8%',
            marginRight: '8%',
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            marginLeft: '15px',
            marginRight: '15px',
            marginTop: '40px',
            marginBottom: '40px',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginLeft: '15px',
            marginRight: '15px',
            marginTop: '40px',
            marginBottom: '40px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginLeft: '15px',
            marginRight: '15px',
            marginTop: '40px',
            marginBottom: '40px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginLeft: '15px',
            marginRight: '15px',
            marginTop: '40px',
            marginBottom: '40px',
        },
    },

    csProductContainer: {
        borderBottomWidth: 1,
        borderBottomColor: colors.blogBorderColor,
        paddingVertical: 30,
    },
    csProductInfoContainer: {
        flexDirection: 'row',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: 'column',
        },
    },
    csProductImageContainer: {
        borderWidth: 1,
        borderColor: colors.blogBorderColor,
        padding: 7,
        borderRadius: 360,
        width: 100,
        height: 100,
        alignItems: 'center',
        justifyContent: 'center',
    },
    csProductImage: {
        width: 50,
        height: 72,
        resizeMode: 'contain',
    },
    csProductDetailsContainer: {
        marginLeft: 15, flex: 1,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginLeft: '0px',
        },
    },
    csProductName: {
        textAlign: 'left',
        fontSize: 30,
        color: colors.black,
        // fontWeight: 'bold',
        fontFamily: getDMSansExtraBoldFont()
    },
    csProductPrice: {
        textAlign: 'left',
        fontSize: 20,
        color: colors.black,
        fontWeight: 'bold',
        marginTop: 10,
        fontFamily: getDMSansBoldFont()
    },
    csProductSize: {
        textAlign: 'left',
        fontSize: 18,
        color: "#6d6d6d",
        marginTop: 10,
        fontFamily: getDMSansRegularFont()
    },

    //CheckOut Info
    checkOutInfoView: {
        borderBottomWidth: 1, borderBottomColor: colors.blogBorderColor, paddingVertical: 30
    },

    purchaseTypeContainer: {
        alignSelf: 'flex-start',
        backgroundColor: colors.soothBackground,
        borderColor: colors.blogBorderColor,
        borderWidth: 1,
        padding: 5,
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center', width: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center', marginBottom: '20px',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            display: 'block',
        },
    },
    // Buy once 

    quantityContainer: {
        marginTop: 15,
    },
    quantityRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    quantityLabel: {
        textAlign: 'left',
        fontSize: 20,
        color: colors.black,
        fontFamily: getDMSansSemiBoldFont(),
    },
    quantityValue: {
        paddingHorizontal: 20,
        textAlign: 'left',
        fontSize: 15,
        color: colors.black,
        fontFamily: getDMSansSemiBoldFont(),
    },
    quantityControlContainer: {
        borderWidth: 1,
        borderColor: colors.blogBorderColor,
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 20,
        borderRadius: 10,
    },
    quantityControlButton: {
        top: -1,
        paddingVertical: 0,
        paddingHorizontal: 15,
    },
    quantityText: {

        textAlign: 'left',
        fontSize: 30,
        color: colors.black,
        fontFamily: getDMSansSemiBoldFont(),
    },

    // Subscribe type
    subscribeTypeContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: -10,
    },
    subscribeOption: {
        flexDirection: 'row',
        alignItems: 'center',

    },
    radioButton: {
        fontFamily: getDMSansSemiBoldFont(),
        fontSize: 16,
    },

    //instructions

    instructionsContainer: {
        backgroundColor: colors.white,
        borderColor: colors.blogBorderColor,
        borderWidth: 0,
        padding: 0,
        borderRadius: 10,
        marginTop: 20,
    },
    instructionsTitle: {
        textAlign: 'left',
        fontSize: 20,
        color: colors.black,
        fontFamily: getDMSansBoldFont(),
    },
    instructionsText: {
        textAlign: 'left',
        fontSize: 18,
        color: colors.black,
        marginTop: 10,
        fontFamily: getDMSansRegularFont(),
    },
    varyingInstructionsText: {
        marginTop: 2,
    },

    //qty
    varyingQuantityContainer: {
        marginTop: 20,
    },
    varyingQtyRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    varyingQtyText: {
        textAlign: 'left',
        fontSize: 20,
        color: colors.black,
        fontFamily: getDMSansSemiBoldFont(),
    },
    varyingQtyText2: {
        textAlign: 'left',
        fontSize: 30,
        color: colors.black,
        fontFamily: getDMSansSemiBoldFont(),
    },
    varyingQtyInputContainer: {
        borderWidth: 1,
        borderColor: colors.blogBorderColor,
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 20,
        borderRadius: 10,
    },
    varyingQtyControl: {
        top: -1,
        paddingVertical: 0,
        paddingHorizontal: 15,
    },
    varyingQtyValue: {
        paddingHorizontal: 20,
        textAlign: 'left',
        fontSize: 15,
        color: colors.black,
        fontFamily: getDMSansSemiBoldFont(),
    },

    //start and end Dates 
    startDateContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 30,
    },
    pauseDateContainer: {
        marginTop: 30,
    },
    startDateText: {
        textAlign: 'left',
        fontSize: 20,
        color: colors.black,
        fontFamily: getDMSansSemiBoldFont(),
    },
    startDateInput: {
        marginLeft: 15,
        borderWidth: 1,
        borderColor: colors.blogBorderColor,
        borderRadius: 10,
        padding: 10,
        fontSize: 14,
        fontFamily: getDMSansRegularFont(),
    },

    endDateContainer: {
        marginTop: 30,
    },
    endDateText: {
        textAlign: 'left',
        fontSize: 20,
        color: colors.black,
        fontFamily: getDMSansSemiBoldFont(),
    },
    totalAmountText: {
        textAlign: 'left',
        fontSize: 20,
        color: colors.black,
        fontFamily: getDMSansSemiBoldFont(),
        marginTop: 15
    },
    radioButtonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 10,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    radioButton: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    radioButtonLabel: {
        fontFamily: getDMSansSemiBoldFont(),
        fontSize: 16,
    },
    //delivery Info
    deliveryInfoContainer: {
        borderBottomWidth: 1,
        borderBottomColor: colors.blogBorderColor,
        paddingVertical: 30,
    },
    deliveryInfoRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    deliveryInfoIcon: {
        borderWidth: 1,
        borderColor: colors.blogBorderColor,
        padding: 7,
        borderRadius: 360,
    },
    deliveryInfoIconImage: {
        // width: 15, height: 15, tintColor: colors.buttonColor, resizeMode: "contain"
        width: 16,
        height: 16,
        tintColor: colors.buttonColor,
        resizeMode: "contain",
    },
    deliveryInfoText: {
        textAlign: 'left',
        fontSize: 20,
        color: colors.black,
        marginLeft: 5,
        fontWeight: 'bold',
    },
    deliveryAddressText: {
        textAlign: 'left',
        color: colors.shippingAddress,
        fontSize: 18,
        marginTop: 10,
        fontFamily: getDMSansRegularFont(),

    },
    addAddressButton: {
        marginTop: 30,
        width: 180,
        backgroundColor: colors.white,
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 10,
        paddingHorizontal: 0,
        borderRadius: 7,
        borderWidth: 1,
        borderColor: colors.buttonColor,
    },
    addAddressButtonText: {
        textAlign: 'center',
        fontSize: 16,
        color: colors.buttonColor,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        marginLeft: 5,
    },

    //Add item

    addItemContainer: {
        paddingVertical: 30,
    },
    addItemButton: {
        width: 180,
        backgroundColor: colors.buttonColor,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        paddingVertical: 10,
        paddingHorizontal: 0,
        borderRadius: 7,
        borderWidth: 1,
        borderColor: colors.buttonColor,
    },
    addItemIcon: {
        width: 16,
        height: 16,
        tintColor: colors.white,
        resizeMode: "contain",
    },
    addItemButtonText: {
        textAlign: 'center',
        fontSize: 16,
        color: colors.white,
        textTransform: "uppercase",
        fontWeight: "bold",
        marginLeft: 5,
    },

    //address modal
    modalContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        backgroundColor: 'white',
        borderColor: '#eee',
        borderRadius: 10,
        borderWidth: 1,
        justifyContent: 'space-between',
        margin: 'auto',
        padding: 30,
        width: '70%',
        alignSelf: 'center',
        maxHeight: "60%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            width: "90%", left: 0,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: "90%", left: 0,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: "90%", left: 0,
        },
    },
    modalTitle: {
        fontFamily: getDMSansMediumFont(),
        fontSize: 20,
        marginBottom: 20,
    },
    noAddressContainer: {
        height: 300,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noAddressText: {
        fontSize: 20,
        marginLeft: 20,
    },
    modalButtonsContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-end',
    },
    addButton: {
        alignSelf: 'center',
        backgroundColor: colors.buttonColor,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderRadius: 5,
    },
    closeButton: {
        alignSelf: 'center',
        backgroundColor: colors.buttonColor,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderRadius: 5,
        marginLeft: 10,
    },
    buttonText: {
        textAlign: 'center',
        fontSize: 12,
        color: colors.white,
        textTransform: 'uppercase',
        fontFamily: getDMSansBoldFont(),
        fontWeight: 'bold',
    },
    coActivePurchaseTypeView: {
        backgroundColor: colors.buttonColor,
        borderRadius: 10, paddingVertical: 10, paddingHorizontal: 20,
    },
    coInactivePurchaseTypeView: {
        backgroundColor: "transparent",
        borderRadius: 10, paddingVertical: 10, paddingHorizontal: 20,
    },
    coActivePurchaseTypeText: {
        textTransform: "uppercase",
        color: colors.white,
        fontFamily: getDMSansSemiBoldFont(), fontSize: 16,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 14,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {

        },
    },
    coInactivePurchaseTypeText: {
        textTransform: "uppercase",
        color: colors.black,
        fontFamily: getDMSansSemiBoldFont(), fontSize: 16,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 14,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {

        },
    },
    // addshipping address
    addShippingAddMainView: {
        marginVertical: "80px", paddingHorizontal: "15%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
            marginTop: "40px",
            marginBottom: "40px",
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
            marginTop: "40px",
            marginBottom: "40px",
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
            marginTop: "40px",
            marginBottom: "40px",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
            marginTop: "40px",
            marginBottom: "40px",
        },
    },
    fillAddressTextView: {
        marginBottom: 30
    },
    fillAddressText: {
        fontSize: 25, color: colors.black, textAlign: "left", fontFamily: getDMSansSemiBoldFont()
    },
    addressFlexRowView: {
        flexDirection: "row", alignItems: "flex-start", marginHorizontal: -15,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column",
            margin: '0'
        },
    },
    addressFlexOneView: {
        flex: 1, margin: 10,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            display: 'block', margin: '0 0 10px', width: '100%'
        },
    },
    addressCountryPicker: {
        marginTop: 5, paddingHorizontal: 10, backgroundColor: colors.white, height: 40, borderWidth: 1, borderRadius: 5, borderColor: '#9A9A9A', fontFamily: getDMSansRegularFont(), marginBottom: "15px",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            display: 'block', margin: '0 0 10px', width: '100%'
        },
    },
    addressTypeView: {
        marginHorizontal: -6
    },
    addressTypeSubView: {
        flexDirection: "row", alignItems: "center"
    },
    addressTypeSubSubView: {
        flexDirection: "row", alignItems: "center"
    },
    addressTypeText: {
        fontFamily: getDMSansRegularFont(), fontSize: 15
    },
    saveAddressView: {
        alignSelf: "center"
    },
    saveAddressButton: {
        borderRadius: 10,
        height: 47,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.buttonColor,
        marginTop: 22,
        paddingHorizontal: 30
    },

    // order list
    orderItemContainerView: {
        borderWidth: 1, borderColor: colors.borderColor, borderRadius: 30, paddingTop: 25, paddingBottom: 25,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            margin: 0,
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            margin: 0,
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            margin: 0,
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            margin: 0,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            margin: 0,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            margin: 0,
        },
    },
    orderItemMainView: {
        width: "auto", flexDirection: "row", paddingBottom: 25,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            margin: 0,
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            margin: 0,
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            margin: 0,
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            margin: 0,
            flexDirection: "column",
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            margin: 0,
            flexDirection: "column",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            margin: 0,
            flexDirection: "column",
        },
    },
    orderItemDateView: {
        flex: 0.3, borderRightWidth: 1, borderRightColor: colors.borderColor, paddingHorizontal: 15, alignItems: "first baseline",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            flex: 0.4,
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginBottom: '10px', flex: 'inherit', borderRight: 'none'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginBottom: '10px', flex: 'inherit', borderRight: 'none'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginBottom: '10px', flex: 'inherit', borderRight: 'none'
        },
    },
    orderItemDateText: {
        fontSize: 18, fontFamily: getDMSansMediumFont(), color: colors.grey
    },
    orderItemDetails: {
        flex: 0.5, borderRightWidth: 1, borderRightColor: colors.borderColor, paddingHorizontal: 15,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            flex: 'inherit', borderBottomColor: '#CCC', borderBottomWidth: '1px', borderTopColor: '#CCC', borderTopWidth: '1px', paddingTop: '15px', paddingBottom: '15px', borderRight: 'none'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flex: 'inherit', borderBottomColor: '#CCC', borderBottomWidth: '1px', borderTopColor: '#CCC', borderTopWidth: '1px', paddingTop: '15px', paddingBottom: '15px', borderRight: 'none'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flex: 'inherit', borderBottomColor: '#CCC', borderBottomWidth: '1px', borderTopColor: '#CCC', borderTopWidth: '1px', paddingTop: '15px', paddingBottom: '15px', borderRight: 'none'
        },
    },
    logItemDetails: {
        flex: 0.5, borderRightWidth: 0, borderRightColor: colors.borderColor, paddingHorizontal: 15,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            // flex: 'inherit', borderBottomColor: '#CCC', borderBottomWidth: '1px', borderTopColor: '#CCC', borderTopWidth: '1px', paddingTop: '15px', paddingBottom: '15px', borderRight: 'none'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            // flex: 'inherit', borderBottomColor: '#CCC', borderBottomWidth: '1px', borderTopColor: '#CCC', borderTopWidth: '1px', paddingTop: '15px', paddingBottom: '15px', borderRight: 'none'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            // flex: 'inherit', borderBottomColor: '#CCC', borderBottomWidth: '1px', borderTopColor: '#CCC', borderTopWidth: '1px', paddingTop: '15px', paddingBottom: '15px', borderRight: 'none'
        },
    },
    orderItemOrderNoText: {
        fontSize: 20, fontFamily: getDMSansRegularFont(),
    },
    orderItemOrderNoBoldText: {
        fontSize: 20, fontFamily: getDMSansBoldFont(),
    },
    orderItemOrderDetailsText: {
        fontSize: 18, fontFamily: getDMSansRegularFont(), marginTop: 10
    },
    orderItemOrderDetailsBoldText: {
        fontSize: 18, fontFamily: getDMSansBoldFont(),
    },
    orderItemOrderDetailsPendingStatusText: {
        fontSize: 18, fontFamily: getDMSansBoldFont(), color: "#edbe94", backgroundColor: "#ffefe0", padding: 5, borderRadius: 5
    },
    orderItemOrderDetailsPauseStatusText: {
        fontSize: 18, fontFamily: getDMSansBoldFont(), color: "#63A5DE", backgroundColor: "#E3F2FF", padding: 5, borderRadius: 5
    },
    orderItemOrderDetailsCancelledStatusText: {
        fontSize: 18, fontFamily: getDMSansBoldFont(), color: "#e91010", backgroundColor: "#ffeaea", padding: 5, borderRadius: 5
    },
    orderItemOrderDetailsOtherStatusText: {
        fontSize: 18, fontFamily: getDMSansBoldFont(), color: "#338300", backgroundColor: "#CBFFAB", padding: 5, borderRadius: 5
    },
    orderItemProductDetailsView: {
        // marginLeft: 20
        flex: 1, paddingHorizontal: 15
    },
    orderItemProductDetailsViewWithBorder: {
        flexDirection: "row",
        // marginTop: 10,
        borderBottomWidth: 1,
        borderBottomColor: colors.borderColor,
        paddingBottom: 10, paddingTop: 10
    },
    orderModifyButtonBtn: {
        backgroundColor: colors.buttonColor, alignSelf: "flex-start", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 10, borderRadius: 5, marginHorizontal: 5, marginTop: 20,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            margin: '20px auto 0'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            margin: '20px auto 0'
        },
    },
    orderItemProductDetailsViewWithoutBorder: {
        flexDirection: "row",
        // marginTop: 10,
        borderBottomWidth: 0,
        paddingVertical: 10
    },
    orderItemProductImageView: {
        borderWidth: 1, borderColor: colors.borderColor, borderRadius: 15, width: 120, justifyContent: "center", alignItems: "center"
    },
    orderItemProductImage: {
        width: 80, height: 80, resizeMode: "contain"
    },
    orderItemProductDetailsSubView: {
        flex: 1, marginLeft: 10
    },
    orderItemProductName: {
        fontSize: 20, color: colors.black, textAlign: "left", fontFamily: getDMSansRegularFont(),
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            textAlign: "center", marginBottom: '10px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            textAlign: "center", marginBottom: '10px',
        },
    },
    orderHistoryItemText: {
        fontSize: 18, color: colors.black, textAlign: "left", fontFamily: getDMSansRegularFont(), marginRight: 10,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            textAlign: "left", marginBottom: '10px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            textAlign: "left", marginBottom: '10px',
        },
    },
    orderItemProductQty: {
        fontSize: 16, fontFamily: getDMSansRegularFont(), color: colors.grey
    },
    orderItemProductTotal: {
        fontSize: 18, fontFamily: getDMSansRegularFont(),
        // marginTop: 15
    },
    orderItemProductTotalBold: {
        fontSize: 18, fontFamily: getDMSansBoldFont(),
        // marginTop: 15
    },
    orderItemProductTotalQty: {
        fontSize: 18, fontFamily: getDMSansRegularFont(), marginTop: 15
    },
    orderItemProductTotalQtyBold: {
        fontSize: 18, fontFamily: getDMSansBoldFont(), marginTop: 15
    },
    orderListMainView: {
        marginVertical: "80px", paddingHorizontal: "15%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
            marginTop: "40px",
            marginBottom: "40px",
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
            marginTop: "40px",
            marginBottom: "40px",
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
            marginTop: "40px",
            marginBottom: "40px",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
            marginTop: "40px",
            marginBottom: "40px",
        },
    },
    orderHeadingTextView: {
        paddingVertical: 10, justifyContent: "space-between", flexDirection: "row", alignItems: "center"
    },
    orderHeadingText: {
        fontSize: 25, color: colors.black, textAlign: "left", fontFamily: getDMSansSemiBoldFont()
    },
    orderHeadingSubContainer: {
        flexDirection: "row", alignItems: "center"
    },
    orderFlatListView: {
        marginTop: 30
    },
    orderItemSeperator: {
        height: 40
    },
    orderEmptyView: {
        height: 200, justifyContent: "center"
    },
    orderEmptyText: {
        textAlign: 'center', fontSize: 20, color: colors.black, textTransform: "uppercase", fontFamily: getDMSansBoldFont()
    },
    orderItemButtonsView: {
        flexDirection: "column", alignItems: "center", justifyContent: 'end',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            justifyContent: 'flex-start',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            justifyContent: 'flex-start',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            justifyContent: 'flex-start',
        },
    },
    orderItemView: {
        flex: 0.3,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            flex: 'inherit',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flex: 'inherit',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flex: 'inherit',
        },
    },
    orderItemButton: {
        backgroundColor: colors.buttonColor, alignSelf: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 10, borderRadius: 10, marginHorizontal: 5, marginTop: 20
    },
    orderButtonText: {
        textAlign: 'center', fontSize: 12, color: colors.white, textTransform: "uppercase", fontFamily: getDMSansBoldFont()
    },
    orderItemCancelButton: {
        backgroundColor: "#ffeaea", alignSelf: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 10, borderRadius: 5, marginHorizontal: 5, marginTop: 0, borderColor: "#ffacac", borderWidth: 1, width: "70%"
    },
    orderItemCancelButtonText: {
        textAlign: 'center', fontSize: 12, color: "#e91010", textTransform: "uppercase", fontFamily: getDMSansBoldFont()
    },
    orderItemModifyButton: {
        backgroundColor: "#e3f2ff", alignSelf: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 10, borderRadius: 10, marginHorizontal: 5, marginTop: 20, borderColor: "#a4d3fa", borderWidth: 1, width: "70%"
    },
    orderItemModifyButtonText: {
        textAlign: 'center', fontSize: 12, color: "#1074c9", textTransform: "uppercase", fontFamily: getDMSansBoldFont()
    },
    orderItemPauseButton: {
        backgroundColor: "#ffefe0", alignSelf: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 10, borderRadius: 10, marginHorizontal: 5, marginTop: 20, borderColor: "#ffbf86", borderWidth: 1, width: "70%"
    },
    orderItemPauseButtonText: {
        textAlign: 'center', fontSize: 12, color: "#ff7800", textTransform: "uppercase", fontFamily: getDMSansBoldFont()
    },
    orderModifyTextButton: {
        justifyContent: 'center',
        paddingVertical: 10,
        borderRadius: 10,
        marginHorizontal: 15,
        alignSelf: "flex-start"
    },
    orderModifyBlueText: {
        textAlign: 'center',
        fontSize: 15,
        color: colors.buttonColor,
        textTransform: 'uppercase',
        fontFamily: getDMSansBoldFont(),
        textDecorationLine: "underline"
    },
    orderOneTimeQtyUpdateView: {
        paddingHorizontal: 15, paddingTop: 25,
    },
    orderOneTimeQtyUpdateSubViewWithBorder: {
        borderBottomColor: colors.borderColor,
        borderBottomWidth: 1,
    },
    orderOneTimeQtyUpdateSubViewWithoutBorder: {
        borderBottomColor: colors.borderColor,
        borderBottomWidth: 0,
    },
    orderVacationHistorySubViewWithBorder: {
        borderBottomColor: colors.borderColor,
        borderBottomWidth: 1,
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: 5
    },
    orderVacationHistorySubViewWithoutBorder: {
        borderBottomColor: colors.borderColor,
        borderBottomWidth: 0,
        flexDirection: "row",
        alignItems: "center",
        paddingVertical: 5
    },
    orderVacationHistoryModalSubView: {
        backgroundColor: 'white',
        borderColor: '#eee',
        borderRadius: 10,
        borderWidth: 1,
        justifyContent: 'space-between',
        margin: 'auto',
        padding: 30,
        width: "50%",
        alignSelf: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            left: '0px',
            width: "90%",
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            left: '0px',
            width: "90%",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            left: '0px',
            width: "90%",
        },
    },
    orderVacationHistoryView: {
        flexDirection: "row", alignItems: "center", justifyContent: "space-between",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flexDirection: "column",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column",
        },
    },
    orderOneTimeQtyView: {
        flexDirection: "row", alignItems: "center", marginBottom: 10, marginTop: 10,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flexDirection: "column", borderBlockColor: '#CCC', borderBottomWidth: '1px', paddingBottom: '10px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column", borderBlockColor: '#CCC', borderBottomWidth: '1px', paddingBottom: '10px',
        },
    },
    orderOneTimePickerConatiner: {
        flex: 1, alignItems: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginBottom: "20px", width: "100%"
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginBottom: "20px", width: "100%"
        },
    },
    orderOneTimePickerCardView: {
        fontSize: 18, height: 43, flexDirection: "row", borderWidth: 1, borderColor: colors.soothBorder1, borderRadius: 10, alignItems: "center",
    },
    orderOneTimeCardPicker: {
        paddingHorizontal: 10, backgroundColor: colors.white, height: 40, borderWidth: 0, borderRightWidth: 0, borderRadius: 10, borderColor: colors.soothBorder1, fontSize: 18,
        fontWeight: 400, color: '#858585',
    },
    orderOneTimeQtyText: {
        fontSize: 18, fontFamily: getDMSansMediumFont(), color: colors.grey, flex: 1,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            margin: '10px 0',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            margin: '10px 0',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            margin: '10px 0',
        },
    },
    orderOneTimeQtyChangeView: {
        flexDirection: "row", alignItems: 'center', flex: 1
    },
    orderOneTimeQtyChangeButtonView: {
        flex: 1, flexDirection: "row", alignItems: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginTop: "10px", width: "100%"
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginTop: "10px", width: "100%"
        },
    },


    orderHeadingView: {
        paddingVertical: 10, justifyContent: "space-between", flexDirection: "row", alignItems: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column",
        },
    },
    orderHeadingText: {
        fontSize: 25, color: colors.black, textAlign: "left", fontFamily: getDMSansSemiBoldFont()
    },
    orderHeadingLinkText: {
        fontSize: 17, color: colors.buttonColor, textAlign: "left", fontFamily: getDMSansSemiBoldFont()
    },
    cancelButtonView: {
        backgroundColor: colors.buttonColor, alignSelf: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 10, borderRadius: 10,
    },
    logButtonView: {
        backgroundColor: colors.buttonColor, alignSelf: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 10, borderRadius: 10, marginLeft: 10
    },
    cancelButtonText: {
        textAlign: 'center', fontSize: 12, color: colors.white, textTransform: "uppercase", fontFamily: getDMSansBoldFont()
    },
    orderModifyModalMainView: {
        flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.5)',
    },
    orderModifyModalSubView: {
        backgroundColor: 'white',
        borderColor: '#eee',
        borderRadius: 10,
        borderWidth: 1,
        justifyContent: 'space-between',
        margin: 'auto',
        padding: 30,
        width: "70%",
        alignSelf: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            left: '0px',
            width: "90%",
            top: '0px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            left: '0px',
            width: "90%",
            top: '0px'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            left: '0px',
            width: "90%",
            top: '0px',
        },
    },
    orderSingleModifyModalSubView: {
        backgroundColor: 'white',
        borderColor: '#eee',
        borderRadius: 10,
        borderWidth: 1,
        justifyContent: 'space-between',
        margin: 'auto',
        padding: 30,
        width: "70%",
        alignSelf: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            left: '0px',
            width: "90%",
            flex: 'inherit',
            top: '0px',

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            left: '0px',
            width: "90%",
            flex: 'inherit',
            top: '0px',
            height: "50%"
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            left: '0px',
            width: "90%",
            flex: 'inherit',
            top: '0px',
            height: "50%"
        },
    },
    orderPauseModalSubView: {
        backgroundColor: 'white',
        borderColor: '#eee',
        borderRadius: 10,
        borderWidth: 1,
        justifyContent: 'space-between',
        margin: 'auto',
        padding: 30,
        width: "30%",
        alignSelf: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            left: '0px',
            width: "90%",
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            left: '0px',
            width: "90%",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            left: '0px',
            width: "90%",
        },
    },
    orderModifyText: {
        fontFamily: getDMSansMediumFont(), fontSize: 20,
    },
    orderModifyCloseIcon: {
        height: 30, width: 30
    },
    orderModifyModalButtonView: {
        flexDirection: "row", alignItems: "center", alignSelf: "flex-end",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            alignSelf: "center", marginTop: '20px'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            alignSelf: "center", marginTop: '20px'
        },
    },
    orderConfirmCancelModalButtonView: {
        flexDirection: "row", alignItems: "center", alignSelf: "center", marginTop: '20px',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            alignSelf: "center", marginTop: '20px'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            alignSelf: "center", marginTop: '20px'
        },
    },
    orderModifyButton: {
        backgroundColor: colors.buttonColor, alignSelf: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 10, borderRadius: 5, marginHorizontal: 5,
    },
    orderModifyButtonText: {
        textAlign: 'center', fontSize: 12, color: colors.white, textTransform: "uppercase", fontFamily: getDMSansBoldFont()
    },
    orderModifyButtonView: {
        alignSelf: "center", backgroundColor: colors.buttonColor, flexDirection: "row", alignItems: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 15, borderRadius: 5, marginLeft: 10, flex: 1,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginLeft: 0, marginTop: '10px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginLeft: 0, marginTop: '10px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginLeft: 0, marginTop: '10px',
        },
    },
    orderModifyPauseButtonView: {
        alignSelf: "center", backgroundColor: colors.buttonColor, flexDirection: "row", alignItems: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 15, borderRadius: 5, marginLeft: 10, flex: 1,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginLeft: 10, marginTop: '10px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginLeft: 10, marginTop: '10px',
        },
    },
    orderCloseButton: {
        alignSelf: "center", backgroundColor: colors.buttonColor, flexDirection: "row", alignItems: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 15, borderRadius: 5, marginLeft: 10,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginLeft: 0, marginTop: '10px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginLeft: 0, marginTop: '10px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginLeft: 0, marginTop: '10px',
        },
    },
    modifyProductItemMainView: {
        flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderBottomWidth: 1, borderBottomColor: colors.borderColor,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column",
        },
    },
    modifyProductItemImage: {
        width: 80, height: 80
    },
    modifyProductItemDetailsView: {
        flex: 1
    },
    modifyProductName: {
        fontSize: 15, color: colors.black, textAlign: "left", fontFamily: getDMSansSemiBoldFont()
    },
    modifyProductQty: {
        fontSize: 13, fontFamily: getDMSansRegularFont()
    },
    modifyProductTotal: {
        fontSize: 14, fontFamily: getDMSansBoldFont(), marginTop: 15
    },
    modifyProductDetailsViewWithBorder: {
        // flexDirection: "row",
        // marginTop: 10,
        // borderBottomWidth: 1,
        // paddingVertical: 10
        flexDirection: "row", alignItems: "center", justifyContent: "space-between",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column",
        },
    },
    modifyProductDetailsViewWithoutBorder: {
        flexDirection: "row",
        marginTop: 10,
        borderBottomWidth: 0,
        paddingVertical: 10
    },
    modifyQtyMainView: {
        flexDirection: 'row', alignItems: 'center'
    },
    modifyQtySubView: {
        borderWidth: 1, borderColor: colors.blogBorderColor, flexDirection: 'row', alignItems: "center", marginLeft: 20, borderRadius: 10,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginLeft: 0,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginLeft: 0,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginLeft: 0,
        },
    },
    modifyQtyPresseble: {
        top: -1, paddingVertical: 0, paddingHorizontal: 15
    },
    modifyQtyPressebleText: {
        textAlign: 'left', fontSize: 30, color: colors.black, fontFamily: getDMSansSemiBoldFont()
    },
    modifyQtyText: {
        paddingHorizontal: 20, textAlign: 'left', fontSize: 15, color: colors.black, fontFamily: getDMSansSemiBoldFont()
    },

    // my account
    myAccountMainView: {
        paddingVertical: "80px", paddingHorizontal: "15%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            padding: '40px 15px',
        },
    },
    myAccountHeadingView: {
        paddingVertical: 10, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',

    },
    myAccountText: {
        flex: 1, fontSize: 25, color: colors.black, textAlign: "left", fontFamily: getDMSansSemiBoldFont()
    },
    referFriendText: {
        fontSize: 20, color: colors.buttonColor, textAlign: "left", fontFamily: getDMSansSemiBoldFont(), textDecorationLine: "underline"
    },
    myAccountBoxMainView: {
        marginTop: 20
    },
    myAccountBoxSubView: {
        flexDirection: "row", alignItems: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column",
        },
    },
    myAccountBoxViewWithMarginRight: {
        flex: 1, borderWidth: 1, borderRadius: 10, borderColor: colors.borderColor, height: 100, marginRight: 10, marginBottom: 10, alignItems: "center", justifyContent: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '100%', margin: '0 0 20px', display: 'inline-block', padding: '30px 0'
        },
    },
    myAccountBoxViewWithoutMarginRight: {
        flex: 1, borderWidth: 1, borderRadius: 10, borderColor: colors.borderColor, height: 100, marginBottom: 10, alignItems: "center", justifyContent: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '100%', margin: '0 0 20px', display: 'inline-block', padding: '30px 0'
        },
    },
    myAccountBoxViewWithoutMarginRightNotVisible: {
        flex: 1,
        // borderWidth: 1, borderRadius: 10, borderColor: colors.borderColor, height: 100, marginBottom: 10, alignItems: "center", justifyContent: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '100%', margin: '0 0 20px', display: 'inline-block', padding: '30px 0'
        },
    },
    myAccountBoxText: {
        fontSize: 20, fontFamily: getDMSansMediumFont()
    },
    myAccountDialogMainView: {
        marginTop: Dimensions.get("window").height * 0.8, width: 300, backgroundColor: colors.white, alignSelf: "center", borderWidth: 1, borderColor: colors.borderColor
    },
    myAccountDialogAlertText: {
        color: colors.buttonColor, alignSelf: "center"
    },
    myAccountDialogHeadingText: {
        color: colors.black, alignSelf: "center"
    },
    myAccountDialogButtonView: {
        flexDirection: "row", alignSelf: "center"
    },
    myAccountDialogButtonText: {
        backgroundColor: colors.buttonColor, width: 100, borderRadius: 10
    },
    // cart screen
    couponMainView: {
        width: "32.33%", margin: 5, flexDirection: "row", borderWidth: 1, borderRadius: 10, borderColor: colors.borderColor, padding: 10, justifyContent: "space-between",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            width: "48%",
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            width: "48%",
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            width: "100%",
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            width: "100%",
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: "100%",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: "100%",
        },
    },
    couponHeadingText: {
        fontSize: 20, marginLeft: 5, fontFamily: getDMSansBoldFont()
    },
    couponText: {
        fontSize: 15, marginLeft: 5, fontFamily: getDMSansRegularFont()
    },
    couponButton: {
        backgroundColor: colors.buttonColor, flexDirection: "row", alignItems: "center", alignSelf: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 30, borderRadius: 10, marginHorizontal: 10, marginTop: 20
    },
    couponButtonText: {
        textAlign: 'center', fontSize: 12, color: colors.white, textTransform: "uppercase", fontFamily: getDMSansBoldFont()
    },
    cartMainView: {
        paddingVertical: "80px", paddingHorizontal: "15%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            padding: '40px 15px',
        },
    },
    cartSubView: {
        flexDirection: "row",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            flexDirection: 'column',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            flexDirection: 'column',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flexDirection: 'column',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: 'column',
        },
    },
    cartLoadingView: {
        height: 200
    },
    //cart product List view

    cartContainer: {
        flex: 2,
        borderWidth: 0,
        marginRight: 20,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            marginRight: 0,
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginRight: 0,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginRight: 0,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginRight: 0,
        },
    },
    cartHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cartHeaderText: {
        fontSize: 25,
        color: colors.black,
        textAlign: 'left',
        fontFamily: getDMSansSemiBoldFont(),
    },
    emptyCartButton: {
        backgroundColor: colors.white,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 10,
        paddingHorizontal: 30,
        borderRadius: 10,
        marginHorizontal: 10,
    },
    emptyCartButtonText: {
        textAlign: 'center',
        fontSize: 12,
        color: colors.buttonColor,
        textTransform: 'uppercase',
        fontFamily: getDMSansBoldFont(),
    },
    cartItemList: {
        marginTop: 20,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            marginBottom: 20,
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginBottom: 20,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginBottom: 20,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginBottom: 20,
        },
    },
    cartItem: {
        width: '48%',
        marginRight: '1%',
        marginLeft: '1%',
        borderWidth: 1,
        borderColor: colors.borderColor,
        borderRadius: 10,
        padding: 10,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: '100%',
            marginRight: '0',
            marginLeft: '0',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '100%',
            marginRight: '0',
            marginLeft: '0',
        },
    },
    cartItemSeparator: {
        margin: 5,
    },
    cartItemContent: {
        flexDirection: 'row',

    },
    cartItemImage: {
        width: 100,
        height: 100,
        resizeMode: "contain"
    },
    cartItemDetails: {
        flex: 1,
        marginLeft: 10,
    },
    cartItemName: {
        fontSize: 15,
        color: colors.black,
        textAlign: 'left',
        fontFamily: getDMSansSemiBoldFont(),
    },
    cartItemOption: {
        fontSize: 16,
        fontFamily: getDMSansRegularFont(),
        color: colors.grey
    },
    cartItemSize: {
        fontSize: 13,
        fontFamily: getDMSansMediumFont(),
    },
    cartItemBottomSection: {
        borderTopWidth: 1,
        borderTopColor: colors.borderColor,
        flexDirection: "row",
        alignItems: "center",
        paddingTop: 10, marginTop: 10
    },
    cartItemModifyView: {
        flex: 1, alignItems: "center", borderRightWidth: 1, borderRightColor: colors.borderColor
    },
    cartItemModifyText: {
        fontSize: 15,
        color: "#868e96",
        textAlign: 'left',
        fontFamily: getDMSansSemiBoldFont(),
    },
    cartItemDeleteView: {
        flex: 1, alignItems: "center"
    },
    cartItemDeleteText: {
        fontSize: 15,
        color: "#868e96",
        textAlign: 'left',
        fontFamily: getDMSansSemiBoldFont(),
    },
    couponApplyView: {
        flexDirection: "row", alignItems: "center", marginVertical: 10, justifyContent: 'center',
    },
    couponApplyTextInput: {
        height: 47,
        backgroundColor: colors.white,
        borderWidth: 1,
        borderColor: "#9A9A9A",
        borderRadius: 5,
        fontSize: 12,
        fontWeight: "500",
        color: colors.textInputTextColor,
        lineHeight: 15,
        marginTop: 5,
        marginBottom: 10,
        paddingHorizontal: 18,
        fontFamily: getDMSansRegularFont(),
        outline: "none",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '170px'
        },
    },
    couponApplyButton: {
        backgroundColor: colors.buttonColor, flexDirection: "row", alignItems: "center", justifyContent: "center", paddingVertical: 10, paddingHorizontal: 30, borderRadius: 10, marginHorizontal: 10, lineHeight: '47px', height: '47px', top: '-3px',
    },
    couponApplyButtonText: {
        textAlign: 'center', fontSize: 12, color: colors.white, textTransform: "uppercase", fontFamily: getDMSansBoldFont()
    },
    couponListView: {
        flexDirection: 'row', flexWrap: 'wrap', justifyContent: "center", width: "100%",
        '@media (max-width: 1199px)': {

        },
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {

        },
        '@media (max-width: 479px)': {
            // marginLeft: '15px',
            // marginRight: '15px',
        },
    },
    cartItemSubscriptionName: {
        fontSize: 16,
        fontFamily: getDMSansRegularFont(),
        color: colors.grey,
        textTransform: "capitalize"
    },
    cartItemSubscriptionDay: {
        fontSize: 16,
        fontFamily: getDMSansRegularFont(),
        color: colors.grey,
        textTransform: "capitalize"
    },
    cartItemTotal: {
        fontSize: 14,
        fontFamily: getDMSansBoldFont(),
        marginTop: 15,
    },
    cartItemTotalPriceAndQty: {
        flexDirection: "row", alignItems: "center", justifyContent: "space-between"
    },

    // coupon and order conform view 
    couponContainer: {
        borderWidth: 0,
        marginLeft: 10,
        flex: 0.5,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            marginLeft: 0, flex: 'inherit',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            marginLeft: 0, flex: 'inherit',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            marginLeft: 0, flex: 'inherit',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            marginLeft: 0, flex: 'inherit',
        },
    },
    couponViewContainer: {
        marginBottom: 10
    },

    applyButton: {
        flexDirection: "row", alignItems: "center", borderWidth: 1, borderRadius: 5, borderColor: colors.borderColor, paddingVertical: 10, paddingHorizontal: 10, justifyContent: "space-between"
    },
    applyButtonText: {
        fontSize: 18, color: colors.black, textAlign: "left", fontFamily: getDMSansSemiBoldFont(), marginLeft: 10
    },
    applyCouponView: {
        flexDirection: "row",
        alignItems: "center",
    },

    applyCouponImg1: {
        height: 25,
        width: 25, resizeMode: "contain",
    },
    applyCouponImg2: {
        height: 15,
        width: 15, resizeMode: "contain",
    },
    //coupon modal 

    couponModalMainContainer: {
        flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.5)'
    },
    couponModalContainer: {
        backgroundColor: 'white',
        borderColor: '#eee',
        borderRadius: 10,
        borderWidth: 1,
        justifyContent: 'space-between',
        margin: 'auto',
        padding: 30,
        width: '70%',
        alignSelf: 'center',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            width: '90%', padding: 20,
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: '90%', padding: 20,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: '90%', padding: 20,
        },
    },
    couponModalTitle: {
        fontFamily: getDMSansMediumFont(),
        fontSize: 20,
        marginBottom: 20, textAlign: 'center',
    },
    couponModalButtonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'center',
    },
    couponModalCloseButton: {
        alignSelf: 'center',
        backgroundColor: colors.buttonColor,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 10,
        paddingHorizontal: 15,
        borderRadius: 5,
        marginLeft: 10, lineHeight: '47px', height: '47px', marginTop: '20px',
    },
    couponModalCloseButtonText: {
        textAlign: 'center',
        fontSize: 12,
        color: colors.white,
        textTransform: 'uppercase',
        fontFamily: getDMSansSemiBoldFont(),
    },
    // Styles for delivery instructions
    deliveryInstructions: {
        marginBottom: 10,
        marginTop: 15,
    },
    deliveryInstructionsText: {
        fontSize: 20,
        color: colors.black,
        textAlign: "left",
        fontFamily: getDMSansSemiBoldFont(),
    },
    deliveryInstructionsInput: {
        marginTop: 10,
        height: 47,
        backgroundColor: colors.white,
        borderWidth: 1,
        borderColor: "#9A9A9A",
        borderRadius: 5,
        fontSize: 12,
        fontWeight: "500",
        color: colors.textInputTextColor,
        lineHeight: 15,
        marginBottom: 10,
        paddingHorizontal: 18,
        fontFamily: getDMSansRegularFont(),
        outline: "none"
    },
    // Styles for order summary
    orderSummary: {
        borderWidth: 0,
        marginBottom: 10,
    },
    orderSummaryText: {
        fontSize: 20,
        color: colors.black,
        textAlign: "left",
        fontFamily: getDMSansBoldFont(),
    },
    summaryRow: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 15,
    },
    summaryRow1: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 10,
    },
    summaryLabelText: {
        fontSize: 15,
        color: colors.black,
        textAlign: "left",
        fontFamily: getDMSansRegularFont()
    },
    summaryValueText: {
        fontSize: 15,
        color: colors.black,
        textAlign: "right",
        fontFamily: getDMSansRegularFont()
    },
    couponRemoveIcon: {
        height: 20,
        width: 20, resizeMode: "contain", marginLeft: 5,
        // tintColor: colors.footerFontColor
    },
    couponApplyAmountView: {
        flexDirection: "row", alignItems: "center"
    },
    summaryLabelText1: {
        fontSize: 15,
        color: colors.black,
        textAlign: "left",
        fontFamily: getDMSansBoldFont()
    },
    summaryValueText1: {
        fontSize: 15,
        color: colors.black,
        textAlign: "right",
        fontFamily: getDMSansBoldFont()
    },
    // Styles for payment methods

    paymentMethodTitle: {
        fontSize: 20, color: colors.black, textAlign: "left", fontFamily: getDMSansSemiBoldFont()
    },
    paymentMethods: {
        marginTop: 20,
    },
    paymentMethodOption: {
        marginRight: 5,
        borderWidth: 1,
        borderColor: colors.borderColor,
        borderRadius: 10,
        padding: 10,
        flexDirection: "row",
        alignItems: "center",
    },
    paymentSeparator: {
        margin: 5
    },
    paymentMethodRadioButton: {
        flexDirection: "row",
        alignItems: "center",
        alignSelf: "flex-end",
    },
    paymentMethodText: {
        textAlign: 'left',
        fontSize: 15,
        color: colors.black,
        fontFamily: getDMSansRegularFont(),
    },
    paymentMethodTextView: {
        flex: 1
    },
    paymentWalletText: {
        fontSize: 15, color: colors.black, textAlign: "left", fontFamily: getDMSansSemiBoldFont()
    },
    confirmOrderButton: {
        backgroundColor: colors.buttonColor,
        flexDirection: "row",
        alignItems: "center",
        alignSelf: "center",
        justifyContent: "center",
        paddingVertical: 10,
        paddingHorizontal: 30,
        borderRadius: 10,
        marginHorizontal: 10,
        marginTop: 20,
    },
    confirmOrderButtonText: {
        textAlign: 'center',
        fontSize: 12,
        color: colors.white,
        textTransform: "uppercase",
        fontFamily: getDMSansBoldFont(),
    },

    // common screen
    commonMainView: {
        paddingHorizontal: "15%", paddingTop: 80, paddingBottom: 80,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            padding: '80px 15px'
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            padding: '80px 15px'
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            padding: '80px 15px'
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            padding: '40px 15px'
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            padding: '40px 15px'
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            padding: '40px 15px'
        },
    },
    commonScreenText: {
        fontSize: 18,
        fontFamily: getDMSansRegularFont(),
    },

    //FAQ Screen 
    faqMainView: {
        marginVertical: "5%", paddingHorizontal: "15%", borderRadius: 30
    },
    faqTitleText: {
        fontSize: 25, color: colors.black, textAlign: "left", fontFamily: getDMSansSemiBoldFont()
    },
    faqContainer: {
        flexDirection: "row", marginTop: 50
    },
    faqMainList: {
        flex: 0.5, borderWidth: 0, borderColor: colors.borderColor, borderRadius: 10, marginRight: 30
    },
    faqSubList: {
        flex: 1, borderWidth: 1, borderColor: colors.borderColor, borderRadius: 10,
    },
    //main Question Item 
    mainQItem: {
        paddingHorizontal: 15, paddingVertical: 10, flexDirection: "row", alignItems: "center", justifyContent: "space-between", borderBottomWidth: 1, borderBottomColor: colors.borderColor
    },
    subQItem: {
        paddingHorizontal: 15,
    },
    subQPress: {
        borderBottomWidth: 1, borderBottomColor: colors.borderColor, paddingVertical: 10, flexDirection: "row", alignItems: "center", justifyContent: "space-between"
    },
    subQTitle: {
        fontSize: 15, color: colors.black, textAlign: "left", fontFamily: getDMSansRegularFont()
    },
    subQImg: {
        marginHorizontal: 9,
        left: 5,
        height: 10,
        width: 10,
        resizeMode: "contain",
    },
    mainQActiveText: {
        fontSize: 15,
        color: colors.buttonColor,
        textAlign: "left",
        fontFamily: getDMSansBoldFont()
    },
    mainQInactiveText: {
        fontSize: 15,
        color: colors.black,
        textAlign: "left",
        fontFamily: getDMSansRegularFont()
    },
    mainQActiveImage: {
        marginHorizontal: 9,
        left: 5,
        height: 10,
        width: 10,
        resizeMode: "contain",
        tintColor: colors.buttonColor
    },
    mainQInactiveImage: {
        marginHorizontal: 9,
        left: 5,
        height: 10,
        width: 10,
        resizeMode: "contain",
        tintColor: colors.black
    },
    subQDescription: {
        paddingHorizontal: 15
    },
    subQAnswerText: {
        fontSize: 15,
        fontFamily: getDMSansRegularFont(),
    },

    // shipping list
    myAddressMainView: {
        paddingVertical: "80px", paddingHorizontal: "15%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            padding: '40px 15px',
        },
    },
    myAddTitleView: {
        paddingVertical: 10, flexDirection: "row", alignItems: "center", justifyContent: "space-between"
    },
    myAddTitle: {
        fontSize: 25, color: colors.black, textAlign: "left", fontFamily: getDMSansSemiBoldFont()
    },
    myAddressAddButton: {
        borderRadius: 10,
        height: 47,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.buttonColor,
        marginTop: 22,
        paddingHorizontal: 15
    },
    noAddMsg: {
        height: 300, justifyContent: "center", alignItems: "center"
    },
    noAddText: {
        fontSize: 20, marginLeft: 20
    },
    shippingFlat: {
        marginTop: 50
    },
    shippingFlatSeparator: {
        borderWidth: 1, borderColor: colors.borderColor, marginVertical: 10
    },
    shippingAddItemView: {
        width: "auto", flexDirection: "row", justifyContent: "space-between",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flexDirection: "column",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column",
        },
    },
    myAddTitleText: {
        fontSize: 20, fontFamily: getDMSansSemiBoldFont()
    },
    myAddInfoName: {
        fontSize: 15, fontFamily: getDMSansRegularFont(), marginTop: 10,
    },
    myAddInfoText: {
        fontSize: 15, fontFamily: getDMSansRegularFont()
    },
    myAddButtonView: {
        flexDirection: "row", alignItems: "center"
    },
    myAddEditButton: {
        borderRadius: 10,
        height: 47,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.buttonColor,
        marginTop: 22,
        width: 100, marginRight: 10
    },
    myAddDeleteButton: {
        borderRadius: 10,
        height: 47,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.buttonColor,
        marginTop: 22,
        width: 100,
    },
    // stories screen
    storiesScreenMainView: {
        marginVertical: "5%", paddingHorizontal: "15%",
    },
    // thank you screen
    thankYouMainView: {
        borderWidth: 1, marginVertical: "5%", marginHorizontal: "25%", borderRadius: 30
    },
    thankYouSubView: {
        paddingVertical: 60
    },
    thankYouText: {
        color: colors.black, fontSize: 40, textAlign: "center"
    },
    thankYouSubText: {
        color: colors.black, fontSize: 20, textAlign: "center", marginTop: 15
    },
    goToOrdersButton: {
        borderRadius: 10,
        height: 47,
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center",
        backgroundColor: colors.buttonColor,
        marginTop: 30, paddingHorizontal: 50
    },
    // upadteuserinfo screen
    updateUserInfoMainView: {
        paddingVertical: "80px", paddingHorizontal: "15%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            padding: '40px 15px',
        },
    },
    updateUserInfoSubView: {
        flexDirection: "row", alignItems: "flex-start",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flexDirection: "column",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column",
        },
    },
    updateUserInfoSubSubView: {
        flex: 1, margin: 10,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            margin: 0, width: '100%',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            margin: 0, width: '100%',
        },
    },
    updateUserInfoSaveChanges: {
        borderRadius: 10,
        height: 47,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.buttonColor,
        marginTop: 22,
        paddingHorizontal: 30
    },
    updateUserInfoSaveChangesButtonView: {
        alignSelf: "center"
    },
    // wallet screen
    walletHistoryItemView: {
        margin: 5, flexDirection: "row", justifyContent: "space-between"
    },
    walletHistoryItemSubView: {
        flex: 1
    },
    walletHistoryDescription: {
        fontSize: 15, color: colors.black, textAlign: "left", fontFamily: getDMSansSemiBoldFont()
    },
    walletHistoryDate: {
        fontSize: 15, color: colors.black, textAlign: "left", fontFamily: getDMSansRegularFont()
    },
    walletHistoryCreditText: {
        fontSize: 17,
        color: "green",
        textAlign: "right",
        fontFamily: getDMSansBoldFont()
    },
    walletHistoryDebitText: {
        fontSize: 17,
        color: "red",
        textAlign: "right",
        fontFamily: getDMSansBoldFont()
    },
    walletScreenMainView: {
        paddingVertical: "80px", paddingHorizontal: "15%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            padding: '40px 15px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            padding: '40px 15px',
        },
    },
    walletbalanceMainView: {
        paddingVertical: 10, marginTop: 20, justifyContent: "space-between", flexDirection: "row", alignItems: "center",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            flexDirection: "column",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            flexDirection: "column",
        },
    },
    walletbalanceHeadingText: {
        fontSize: 25, color: colors.black, textAlign: "left", fontFamily: getDMSansSemiBoldFont()
    },
    transactionHistoryText: {
        fontSize: 17, color: colors.buttonColor, textAlign: "left", fontFamily: getDMSansSemiBoldFont()
    },
    walletBalanceView: {
        marginTop: 30
    },
    walletBalanceText: {
        fontSize: 20, color: "#3F414D", textAlign: "left", fontFamily: getDMSansSemiBoldFont(),
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            textAlign: "center",
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            textAlign: "center",
        },
    },
    transactionHistoryHeadingView: {
        paddingVertical: 10, marginTop: 20, flexDirection: "row", alignItems: "center"
    },
    transactionHistoryBackArrow: {
        height: 20, width: 20
    },
    transactionHistoryTextView: {
        marginLeft: 15, fontSize: 25, color: colors.black, textAlign: "left", fontFamily: getDMSansSemiBoldFont()
    },
    transactionHistoryListView: {
        marginTop: 30
    },
    transactionHistoryItemSeperator: {
        borderWidth: 1, borderColor: colors.borderColor
    },
    transactionHistoryItemProductName: {
        fontSize: 18, color: colors.black, textAlign: "left", fontFamily: getDMSansRegularFont(),
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            textAlign: "center", marginBottom: '10px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            textAlign: "center", marginBottom: '10px',
        },
    },
    transactionHistoryItemProductTotalQty: {
        fontSize: 15, fontFamily: getDMSansRegularFont(), marginTop: 15
    },
    transactionHistoryItemProductTotalQtyBold: {
        fontSize: 15, fontFamily: getDMSansBoldFont(), marginTop: 15
    },
    transactionHistoryItemProductTotal: {
        fontSize: 15, fontFamily: getDMSansRegularFont(),
    },
    transactionHistoryItemProductTotalBold: {
        fontSize: 15, fontFamily: getDMSansBoldFont(),
    },
    transactionHistoryTextButton: {
        justifyContent: 'center',
        paddingTop: 10,
        marginHorizontal: 15,
        marginBottom: 10,
        alignSelf: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        borderBottomWidth: 1,
        borderBottomColor: colors.buttonColor
    },
    transactionHistoryBlueText: {
        textAlign: 'center',
        fontSize: 15,
        color: colors.buttonColor,
        textTransform: 'uppercase',
        fontFamily: getDMSansBoldFont(),
        // textDecorationLine: "underline"
    },
    transactionHistoryDownArrow: {
        height: 10, width: 10, resizeMode: "contain", tintColor: colors.buttonColor, marginLeft: 5
    },
    transactionHistoryPaginationView: {
        margin: 5, flexDirection: "row", justifyContent: "center", alignItems: "center", height: 40, width: 40, borderRadius: 360, backgroundColor: colors.headerBackgroundcolor
    },
    transactionHistoryPaginationTextInactive: {
        fontSize: 18, color: colors.black, textAlign: "left", fontFamily: getDMSansRegularFont(),
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            textAlign: "center", marginBottom: '10px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            textAlign: "center", marginBottom: '10px',
        },
    },
    transactionHistoryPaginationTextActive: {
        fontSize: 18, color: colors.buttonColor, textAlign: "left", fontFamily: getDMSansBoldFont(),
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            textAlign: "center", marginBottom: '10px',
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            textAlign: "center", marginBottom: '10px',
        },
    },
    transactionHistoryPagination: {
        alignSelf: "center", width: "50%",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {
        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            width: "80%"
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            width: "80%"
        },
    },
    transactionHistoryProgressbarView: {
        flex: 1, alignItems: 'center', justifyContent: 'center', 
    },
    transactionHistoryProgressbarSubView: {
        alignItems: 'center',
        borderColor: '#eee',
        borderRadius: 10,
        height: 300,
        margin: 'auto',
        padding: 30,
        width: 300
    },
    transactionHistoryloadingText: {
        fontWeight: "400",
        fontSize: 30,
        lineHeight: 18,
        color: colors.black,
        textAlign: "center",
        marginTop: 20,
        marginLeft: 10,
        fontFamily: getDMSansBoldFont()
    },

});

export { ids, styles };