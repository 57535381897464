import React, { useState, useMemo } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, Image, Modal } from 'react-native';
import { colors } from '../common/color';
import { CustomConsole, HeaderComponent, FooterComponent, validateEmail, scrollToTop, CustomProgressBar } from '../common/utils';
import { CARTLIST, CITY, REGISTER, } from '../common/webUtils';
import { CITY_NAME, COUNTRY_ID, EMAIL, FIRST_NAME, LAST_NAME, OTP_VERIFY, PASSWORD, PHONE, REFERRAL_CODE, STATE_ID, USER_ID, getSession, saveSession } from '../common/LocalStorage';
import { useIsFocused } from '@react-navigation/native';
import { Picker } from '@react-native-picker/picker';
import { styles, ids } from '../common/responsive.js';

function RegisterScreen({ navigation, route }) {

    var onlyDigitReg = /^\d+$/;

    const WIGGLE_ROOM = 128;

    const offset = useMemo(() => {
        return {
            top: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2,
            left: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2
        };
    }, []);

    const [alertShow, setAlertShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    // modal hide/show
    const showAlert = (text) => {
        setAlertShow(true);
        setAlertMsg(text)
    };
    const hideAlert = () => setAlertShow(false);

    const [termsChecked, setTermsChecked] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [referral_code, setReferralCode] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [loading, setLoading] = useState(false);
    const [city, setCity] = useState('');
    const [cityList, setCityList] = useState([]);
    const [cartLength, setcartLength] = useState('0');
    const [userId, setUserId] = useState('');
    const [stateId, setStateId] = useState('');
    const [countryId, setCountryId] = useState('');
    const [firstnameError, setFirstNameError] = useState(false);
    const [lastnameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [invalidEmailError, setInvalidEmailError] = useState(false);
    const [phoneNoError, setPhoneNoError] = useState(false);
    const [phoneNoDigitsError, setPhoneNoDigitsError] = useState(false);
    const [phoneOnlyDigitError, setPhoneNoOnlyDigitError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [passwordLengthError, setPasswordLengthError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [matchPasswordError, setMatchPasswordError] = useState(false);
    const [termsError, setTermsError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        if (route.params) {
            setReferralCode(route.params.paramRefercode != undefined ? route.params.paramRefercode : "")
        }
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            cityListApi();
            cartListApi();
        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);

            // setLoading(true);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                    // setLoading(false);
                })
                .catch((error) => {
                    // setLoading(false);
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    // city list api
    const cityListApi = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(CITY);

            setLoading(true);
            fetch(CITY, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    cityList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {
                        cityList.push({
                            index: i,
                            city_id: json.data[i].city_id,
                            name: json.data[i].name,
                            status: json.data[i].status,
                            state_id: json.data[i].state_id,
                            country_id: json.data[i].country_id,
                        });
                    }

                    setCityList(cityList);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("City list Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("City list Exception=>" + e + "");
        }
    }

    // sign in
    const signUpViaSimple = () => {

        if (city.trim().length == 0) {
            // alert("Enter city name");
            setCityError(true);
        }
        else if (first_name.trim().length == 0) {
            // alert("Enter first name");
            setFirstNameError(true);
        }
        else if (last_name.trim().length == 0) {
            // alert("Enter last name");
            setLastNameError(true);
        }
        else if (email.trim().length == 0) {
            // alert("Enter email");
            setEmailError(true);
        }
        else if (!validateEmail(email.trim())) {
            // alert("Invalid email");
            setInvalidEmailError(true);
        }
        else if (phoneNo.trim().length == 0) {
            // alert("Enter Mobile Number");
            setPhoneNoError(true);
        }
        else if (phoneNo.length != 10) {
            // alert("Enter 10-digit Mobile Number");
            setPhoneNoDigitsError(true);
        }
        else if (!onlyDigitReg.test(phoneNo)) {
            // alert("Mobile Number should be only digits");
            setPhoneNoOnlyDigitError(true);
        }
        else if (password.trim().length == 0) {
            // alert("Enter password");
            setPasswordError(true);
        }
        // else if (password.trim().length < 8) {
        //     // alert("Please enter password more than 6 characters");
        //     setPasswordLengthError(true);
        // }
        else if (confirm_password.trim().length == 0) {
            // alert("Enter confirm password");
            setConfirmPasswordError(true);
        }
        // else if (confirm_password.trim().length < 6) {
        //     alert("Please enter confirm password more than 6 characters");
        // }
        else if (password.trim() != confirm_password.trim()) {
            // alert("Please Check Password and Confirm Password. Both should be same");
            setMatchPasswordError(true);
        }
        else if (!termsChecked) {
            // alert("Please select terms & conditions")
            setTermsError(true);
        }
        else {
            try {

                var raw = JSON.stringify({
                    "firstname": first_name,
                    "lastname": last_name,
                    "email": email,
                    "telephone": phoneNo,
                    "referral_code": referral_code,
                    "password": password,
                    "confirm": confirm_password,
                    "city": city
                });

                var requestOptions = {
                    method: 'POST',
                    body: raw,
                    redirect: 'follow'
                };

                CustomConsole(REGISTER + "\n" + raw);

                setLoading(true);
                fetch(REGISTER, requestOptions)
                    .then(response => response.json())
                    .then((json) => {

                        CustomConsole(json);
                        if (json.success == 1) {
                            //store deatils
                            saveSession(USER_ID, json.data.user_id);
                            saveSession(FIRST_NAME, json.data.first_name);
                            saveSession(LAST_NAME, json.data.last_name);
                            saveSession(PHONE, json.data.phone);
                            saveSession(OTP_VERIFY, json.data.otp_verify);
                            saveSession(EMAIL, json.data.email);
                            saveSession(REFERRAL_CODE, json.data.user_referral_code);
                            saveSession(PASSWORD, password);
                            saveSession(CITY_NAME, city);
                            saveSession(STATE_ID, stateId);
                            saveSession(COUNTRY_ID, countryId);
                            setLoading(false);
                            // navigation.goBack();
                            if (route.params) {
                                if (route.params.paramScreenRoute) {
                                    window.open(route.params.paramScreenRoute, "_self");
                                } else {
                                    navigation.navigate('DashBoardScreen');
                                }
                            } else {
                                navigation.navigate('DashBoardScreen');
                            }
                            // navigation.reset({
                            //     index: 0,
                            //     routes: [{ name: 'DashBoardScreen' }],
                            // });
                        } else {
                            setLoading(false);
                            // alert("Invalid Email and Password");
                            if (json.error.length > 0) {
                                showAlert(json.error[0]);
                            }
                        }

                    })
                    .catch((error) => {
                        setLoading(false);
                        CustomConsole("Error=>" + error);
                    });
            } catch (e) {
                setLoading(false);
                CustomConsole("Exception=>" + e + "");
            }

        }
    }

    return (
        <View>
            <StatusBar barStyle="dark-content" />
            <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent(cartLength, navigation, userId)}
                    {/* end of Header view */}


                    {/* login form view */}
                    <View style={styles.loginMainView} dataSet={{ media: ids.loginMainView }}>
                        <View style={styles.loginSubView} dataSet={{ media: ids.loginSubView }}>

                            {/* welcome to text view */}
                            <View style={styles.registerWelcomeText} dataSet={{ media: ids.registerWelcomeText }}>
                                <View>
                                    <Image source={require("../assets/header/register.png")}
                                        style={styles.loginImageIcon} dataSet={{ media: ids.loginImageIcon }} />
                                    <Text style={styles.loginWelcomeText} dataSet={{ media: ids.loginWelcomeText }}>{"Welcome to\nSoothe Dairy Farm"}</Text>
                                </View>
                                <Image source={require("../assets/header/register-img.png")}
                                    style={styles.loginBackgroundImage} dataSet={{ media: ids.loginBackgroundImage }} />
                            </View>
                            {/* end of welcome to text view */}

                            <View style={styles.loginTextInputView} dataSet={{ media: ids.loginTextInputView }}>

                                <Text style={styles.loginFillDetailsText} dataSet={{ media: ids.loginFillDetailsText }}>Fill the Details to Sign up</Text>

                                {/* City view */}
                                <Text style={styles.loginLabelText} dataSet={{ media: ids.loginLabelText }}>Select city</Text>
                                <Picker
                                    style={styles.cityPicker}
                                    itemStyle={styles.cityItemPicker}
                                    dataSet={{ media: ids.cityPicker }}
                                    selectedValue={city}
                                    onValueChange={(itemValue, itemIndex) => {
                                        setCity(itemValue);
                                        setCityError(false);
                                        if (itemValue == "") {
                                            setStateId("")
                                            setCountryId("")
                                        } else {
                                            let Data = cityList.find(element => element.name == itemValue);
                                            CustomConsole(Data);
                                            setCountryId(Data.country_id)
                                            setStateId(Data.state_id);
                                        }
                                    }}>
                                    <Picker.Item label="Please Select" value="" />
                                    {cityList.map((item) => (
                                        <Picker.Item label={item.name} value={item.name} key={item.name} />
                                    ))}
                                </Picker>
                                {cityError ? <Text style={styles.textInputError}>Please select city</Text> : null}

                                {/* first name view */}
                                <Text style={styles.loginLabelText} dataSet={{ media: ids.loginLabelText }}>First Name</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    dataSet={{ media: ids.loginTextInput }}
                                    value={first_name}
                                    onChangeText={txt => { setFirstName(txt); setFirstNameError(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter First Name" />
                                {firstnameError ? <Text style={styles.textInputError}>Enter first name</Text> : null}

                                {/* last name view */}
                                <Text style={styles.loginLabelText} dataSet={{ media: ids.loginLabelText }}>Last Name</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    dataSet={{ media: ids.loginTextInput }}
                                    value={last_name}
                                    onChangeText={txt => { setLastName(txt); setLastNameError(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter Last Name" />
                                {lastnameError ? <Text style={styles.textInputError}>Enter last name</Text> : null}

                                {/* email address view */}
                                <Text style={styles.loginLabelText} dataSet={{ media: ids.loginLabelText }}>Email ID</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    dataSet={{ media: ids.loginTextInput }}
                                    value={email}
                                    onChangeText={txt => { setEmail(txt); setEmailError(false); setInvalidEmailError(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter Email address" />
                                {emailError ? <Text style={styles.textInputError}>Enter email address</Text> : null}
                                {invalidEmailError ? <Text style={styles.textInputError}>Invalid email address</Text> : null}

                                {/* phone no view */}
                                <Text style={styles.loginLabelText} dataSet={{ media: ids.loginLabelText }}>Phone No</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    dataSet={{ media: ids.loginTextInput }}
                                    value={phoneNo}
                                    onChangeText={txt => { setPhoneNo(txt.replace(/[^0-9]/g, '')); setPhoneNoError(false); setPhoneNoDigitsError(false); setPhoneNoOnlyDigitError(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter Phone No"
                                    keyboardType='numeric'
                                    inputMode='numeric'
                                    maxLength={10} />
                                {phoneNoError ? <Text style={styles.textInputError}>Enter phone no</Text> : null}
                                {phoneNoDigitsError ? <Text style={styles.textInputError}>Enter 10-digit phone no</Text> : null}
                                {phoneOnlyDigitError ? <Text style={styles.textInputError}>Phone no should be only digits</Text> : null}

                                {/* password view */}
                                <Text style={styles.loginLabelText} dataSet={{ media: ids.loginLabelText }}>Password</Text>
                                <View style={styles.loginPasswordView} dataSet={{ media: ids.loginPasswordView }}>
                                    <TextInput
                                        style={styles.loginPasswordTextInput}
                                        dataSet={{ media: ids.loginPasswordTextInput }}
                                        value={password}
                                        onChangeText={txt => { setPassword(txt); setPasswordError(false); setPasswordLengthError(false); setMatchPasswordError(false); }}
                                        placeholderTextColor={colors.placeHolderColor}
                                        placeholder="Enter Password"
                                        secureTextEntry={!passwordShow} />
                                    <Pressable onPress={() => { setPasswordShow(!passwordShow) }}>
                                        <Image source={passwordShow ? require("../assets/header/plus.png") : require("../assets/header/hide.png")}
                                            style={styles.loginPasswordIcon} dataSet={{ media: ids.loginPasswordIcon }}/>
                                    </Pressable>
                                </View>
                                {passwordError ? <Text style={styles.textInputError}>Enter password</Text> : null}
                                {passwordLengthError ? <Text style={styles.textInputError}>Please enter password more than 8 characters</Text> : null}

                                {/* confirm password view */}
                                <Text style={styles.loginLabelText} dataSet={{ media: ids.loginLabelText }}>Confirm Password</Text>
                                <View style={styles.loginPasswordView} dataSet={{ media: ids.loginPasswordView }}>
                                <TextInput
                                    style={styles.loginPasswordTextInput}
                                    dataSet={{ media: ids.loginPasswordTextInput }}
                                    value={confirm_password}
                                    onChangeText={txt => { setConfirmPassword(txt); setConfirmPasswordError(false); setMatchPasswordError(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Confirm Password"
                                    secureTextEntry={!confirmPasswordShow} />
                                    <Pressable onPress={() => { setConfirmPasswordShow(!confirmPasswordShow) }}>
                                        <Image source={confirmPasswordShow ? require("../assets/header/plus.png") : require("../assets/header/hide.png")}
                                            style={styles.loginPasswordIcon} dataSet={{ media: ids.loginPasswordIcon }}/>
                                    </Pressable>
                                </View>
                                {confirmPasswordError ? <Text style={styles.textInputError}>Enter confirm password</Text> : null}
                                {matchPasswordError ? <Text style={styles.textInputError}>Please Check Password and Confirm Password. Both should be same</Text> : null}

                                {/* referral code view */}
                                <Text style={styles.loginLabelText} dataSet={{ media: ids.loginLabelText }}>Referral code</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    dataSet={{ media: ids.loginTextInput }}
                                    value={referral_code}
                                    onChangeText={txt => { setReferralCode(txt) }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter Referral Code" />

                                <View style={styles.termNconditionView}>
                                    <input type="checkbox"
                                        checked={termsChecked}
                                        onChange={() => {
                                            setTermsChecked(!termsChecked);
                                            setTermsError(false);
                                        }}
                                        onValueChange={setTermsChecked}
                                    />
                                    <Pressable onPress={() => navigation.navigate("CommonScreen", { paramInfoId: "2" })}>
                                        <Text style={styles.termNconditionText} dataSet={{ media: ids.termNconditionText }}>Terms & Conditions</Text>
                                    </Pressable>
                                </View>
                                {termsError ? <Text style={styles.textInputError}>Please select terms & conditions</Text> : null}

                                {/* sign up button view */}
                                <Pressable style={styles.loginBtn} onPress={signUpViaSimple}>
                                    <Text style={styles.loginText}>Sign Up</Text>
                                </Pressable>

                                {/* login view */}
                                <View style={styles.bottomContainer}>
                                    <Text style={styles.colorBlackText}>Already have an account? </Text>
                                    <Pressable onPress={() => {
                                        if (route.params) {
                                            if (route.params.paramScreenRoute) {
                                                navigation.navigate("LoginScreen", { paramScreenRoute: route.params.paramScreenRoute });
                                            }
                                        } else {
                                            navigation.navigate('LoginScreen');
                                        }
                                        // navigation.navigate("LoginScreen");
                                        // navigation.reset({
                                        //     index: 0,
                                        //     routes: [{ name: 'LoginScreen' }],
                                        // });
                                    }}>
                                        <Text style={styles.createAccountText}>sign in</Text>
                                    </Pressable>
                                </View>


                            </View>
                        </View>
                    </View>
                    {/* end of form view */}

                    {/* common modal */}
                    <Modal
                        onRequestClose={hideAlert}
                        transparent
                        visible={alertShow}
                        animationType={'slide'}
                    >
                        <View style={styles.commonModalMainView} dataSet={{ media: ids.commonModalMainView }}>
                            <View style={[styles.commonModalViewContainer, offset]} dataSet={{ media: ids.commonModalViewContainer }}>

                                <View>
                                    <Text style={styles.commonModalText}>{alertMsg}</Text>
                                </View>
                                <Pressable
                                    onPress={hideAlert}
                                    style={styles.commonModalButton}>
                                    <Text style={styles.commonModalButtonText}>Ok, Got it</Text>
                                </Pressable>
                            </View>
                        </View>
                    </Modal>
                    {/* end of common modal */}

                    {/* Footer view */}
                    {/* {FooterComponent(navigation, userId)}*/}
                    {/* end of Footer view */}
                </View>

            </ScrollView>
        </View>
    );
};

export default RegisterScreen;
