import './App.css';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import LoginScreen from './components/LoginScreen';
import DashBoardScreen from './components/DashboardScreen';
import RegisterScreen from './components/RegisterScreen';
import OtpScreen from './components/OtpScreen';
import ProductListScreen from './components/ProductListScreen';
import ProductDetailsScreen from './components/ProductDetailsScreen';
import UpdateUserInfoScreen from './components/UpdateUserInfoScreen';
import OrderListScreen from './components/OrderListScreen';
import CartScreen from './components/CartScreen';
import MyAccountScreen from './components/MyAccountScreen';
import ShippingAddressScreen from './components/ShippingAddressScreen';
import AddShippingAddressScreen from './components/AddShippingAddressScreen';
import SubscribeListScreen from './components/SubscribeListScreen';
import CheckoutScreen from './components/CheckoutScreen';
import ForgotPasswordScreen from './components/ForgotPasswordScreen';
import ChangePasswordScreen from './components/ChangePasswordScreen';
import CommonScreen from './components/CommonScreen';
import FaqScreen from './components/FaqScreen';
import StoriesScreen from './components/StoriesScreen';
import WalletScreen from './components/WalletScreen';
import ThankyouScreen from './components/ThankyouScreen';
import CancelSubscriptionListScreen from './components/CancelSubscriptionListScreen';
import ContactScreen from './components/ContactScreen';
import InquiryScreen from './components/InquiryScreen';
import LogSubscriptionListScreen from './components/LogSubscriptionListScreen';

const Stack = createNativeStackNavigator();

const linking = {
  prefixes: ['https://dairyfarm.alakmalak.ca/', 'dairyfarm.alakmalak.ca://'],
  config: {
    screens: {
      WalletScreen: '/wallet-screen',
      CartScreen: 'cart-screen',
      ProductListScreen: '/products-screen',
      ContactScreen: '/contact-screen',
      InquiryScreen: '/inquiry-screen',
      LoginScreen: '/login-screen',
      SubscribeListScreen: '/subscriptionlist-screen',
      ProductDetailsScreen: {
        path:'product-details-screen/:paramId/:isWallet',
        parse: {
          paramId: (id) => `${id}`,
          isWallet: (isWallet) => `${isWallet}`
        },
        stringify: {
          paramId: (id) => id,
          isWallet: (isWallet) => isWallet
        },
      },
      RegisterScreen: {
        path:'register-screen/:paramRefercode',
        parse: {
          paramRefercode: (id) => `${id}`,
        },
        stringify: {
          paramRefercode: (id) => id,
        },
      },
      // AddShippingAddressScreen: '/AddShippingAddressScreen',

    }
  },
};

function App() {


  return (
    <div className="App">
      {/* <script src="https://maps.googleapis.com/maps/api/js?key=<INSERT-API-KEY-HERE>&libraries=places&callback=initMap"async></script> */}

      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      <NavigationContainer
        linking={linking}
        // fallback={<DashBoardScreen />}
        documentTitle={{
          formatter: (options, route) => 
            `Soothe Organics`,
        }}
      >
        <Stack.Navigator screenOptions={{ headerShown: false }} initialRouteName='DashBoardScreen'>
          <Stack.Screen name="LoginScreen" component={LoginScreen} />
          <Stack.Screen name="RegisterScreen" component={RegisterScreen} />
          <Stack.Screen name="OtpScreen" component={OtpScreen} />
          <Stack.Screen name="ForgotPasswordScreen" component={ForgotPasswordScreen} />
          <Stack.Screen name="ChangePasswordScreen" component={ChangePasswordScreen} />

          <Stack.Screen name="DashBoardScreen" component={DashBoardScreen} />
          <Stack.Screen name="ProductListScreen" component={ProductListScreen} />
          <Stack.Screen name="ProductDetailsScreen" component={ProductDetailsScreen} />
          <Stack.Screen name="CartScreen" component={CartScreen} />
          <Stack.Screen name="CheckoutScreen" component={CheckoutScreen} />
          <Stack.Screen name="FaqScreen" component={FaqScreen} />
          <Stack.Screen name="StoriesScreen" component={StoriesScreen} />

          <Stack.Screen name="MyAccountScreen" component={MyAccountScreen} />
          <Stack.Screen name="UpdateUserInfoScreen" component={UpdateUserInfoScreen} />
          <Stack.Screen name="OrderListScreen" component={OrderListScreen} />
          <Stack.Screen name="ShippingAddressScreen" component={ShippingAddressScreen} />
          <Stack.Screen name="AddShippingAddressScreen" component={AddShippingAddressScreen} />
          <Stack.Screen name="SubscribeListScreen" component={SubscribeListScreen} />
          <Stack.Screen name="CommonScreen" component={CommonScreen} />
          <Stack.Screen name="ContactScreen" component={ContactScreen} />
          <Stack.Screen name="InquiryScreen" component={InquiryScreen} />
          <Stack.Screen name="WalletScreen" component={WalletScreen} />
          <Stack.Screen name="ThankyouScreen" component={ThankyouScreen} />
          <Stack.Screen name="CancelSubscriptionListScreen" component={CancelSubscriptionListScreen} />
          <Stack.Screen name="LogSubscriptionListScreen" component={LogSubscriptionListScreen} />

        </Stack.Navigator>
      </NavigationContainer>
    </div>
  );
}

export default App;
