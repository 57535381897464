import React, { useState, useMemo } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, Image, Modal } from 'react-native';
import { colors } from '../common/color';
import { APP_NAME } from '../common/string';
import { CustomConsole, HeaderComponent, FooterComponent, getDMSansBoldFont, getDMSansRegularFont, scrollToTop, validateEmail, CustomProgressBar } from '../common/utils';
import { CARTLIST, FORGOTVERIFYOTP, LOGIN, VERIFYOTP } from '../common/webUtils';
import { useIsFocused } from '@react-navigation/native';
import { CITY_NAME, COUNTRY_ID, EMAIL, FIRST_NAME, LAST_NAME, OTP_VERIFY, PASSWORD, PHONE, STATE_ID, USER_ID, getSession, saveSession } from '../common/LocalStorage';
import { styles, ids } from '../common/responsive.js';

function OtpScreen({ navigation, route }) {

    var onlyDigitReg = /^\d+$/;

    const WIGGLE_ROOM = 128;

    const offset = useMemo(() => {
        return {
            top: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2,
            left: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2
        };
    }, []);

    const [alertShow, setAlertShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    // modal hide/show
    const showAlert = (text) => {
        setAlertShow(true);
        setAlertMsg(text)
    };
    const hideAlert = () => setAlertShow(false);

    const apiOtp = route.params.paramOtp;
    const phone = route.params.paramPhone;
    const email = route.params.paramEmail;
    const routeFrom = route.params.paramFrom;

    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [cartLength, setcartLength] = useState('0');
    const [otpError, setOtpError] = useState(false);
    const [otpDigitsError, setOtpDigitsError] = useState(false);
    const [otpOnlyDigitsError, setOtpOnlyDigitsError] = useState(false);

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        // setOtp(apiOtp);
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            cartListApi();
        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);

            // setLoading(true);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                    // setLoading(false);
                })
                .catch((error) => {
                    // setLoading(false);
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    // verify otp
    const verfiyOtp = () => {
        // console.log(otp)
        try {
            if (otp.length == 0) {
                // alert("Enter OTP");
                setOtpError(true);
            }
            else if (otp.length != 4) {
                // alert("Enter 4 - Digit OTP");
                setOtpDigitsError(true);
            }
            else if (!onlyDigitReg.test(otp)) {
                // alert("OTP should be only digits");
                setOtpOnlyDigitsError(true);
            }
            else {
                if (routeFrom == "forgotpassword") {

                    var raw = JSON.stringify({
                        "email": email,
                        "otp": otp
                    });

                    var requestOptions = {
                        method: 'POST',
                        body: raw,
                        redirect: 'follow'
                    };

                    CustomConsole(FORGOTVERIFYOTP + "\n" + raw);

                    setLoading(true);
                    fetch(FORGOTVERIFYOTP, requestOptions)
                        .then(response => response.json())
                        .then((json) => {

                            CustomConsole(json);
                            if (json.success == 1) {
                                setLoading(false);
                                // alert(json.data.msg);
                                navigation.navigate('ChangePasswordScreen', {
                                    paramToken: json.data.token,
                                    paramEmail: email,
                                    paramPhone: json.data.telephone,
                                    paramFrom: "forgotpassword"
                                });

                            } else {
                                setLoading(false);
                                // if (json.error.length > 0) {
                                showAlert(json.data.msg);
                                // }
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            CustomConsole("Error=>" + error);
                        });

                } else {
                    var raw = JSON.stringify({
                        "phone": phone,
                        "otp": otp
                    });

                    var requestOptions = {
                        method: 'POST',
                        body: raw,
                        redirect: 'follow'
                    };

                    CustomConsole(VERIFYOTP + "\n" + raw);

                    setLoading(true);
                    fetch(VERIFYOTP, requestOptions)
                        .then(response => response.json())
                        .then((json) => {

                            CustomConsole(json);
                            if (json.success == 1) {
                                //store deatils
                                saveSession(USER_ID, json.data.user_id);
                                saveSession(FIRST_NAME, json.data.first_name);
                                saveSession(LAST_NAME, json.data.last_name);
                                saveSession(PHONE, json.data.phone);
                                saveSession(OTP_VERIFY, json.data.otp_verify);
                                saveSession(EMAIL, json.data.email);
                                saveSession(CITY_NAME, json.data.location.city);
                                saveSession(STATE_ID, json.data.location.state_id);
                                saveSession(COUNTRY_ID, json.data.location.country_id);
                                setLoading(false);
                                // saveSession(PASSWORD, password);

                                navigation.navigate('DashBoardScreen');
                                navigation.reset({
                                    index: 0,
                                    routes: [{ name: 'DashBoardScreen' }],
                                });
                            } else {
                                setLoading(false);
                                // if (json.error.length > 0) {
                                showAlert(json.error);
                                // }
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            CustomConsole("Error=>" + error);
                        });
                }
            }
        } catch (e) {
            setLoading(false);
            CustomConsole("Exception=>" + e + "");
        }
    }

    return (
        <View>
            <StatusBar barStyle="dark-content" />
            <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent(cartLength, navigation, userId)}
                    {/* end of Header view */}

                    <View style={styles.loginMainView} dataSet={{ media: ids.loginMainView }}>

                        <View style={styles.loginSubView} dataSet={{ media: ids.loginSubView }}>
                            <View style={styles.loginWelcomeTextView} dataSet={{ media: ids.loginWelcomeTextView }}>
                                <Image source={require("../assets/header/register.png")}
                                    style={styles.loginImageIcon} dataSet={{ media: ids.loginImageIcon }} />
                                <Text style={styles.loginWelcomeText} dataSet={{ media: ids.loginWelcomeText }}>{"Verify Your\nMobile Number"}</Text>
                                <Image source={require("../assets/header/register-img.png")}
                                    style={styles.loginBackgroundImage} dataSet={{ media: ids.loginBackgroundImage }} />
                            </View>



                            <View style={styles.loginTextInputView}>
                                {/* otp view */}
                                <Text style={styles.loginLabelText}>OTP</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    value={otp}
                                    onChangeText={txt => { setOtp(txt); setOtpError(false); setOtpDigitsError(false); setOtpOnlyDigitsError(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    maxLength={4}
                                    inputMode="numeric"
                                    placeholder="Enter OTP" />
                                {otpError ? <Text style={styles.textInputError}>Enter otp</Text> : null}
                                {otpDigitsError ? <Text style={styles.textInputError}>Enter 4-digit otp</Text> : null}
                                {otpOnlyDigitsError ? <Text style={styles.textInputError}>OTP should be only digits</Text> : null}

                                {/* verify otp button view */}
                                <Pressable style={styles.loginBtn} onPress={verfiyOtp}>
                                    <Text style={styles.loginText}>Verfiy OTP</Text>
                                </Pressable>

                                {/* back to sign in view */}
                                <View style={styles.bottomContainer}>
                                    <Text style={styles.colorBlackText}>Back to </Text>
                                    <Pressable onPress={() => navigation.goBack()}>
                                        <Text style={styles.createAccountText}>sign in</Text>
                                    </Pressable>
                                </View>
                                {/* end of back to sign in view */}

                            </View>
                        </View>
                    </View>

                    {/* common modal */}
                    <Modal
                        onRequestClose={hideAlert}
                        transparent
                        visible={alertShow}
                        animationType={'slide'}
                    >
                        <View style={styles.commonModalMainView} dataSet={{ media: ids.commonModalMainView }}>
                            <View style={[styles.commonModalViewContainer, offset]} dataSet={{media:ids.commonModalViewContainer}}>

                                <View>
                                    <Text style={styles.commonModalText}>{alertMsg}</Text>
                                </View>
                                <Pressable
                                    onPress={hideAlert}
                                    style={styles.commonModalButton}>
                                    <Text style={styles.commonModalButtonText}>Ok, Got it</Text>
                                </Pressable>
                            </View>
                        </View>
                    </Modal>
                    {/* end of common modal */}

                    {/* Footer view */}
                    {/* {FooterComponent(navigation, userId)}*/}
                    {/* end of Footer view */}

                </View>

            </ScrollView>
        </View>
    );
};

export default OtpScreen;
