import React, { useMemo, useState } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, Image, Modal } from 'react-native';
import { colors } from '../common/color';
import { APP_NAME } from '../common/string';
import { CustomConsole, CustomModal, CustomProgressBar, FooterComponent, HeaderComponent, scrollToTop, validateEmail } from '../common/utils';
import { CARTLIST, LOGIN, UPDATEPROFILE } from '../common/webUtils';
import { useIsFocused } from '@react-navigation/native';
import { EMAIL, PASSWORD, getSession, USER_ID, FIRST_NAME, LAST_NAME, PHONE, saveSession } from '../common/LocalStorage';
import { styles, ids } from '../common/responsive.js';

function UpdateUserInfoScreen({ navigation }) {

    var onlyDigitReg = /^\d+$/;

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm_password, setConfirmPassword] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [loading, setLoading] = useState(false);
    const [firstnameError, setFirstNameError] = useState(false);
    const [lastnameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [invalidEmailError, setInvalidEmailError] = useState(false);
    const [phoneNoError, setPhoneNoError] = useState(false);
    const [phoneNoDigitsError, setPhoneNoDigitsError] = useState(false);
    const [phoneOnlyDigitError, setPhoneNoOnlyDigitError] = useState(false);
    const [userId, setUserId] = useState('');
    const [cartLength, setcartLength] = React.useState('0');
    const [alertShow, setAlertShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    // modal hide/show
    const showAlert = (text) => {
        setAlertShow(true);
        setAlertMsg(text)
    };
    const hideAlert = () => setAlertShow(false);

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            const email = await getSession(EMAIL);
            const first_name = await getSession(FIRST_NAME);
            const last_name = await getSession(LAST_NAME);
            const phone = await getSession(PHONE);

            setUserId(user_id);
            setFirstName(first_name);
            setLastName(last_name);
            setEmail(email);
            setPhoneNo(phone);
            cartListApi();
            // userDetailsApi();
        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);

            // setLoading(true);
            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength)
                    // setLoading(false);
                })
                .catch((error) => {
                    // setLoading(false);
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    // update user details api
    const updateUserDetails = () => {
        try {
            if (first_name.trim().length == 0) {
                // alert("Enter first name");
                setFirstNameError(true);
            }
            else if (last_name.trim().length == 0) {
                // alert("Enter last name");
                setLastNameError(true);
            }
            else if (email.trim().length == 0) {
                // alert("Enter email");
                setEmailError(true);
            }
            else if (!validateEmail(email.trim())) {
                // alert("Invalid email");
                setInvalidEmailError(true);
            }
            else if (phoneNo.trim().length == 0) {
                // alert("Enter Mobile Number");
                setPhoneNoError(true);
            }
            else if (phoneNo.length != 10) {
                // alert("Enter 10-digit Mobile Number");
                setPhoneNoDigitsError(true);
            }
            else if (!onlyDigitReg.test(phoneNo)) {
                // alert("Mobile Number should be only digits");
                setPhoneNoOnlyDigitError(true);
            }
            else {
                try {

                    var raw = JSON.stringify({
                        "email": email,
                        "firstname": first_name,
                        "lastname": last_name,
                        "telephone": phoneNo,
                        "customer_id": userId
                    });

                    var requestOptions = {
                        method: 'POST',
                        body: raw,
                        redirect: 'follow'
                    };

                    CustomConsole(UPDATEPROFILE + "\n" + raw);

                    setLoading(true);
                    fetch(UPDATEPROFILE, requestOptions)
                        .then(response => response.json())
                        .then((json) => {

                            CustomConsole(json);
                            if (json.success == 1) {
                                // alert("Saved Successfully.")
                                saveSession(FIRST_NAME, json.data.firstname);
                                saveSession(LAST_NAME, json.data.lastname);
                                saveSession(PHONE, json.data.telephone);
                                saveSession(EMAIL, json.data.email);

                                navigation.goBack();

                                setLoading(false);
                            } else {
                                setLoading(false);
                                if (json.error.length > 0) {
                                    // alert(json.error[0]);
                                    showAlert(json.error[0]);
                                }
                            }

                        })
                        .catch((error) => {
                            setLoading(false);
                            CustomConsole("Error=>" + error);
                        });
                } catch (e) {
                    setLoading(false);
                    CustomConsole("Exception=>" + e + "");
                }

            }
        } catch (error) {
            CustomConsole("update userinfo Exception=>" + error + "");
        }
    }

    const WIGGLE_ROOM = 128;

    const offset = useMemo(() => {
        return {
            top: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2,
            left: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2
        };
    }, []);

    return (
        <View>
            <StatusBar barStyle="dark-content" />
            <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent(cartLength, navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View style={styles.updateUserInfoMainView} dataSet={{ media: ids.updateUserInfoMainView }}>

                        <View style={styles.updateUserInfoSubView} dataSet={{ media: ids.updateUserInfoSubView }}>
                            <View style={styles.updateUserInfoSubSubView} dataSet={{ media: ids.updateUserInfoSubSubView }}>
                                {/* first name view */}
                                <Text style={styles.textInputTitle}>First Name</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    value={first_name}
                                    onChangeText={txt => { setFirstName(txt); setFirstNameError(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter First Name" />
                                {firstnameError ? <Text style={styles.textInputError}>Enter first name</Text> : null}
                            </View>
                            <View style={styles.updateUserInfoSubSubView} dataSet={{ media: ids.updateUserInfoSubSubView }}>
                                {/* last name view */}
                                <Text style={styles.textInputTitle}>Last Name</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    value={last_name}
                                    onChangeText={txt => { setLastName(txt); setLastNameError(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter Last Name" />
                                {lastnameError ? <Text style={styles.textInputError}>Enter last name</Text> : null}
                            </View>
                        </View>

                        <View style={styles.updateUserInfoSubView} dataSet={{ media: ids.updateUserInfoSubView }}>
                            <View style={styles.updateUserInfoSubSubView} dataSet={{ media: ids.updateUserInfoSubSubView }}>
                                {/* email address view */}
                                <Text style={styles.textInputTitle}>Email ID</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    value={email}
                                    onChangeText={txt => { setEmail(txt); setEmailError(false); setInvalidEmailError(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter Email address" />
                                {emailError ? <Text style={styles.textInputError}>Enter email address</Text> : null}
                                {invalidEmailError ? <Text style={styles.textInputError}>Invalid email address</Text> : null}
                            </View>
                            <View style={styles.updateUserInfoSubSubView} dataSet={{ media: ids.updateUserInfoSubSubView }}>
                                {/* phone no view */}
                                <Text style={styles.textInputTitle}>Phone No</Text>
                                <TextInput
                                    style={styles.loginTextInput}
                                    value={phoneNo}
                                    onChangeText={txt => { setPhoneNo(txt.replace(/[^0-9]/g, '')); setPhoneNoError(false); setPhoneNoDigitsError(false); setPhoneNoOnlyDigitError(false); }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter Phone No"
                                    keyboardType='number-pad'
                                    maxLength={10} />
                                {phoneNoError ? <Text style={styles.textInputError}>Enter phone no</Text> : null}
                                {phoneNoDigitsError ? <Text style={styles.textInputError}>Enter 10-digit phone no</Text> : null}
                                {phoneOnlyDigitError ? <Text style={styles.textInputError}>Phone no should be only digits</Text> : null}
                            </View>
                        </View>

                        <View style={styles.updateUserInfoSaveChangesButtonView}>
                            {/* sign in button view */}
                            <Pressable style={styles.updateUserInfoSaveChanges} onPress={updateUserDetails}>
                                <Text style={styles.loginText}>Save Changes</Text>
                            </Pressable>
                        </View>

                    </View>
                    {/* end of main view */}

                    {/* common modal */}
                    <Modal
                        onRequestClose={hideAlert}
                        transparent
                        visible={alertShow}
                        animationType={'slide'}
                    >
                        <View style={styles.commonModalMainView} dataSet={{ media: ids.commonModalMainView }}>
                            <View style={[styles.commonModalViewContainer, offset]} dataSet={{media:ids.commonModalViewContainer}}>

                                <View>
                                    <Text style={styles.commonModalText}>{alertMsg}</Text>
                                </View>
                                <Pressable
                                    onPress={hideAlert}
                                    style={styles.commonModalButton}>
                                    <Text style={styles.commonModalButtonText}>Ok, Got it</Text>
                                </Pressable>
                            </View>
                        </View>
                    </Modal>
                    {/* end of common modal */}

                    {/* Footer view */}
                    {/* {FooterComponent(navigation, userId)}*/}
                    {/* end of Footer view */}

                </View>


            </ScrollView>
        </View>
    );
};

export default UpdateUserInfoScreen;
