import React, { useMemo, useState } from 'react';
import { Dimensions, Linking, ScrollView, } from 'react-native';
import { Text, View, Image, Pressable, Modal as Modal1 } from 'react-native';
import { externalStyles } from './style.js';
import { colors } from './color.js';
import { Button, Dialog, Portal, PaperProvider, } from 'react-native-paper';
import { DELETEUSER, LOGOUT, PRODUCT } from './webUtils.js';
import { styles, ids } from '../common/responsive.js';
import { ADDRESS, ADDRESS_MAP, EMAIL, MOBILE } from './string.js';
import Modal from 'modal-enhanced-react-native-web';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookShareButton,
    GabIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TelegramShareButton,
    TumblrIcon,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WhatsappShareButton,
    WorkplaceIcon,
    XIcon,
} from "react-share";
import { getSession, PASSWORD, REFERRAL_CODE, USER_ID, clearAsyncStorage } from './LocalStorage.js';
import moment from 'moment';
Modal.setAppElement('#root');


// custom fonts
export const getDMSansBoldFont = () => {
    return "DMSans-Bold"
}
export const getDMSansExtraBoldFont = () => {
    return "DMSans-ExtraBold"
}
export const getDMSansLightFont = () => {
    return "DMSans-Light"
}
export const getDMSansRegularFont = () => {
    return "DMSans-Regular"
}
export const getDMSansMediumFont = () => {
    return "DMSans-Medium"
}
export const getDMSansSemiBoldFont = () => {
    return "DMSans-SemiBold"
}
export const getDMSansThinFont = () => {
    return "DMSans-Thin"
}
export const getRobotoSlabBlackFont = () => {
    return "RobotoSlab-Black"
}
export const getRobotoSlabExtraBoldFont = () => {
    return "RobotoSlab-ExtraBold"
}
export const getRobotoSlabLightFont = () => {
    return "RobotoSlab-Light"
}
export const getRobotoSlabMediumFont = () => {
    return "RobotoSlab-Medium"
}
export const getRobotoSlabSemiBoldFont = () => {
    return "RobotoSlab-SemiBold"
}
export const getRobotoSlabThinFont = () => {
    return "RobotoSlab-Thin"
}

//progress bar
export const CustomProgressBar = ({ visible }) => (
    <Modal1
        onRequestClose={null}
        transparent
        visible={visible}
    // animationType={'slide'}
    >
        <View style={styles.customProgressbarView}>
            <View style={[styles.customProgressbarSubView]}>
                <Image source={require("../assets/header/footer-logo.png")}
                    style={styles.customProgressbarLogo} />
                <Text style={styles.loadingText}>Loading....</Text>
            </View>
        </View>
    </Modal1>
);

//for console method
export const CustomConsole = (visible) => {
    console.log(visible);
}

// .00 digits
export const CustomDigits = (text) => {
    return parseFloat(text).toFixed(2);
}

//empty state
export const CustomEmptyState = ({ text }) => (
    <View style={externalStyles.emptyMainContainer}>
        <Text style={externalStyles.emptyText}>
            {text}
        </Text>
    </View>
);

//validate email method
export const validateEmail = (text) => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (reg.test(text) === false) {
        return false;
    }
    else {
        return true;
    }
}

export const RequestLogPrint = (url, formdata) => {
    // CustomConsole(url);
    // CustomConsole("REQUEST=>");
    for (const part of formdata.getParts()) {
        CustomConsole(part.fieldName + ":" + part.string);
    }
    // CustomConsole("RESPONSE=>");
}

// HeaderComponent view
export const HeaderComponent = (text, navigation, userId) => {

    const [referralCode, setReferralCode] = React.useState();
    const [shareShow, setShareShow] = React.useState(false);
    const [showModalVisible, setShowModalVisible] = React.useState(false);
    const [visible, setVisible] = useState(false);
    const [modalText, setModalText] = useState('');
    const [functionality, setFunctionality] = useState('');
    const [loading, setLoading] = useState('');

    const WIGGLE_ROOM = 128;

    const offset = useMemo(() => {
        return {
            top: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2,
            left: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2
        };
    }, []);

    const [alertShow, setAlertShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    // modal hide/show
    const showAlert = (text) => {
        setAlertShow(true);
        setAlertMsg(text)
    };
    const hideAlert = () => setAlertShow(false);

    const showDialog = (text, functionality) => {
        setModalText(text);
        setFunctionality(functionality);
        setVisible(true)
    };

    const hideDialog = () => setVisible(false);

    // delete user/logout api
    const deletUserOrLogoutApi = async (functionality) => {
        try {

            const user_id = await getSession(USER_ID);
            const email = await getSession(EMAIL);
            const password = await getSession(PASSWORD);

            var raw = JSON.stringify({
                "email": email,
                "password": password
            });

            var raw1 = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: functionality == "0" ? raw : raw1,
                redirect: 'follow'
            };

            CustomConsole(functionality == "0" ? DELETEUSER : LOGOUT);
            CustomConsole(requestOptions);

            setLoading(true);
            fetch(functionality == "0" ? DELETEUSER : LOGOUT, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    setLoading(false);
                    CustomConsole(json);
                    if (json.success == 1) {
                        // alert("Success");
                        hideDialog();
                        clearAsyncStorage();
                        navigation.navigate('LoginScreen');
                        navigation.reset({
                            index: 0,
                            routes: [{ name: 'LoginScreen' }],
                        });
                    } else {
                        if (json.error.length > 0) {
                            showAlert(json.error[0]);
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Error=>" + error);
                });

        } catch (e) {
            CustomConsole("Delete user/Logout Exception=>" + e + "");
        }
    }

    // show modal 
    const showModal = () => {
        setShowModalVisible(true)
    };

    const hideModal = () => setShowModalVisible(false);

    // common navigation method
    const onClickOfMenu = (screenName) => {
        hideModal();
        navigation.navigate(screenName);
    }

    return (
        <PaperProvider>

            <View style={styles.headerMainStyle} dataSet={{ media: ids.headerMainStyle }}>

                {/* product view */}
                <Pressable onPress={() => navigation.navigate("ProductListScreen")}
                    style={styles.headerProductView} dataSet={{ media: ids.headerProductView }}>
                    <Text style={styles.headerOurProductText} dataSet={{ media: ids.headerOurProductText }}>Our Products</Text>
                    <Image source={require("../assets/header/down_arrow.png")} style={styles.headerOurProductIcon} />
                </Pressable>

                {/* icons view */}
                <View style={styles.topIconView} dataSet={{ media: ids.topIconView }}>

                    <Pressable onPress={() => {
                        if (userId == null || userId == "") {
                            navigation.navigate("RegisterScreen")
                        } else {
                            navigation.navigate("UpdateUserInfoScreen")
                        }
                    }} style={styles.topIconSubView} dataSet={{ media: ids.topIconSubView }}>
                        <Image source={require("../assets/header/manas.png")}
                            style={styles.topIcon} />
                    </Pressable>

                    <Pressable onPress={() => {
                        if (userId == null || userId == "") {
                            // navigation.navigate("RegisterScreen")
                            navigation.navigate('LoginScreen');
                        } else {
                            navigation.navigate("WalletScreen")
                        }
                    }} style={styles.topIconSubView} dataSet={{ media: ids.topIconSubView }}>
                        <Image source={require("../assets/header/pakit.png")}
                            style={styles.topIcon} />
                    </Pressable>

                    <Pressable onPress={() => {
                        if (userId == null || userId == "") {
                            navigation.navigate("RegisterScreen")
                        } else {
                            navigation.navigate("CartScreen")
                        }
                    }} style={styles.topIconSubView} dataSet={{ media: ids.topIconSubView }}>
                        <Image source={require("../assets/header/cart.png")}
                            style={styles.topIcon} />
                        <View style={styles.cartTextView}>
                            <Text style={styles.cartText}>{(userId == null || userId == "") ? "0" : text}</Text>
                        </View>
                    </Pressable>


                    <Pressable onPress={() => {
                        if (userId == null || userId == "") {
                            navigation.navigate("RegisterScreen")
                        } else {
                            showModal();
                        }
                    }} style={styles.topMenuIconView} dataSet={{ media: ids.topMenuIconView }}>
                        <Image source={require("../assets/header/lita-box.png")}
                            style={styles.topMenuIcon} />
                    </Pressable>

                </View>
            </View>

            <View style={styles.logoMainView}>
                <Pressable onPress={() => navigation.navigate("DashBoardScreen")}
                    style={styles.logoSubView} dataSet={{ media: ids.logoSubView }}>
                    <Image source={require("../assets/header/footer-logo.png")}
                        style={styles.logoImage} />
                </Pressable>
            </View>

            <Modal
                isVisible={showModalVisible}
                animationIn="slideInRight"
                animationOut="slideOutRight"
                onDismiss={() => {
                    hideModal();
                }}
                onBackdropPress={() => hideModal()}
                style={styles.drawerMainView}>
                <View style={styles.drawerSubView} dataSet={{ media: ids.drawerSubView }}>
                    <View style={styles.drawerSubSubView} dataSet={{ media: ids.drawerSubSubView }}>
                        <View>
                            <Pressable onPress={() => {
                                onClickOfMenu("DashBoardScreen")
                            }} style={styles.headerModalLinkTextView} dataSet={{ media: ids.headerModalLinkTextView }}>
                                <Text style={styles.headerModalLinkText} dataSet={{ media: ids.headerModalLinkText }}>Home</Text>
                            </Pressable>
                            <View style={styles.headerModalLinkBorder} dataSet={{ media: ids.headerModalLinkBorder }} />
                            <Pressable onPress={async () => {
                                setShareShow(!shareShow);
                                const referral_code = await getSession(REFERRAL_CODE);
                                setReferralCode(referral_code);
                            }} style={styles.headerModalLinkTextView} dataSet={{ media: ids.headerModalLinkTextView }}>
                                <Text style={styles.headerModalLinkText} dataSet={{ media: ids.headerModalLinkText }}>Refer to friend</Text>
                            </Pressable>
                            {shareShow ? <View style={styles.referCodeShareView} dataSet={{ media: ids.referCodeShareView }}>
                                <View style={styles.productMultipleShareButton}>
                                    <EmailShareButton
                                        url={`Hi,\n\nhere is the referral code : ${referralCode} \n\n Press on: https://dairyfarm.alakmalak.ca/register-screen/${referralCode}`}
                                        subject={"Soothe Organics"}
                                        body="body"
                                        className="Demo__some-network__share-button"
                                    >
                                        <EmailIcon size={32} round />
                                    </EmailShareButton>
                                </View>
                                <View style={styles.productMultipleShareButton}>
                                    <WhatsappShareButton
                                        url={`Hi,\n\nhere is the referral code : ${referralCode} \n\n Press on: https://dairyfarm.alakmalak.ca/register-screen/${referralCode}`}
                                        title={"Soothe Organics"}
                                        separator=":: "
                                        className="Demo__some-network__share-button"
                                    >
                                        <WhatsappIcon size={32} round />
                                    </WhatsappShareButton>
                                </View>
                                <View style={styles.productMultipleShareButton}>
                                    <TwitterShareButton
                                        url={`Hi,\n\nhere is the referral code : ${referralCode} \n\n Press on: https://dairyfarm.alakmalak.ca/register-screen/${referralCode}`}
                                        title={"Soothe Organics"}
                                        className="Demo__some-network__share-button"
                                    >
                                        <XIcon size={32} round />
                                    </TwitterShareButton>
                                </View>
                                <View style={styles.productMultipleShareButton}>
                                    <TelegramShareButton
                                        url={`Hi,\n\nhere is the referral code : ${referralCode} \n\n Press on: https://dairyfarm.alakmalak.ca/register-screen/${referralCode}`}
                                        title={"Soothe Organics"}
                                        className="Demo__some-network__share-button"
                                    >
                                        <TelegramIcon size={32} round />
                                    </TelegramShareButton>
                                </View>
                            </View> : null}
                            <View style={styles.headerModalLinkBorder} dataSet={{ media: ids.headerModalLinkBorder }} />
                            <Pressable onPress={() => {
                                onClickOfMenu("UpdateUserInfoScreen")
                            }} style={styles.headerModalLinkTextView} dataSet={{ media: ids.headerModalLinkTextView }}>
                                <Text style={styles.headerModalLinkText} dataSet={{ media: ids.headerModalLinkText }}>Update Info</Text>
                            </Pressable>
                            <View style={styles.headerModalLinkBorder} dataSet={{ media: ids.headerModalLinkBorder }} />
                            <Pressable onPress={() => {
                                onClickOfMenu("OrderListScreen")
                            }} style={styles.headerModalLinkTextView} dataSet={{ media: ids.headerModalLinkTextView }}>
                                <Text style={styles.headerModalLinkText} dataSet={{ media: ids.headerModalLinkText }}>My Orders</Text>
                            </Pressable>
                            <View style={styles.headerModalLinkBorder} dataSet={{ media: ids.headerModalLinkBorder }} />
                            <Pressable onPress={() => {
                                onClickOfMenu("ShippingAddressScreen")
                            }} style={styles.headerModalLinkTextView} dataSet={{ media: ids.headerModalLinkTextView }}>
                                <Text style={styles.headerModalLinkText} dataSet={{ media: ids.headerModalLinkText }}>My Address</Text>
                            </Pressable>
                            <View style={styles.headerModalLinkBorder} dataSet={{ media: ids.headerModalLinkBorder }} />
                            <Pressable onPress={() => {
                                onClickOfMenu("SubscribeListScreen")
                            }} style={styles.headerModalLinkTextView} dataSet={{ media: ids.headerModalLinkTextView }}>
                                <Text style={styles.headerModalLinkText} dataSet={{ media: ids.headerModalLinkText }}>Subscriptions</Text>
                            </Pressable>
                            <View style={styles.headerModalLinkBorder} dataSet={{ media: ids.headerModalLinkBorder }} />
                            <Pressable onPress={() => {
                                hideModal();
                                navigation.navigate('ChangePasswordScreen', {
                                    paramFrom: "drawer"
                                });

                            }} style={styles.headerModalLinkTextView} dataSet={{ media: ids.headerModalLinkTextView }}>
                                <Text style={styles.headerModalLinkText} dataSet={{ media: ids.headerModalLinkText }}>Change Password</Text>
                            </Pressable>
                            <View style={styles.headerModalLinkBorder} dataSet={{ media: ids.headerModalLinkBorder }} />
                            <Pressable onPress={() => {
                                hideModal();
                                showDialog('Are you sure to logout?', "1")
                            }} style={styles.headerModalLinkTextView} dataSet={{ media: ids.headerModalLinkTextView }}>
                                <Text style={styles.headerModalLinkText} dataSet={{ media: ids.headerModalLinkText }}>Logout</Text>
                            </Pressable>
                            <View style={styles.headerModalLinkBorder} dataSet={{ media: ids.headerModalLinkBorder }} />
                        </View>
                        <View style={styles.drawerLogoMainView} dataSet={{ media: ids.drawerLogoMainView }}>
                            <Image source={require("../assets/header/footer-logo.png")}
                                style={styles.drawerLogo} dataSet={{ media: ids.drawerLogo }} />
                        </View>


                    </View>
                </View>
            </Modal>

            {/* <Portal>
                <Dialog visible={visible} onDismiss={hideDialog} style={[styles.myAccountDialogMainView, offset]}>
                    <Dialog.Title style={styles.myAccountDialogAlertText}>Alert</Dialog.Title>
                    <Dialog.Content>
                        <Text variant="bodyMedium" style={styles.myAccountDialogHeadingText}>{modalText}</Text>
                    </Dialog.Content>
                    <View style={styles.myAccountDialogButtonView}>
                        <Dialog.Actions>
                            <Button onPress={hideDialog} textColor={colors.white} style={styles.myAccountDialogButtonText}>No</Button>
                        </Dialog.Actions>
                        <Dialog.Actions>
                            <Button onPress={() => deletUserOrLogoutApi(functionality)} textColor={colors.white} style={styles.myAccountDialogButtonText}>Yes</Button>
                        </Dialog.Actions>
                    </View>
                </Dialog>
            </Portal> */}

            {/* logout modal */}
            <Modal1
                onRequestClose={hideDialog}
                transparent
                visible={visible}
                animationType={'slide'}
            >
                <View style={styles.commonModalMainView} dataSet={{ media: ids.commonModalMainView }}>
                    <View style={[styles.commonModalViewContainer, offset]} dataSet={{ media: ids.commonModalViewContainer }}>
                        <View>
                            <Text style={styles.commonModalText}>{modalText}</Text>
                        </View>
                        <View style={styles.logoutButtonsView} dataSet={{ media: ids.logoutButtons }}>
                            <Pressable
                                onPress={hideDialog}
                                style={styles.logoutNoButtonView}>
                                <Text style={styles.commonModalButtonText}>No</Text>
                            </Pressable>
                            <Pressable
                                onPress={() => deletUserOrLogoutApi(functionality)}
                                style={styles.commonModalButton}>
                                <Text style={styles.commonModalButtonText}>Yes</Text>
                            </Pressable>
                        </View>
                    </View>
                </View>
            </Modal1>
            {/* end of logout modal */}


            {/* common modal */}
            <Modal1
                onRequestClose={hideAlert}
                transparent
                visible={alertShow}
                animationType={'slide'}
            >
                <View style={styles.commonModalMainView} dataSet={{ media: ids.commonModalMainView }}>
                    <View style={[styles.commonModalViewContainer, offset]} dataSet={{ media: ids.commonModalViewContainer }}>
                        <View>
                            <Text style={styles.commonModalText}>{alertMsg}</Text>
                        </View>
                        <Pressable
                            onPress={hideAlert}
                            style={styles.commonModalButton}>
                            <Text style={styles.commonModalButtonText}>Ok, Got it</Text>
                        </Pressable>
                    </View>
                </View>
            </Modal1>
            {/* end of common modal */}

        </PaperProvider>
    );
}

// FooterComponent view
export const FooterComponent = (navigation, userId) => {

    const [productList, setProductList] = React.useState([]);
    const [selectSize, setSelectSize] = React.useState([]);
    const [loading, setLoading] = React.useState('');

    React.useEffect(() => {
        productListApi();
    }, [productList.length == 0])

    // product list api
    const productListApi = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(PRODUCT);

            // setLoading(true);
            fetch(PRODUCT, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    productList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {

                        let sizes_arr = [];

                        if (json.data[i].options.length != 0) {
                            for (let j = 0; j < json.data[i].options[0].product_option_value.length; j++) {
                                sizes_arr.push({
                                    "product_option_value_id": json.data[i].options[0].product_option_value[j].product_option_value_id,
                                    "option_value_id": json.data[i].options[0].product_option_value[j].option_value_id,
                                    "name": json.data[i].options[0].product_option_value[j].name,
                                    "image": json.data[i].options[0].product_option_value[j].image,
                                    "quantity": json.data[i].options[0].product_option_value[j].quantity,
                                    "subtract": json.data[i].options[0].product_option_value[j].subtract,
                                    "price": json.data[i].options[0].product_option_value[j].price,
                                    "price_prefix": json.data[i].options[0].product_option_value[j].price_prefix,
                                    "weight": json.data[i].options[0].product_option_value[j].weight,
                                    "weight_prefix": json.data[i].options[0].product_option_value[j].weight_prefix,
                                    "product_option_id": json.data[i].options[0].product_option_id
                                });
                            }
                            let selectedSize = selectSize;
                            selectedSize[i] = {
                                product_option_value_id: json.data[i].options[0].product_option_value[0].product_option_value_id,
                                name: json.data[i].options[0].product_option_value[0].name,
                                product_option_id: json.data[i].options[0].product_option_id
                            }
                            setSelectSize(selectedSize);
                        } else {
                            CustomConsole("Index" + i);
                            let selectedSize = selectSize;
                            selectedSize[i] = {
                                product_option_value_id: '',
                                name: '',
                                product_option_id: ''
                            }
                            setSelectSize(selectedSize);
                        }

                        productList.push({
                            index: i,
                            product_id: json.data[i].product_id,
                            name: json.data[i].name,
                            is_wallet: json.data[i].is_wallet == undefined ? 0 : json.data[i].is_wallet,
                            description: json.data[i].description.split("</p>")[0].slice(0, 120),
                            price: json.data[i].price,
                            image: json.data[i].image != "https://dairyfarm.alakmalak.ca/image/" ? { uri: json.data[i].image } : require("../assets/header/footer-logo.png"),
                            weight: parseFloat(json.data[i].weight).toFixed(2),
                            rating: Math.floor(parseFloat(json.data[i].rating)),
                            sizes: sizes_arr
                        });
                    }

                    setProductList(productList);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Product Api in footer Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Product in footer Exception=>" + e + "");
        }
    }

    return (
        <View style={styles.footerMainView} dataSet={{ media: ids.footerMainView }}>
            <View style={styles.footerSubView} dataSet={{ media: ids.footerSubView }}>

                {/* view 1 */}
                <View style={styles.footerFirstSubView} dataSet={{ media: ids.footerFirstSubView }}>

                    <View style={styles.footerLogoView} dataSet={{ media: ids.footerLogoView }}>
                        <Image source={require("../assets/header/footer-logo.png")}
                            style={styles.footerLogo} />
                    </View>

                    <View style={styles.footerGeneralInfoView} dataSet={{ media: ids.footerGeneralInfoView }}>
                        <Pressable style={styles.footerGeneralInfoSubView} dataSet={{ media: ids.footerGeneralInfoSubView }} onPress={() => Linking.openURL(ADDRESS_MAP)}>
                            <View style={styles.footerGeneralInfoIconView} dataSet={{ media: ids.footerGeneralInfoIconView }}>
                                <Image source={require("../assets/footer/location.png")}
                                    style={styles.footerGeneralInfoIcon} />
                            </View>
                            <Text style={styles.footerGeneralInfoIconText} dataSet={{ media: ids.footerGeneralInfoIconText }}>{ADDRESS}</Text>
                        </Pressable>
                        <Pressable style={styles.footerGeneralInfoSubView} dataSet={{ media: ids.footerGeneralInfoSubView }} onPress={() => Linking.openURL(`tel:${MOBILE}`)}>
                            <View style={styles.footerGeneralInfoIconView} dataSet={{ media: ids.footerGeneralInfoIconView }}>
                                <Image source={require("../assets/footer/phone.png")}
                                    style={styles.footerGeneralInfoIcon} />
                            </View>
                            <Text style={styles.footerGeneralInfoIconText} dataSet={{ media: ids.footerGeneralInfoIconText }}>{"Call us:\n"}{MOBILE}</Text>
                        </Pressable>
                        <Pressable style={styles.footerGeneralInfoSubView} dataSet={{ media: ids.footerGeneralInfoSubView }} onPress={() => Linking.openURL(`mailto:${EMAIL}`)}>
                            <View style={styles.footerGeneralInfoIconView} dataSet={{ media: ids.footerGeneralInfoIconView }}>
                                <Image source={require("../assets/footer/email.png")}
                                    style={styles.footerGeneralInfoIcon} />
                            </View>
                            <Text style={styles.footerGeneralInfoIconText} dataSet={{ media: ids.footerGeneralInfoIconText }}>{"Email us:\n"}{EMAIL}</Text>
                        </Pressable>
                    </View>

                </View>

                {/* view 2 */}
                <View style={styles.footerSecondSubView} dataSet={{ media: ids.footerSecondSubView }}>
                    <View style={styles.footerSecondSection} dataSet={{ media: ids.footerSecondSection }}>
                        <Text style={styles.footerSectionTitle} dataSet={{ media: ids.footerSectionTitle }}>The Company</Text>
                        <Pressable onPress={() => navigation.navigate("DashBoardScreen")}>
                            <Text style={styles.footerSectionText} dataSet={{ media: ids.footerSectionText }}>Home</Text>
                        </Pressable>
                        <Pressable onPress={() => navigation.navigate("ProductListScreen")}>
                            <Text style={styles.footerSectionText} dataSet={{ media: ids.footerSectionText }}>Products</Text>
                        </Pressable>
                        <Pressable onPress={() => {
                            if (userId == null || userId == "") {
                                // navigation.navigate("RegisterScreen")
                                navigation.navigate('LoginScreen');
                            } else {
                                navigation.navigate("WalletScreen")
                            }
                        }}>
                            <Text style={styles.footerSectionText} dataSet={{ media: ids.footerSectionText }}>Wallets</Text>
                        </Pressable>
                        <Pressable onPress={() => navigation.navigate("ContactScreen")}>
                            <Text style={styles.footerSectionText} dataSet={{ media: ids.footerSectionText }}>Contact Us</Text>
                        </Pressable>
                    </View>
                    <View style={styles.footerSecondSectionWithMargin} dataSet={{ media: ids.footerSecondSectionWithMargin }}>
                        <Text style={styles.footerSectionTitle} dataSet={{ media: ids.footerSectionTitle }}>Our Products</Text>
                        <Pressable onPress={() => {
                            // history.pushState({urlPath:'/ProductDetailsScreen'},"",'/ProductDetailsScreen')
                            // window.history.pushState({ urlPath: '/ProductDetailsScreen' + '?paramId=' + productList[0].product_id + '&paramName=' + productList[0].name }, "", '/ProductDetailsScreen'+ '?paramId=' + productList[0].product_id + '&paramName=' + productList[0].name)

                            navigation.navigate("ProductDetailsScreen", { paramId: productList[0].product_id, paramName: productList[0].name, isWallet: productList[0].is_wallet })
                        }}>
                            <Text style={styles.footerSectionText} dataSet={{ media: ids.footerSectionText }} numberOfLines={1}>{productList.length >= 1 ? productList[0].name : ""}</Text>
                        </Pressable>
                        <Pressable onPress={() => navigation.navigate("ProductDetailsScreen", { paramId: productList[1].product_id, paramName: productList[1].name, isWallet: productList[1].is_wallet })}>
                            <Text style={styles.footerSectionText} dataSet={{ media: ids.footerSectionText }} numberOfLines={1}>{productList.length >= 2 ? productList[1].name : ""}</Text>
                        </Pressable>
                        <Pressable onPress={() => navigation.navigate("ProductDetailsScreen", { paramId: productList[2].product_id, paramName: productList[2].name, isWallet: productList[2].is_wallet })}>
                            <Text style={styles.footerSectionText} dataSet={{ media: ids.footerSectionText }} numberOfLines={1}>{productList.length >= 3 ? productList[2].name : ""}</Text>
                        </Pressable>
                        <Pressable onPress={() => navigation.navigate("ProductDetailsScreen", { paramId: productList[3].product_id, paramName: productList[3].name, isWallet: productList[3].is_wallet })}>
                            <Text style={styles.footerSectionText} dataSet={{ media: ids.footerSectionText }} numberOfLines={1}>{productList.length >= 4 ? productList[3].name : ""}</Text>
                        </Pressable>
                    </View>
                    <View style={styles.footerSecondSectionWithMargin} dataSet={{ media: ids.footerSecondSectionWithMargin }}>
                        <Text style={styles.footerSectionTitle} dataSet={{ media: ids.footerSectionTitle }}> Quick Links</Text>
                        <Pressable onPress={() => navigation.navigate("InquiryScreen")}>
                            <Text style={styles.footerSectionText} dataSet={{ media: ids.footerSectionText }}>Inquiry</Text>
                        </Pressable>
                        <Pressable onPress={() => navigation.navigate("CommonScreen", { paramInfoId: "2" })}>
                            <Text style={styles.footerSectionText} dataSet={{ media: ids.footerSectionText }}>Terms and Conditions</Text>
                        </Pressable>
                        <Pressable onPress={() => navigation.navigate("CommonScreen", { paramInfoId: "3" })}>
                            <Text style={styles.footerSectionText} dataSet={{ media: ids.footerSectionText }}>Privacy Policy</Text>
                        </Pressable>
                        <Pressable onPress={() => navigation.navigate("CommonScreen", { paramInfoId: "6" })}>
                            <Text style={styles.footerSectionText} dataSet={{ media: ids.footerSectionText }}>Refund Policy</Text>
                        </Pressable>
                    </View>
                    <View style={styles.footerSecondSectionWithoutBorder} dataSet={{ media: ids.footerSecondSectionWithoutBorder }}>
                        <Text style={styles.footerSectionTitle} dataSet={{ media: ids.footerSectionTitle }}>Follow Us</Text>
                        <Pressable onPress={() => Linking.openURL("https://www.facebook.com/")}
                            style={styles.footerSocialIconView}>
                            <Image source={require("../assets/footer/facebook.png")}
                                style={styles.footerSocialIcon} />
                            <Text style={styles.footerSocialText}>Facebook</Text>
                        </Pressable>
                        <Pressable onPress={() => Linking.openURL("https://www.instagram.com/")}
                            style={styles.footerSocialIconView}>
                            <Image source={require("../assets/footer/instagram.png")}
                                style={styles.footerSocialIcon} />
                            <Text style={styles.footerSocialText}>Instagram</Text>
                        </Pressable>
                        <Pressable onPress={() => Linking.openURL("https://twitter.com/i/flow/login")}
                            style={styles.footerSocialIconView}>
                            <Image source={require("../assets/footer/twitter.png")}
                                style={styles.footerSocialIcon} />
                            <Text style={styles.footerSocialText}>Twitter</Text>
                        </Pressable>
                        <Pressable onPress={() => Linking.openURL("https://www.youtube.com/")}
                            style={styles.footerSocialIconView}>
                            <Image source={require("../assets/footer/youtube.png")}
                                style={styles.footerSocialIcon} />
                            <Text style={styles.footerSocialText}>YouTube</Text>
                        </Pressable>
                    </View>
                </View>

            </View>

            {/* copy right text */}
            <View style={styles.copyRightView} dataSet={{ media: ids.copyRightView }}>
                <Text style={styles.copyRightText}>© 2023, All Rights Reserved.</Text>
                <Text style={styles.copyRightText}>Developed by Alakmalak Technologies</Text>
            </View>

        </View>
    );
}

export const formatDate = (date) => {
    const datefor = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
    const month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1);
    const year = date.getFullYear()

    return datefor + "-" + month + "-" + year;
}

export const formatDateYMD = (date) => {
    const datefor = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
    const month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : "0" + (date.getMonth() + 1);
    const year = date.getFullYear()

    return year + "-" + month + "-" + datefor;
}

export const scrollToTop = () => {
    window.scrollTo(0, 0);
}

export const formatDateDMY = (date) => {

    const date_string = moment(date, "YYYY-MM-DD").format('LL');
    return date_string;
}

export const CustomModal = ({ visible, text }) => {
    CustomConsole(visible);

    const WIGGLE_ROOM = 128;

    const offset = useMemo(() => {
        return {
            top: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2,
            left: Math.random() * WIGGLE_ROOM - WIGGLE_ROOM / 2
        };
    }, []);

    const [visible1, setVisible] = useState(visible);
    return (
        <Modal
            onRequestClose={null}
            transparent
            visible={visible1}
            animationType={'slide'}
        >
            <View style={styles.commonModalMainView} dataSet={{ media: ids.commonModalMainView }}>
                <View style={[styles.commonModalViewContainer, offset]}>

                    <View>
                        <Text style={styles.commonModalText}>{text}</Text>
                    </View>
                    <Pressable
                        onPress={() => { setVisible(!visible1) }}
                        style={styles.commonModalButton}>
                        <Text style={styles.commonModalButtonText}>Ok, Got it</Text>
                    </Pressable>
                </View>
            </View>
        </Modal>
    );
}
// const { ids, styles } = StyleSheet.create({

//     common_edittext: {
//         borderColor: 'gray',
//         borderRadius: 5,
//         borderWidth: 1,
//         paddingLeft: 10,
//         paddingRight: 10,
//         fontSize: 18,
//         paddingVertical: 10,
//     },
//     topLogoView: {
//         '@media only screen and (min-width: 480px) and (max-width: 639px)': {
//             marginTop: -30,
//         },
//         '@media only screen and (min-width: 320px) and (max-width: 479px)': {
//             marginTop: -30,
//         },
//     },
//     topIconView: {
//         '@media only screen and (min-width: 480px) and (max-width: 639px)': {
//             marginRight: 8,
//             marginLeft: 8,
//         },
//         '@media only screen and (min-width: 320px) and (max-width: 479px)': {
//             marginRight: 8,
//             marginLeft: 8,
//         },
//     },
//     topIcon1: {
//         '@media only screen and (min-width: 480px) and (max-width: 639px)': {
//             marginRight: 8,
//             marginLeft: 8,
//         },
//         '@media only screen and (min-width: 320px) and (max-width: 479px)': {
//             marginRight: 8,
//             marginLeft: 8,
//         },
//     },
//     headerMainStyle: {
//         '@media (max-width: 991px)': {
//             paddingRight: 15,
//             paddingLeft: 15,
//         },
//         '@media (max-width: 767px)': {
//             paddingRight: 15,
//             paddingLeft: 15,
//         },
//         '@media (max-width: 639px)': {
//             paddingRight: 15,
//             paddingLeft: 15,
//         },
//         '@media (max-width: 479px)': {
//             paddingRight: 15,
//             paddingLeft: 15,
//         },
//     },
//     democstyle: {
//         '@media (max-width: 1600px) and (min-width: 480px)': {

//         },
//         '@media (max-width: 639px)': {

//         },
//         '@media (max-width: 479px)': {
//             paddingRight: 15,
//             paddingLeft: 15,
//         },
//     },


//     withoutHoverViewStyle: {
//         left: -100,
//         padding: 25,
//         width: Dimensions.get("window").width / 5 - 20,
//         height: 450,
//         margin: 5,
//         borderWidth: 1,
//         borderRadius: 20,
//         borderColor: colors.soothBorder2,
//         backgroundColor: colors.soothBackground,
//         flexDirection: "column",
//         overflow: "hidden"
//     },
//     hoverViewStyle: {
//         left: -100,
//         padding: 25,
//         width: Dimensions.get("window").width / 5 + 100,
//         height: 450,
//         margin: 5,
//         borderWidth: 1,
//         borderRadius: 20,
//         borderColor: colors.soothBorder2,
//         backgroundColor: colors.buttonColor,
//         flexDirection: "column",
//         overflow: "hidden"
//     },
//     withoutHoverTextStyle: {
//         fontSize: 20,
//         color: colors.black,
//         fontFamily: getDMSansSemiBoldFont()
//     },
//     hoverTextStyle: {
//         fontSize: 20,
//         color: colors.white,
//         fontFamily: getDMSansSemiBoldFont()
//     },
//     withoutHoverImageStyle: {
//         height: 350,
//         width: 190,
//         resizeMode: "stretch",
//         position: "absolute",
//         bottom: -40,
//     },
//     hoverImageStyle: {
//         height: 350,
//         width: 190,
//         resizeMode: "stretch",
//         position: "absolute",
//         bottom: -130,

//     }


// });