import React, { useState } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, FlatList, ActivityIndicator } from 'react-native';
import { colors } from '../common/color';
import { APP_NAME } from '../common/string';
import { CustomConsole, HeaderComponent, FooterComponent, getDMSansBoldFont, getDMSansLightFont, validateEmail, getDMSansRegularFont, scrollToTop, CustomProgressBar, getDMSansSemiBoldFont, CustomDigits, formatDate } from '../common/utils';
import { CARTLIST, LOGIN, PRODUCT, SENDOTP, WALLETBALANCE, WALLETHISTORY } from '../common/webUtils';
import { EMAIL, FIRST_NAME, LAST_NAME, OTP_VERIFY, PASSWORD, PHONE, USER_ID, saveSession, getSession } from '../common/LocalStorage';
import { Image } from 'react-native';
import { useIsFocused } from '@react-navigation/native';
import { Divider } from 'react-native-paper';
import { styles, ids } from '../common/responsive.js';

function WalletScreen({ navigation }) {

    var onlyDigitReg = /^\d+$/;
    let page_var = 1;

    const [walletBalance, setWalletBalance] = useState(0);
    const [walletHistoryList, setWalletHistoryList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [cartLength, setcartLength] = React.useState('0');
    const [showTransactionHistory, setShowTransactionHistory] = React.useState(false);
    const [walletProductId, setWalletProductId] = useState('');
    const [walletProductName, setWalletProductName] = useState('');
    const [isWalletProduct, setIsWalletProduct] = useState('');
    const [showProductsInfo, setShowProductsInfo] = useState('');
    const [totalPages, setTotalPages] = useState([]);
    const [totalPagination, setTotalPagination] = useState('');
    const [currentPage, setCurrentPage] = useState('1');


    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            page_var = 1;
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            productListApi();
            cartListApi();
        } catch (error) {

        }
    }

    // cart list api
    const cartListApi = async () => {
        try {
            const user_id = await getSession(USER_ID);
            var raw = JSON.stringify({
                "customer_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                body: raw,
            };

            CustomConsole(CARTLIST);

            fetch(CARTLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setcartLength(json.data.cart.length);
                    CustomConsole("Cart length" + cartLength);
                    walletBalanceApi();
                })
                .catch((error) => {
                    CustomConsole("Cart Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Cart Exception=>" + e + "");
        }
    }

    // wallet api
    const walletBalanceApi = async () => {
        try {
            const user_id = await getSession(USER_ID);

            var requestOptions = {
                method: 'GET',
            };

            CustomConsole(WALLETBALANCE + user_id);

            fetch(WALLETBALANCE + user_id, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setWalletBalance(json.data.balance);
                    walletHistoryListApi(page);
                })
                .catch((error) => {
                    CustomConsole("Wallet Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Wallet Exception=>" + e + "");
        }
    }

    // product list api
    const productListApi = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(PRODUCT);

            fetch(PRODUCT, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    for (let i = 0; i < json.data.length; i++) {

                        if (json.data[i].is_wallet == 1) {
                            setWalletProductId(json.data[i].product_id);
                            setWalletProductName(json.data[i].name);
                            setIsWalletProduct(json.data[i].is_wallet);
                            break;
                        }

                        // productList.push({
                        //     index: i,
                        //     product_id: json.data[i].product_id,
                        //     name: json.data[i].name,
                        //     description: json.data[i].description,
                        //     price: json.data[i].price.substring(1).replace(",", ""),
                        //     currencySymbol: json.data[i].price.substring(0, 1),
                        //     image: json.data[i].image != "https://dairyfarm.alakmalak.ca/image/" ? { uri: json.data[i].image } : require("../assets/header/footer-logo.png"),
                        //     // image: json.data[i].image != "https://dairyfarm.alakmalak.ca/image/" ? { uri: "https://design.hire-webdeveloper.com/smooth-oraganic/v2/images/product-list-img-1.png" } : require("../assets/header/footer-logo.png"),
                        //     weight: parseFloat(json.data[i].weight).toFixed(2),
                        //     rating: Math.floor(parseFloat(json.data[i].rating)),
                        //     sizes: sizes_arr
                        // });
                    }

                })
                .catch((error) => {
                    CustomConsole("Product Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Product Exception=>" + e + "");
        }
    }

    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true); // To check if more data is available

    // wallet history list api
    const walletHistoryListApi = async (page) => {
        try {
            setCurrentPage(page);
            CustomConsole("page_no" + page);
            const user_id = await getSession(USER_ID);

            var requestOptions = {
                method: 'GET'
            };

            CustomConsole(WALLETHISTORY + user_id + "&page=" + page + "&limit=" + 50);

            setLoading(true);
            setWalletHistoryList([]);

            fetch(WALLETHISTORY + user_id + "&page=" + page + "&limit=" + 50, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    if (page == 1) {
                        totalPages.length = 0;

                        for (let j = 0; j < json.pagination.pages; j++) {
                            totalPages.push({
                                page: j + 1
                            });
                        }
                        setTotalPages(totalPages);

                        setTotalPagination(json.pagination.pages);
                    }

                    // if (page_var == 1) {
                    //     setWalletHistoryList([]);
                    // }

                    walletHistoryList.length = 0;

                    for (let i = 0; i < json.data.length; i++) {
                        CustomConsole("line_items==>");
                        CustomConsole(json.data[i].line_items[0]);

                        walletHistoryList.push({
                            index: i,
                            wallet_id: json.data[i].wallet_id,
                            customer_id: json.data[i].customer_id,
                            order_id: json.data[i].order_id,
                            type: json.data[i].type,
                            date: json.data[i].date,
                            amount: json.data[i].amount,
                            status: json.data[i].status,
                            zero_wallet: json.data[i].zero_wallet,
                            description: json.data[i].description,
                            line_items: json.data[i].line_items.length != 0 ? json.data[i].line_items[0] : []

                        });
                    }
                    setWalletHistoryList(walletHistoryList);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Wallet Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Wallet Exception=>" + e + "");
        }

    }

    // wallet history item view
    const walletHistoryItem = (item, index) => {
        return (
            <>
                <View style={styles.walletHistoryItemView}>
                    <View style={styles.walletHistoryItemSubView}>
                        <Text style={styles.walletHistoryDescription}>{item.description}</Text>
                        <Text style={styles.walletHistoryDate}>Date : {formatDate(new Date(item.date))}</Text>
                    </View>
                    <View>
                        <Text style={item.type == "C" ? styles.walletHistoryCreditText : styles.walletHistoryDebitText}>{item.type == "C" ? "+" : "-"} {CustomDigits(item.amount)}</Text>
                    </View>
                </View>

                {item.line_items.length != 0 ? <Pressable onPress={() => {
                    if (showProductsInfo == item.wallet_id) {
                        setShowProductsInfo('');
                    } else {
                        setShowProductsInfo(item.wallet_id);
                    }
                }}
                    style={styles.transactionHistoryTextButton} dataSet={{ media: ids.transactionHistoryTextButton }}>
                    <Text style={styles.transactionHistoryBlueText} dataSet={{ media: ids.transactionHistoryBlueText }} >Products Info</Text>
                    <Image style={styles.transactionHistoryDownArrow} dataSet={{ media: ids.transactionHistoryDownArrow }} source={showProductsInfo == item.wallet_id ? require("../assets/header/up_arrow.png") : require("../assets/header/down_arrow.png")} />
                </Pressable> : null}

                {showProductsInfo == item.wallet_id ?
                    <View>
                        {item.line_items.map((item1, index1) => {
                            // CustomConsole(item1)
                            return (
                                <View style={(item.line_items.length - 1) == index1 ? styles.orderItemProductDetailsViewWithoutBorder : styles.orderItemProductDetailsViewWithBorder}>
                                    <View style={styles.orderItemProductImageView}>
                                        <Image source={item1.image != "" ? { uri: item1.image } : require("../assets/header/footer-logo.png")} style={styles.orderItemProductImage} />
                                    </View>
                                    <View style={styles.orderItemProductDetailsSubView}>
                                        <Text style={styles.transactionHistoryItemProductName} numberOfLines={2}>{item1.name}</Text>
                                        <Text style={styles.transactionHistoryItemProductTotalQty}>Total Qty: <Text style={styles.transactionHistoryItemProductTotalQtyBold}>{item1.quantity}</Text></Text>
                                        <Text style={styles.transactionHistoryItemProductTotal}>Total: <Text style={styles.transactionHistoryItemProductTotalBold}>{item1.price}</Text></Text>
                                    </View>
                                </View>
                            );
                        })}
                    </View>
                    : null}
            </>
        );
    }

    // pagey item view
    const pageItem = (item, index) => {
        return (
            <>
                <Pressable onPress={() => walletHistoryListApi(item.page)}
                    style={styles.transactionHistoryPaginationView}>
                    <Text style={item.page != currentPage ? styles.transactionHistoryPaginationTextInactive : styles.transactionHistoryPaginationTextActive} numberOfLines={2}>{item.page}</Text>
                </Pressable>
            </>
        );
    }

    return (
        <View>
            <StatusBar barStyle="dark-content" />
            <ScrollView style={styles.container} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* {CustomConsole("isloading===> " + loading)} */}

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent(cartLength, navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View style={styles.walletScreenMainView} dataSet={{ media: ids.walletScreenMainView }}>

                        {!showTransactionHistory ?
                            <>
                                {/* wallet balance view */}
                                <View>
                                    <View style={styles.walletbalanceMainView} dataSet={{ media: ids.walletbalanceMainView }}>
                                        <Text style={styles.walletbalanceHeadingText}>{"Wallet Balance"}</Text>
                                        <Pressable onPress={() => setShowTransactionHistory(!showTransactionHistory)}>
                                            <Text style={styles.transactionHistoryText}>{"Transaction History"}</Text>
                                        </Pressable>
                                    </View>

                                    <View style={styles.walletBalanceView} dataSet={{ media: ids.walletBalanceView }}>
                                        <Text style={styles.walletBalanceText} dataSet={{ media: ids.walletBalanceText }}>Wallet  {CustomDigits(walletBalance)}</Text>
                                    </View>

                                    {walletProductId != '' ? <Pressable
                                        onPress={() => {
                                            // window.open("https://dairyfarm.alakmalak.ca/product-details-screen/"+walletProductId+"?paramName="+walletProductName, "_self");
                                            navigation.navigate("ProductDetailsScreen", { paramId: walletProductId, paramName: walletProductName, isWallet: isWalletProduct })
                                        }}
                                        style={styles.orderModifyButtonBtn} dataSet={{ media: ids.orderModifyButtonBtn }}>
                                        <Text style={styles.orderModifyButtonText} dataSet={{ media: ids.orderModifyButtonText }}>Add Balance</Text>
                                    </Pressable> : null}

                                </View>
                                {/* end of wallet balance view */}
                            </>
                            :
                            <>
                                {/* wallet history list view */}
                                <View>
                                    <View style={styles.transactionHistoryHeadingView}>
                                        <Pressable onPress={() => setShowTransactionHistory(!showTransactionHistory)}>
                                            <Image source={require("../assets/back_arrow.png")} style={styles.transactionHistoryBackArrow}
                                            />
                                        </Pressable>
                                        <Text style={styles.transactionHistoryTextView}>{"Transaction History"}</Text>
                                    </View>

                                    <View style={styles.transactionHistoryListView}>
                                        {walletHistoryList.length != 0 ?
                                            <FlatList
                                                data={walletHistoryList}
                                                renderItem={({ item, index }) => walletHistoryItem(item, index)}
                                                keyExtractor={(item, id) => String(id)}
                                                showsVerticalScrollIndicator={false}
                                                ItemSeparatorComponent={<View style={styles.transactionHistoryItemSeperator} />}
                                            // ListFooterComponent={loading ? CustomProgressBar(loading) : null}
                                            />

                                            : !loading ?
                                                <View style={styles.orderEmptyView}>
                                                    <Text style={styles.orderEmptyText}>Wallet History is not found</Text>
                                                </View> :
                                                // <View style={styles.transactionHistoryProgressbarView}>
                                                //     <View style={[styles.transactionHistoryProgressbarSubView]}>
                                                //         <Image source={require("../assets/header/footer-logo.png")}
                                                //             style={styles.customProgressbarLogo} />
                                                //         <Text style={styles.transactionHistoryloadingText}>Loading....</Text>
                                                //     </View>
                                                // </View>
                                                null}
                                    </View>

                                    <View style={styles.transactionHistoryPagination} dataSet={{ media: ids.transactionHistoryPagination }}>
                                        <FlatList
                                            data={totalPages}
                                            horizontal
                                            renderItem={({ item, index }) => pageItem(item, index)}
                                            showsHorizontalScrollIndicator={false}
                                        />
                                    </View>
                                </View>
                                {/* end of wallet history list view */}
                            </>}

                    </View>
                    {/* end of form view */}

                    {/* Footer view */}
                    {/* {FooterComponent(navigation, userId)}*/}
                    {/* end of Footer view */}

                </View>

            </ScrollView>
        </View>
    );
};

export default WalletScreen;
